import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withStyles, TextField,
        } from '@material-ui/core'
import {Autocomplete} from '@material-ui/lab'
import {DRIVER_TYPE} from '../../Constants'
import DriverIcon from '@material-ui/icons/Face'
import VehicleIcon from '@material-ui/icons/LocalShipping'

/**
 * Renvoie un objet contenant un label propre pour le conducteur ou le véhicule choisi
 * dépendance du choix de sélection
 * @param {string} type 
 * @param {string} fieldName 
 * @param {object} elt 
 * @returns {{value, label}}
 */
export function beautifyItem(type, fieldName, elt) {
    
    let value = ''
    let label = ''

    if (elt) {
        if (type === DRIVER_TYPE) {
                    
            switch (fieldName) {
                case 'drv_code':
                    value = elt.drv_code
                    label = `${elt.drv_code} (${elt.name} ${elt.forename})`
                    break
                case 'name':
                    value = elt.name
                    label = `${elt.name} (${elt.forename} ${elt.drv_code})`
                    break
                case 'forename':
                    value = elt.forename
                    label = `${elt.forename} (${elt.name} ${elt.drv_code})`
                    break
                case 'card_number':
                    value = elt.card_number
                    label = `${elt.card_number} (${elt.name} ${elt.forename} - ${elt.drv_code})`
                    break
                default :
                    value = elt.drv_code
                    label = `${elt.drv_code}`
                    console.error ('fieldName driver non implémenté', fieldName, elt)
                    break
            }
            // card_number: "SANS005F96A6ED" drv_code: "toto" forename: "prénom" id: 36 name: "Toto"

        } else {
                            
            switch (fieldName) {
                case 'veh_code' :
                    value = elt.veh_code
                    label = `${elt.veh_code} (${elt.veh_reg_no})`
                    break
                case 'veh_reg_no':
                    value = elt.veh_reg_no
                    label = `${elt.veh_reg_no} (${elt.veh_code})`
                    break;
                default :
                    value = elt.veh_code
                    label = elt.veh_code
                    console.error ('fieldName de vehicle non implémenté', fieldName, elt)
                    break
            }
            //veh_code: "BS782CW", veh_reg_no: "BS_782_CW", id: 10

        }
    }

    return {
        value: value,
        label: label,
    }
}
class AutoCompleteSelection extends Component {
    static SEPARATOR = '|'
    static propTypes = {
        type: PropTypes.string.isRequired,
        list: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        valueId : PropTypes.number,                /* id de la valeur présélectionnée */
    }
    state = {
        items: [],
        field: '',
        id: 0,
    }

    componentDidMount() {
        this.hydrate()
    }

    componentDidUpdate(prevProps, prevState) {
        if ( (prevProps.list !== this.props.list)
                || (prevProps.valueId !== this.props.valueId)) {
            this.hydrate()
        }
    }

    beautifyItems (type, list) {
        
        const fieldName = (type === DRIVER_TYPE) ? 
                                localStorage.getItem ('OW_Search_driverField') : 
                                localStorage.getItem('OW_Search_vehicleField')
    
        return list.map ( elt => {
    
            let key =  `${type}_${fieldName}_${elt.id}`
            
            const item = beautifyItem(type, fieldName, elt)
    
            return {
                key: key,
                id: elt.id,
                label: item.label,
                value: item.value
            }
    
        })
    }
    
    hydrate() {
        
        const fieldName = (this.props.type === DRIVER_TYPE) ? 
                                localStorage.getItem ('OW_Search_driverField') : 
                                localStorage.getItem('OW_Search_vehicleField')
        this.setState({
            items: this.beautifyItems(this.props.type, this.props.list),
            field: fieldName,
            id: this.props.valueId,
        })

    }

    onChange = (evt, value) => {

        if (value) {
            this.setState({
                id: value.id
            })
            this.props.onChange(Object.assign({
                type: this.props.type,
                id: 0,
            }, value))
        }
    }
    render() {

        const labelDriver = (<div className={this.props.classes.labelType}>
            <DriverIcon className={this.props.classes.labelIcon} /> <span>Conducteur</span>
        </div>)
        const labelVehicle = (<div className={this.props.classes.labelType}>
            <VehicleIcon  className={this.props.classes.labelIcon} /> <span>Véhicule</span>
        </div>)
        
        if (this.props.valueId) {
            return (
                <Autocomplete
                    key={`${this.props.type}_controlled`}
                    options={this.state.items}
                    getOptionLabel={option => option.label}
                    className={this.props.classes.root}
                    renderInput={(params) => <TextField key={`tf${this.props.type}`} {...params}
                        label={this.props.type === DRIVER_TYPE ? labelDriver : labelVehicle}
                        variant="outlined" />}
                    onChange={this.onChange}
                    value={this.state.items.find(elt => elt.id === this.state.id)}
                />
            )
        } else {
            return (
                <Autocomplete 
                    key={this.props.type}
                    options={this.state.items}
                    getOptionLabel={option => option.label}
                    className={this.props.classes.root}
                    renderInput={(params) => <TextField key={`tf${this.props.type}`} {...params} 
                                                    label={this.props.type === DRIVER_TYPE ?  labelDriver : labelVehicle}
                                                    variant="outlined" />}
                    onChange={this.onChange}
                    />
            )
        }
        
    }

}

const classes = {
    root: {
        minWidth: '20em'
    },
    labelType: {
        display: 'flex',
        alignItems: 'center',
    },
    labelIcon: {
        marginRight: '0.2em',
    }
}
export default withStyles(classes) (AutoCompleteSelection)
