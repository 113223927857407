import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Table, TableRow, TableBody, TableCell, TableHead } from '@material-ui/core';

/**
 * Inutile mais 
 * https://github.com/mui-org/material-ui-x/issues/1101
 */
class DataGridInPopper extends Component {
	static propTypes = {
		columns: PropTypes.arrayOf(PropTypes.object).isRequired,
		rows: PropTypes.array.isRequired,
	}

	render() {
		return (
			<Table className={this.props.classes.root}>
				<TableHead>
					<TableRow>
						{this.props.columns.map( (col, index) => 
							<TableCell key={`hcell_${index}`}>{col.headerName}</TableCell>
						)}
					</TableRow>
				</TableHead>
				<TableBody>
					{this.props.rows.map( (row, id) => 
						<TableRow key={`row_${id}`}>
							{this.props.columns.map( (col, indexC) =>
								<TableCell key={`r${id}_${indexC}`}>{row[col.field]}</TableCell>
							)}
						</TableRow>
					)}
				</TableBody>
			</Table>
		)
	}
}

const classes = {
	root: {  
		border: '1px solid #e0e0e0',
		borderCollapse: 'separate',
		borderRadius: '4px',
	},
	
}
export default withStyles(classes) (DataGridInPopper)
