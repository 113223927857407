import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withStyles, TextField} from '@material-ui/core'

class NumberInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number,
    id: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    disabled: PropTypes.bool,
  }

  state = {
      value: '',
      update: false,
  }

  componentDidMount() {
    let initial = (typeof(this.props.value) === 'number') ? this.props.value : 0
    this.setState({
      value: initial
    })
  }

  componentDidUpdate(prevProps, prevState) {

    /*
     * propagation du changement de valeur en provenance du parent
     */
    if (this.props.value !== this.state.value) {
      this.setState({
        value: this.props.value,
        update: true,
      })
    }
      /*
       * mise à jour effectuée, donc à propager
       */
      if ( (prevState.value !== '') && (prevState.value !== this.state.value)) {
        
        if (this.state.update) {
          this.setState({
            update: false,
          })
        } else {
          this.props.onChange({
              name: this.props.name,
              value: this.state.value,
          })
        }
      }
 
  }
  handleChange = (evt) => {
      
    let value = Number.parseInt(evt.target.value)

    /*
     * Contrôle type
     */
    if (Number.isNaN(value)) {
      return
    }

    /*
     * contrôle des bornes de la définition
     */
    if (typeof(this.props.min) === 'number' && 
            (value < this.props.min)
    ) {
      value = this.props.min
    } else if (typeof(this.props.max) === 'number' &&
            (value > this.props.max) 
    ) {
      value = this.props.max
    }

    this.setState({
      value: value,
    })

  }
  render() {
    return (
        <TextField id={this.props.id}
            name={this.props.name}
            label={this.props.label}
            type="number"
            onChange={this.handleChange}
            value={this.state.value}
            className={this.props.classes.root}
            disabled={this.props.disabled}
        />
    )
  }
}

const classes = ({
  root: {
    maxWidth:'12em',
    marginLeft: '0.5em',
    marginRight: '0.5em',
  }
})
export default withStyles(classes) (NumberInput)
