import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import WaitBlock from '../window/WaitBlock'
import {withStyles,
        Card, CardContent, CardHeader, CardActions, Button,
        TextField, Switch, 
        } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import DriverIcon from '../icons/DriverIcon'
import {resetDataError} from '../../redux/actions/DataActions'
import {addDriver, verifyContractDriver} from '../../redux/actions/ElementsActions'
import DatePickerFr from '../forms/date/DatePickerFr'
import {startOfMonth} from 'date-fns'

class AddDriverForm extends Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
    }

    state = {
        driver: {
            nom: '',
            prenom: '',
            code: '',
            avecCarte: true,
            carte: '',
            debut: new Date(),           
            interimaire: false,
            naissance: null,
        },
        error: {
            nom: false,
            prenom: false,
            code: false,
            carte: false,
            debut: false,
        },
        autoCode: true,
        creating: false,
        verify: false,
    }

    componentDidMount() {
        this.props.resetDataError()
    }
    componentDidUpdate(prevProps, prevState) {

        /*
         * fermeture de la fenêtre si la création est terminée
         */
        if (this.state.creating && prevProps.loading && !this.props.loading ) {
            this.props.onClose()
        }

        /*
         * vérification de l'existence d'un contrat
         */
        if ( (this.state.driver.carte !== prevState.driver.carte)
                && (this.state.driver.carte.length === 16)
                && (this.state.driver.debut)) {
            this.setState({
                verify:true
            })
            this.props.verifyContractDriver(this.state.driver.carte, this.state.driver.debut)
        
        /*
         * cas = plus de vérification en cours
         */
        } else if (prevProps.loading && !this.props.loading && this.state.verify) {
            this.setState({
                verify: false,
            })
        }
    }

    onChange = (evt) => {
        const field = {}
        const fieldName= evt.target.name
        field[fieldName] = evt.target.value
        const driver = Object.assign({}, this.state.driver, field)

        /*
         * pas de remplissage du code si remplissage manuel
         */
        if (fieldName === 'code') {
            this.setState({
                autoCode: false,
            })
            this.checkCode(evt.target.value)
        
        /*
         * génération automatique du code
         */
        } else if (this.state.autoCode && fieldName === 'nom') {
            driver.code = evt.target.value.replaceAll(' ', '').substring(0, 10)
        }

        /*
         * sauvegardes
         */
        this.setState({
            driver: driver,
        })

    }

    onChangeBoolean = (evt) => {
        const driver = this.state.driver
        const fieldName = evt.target.name

        driver[fieldName] = evt.target.checked

        this.setState({
            driver: driver
        })
    }
    onChangeDate = (field, newDate) => {

        const driver = this.state.driver
        driver[field] = newDate
        this.setState({
            driver: driver
        })
    }


    validate = () => {

        const driver = this.state.driver
        if (!driver.avecCarte) {
            driver.carte = 'SANS_' + Number.parseInt(Math.random() * 100000000000)
        }

        /*
         * vérifie si le code n'existe pas déjà
         */
        const existingCodeIndex = this.props.drivers.findIndex(drv => drv.drv_code === driver.code)

        const error = {
            nom: !driver.nom,
            prenom: !driver.prenom,
            code: !driver.code || existingCodeIndex !== -1,
            debut: !driver.debut,
            carte: driver.carte.length !== 16,
        }
        let isNotValid = false

        this.setState({
            error: error
        })

        for (const [key, value] of Object.entries(error)) {
            isNotValid = isNotValid || value
        }

        if (!isNotValid) {
            this.setState({
                creating: true,
            })
            this.props.addDriver(driver)
            
        } else {
            console.info('Invalid form', driver, error)
        }
    
    }

    checkCode = (code) => {
        
        const index = this.props.drivers.findIndex(drv => drv.drv_code === code)

        this.setState({
            error: Object.assign({}, this.state.error, {code: (index !== -1)})
        })
    }
    render() {
        
        const today = new Date()
        const titleCard = <span><DriverIcon />Nouveau conducteur</span>

        return (
            <>
            <Card className={this.props.classes.root}>
                <CardHeader title={titleCard} />
                <CardContent>                    
                { (this.props.loading || !this.state.driver || this.state.creating) ? 
                    <WaitBlock /> :
                    <>
                        <div className={this.props.classes.input}>
                            <span className={this.props.classes.label}>Nom</span> 
                            <TextField required 
                                    name='nom' 
                                    value={this.state.driver.nom} 
                                    error={this.state.error.nom}
                                    onChange={this.onChange}/>
                        </div>
                        <div className={this.props.classes.input}>
                            <span className={this.props.classes.label}>Prénom</span> 
                            <TextField required 
                                    name='prenom' 
                                    value={this.state.driver.prenom} 
                                    error={this.state.error.prenom}
                                    onChange={this.onChange}/>
                        </div>
                        <div className={this.props.classes.input}>
                            <span className={this.props.classes.label}>Code</span>
                            <TextField required 
                                    name='code' 
                                    value={this.state.driver.code} 
                                    error={this.state.error.code}
                                    helperText={this.state.error.code ? 'Le code existe déjà.' : ''}
                                    onChange={this.onChange}/>
                        </div>
                        <div className={this.props.classes.input} >
                            <span className={this.props.classes.label}>{this.state.driver.avecCarte ? 'Avec carte' : 'Sans carte'}</span>
                            <Switch name='avecCarte'
                                    checked={this.state.driver.avecCarte}
                                    onChange={this.onChangeBoolean} />
                        </div>
                        {this.state.driver.avecCarte && 
                            <div className={this.props.classes.input}>
                                <span className={this.props.classes.label}>Numéro de carte</span>
                                <TextField required 
                                        name='carte' 
                                        value={this.state.driver.carte} 
                                        error={this.state.error.carte}
                                        label='16 caractères'
                                        onChange={this.onChange}
                                        inputProps={{maxLength: 16, minLength: 16}}/>
                            </div>
                        }
                        <div className={this.props.classes.input}>
                            <span className={this.props.classes.label}>Date de naissance</span>
                            <DatePickerFr 
                                    name='naissance'
                                    value={this.state.driver.naissance}
                                    error={this.state.error.naissance}
                                    disableFuture
                                    onChange={ (value) => this.onChangeDate('naissance', value)}/>
                        </div>
                        <div className={this.props.classes.input} >
                            <span className={this.props.classes.label}>{this.state.driver.interimaire ? 'Est intérimaire' : 'Avec contrat'}</span>
                            <Switch name='interimaire'
                                    checked={this.state.driver.interimaire}
                                    onChange={this.onChangeBoolean} />
                        </div>
                        {this.props.exist === 1 &&
                            <Alert severity="error">
                                Un contrat existe déjà, pour ce conducteur, à cette date !
                            </Alert>
                        }
                        <div className={this.props.classes.input}>
                            <span className={this.props.classes.label}>Début du contrat <em>Optimal Web</em></span>
                            <DatePickerFr
                                name='debut'
                                value={this.state.driver.debut}
                                onChange={value => this.onChangeDate('debut', value)}
                                minDate={startOfMonth(today)}
                                autoOk
                                />
                        </div>
                    </>
                }
                </CardContent>
                {!this.state.creating &&
                    <CardActions className={this.props.classes.cardActions}>
                        <>
                            <Button onClick={this.props.onClose}>Annuler</Button>
                            <Button onClick={this.validate} 
                                    disabled={this.state.verify || Boolean(this.props.exist)}
                                >
                                {this.state.verify ? 'Vérifications en cours...' : 'Créer'}
                            </Button>
                        </>
                    </CardActions>
                }                
            </Card>
            </>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.data.loading,
    exist: state.data.error,
    actualUser: state.identity.user,
    drivers: state.client.drivers,

})
const mapDispatchToProps = ({
    resetDataError,
    addDriver,
    verifyContractDriver,
})

const classes = {
    root: {
        minWidth: '25em',
    },
    input: {
        lineHeight: '2rem',
    },
    label: {
        display: 'inline-block',
        width: '11em',
    },
    labelIdent: {
        width: '19em',
        display: 'inline-block',
        
    },
    cardActions: {
        justifyContent: 'space-around',
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (AddDriverForm))
