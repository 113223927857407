import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withStyles,
        Card, CardHeader, CardContent, CardActions,
        TextField, MenuItem,
        Button,
        } from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import DateTimePicker from '../forms/date/DateTimePickerFr'
import {ZONE_PUBLIQUE, ZONE_PRIVEE, DATE_INFINIE} from '../Constants'
import { connect } from 'react-redux';
import {endOfMonth} from 'date-fns'

class EditVehicleContractForm extends Component {
  static propTypes = {
    contract: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    modeAdd: PropTypes.bool,
    firstDayForNewContract: PropTypes.objectOf(Date)
  }

  state = {
      contract: null,
      overlap: false,
  }

  updateZone = (contract) => {
    
    switch (contract.type_zone) {

        case 'publique':
            contract.type_zone = ZONE_PUBLIQUE
            break

        case 'privee':
            contract.type_zone = ZONE_PRIVEE
            break

        default:
    }

    return contract
    
  }
  componentDidMount() {
      this.initValues()
  }

  initValues = () => {
    let contract

    if (this.props.modeAdd) {
        contract = {
            debut: this.props.firstDayForNewContract,
            fin: null,
            type_zone: ZONE_PUBLIQUE,
        }
    } else {
        contract = this.updateZone(Object.assign({}, this.props.contract))
    }
    this.setState({
        contract: contract,
    })  
    
  }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.modeAdd !== this.props.modeAdd) {
            this.initValues()
        }
    }
  onChangeZone = (evt) => {
    const contract = this.state.contract
    contract.type_zone = evt.target.value
    this.setState({
        contract: contract
    })
  }

  checkContractsOverlap = () => {
    let overlap = false
    
    this.props.contracts.forEach(aContract => {
        if ( (this.state.contract.id_utilisation_vehicule !== aContract.id_utilisation_vehicule) /* pas de comparaison pour le même contrat */
                && (
                    ( (aContract.fin !== null)
                        && (this.state.contract.debut <= aContract.fin)
                        && (this.state.contract.fin >= aContract.debut)
                    ) 
                    || ( (aContract.fin === null)
                        && (this.state.contract.fin >= aContract.debut)
                        )
                    || ( (this.state.contract.fin === null) 
                        && (this.state.contract.debut <= aContract.fin)
                    )
                    || ( (this.state.contract.fin === null)
                        && (aContract.fin === null)
                    )
                )
                 
        ) {
            overlap = true
        }
    })
    this.setState({
        overlap: overlap,
    })
  }
  onChangeDate = (field, newDate) => {
      const contract = this.state.contract
      contract[field] = newDate
      this.setState({
          contract: contract
      })
      this.checkContractsOverlap()
  }

  reset = () => {
      this.initValues()
  }

  validate = () => {
    this.props.onClose(this.state.contract)
  }
  render() {
    return (
      <Card className={this.props.classes.root}>
          <CardHeader title={this.props.modeAdd ? "Création d'un contrat" : "Édition d'un contrat"} />
          <CardContent>
            {this.state.contract && <>
                {this.state.overlap &&
                    <Alert severity='warning'>Un véhicule ne peut être géré par deux entreprises en même temps !</Alert>
                }
                <div>
                    <span className={this.props.classes.label}>Début du contrat</span>
                    <DateTimePicker 
                        value={this.state.contract.debut} 
                        onChange={value => this.onChangeDate('debut', value)}
                        autoOk
                        minDate={this.props.supportAthmo ? this.props.management.begin : this.props.firstDayForNewContract}
                        maxDate={this.state.contract.fin ? this.state.contract.fin : DATE_INFINIE}
                        
                        />

                </div>
                <div>
                    <span className={this.props.classes.label}>Fin du contrat</span>
                    <DateTimePicker 
                        value={this.state.contract.fin} 
                        initialFocusedDate={endOfMonth(new Date())}
                        onChange={value => this.onChangeDate('fin', value)}
                        autoOk
                        minDate={this.state.contract.debut}
                        maxDate={DATE_INFINIE}
                        />
                    <Button 
                        title='Pas de fin de contrat précisée.'
                        onClick={() => this.onChangeDate('fin', null)}>&infin;</Button>
                </div>
                <div>
                    <span className={this.props.classes.label}>Zone d'usage</span>
                    <TextField select
                            value={this.state.contract.type_zone}
                            onChange={this.onChangeZone}>
                        <MenuItem value={ZONE_PUBLIQUE}>Zone publique</MenuItem>
                        <MenuItem value={ZONE_PRIVEE}>Zone privée</MenuItem>
                    </TextField>
                </div>
            </>}
          </CardContent>
          <CardActions className={this.props.classes.cardActions}>
            <Button onClick={() => this.props.onClose()}>Annuler</Button>
            <Button onClick={this.reset} >Réinitialiser</Button>
            <Button onClick={this.validate}
                    disabled={this.state.overlap} >
                Valider
            </Button>
          </CardActions>
      </Card>
    )
  }
}

const mapStateToProps = state => ({
    supportAthmo: state.identity.user.supportAthmo,
    management: state.client.informations.ow.limits,
    contracts: state.data.values.utilisations,
})

const classes = theme => ({
    root: {
        marginBlock: '1em',
    },
    label: {
        display: 'inline-block',
        minWidth: '10em',
    },
    cardActions: {
        justifyContent: 'space-around',
    }
})

export default connect(mapStateToProps) (withStyles(classes) (EditVehicleContractForm))
