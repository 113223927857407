import React, { Component } from 'react'
import LaptopIcon from '@material-ui/icons/Laptop'
class TerminalIcon extends Component {
  render() {
    return (
      <LaptopIcon />
    )
  }
}

export default TerminalIcon
