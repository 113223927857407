/**
 * les différents droits (statuts) pour un utilisateur
 * 
 **/
export const U_ADMINISTRATEUR_ATHMO =  'administrateurSmartit';
export const U_ADMINISTRATEUR_SOCIETE = 'administrateurSociete';
export const U_ADMINISTRATEUR = 'administrateur';
export const U_POUVOIR = 'utilisateurAvecPouvoir';
export const U_VISUALISEUR = 'utilisateurVisualiseur';
export const U_INACTIF = 'inactif'

/**
 * Droits de comptabilité
 */
export const U_COMPTABLE = 'comptable';
export const U_COMPTABLE_ATHMO = 'comptableAthmo';

/**
 * Droits ressources humaines
 */
export const U_RH = 'RH';

/**
 * Type de ressource traitée
 */
export const DRIVER_TYPE = 'driver'
export const VEHICLE_TYPE = 'vehicle'

/**
 * Motifs des activités sans carte
 */
export const OUBLICARTE = 'OUBLICARTE'
export const PERTECARTE = 'PERTECARTE'
export const PASDECARTE = 'PASDECARTE'
export const CARTEHS = 'CARTEHS'
export const PARC = 'PARC'
export const REPARATION = 'REPARATION'
export const GARAGE = 'GARAGE'
export const INCONNU = 'INCONNU'
export const CONTROL = 'CONTROL'
export const HS	 = 'HS'

/**
 * Rôles d'un utilisateur
 */ 
 export const ROLLS = [
    U_INACTIF,
    U_VISUALISEUR,
    U_POUVOIR,
    U_ADMINISTRATEUR,
    U_ADMINISTRATEUR_SOCIETE,
    U_ADMINISTRATEUR_ATHMO
]

export const ZONE_PUBLIQUE = 'ZONE_PUBLIQUE'
export const ZONE_PRIVEE = 'ZONE_PRIVEE'

export const BORNE_PC_PERSONNEL = 'PC personnel'
export const BORNE_OPTIMAL_WEB = 'BORNE_PC_PERSONNEL'

/**
 * Constantes de date
 */
export const DATE_INFINIE = new Date('2100-12-31T23:59:59.00Z')     /* @see constante php */
export const DATE_HEURE_LOCALE = 'dd/MM/yyyy HH:mm'                 /* usage ordinaire dans applications Athmo */

/**
 * Constantes des rapports d'activités
 */
 export const TYPES_RAPPORT = {
    RAPPORT_QUOTIDIEN: 'quotidien',
    RAPPORT_JOURNEE_TRAVAIL: 'mensuel_journee_travail',
    RAPPORT_MENSUEL: 'mensuel'
 }

 export const EVENT_TYPE = {
     ABSENCE: 'absence',
     WORK: 'work',

     /*
      * 2 types d'événements enregistrés
      */
     FAULT: 'fault',
     EVENT: 'event',
 }

/**
 * Mode d'affichage
 */
export const DISPLAY_TYPE = {
    HISTO: 'histogram',
    TAB: 'table',
}

/**
 * Motifs prédéfinis expliquant les modifications d'activités
 * les motifs en commentaire ne sont plus utilisés à partir de la version 3
 */
export const JUSTIFICATIONS = {
    N: "Aucun motif",
    
    // HEURE:  "Correction de l'heure",
    // 
    DSALE:  "Disque sale",
    MANIP:  "Erreur de manipulation",
    TACHY:  "Erreur du tachygraphe",
    SAIS:   "Mauvaise saisie",
    // ERSAI:  "Erreur de saisie",
    SANSK:  "Activité sans carte",
    ERRK:   "Mauvaise carte",
    RELEC:  "Relecture des disques",
    OUBLICARTE: "Carte oubliée",
    PERTECARTE: "Carte perdue",
    PASDECARTE: "Pas encore reçue la carte",
    CARTEHS:    "Carte ne fonctionne plus",
    // PARC:       "Déplacements sur le parc",
    // REPARATION: "Déplacements en réparation",
    // GARAGE:     "Déplacements au garage",
    // INCONNU:    "Carte inconnue",
    // CONTROL:    "Contrôle",
    HS:         "Lecteur hors service",
    CALIB:      "Recalibrage des heures",
    XX:         "Autre motif",
    // ZZ:         "Motif spécial",
}