import React, { Component } from 'react'
import {Typography} from '@material-ui/core'
export class InspectGrid extends Component {

    state = {
        width: window.innerWidth
    }
    componentDidMount () {
        if (process.env.NODE_ENV === 'development') {
            window.onresize = this.onResize
        }
    }
    onResize=(evt) => {
        this.setState({
            width: window.innerWidth
        })
    }
    render() {
        
        if ( (process.env.NODE_ENV === 'development') 
                || (process.env.REACT_APP_ENV === 'beta')
        ) {
           
            let breakPoint = ''
            if (this.state.width >= 1920) {
                breakPoint = 'xl'
            } else if (this.state.width >= 1280) {
                breakPoint = 'lg'
            } else if (this.state.width >= 960) {
                breakPoint = 'md'
            } else if (this.state.width >= 600) {
                breakPoint = 'sm'
            } else {
                breakPoint = 'xl'
            }

            return (
                <div>
                    <Typography 
                        align='center'
                        color='textSecondary'
                        display='block'
                        variant='caption'
                        >
                        {breakPoint}
                    </Typography>
                    <Typography 
                        align='center'
                        color='textSecondary'
                        display='block'
                        variant='caption'
                        >
                        {window.innerWidth} x {window.innerHeight}
                    </Typography>
                </div>
            )
        } else {
            return null
        }
    
  }
}

export default InspectGrid
