/**
 * Libellé correspond à un code d'erreur ou d'événement
 * @see Annexe_1B 2.70 (EventFaultType)
 * 
 * @param {Number} code 
 * @returns 
 */
export function getLabelEvent(code) {

	let label = '???';
	switch (code) {
		case 0:
			label = "absence d'informations complémentaires";
			break;
		case 1:
			label = "insertion d'une carte non valable";
			break;
		case 2:
			label = "conflit de carte";
			break;
		case 3:
			label = "chevauchement temporel";
			break;
		case 4:
			label = "conduite sans carte appropriée";
			break;
		case 5:
			label = "insertion de carte en cours de conduite";
			break;
		case 6:
			label = "dernière session incorrectement clôturée";
			break;
		case 7:
			label = "excès de vitesse";
			break;
		case 8:
			label = "interruption de l'alimentation électrique";
			break;
		case 9:
			label = "erreur sur les données de mouvement";
			break;
		case 10:
			label = "conflit concernant le mouvement du véhicule";
			break;
		case 11:
			label = "conflit temporel (GNSS contre horloge interne de la VU";
			break;

		/*
		 * Tentatives d'atteinte à la sécurité en rapport avec l'unité embarquée sur véhicule
		 */
		case 16:
			label = "défaut d'authentification du capteur de mouvement";
			break;
		case 17:
			label = "défaut d'authentification d'une carte tachygraphique";
			break;
		case 18:
			label = "remplacement sans autorisation du capteur de mouvement";
			break;
		case 19:
			label = "défaut d'intégrité affectant l'entrée de données sur la carte";
			break;
		case 20:
			label = "défaut d'intégrité affectant les données utilisateur mémorisées";
			break;
		case 21:
			label = "erreur de transfert de données internes";
			break;
		case 22:
			label = "ouverture illicite d'un boîtier (unité embarquée)";
			break;
		case 23:
			label = "sabotage du matériel 5 (unité embarquée)";
			break;

		/*
		 * tentatives d'atteinte à la sécurité en rapport avec le capteur de mouvement
		 */
		case 32:
			label = "atteinte à la sécurité en rapport avec le capteur de mouvement";
			break;
		case 33:
			label = "échec d'une authentification (capteur de mouvement)";
			break;
		case 34:
			label = "défaut d'intégrité affectant les données mémorisées (capteur de mouvement)";
			break;
		case 35:
			label = "erreur de transfert de données internes (capteur de mouvement)";
			break;
		case 36:
			label = "ouverture illicite d'un boîtier (capteur de mouvement)";
			break;
		case 37:
			label = "sabotage du matériel (capteur de mouvement)";
			break;

		/*
		 * anomalies affectant l'appareil de contrôle
		 */
		case 48:
			label = "anomalies affectant l'appareil de contrôle";
			break;
		case 49:
			label = "anomalie interne affectant la VU";
			break;
		case 50:
			label = "anomalie affectant l'imprimante";
			break;
		case 51:
			label = "anomalie affectant l'affichage";
			break;
		case 52:
			label = "anomalie affectant le téléchargement";
			break;
		case 53:
			label = "anomalie affectant le capteur de mouvement";
			break;

		case 64:
			label = "anomalies affectant une carte";
			break;

		/*
		 * événements liés au GNSS
		 */
		case 80:
			label = "événements liés au GNSS";
			break;
		case 81:
			label = "anomalie du récepteur du dispositif GNSS interne";
			break;
		case 82:
			label = "anomalie du récepteur du dispositif GNSS externe";
			break;
		case 83:
			label = "anomalie de communication du dispositif GNSS externe";
			break;
		case 84:
			label = "aucune donnée de position du dispositif GNSS";
			break;
		case 85:
			label = "détection de violation du dispositif GNSS";
			break;
		case 86:
			label = "expiration du certificat du dispositif GNSS externe";
			break;

		/*
		 * anomalies liées au module de communication à distance
		 */
		case 96:
			label = "anomalies liées au module de communication à distance";
			break;
		case 97:
			label = "anomalie sur le module de communication à distance";
			break;
		case 98:
			label = "anomalie de communication sur le module de communication à distance";
			break;

		case 112:
			label = "anomalies sur l'interface STI";
			break;

		default:
			label = `code inconnu: ${code}`
			break
	}

	return label;
};

/**
 * Objet du code erreur
 * @see Annexe 1C 2.69 (EventFaultRecordPurpose)
 * @param {Number} code 
 * @returns 
 */
export function getTypePurposeLabel (code) {

	let label = '???'

	switch (code) {

		case 0:
			label = "Un des 10 (derniers) événements ou anomalies les plus récents;"
			break

		case 1:
			label = "Événement le plus long survenu au cours de chacun des 10 derniers jours d'occurrence";
			break

		case 2:
			label = "Un des 5 événements les plus longs enregistrés au cours des 365 derniers jours";
			break

		case 3:
			label = "Dernier événement survenu au cours de chacun des 10 derniers jours d'occurrence";
			break

		case 4:
			label = "Événement le plus sérieux enregistré au cours de chacun des 10 derniers jours d'occurrence";
			break

		case 5:
			label = "Un des 5 événements les plus sérieux enregistrés au cours des 365 derniers jours";
			break

		case 6:
			label = "Premier événement ou anomalie survenu après le dernier étalonnage";
			break

		case 7:
			label = "Événement ou anomalie en cours"
			break

		default:
			label = `Objet inconnu: ${code}`
			break

	}

	return label

}