import React, { Component } from 'react'
import {Grid} from '@material-ui/core'
import ElementsAlertsParameters from './ElementsAlertsParameters'
import {DRIVER_TYPE, VEHICLE_TYPE} from '../Constants'

class AlertsParameters extends Component {

  state = {
    save : false,
  }
  render() {
    
    return (
      <Grid container 
        spacing={2} >
          <Grid item>
            <ElementsAlertsParameters 
                type={DRIVER_TYPE} />
          </Grid>
          <Grid item>
            <ElementsAlertsParameters 
                type={VEHICLE_TYPE} />
          </Grid>
      </Grid>
    )
  }
}

export default AlertsParameters
