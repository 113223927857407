import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core'
import DayActivitiesHistogram from './DayActivitiesHistogram';

class DailyActivitiesHistogram extends Component {

	static = {
		data: PropTypes.array.isRequired,
		magnification: PropTypes.number.isRequired,
		onActivityClick: PropTypes.func,							/* fonction à utiliser lors du clic sur une activité */
		onActivityRightClick: PropTypes.func, 				/* fonction assurant la mémorisation des changements d'une activité */
		onClickNewAct: PropTypes.func,								/* fonction assurant la gestion de l'affichage du formulaire de création d'une nouvelle activité */
	}

	render() {
		const heightSvg = 40 * this.props.magnification
	
		return (
			<svg
				width={`${1500 * this.props.magnification}`}
				height={heightSvg}
				viewBox={`0 0 ${1500 / this.props.magnification} ${heightSvg / this.props.magnification}`}
				style={this.props.classes.svg}
				>
					<DayActivitiesHistogram 
						data={this.props.data} 
						magnification={this.props.magnification}
						onClick={this.props.onActivityClick}
						onRightClick={this.props.onActivityRightClick}
						onClickNewAct={this.props.onClickNewAct}
						/>
			</svg>
		)
	}
}

const classes = ({
	root: {

	}
})
export default withStyles(classes) (DailyActivitiesHistogram)
