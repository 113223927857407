import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {Button, Icon, Tooltip} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'

/**
 * Bouton appliquant une route lors d'un clic
 * 
 * @param   {string}    to      route à appliquer
 * @param   {string}    icon    optionnel - nom de l'icône "material" à appliquer
 * @param   {string}    title   optionnel - Texte de l'info-bulle  
 * @param   {string}    label   optionnel - Texte à afficher au sein du bouton
 */
class ButtonRoute extends Component {
  static propTypes = {
    to : PropTypes.string.isRequired,
    icon : PropTypes.string,
    title : PropTypes.string,
    label : PropTypes.string
  }

    render() {
      
        const {to, icon, title, label, classes, ...rest} = this.props;

        if (title) {
            return (
                <Tooltip title={title} className={classes.root}>
                    <Link to={to}>
                        <Button {...rest}>
                            {icon &&
                                (<Icon>{icon}</Icon>)
                            }                
                            {label}
                        </Button>
                    </Link>
                </Tooltip>  
            )
        } else {
            return (
                <Link to={to} className={classes.root} >
                    <Button {...rest}>
                        {icon &&
                            (<Icon>{icon}</Icon>)
                        }          
                        {label}     
                    </Button>
                </Link>
            )
        }
    }
}

const styles = {
    root : {
        display : 'flex',
        justifyAlign : 'center'
    },
};

export default withStyles(styles) (ButtonRoute)
