import React, { Component } from 'react';
import {withRouter} from 'react-router'
import {withStyles, } from '@material-ui/core'
import LignesMenu from '../core/components/MenuModules/LignesMenu'
import LigneMenu from '../core/components/MenuModules/LigneMenu'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import PeopleIcon from '@material-ui/icons/People'
import SettingsIcon from '@material-ui/icons/Settings'
import PolicyIcon from '@material-ui/icons/Policy'
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard'
import VehicleIcon from '../core/components/icons/VehicleIcon'
import DriverIcon from '../core/components/icons/DriverIcon'
import UserIcon from '../core/components/icons/UserIcon'
import BusinessIcon from '@material-ui/icons/Business'
import TerminalIcon from '../core/components/icons/TerminalIcon'
import MenuModule from '../core/components/MenuModules/MenuModule'
import {U_ADMINISTRATEUR_SOCIETE, U_ADMINISTRATEUR,
        U_POUVOIR,
      } from '../core/components/Constants'

import {connect} from 'react-redux'
class MenuModules extends Component {

  state = {
    opened : ''
  }

  componentDidMount() {

    /*
     * Calcul de l'ouverture des menus
     */
    const elementsRoute = this.props.location.pathname.split ('/')
    
    if (elementsRoute.length >= 2) {
      this.setState ({
        opened : elementsRoute[1]
      })
    } else if (this.props.idClientEnCours === 0) {
      this.setState ({
        opened: 'entreprises'
      })
    }
  }

  /**
   * gestion de l'ouverture des modules
   * @param {string} target 
   */
  change = (target) => (event, isExpanded) => {
    this.setState ({opened: (isExpanded) ? target : '' })    
  }
  
  render() {
    
    return (
      <>
      {this.props.isConnected &&
        <div className={this.props.classes.root} >
              <MenuModule 
                  id="archivage"
                  icon={<CalendarTodayIcon />}
                  label="Archivage"
                  service='archivage'
                  expanded={this.state.opened === 'archivage'}
                  onChange={this.change('archivage')}
                  >
                  <LignesMenu className={this.props.classes.lignes}>
                    <LigneMenu to="/archivage/calendrierMensuel" service="archivage">Calendrier mensuel</LigneMenu>
                    <LigneMenu to="/archivage/calendrierAnnuel" service="archivage">Calendrier annuel</LigneMenu>
                    <LigneMenu to="/archivage/telechargements" service="archivage">Téléchargements</LigneMenu>
                    <LigneMenu to="/archivage/archives" service="archivage" minRoll={U_ADMINISTRATEUR} >Archives</LigneMenu>
                  </LignesMenu>
              </MenuModule>

              <MenuModule 
                  id="optigestweb"
                  icon={<DepartureBoardIcon />}
                  label="Activités"
                  service='optigestweb'
                  expanded={this.state.opened === 'optigestweb'}
                  onChange={this.change('optigestweb')}
                  >
                  <LignesMenu className={this.props.classes.lignes} >
                    <LigneMenu to="/optigestweb/activitesCartes" service="optigest web" >Cartes</LigneMenu>
                    <LigneMenu to="/optigestweb/activitesSansCarte" service="optigest web" >Sans carte</LigneMenu>          
                    <LigneMenu to="/optigestweb/utilisations" service="optigest web">Utilisations</LigneMenu>   
                    <LigneMenu to="/optigestweb/evenements" service="optigest web">Erreurs & événements</LigneMenu>
                    <LigneMenu to="/optigestweb/kilometrages" service="optigest web">Kilométrages</LigneMenu>
                    <LigneMenu to="/optigestweb/ticpe" service="optigest web">TICPE</LigneMenu>
                  </LignesMenu>
              </MenuModule>

              <MenuModule 
                  id="offenses"
                  icon={<PolicyIcon />}
                  label="Réglementation"
                  service='reglementation'
                  expanded={this.state.opened === 'offenses'}
                  onChange={this.change('offenses')}
                  >
                  <LignesMenu className={this.props.classes.lignes} >
                    <LigneMenu to="/offenses/search" service="reglementation"  minRoll={U_POUVOIR}>Infractions</LigneMenu>
                    <LigneMenu to="/offenses/parameters" service="reglementation"  minRoll={U_POUVOIR}>Paramètres</LigneMenu>
                  </LignesMenu>
                  
              </MenuModule>

              {/* <MenuModule 
                  id="rapports"
                  icon={<FolderIcon />}
                  label="Rapports"
                  service='archivage'
                  expanded={this.state.opened === 'rapports'}
                  onChange={this.change('rapports')}
                  >
                  @todo à implémenter
              </MenuModule> */}

              <MenuModule 
                  id="ressources"
                  icon={<PeopleIcon />}
                  label="Ressources"
                  service='*'
                  expanded={this.state.opened === 'ressources'}
                  onChange={this.change('ressources')}
                  >
                 <LignesMenu className={this.props.classes.lignes} >
                    <LigneMenu to="/resources/users" service="*" minRoll={U_ADMINISTRATEUR}><UserIcon />Utilisateurs</LigneMenu>
                    <LigneMenu to="/resources/drivers" service="*" minRoll={U_ADMINISTRATEUR} ><DriverIcon />Conducteurs</LigneMenu>
                    <LigneMenu to="/resources/vehicles" service="*" minRoll={U_ADMINISTRATEUR} ><VehicleIcon />Véhicules</LigneMenu>
                    <LigneMenu to="/resources/terminals" service="*" minRoll={U_ADMINISTRATEUR} ><TerminalIcon />Bornes</LigneMenu>
                  </LignesMenu>
              </MenuModule>

              <MenuModule 
                  id="parameters"
                  icon={<SettingsIcon />}
                  label="Paramètres"
                  service='*'
                  expanded={this.state.opened === 'parameters'}
                  onChange={this.change('parameters')}
                  >
                  <LignesMenu className={this.props.classes.lignes} >
                    <LigneMenu to="/parameters/personalSettings" service="*" >Personnel</LigneMenu>
                    <LigneMenu to='/parameters/companySettings' service="*" minRoll={U_ADMINISTRATEUR_SOCIETE} >Entreprise</LigneMenu>
                  </LignesMenu>

              </MenuModule>

              {this.props.clients?.length > 1 &&
                <MenuModule 
                    id="entreprises"
                    icon={<BusinessIcon />}
                    label="Entreprises"
                    service='*'
                    expanded={this.state.opened === 'entreprises'}
                    onChange={this.change('entreprises')}
                    >
                  <LignesMenu>
                    <LigneMenu to="/choixEntreprise" service="*">Autre entreprise</LigneMenu>
                  </LignesMenu>
                </MenuModule>
              }

        </div>
      }
      </>)
  }
}

const classes = theme => ({
  root : {
    color: theme.palette.primary.main,
    backgroundColor: '#aaaac7',
    minHeight: '91vh',
    maxWidth: '20vw',
    minWidth: '10vw',
    margin: 0,
  },    
  lignes: {
    overflow: 'hidden',
  },

})

const mapStateToProps = (state) => ({
  isConnected : state.identity.isConnected,
  role : state.identity.user?.role,
  idClientEnCours : state.identity.idClientEnCours,
  clients: state.identity.clients,
})
export default connect (mapStateToProps) (withRouter (withStyles (classes) (MenuModules)))
