import * as act from '../actions/DataActions'
import adaptDataToMonthCalendar from './calendarFunctions/adaptDataToMonthCalendar'
import adaptDataToYearCalendar from './calendarFunctions/adaptDataToYearCalendar'
import adaptDataToDailyReport from './reportsFunctions/adaptDataToDailyReport'
import adaptDataToMonthlyReport from './reportsFunctions/adaptDataToMonthlyReport'
import {adaptDataForAttributionGrid,
        fnPrepareDataBeforeAttributions, 
        fnRemoveDataAfterAttributions} from './reportsFunctions/functionsAttributions'

const INITIAL_STATE = {
    loading: false,
    sourceToCancel: null,
    isCached: false, 
    values: null,
    error: null,

    /*
     * spécifique : liste des chevauchements
     */
    overlapsLoading: false,
    overlapsValues: [],
}

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case act.RESET_DATA:
            return Object.assign({}, state, {
                loading: false,
                sourceToCancel: null,
                isCached: false,
                values: null
            })
            
        /*
         * mémorise le jeton d'annulation
         */
        case act.SET_SOURCE_TO_CANCEL: 
            return Object.assign({}, state, {
                sourceToCancel: action.payload
            })

        case act.SET_LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            })

        case act.SET_CACHE:
            return Object.assign({}, state, {
                isCached: action.payload
            })

        case act.SET_DATA:
            return Object.assign ({}, state, {
                sourceToCancel: null,
                values: action.payload
            })

        case act.SET_DATA_MONTH_CALENDAR:
            return Object.assign ({}, state, {
                loading: false,
                values: adaptDataToMonthCalendar(action.payload)
            })

        case act.SET_DATA_YEAR_CALENDAR:
            return Object.assign ({}, state, {
                loading: false,
                values: adaptDataToYearCalendar(action.payload)
            })

        case act.SET_DATA_DAILY_REPORT:
            return Object.assign({}, state, {
                loading: false,
                isCached: action.payload.cache,
                values: adaptDataToDailyReport(action.payload.values)
            })

        case act.SET_DATA_MONTHLY_REPORT:
            return Object.assign({}, state, {
                loading: false,
                isCached: action.payload.cache,
                values: adaptDataToMonthlyReport(action.payload.values)
            })
    
        case act.SET_DATA_ATTRIBUTIONS:
            return Object.assign({}, state, {
                sourceToCancel: null,
                loading: false,
                values: adaptDataForAttributionGrid(action.payload)
            })

        case act.UPDATE_DATA_BEFORE_ATTRIBUTIONS: 
            return Object.assign({}, state, {
                vehicleCode: action.payload[0].vehicleCode,                 /* véhicule sur lequel sont effectués les attributions d'activités */
                values: fnPrepareDataBeforeAttributions(state.values, action.payload)
            })

        case act.UPDATE_DATA_AFTER_ATTRIBUTIONS:
            const obj = Object.assign({}, state, {
                loading: false,
                vehicleCode: null,
                values: fnRemoveDataAfterAttributions(state.values, state.vehicleCode),
            })
            return obj

        case act.DATA_SET_ERROR:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case act.SET_OVERLAPS_LOADING:
            return Object.assign({}, state, {
                overlapsLoading: action.payload,
                overlapsValues: []
            })

        case act.SET_OVERLAPS_VALUES:
            return Object.assign({}, state, {
                overlapsLoading: false,
                overlapsValues: action.payload,
            })

        default:
            return state
    }
    
}

export default reducer