import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Grid, Switch} from '@material-ui/core'
class OnOffSwitch extends Component {
  static propTypes = {
	labelTrue: PropTypes.string.isRequired,
	labelFalse: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
  }

  render() {
	const {labelFalse, labelTrue, ...props} = this.props
	return (
	  <Grid container alignItems="center" spacing={1}>
		  <Grid item>{labelFalse}</Grid>
		  <Grid item><Switch {...props} /></Grid>
		  <Grid item>{labelTrue}</Grid>
	  </Grid>
	)
  }
}

export default OnOffSwitch
