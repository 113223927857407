import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withStyles,
        Container, Grid, Toolbar,
        } from '@material-ui/core'
import Focus from '../../core/components/window/Focus'
import {setTitle} from '../../core/redux/actions/WindowAppActions'
import HelpCard from '../../core/components/window/HelpCard'
import HelpButton from '../../core/components/window/HelpButton'
import ButtonAdd from '../../core/components/window/ButtonAdd'
import WaitBlock from '../../core/components/window/WaitBlock'
import TerminalForm from '../../core/components/resources/TerminalForm'
import AddTerminalForm from '../../core/components/resources/AddTerminalForm'
import { DataGrid, ToolbarOptions } from 'tubular-react';
import {createColumn, ColumnDataType} from 'tubular-common'
import {getList} from '../../core/redux/actions/TerminalsActions'
class Terminals extends Component {

    state = {
        displayAdd: false,
        refreshDataGrid: 0,
        term: null,
    }

    componentDidMount () {
        this.props.setTitle('Gestion des bornes')
        this.props.getList()
    }

    getColumns = () => {
        return [
            createColumn('id', {
                visible: false,
                isKey: true,
            }),
            createColumn('nom', {
                label: 'Nom',
                searchable: true,
            }),
            createColumn('codePostal', {
                label: 'C.P.',
                searchable: true,
            }),
            createColumn('ville', {
                label: 'Ville',
                searchable: true,
            }),
            createColumn('pays', {
                label: 'Pays',
            }),
            createColumn('type', {
                label: 'Type',
                searchable: true,
            }),
            createColumn('sEtat', {
                label: 'État'
            }),
            createColumn('HS', {
                label: 'Hors service',
                dataType: ColumnDataType.Boolean
            })
        ]
    }

    refreshDatagrid = () => {
        this.setState({
            refreshDataGrid: this.state.refreshDataGrid + 1,
        })
    }
    getToolbarOptions = () => {
        return new ToolbarOptions({
            enablePagination: false,
            itemsPerPage: 10000,
            title: 'Bornes',
            customItems:[  
                <ButtonAdd
                    key='add'
                    title="Ajouter une borne" 
                    onClick={this.handleAdd} />,
                // <ButtonOw
                //     key='refresh'
                //     title="Actualiser"
                //     onClick={this.refreshDatagrid}
                //     >
                //     <RefreshIcon />
                // </ButtonOw>
            ]
        });
    }

    handleAdd = () => {
        this.setState({
            term: null,
            displayAdd: !this.state.displayAdd,
            refreshDataGrid: this.state.refreshDataGrid + 1,
        })

    }

    handleTerm = (term) => {
        
        let aTerm
        if (term) {
            aTerm = this.props.terminals.find (elt => term.id === elt.id)
        } else {
            aTerm = term
        }
        this.setState({
            term: aTerm,
            displayAdd: false,
            refreshDataGrid: this.state.refreshDataGrid + 1,
        })
    }

    onRefresh = () => {
        this.setState({
            refreshDataGrid: this.state.refreshDataGrid + 1,
        })
    }
    render() {
        
        return (
        <Container className={this.props.classes.root}>
            <Toolbar className={this.props.classes.toolbar}>
              
                <HelpButton >
                    <HelpCard title="Aide de l'écran">
                        <div className={this.props.classes.helpContent}>
                            <p>
                                Un logiciel de borne, appelé ordinairement "une borne", permet de récupérer les données d'une clef de déchargement
                                et de les transférer de manière sécurisée et aisée vers les serveurs <em>Optimal Web</em>.
                            </p>
                            <p>Cet écran permet de gérer et télécharger les logiciels de borne.</p>
                            <p>Un état <em>KO</em> signifie que la borne n'envoie plus de fichiers depuis trop longtemps.</p>
                        </div>
                    </HelpCard>
                </HelpButton>
            </Toolbar>
        
            <Grid container className={this.props.classes.grid} spacing={3}>
                <Grid item md={12} lg={8}>
                    {!this.props.terminals ?
                        <WaitBlock /> :
                        <DataGrid 
                                columns={this.getColumns()}
                                dataSource={this.props.terminals}
                                gridName="Bornes" 
                                onError={(err) => {
                                    console.warn('Terminals datagrid error', err)
                                    this.props.setError ('Datagrid error')
                                }}
                                deps={[this.state.refreshDataGrid]}
                                onRowClick={this.handleTerm}
                                toolbarOptions={this.getToolbarOptions()}
                                langKey="fr" 
                                />
                    }
                </Grid>

                {this.state.term && 
                    <Focus>
                        <Grid item>
                            <TerminalForm term={this.state.term} 
                                    onRefresh={this.onRefresh}
                                    onClose={this.handleTerm}/>
                        </Grid>
                    </Focus>
                    
                }

                {this.state.displayAdd && 
                    <Focus>
                        <Grid item >
                            <AddTerminalForm onClose={this.handleAdd} />
                        </Grid>
                    </Focus>
                }
            </Grid>

            
        </Container>
        )
    }
}

const mapStateToProps = state => ({
    terminals: state.terminals.terminals
})

const mapDispatchToProps = ({
    setTitle,
    getList,
})

const classes = theme => ({
    root: {
        '&& .MuiTableRow-hover': {
            cursor: 'pointer'
        },
    },
    grid: {
        
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',
    },
    helpContent: {
        '&& p': {
            textAlign: 'justify',
            marginBottom: '1.3em',
        }
    },
    addCard: {
        minWidth: '10em',
    },
    
})
export default connect(mapStateToProps, mapDispatchToProps) (withStyles (classes) (Terminals))
