import React, { Component, createRef } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {withStyles, Paper,
        Dialog, DialogTitle, DialogActions, DialogContent, Button,
        Select, MenuItem, FormControl, InputLabel, CircularProgress, 
        } from '@material-ui/core'
import Draggable from 'react-draggable'
import {DRIVER_TYPE, VEHICLE_TYPE, EVENT_TYPE} from '../Constants'
import DatePickerFr from '../forms/date/DatePickerFr';
import ElementSelection from '../forms/select/ElementSelection'
import OnOffSwitch from '../forms/OnOffSwitch';
import {setAvailability} from '../../redux/actions/ElementsActions'
import { Alert } from '@material-ui/lab';

export const reasonsDriver = [
    {
        value: 'ooo',
        label: 'Arrêt maladie'
    },
    {
        value: 'holiday',
        label: 'En vacances'
    },
    {
        value: 'loan',
        label: 'Hors entreprise'
    }
]

export const reasonsVehicle = [
    {
        value: 'ooo',
        label: 'En réparation'
    },
    {
        value: 'loan',
        label: 'Hors entreprise'
    }
]

class EventDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    eventType: PropTypes.oneOf([EVENT_TYPE.ABSENCE, EVENT_TYPE.WORK]).isRequired,
    type: PropTypes.oneOf([DRIVER_TYPE, VEHICLE_TYPE]).isRequired,
    element: PropTypes.object,                      /* élément (conducteur ou véhicule) éventuellement en cours d'examen */
    year: PropTypes.object,                         /* positionne la période dans l'année examinée */
    month: PropTypes.instanceOf(Date),              /* positionne la période dans le mois examiné */
    day: PropTypes.instanceOf(Date),                /* positionne la période sur le jour sélectionné */
    reason: PropTypes.string,                       /* positionne sur le motif défini */
  }

  state = {
    eventType: EVENT_TYPE.ABSENCE,
    newEvent: {
        element: null,
        reason: this.props.reason,
        begin: this.props.year,
        end: this.props.year,
    },
    isValidForm: false,
    displayAlert: false,
    closedForm: false,
  }

  /**
   * Référence au DOM
   * afin de permettre de faire glisser le composant
   */
  domRef = null

  /**
   * Liste des motifs d'absence
   */
  reasons = {
        driver: reasonsDriver,
        vehicle: reasonsVehicle,
    }

    constructor(props) {
        super(props)
        this.domRef= createRef()
    }

    hydrate() {

        let newBegin
        let newEnd

        if (this.props.day) {
            newBegin = newEnd = this.props.day
        } else if (this.props.month) {
            newBegin = newEnd = this.props.month                
        } else if (this.props.year) {
            newBegin = newEnd = this.props.year
        }

        this.setState({
            closedForm: false,
            isValidForm: false,
            displayAlert: false,
            eventType: this.props.eventType,
            newEvent: {
                begin: newBegin,
                end: newEnd,
                reason: this.props.reason ? this.props.reason : '',
                element: this.props.element
            }
        })
    }
    componentDidMount() {
        this.hydrate()
    }
    componentDidUpdate(prevProps, prevState) {
        
        /*
         * mise à jour lors de la réouveture
         */
        if ( (prevProps.open === false 
                && this.props.open === true)
        ) {
           this.hydrate()
        }
        
        /*
         * gestion enregistrement
         */
        if (prevState.isValidForm === false 
                && this.state.isValidForm === true) {

            this.props.setAvailability(this.state.newEvent)

        /*
         * fermeture de la fenêtre
         */
        } else if (this.state.isValidForm === true
                && this.state.closedForm === false          /* pas de double fermeture (car double loading (put nouvel état et refresh grille)) */
                && prevProps.loading === true && this.props.loading === false) {

            this.setState({
                closedForm: true,
                displayAlert: false,
                isValidForm: false,
            })
            this.props.onClose(true)
        }
        
    }
    handleChange = (evt) => {
        const newEvent = Object.assign({}, this.state.newEvent, {
            [evt.target.name]: evt.target.value
        })
        this.setState({
            newEvent: newEvent,
            displayAlert: false,
        })
    }
    handleElement = (newElement) => {
        const newEvent = Object.assign({}, this.state.newEvent, {
             element: newElement
        })
        this.setState({
           newEvent: newEvent,
           displayAlert: false,
        })
    }   
    handleDate = (newDate, name) => {

        let newEvent = Object.assign({}, this.state.newEvent, {
            [name]: newDate,
        })

        /*
         * décalage de la fin si début est antérieur
         */
        if ( (name === 'begin')
            && (newDate > this.state.newEvent.end)
        ) {
            newEvent = Object.assign({}, newEvent, {
                end: newDate,
            });
        }

        this.setState({
            newEvent: newEvent,
        })

    }

    handleTypeEvent = (evt) => {
        const eventType = evt.target.checked ? EVENT_TYPE.WORK : EVENT_TYPE.ABSENCE
        const reason = (eventType === EVENT_TYPE.WORK) ? EVENT_TYPE.WORK : ''
        const event = Object.assign({}, this.state.newEvent, {
            reason: reason
        })
        this.setState({
            eventType: eventType,
            newEvent: event,
        })
    }
    isValid = () => {
        return (this.state.newEvent.element
            && this.state.newEvent.begin
            && this.state.newEvent.end
            && this.state.newEvent.reason
            )
    }
  validate = () => {

      if (this.isValid()) {
          this.setState({
              isValidForm: true,
              displayAlert: false,
          })
      } else {
          this.setState({
              displayAlert: true,
          })
      }
      
  }

  getPaperComponent = (props) => {
                
        return (
            <Draggable 
                    handle="#draggable-dialog-title"
                    cancel={'[class*="MuiDialogContent-root"]'}
                    nodeRef={this.domRef}>
                <Paper ref={this.domRef}  {...props} />
            </Draggable>
        )
  }
  render() {

    const title = (this.state.eventType === EVENT_TYPE.ABSENCE) ? "Période d'absence" : "Période de travail"

    return (
      <Dialog 
            open={this.props.open} 
            onClose={this.props.onClose}
            PaperComponent={this.getPaperComponent} 
            >
        <DialogTitle
                id="draggable-dialog-title"
                className={this.props.classes.title} 
                >
            {title}
        </DialogTitle>
        <DialogContent dividers className={this.props.classes.content}>
            
            <FormControl className={this.props.classes.period}>
                <DatePickerFr
                    name='begin'
                    value={this.state.newEvent.begin}
                    label='Début'
                    onChange={ (newDate) => this.handleDate(newDate, 'begin')}
                    autoOk
                    />
                <DatePickerFr
                    name='end'
                    value={this.state.newEvent.end}
                    label='Fin'
                    onChange={newDate => this.handleDate(newDate, 'end')}
                    autoOk
                    />
            </FormControl>

            {this.props.element ?
                < ElementSelection
                    name="element"
                    onChange={this.handleElement}
                    uniqueType={this.props.type}
                    uniqueValue={true}
                    id={this.props.element.id}
                    />:
                <ElementSelection
                    name="element"
                    onChange={this.handleElement}
                    uniqueType={this.props.type}
                />
            }            

            <OnOffSwitch 
                labelTrue="Travail"
                labelFalse="Absence"
                checked={this.state.eventType === EVENT_TYPE.WORK}
                onChange={this.handleTypeEvent}
                />

            {this.state.eventType === EVENT_TYPE.ABSENCE &&
                <FormControl className={this.props.classes.reason}>
                    <InputLabel>Motif de l'absence</InputLabel>
                    <Select
                        id="reason"
                        name="reason"
                        value={this.state.newEvent.reason}
                        onChange={this.handleChange}                    
                        >
                        {this.reasons[this.props.type].map(reason => 
                            <MenuItem 
                                key={`${this.props.type}_${reason.value}`}
                                value={reason.value}>
                                {reason.label}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            }

            {this.state.displayAlert &&
                <Alert severity="warning" >
                    Choix invalides
                </Alert>
            }
        </DialogContent>
        <DialogActions className={this.props.classes.actions}>
            {this.props.loading ?
                <CircularProgress /> :
                <>
                    <Button onClick={this.props.onClose}>
                        Annuler
                    </Button>
                    <Button onClick={this.validate} disabled={this.state.displayAlert} >
                        Valider
                    </Button>
                </>
            }
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
    loading: state.data.loading,

})
const mapDispatchToProps = ({
    setAvailability,
})

const classes = theme => ({
    root: {

    }, 
    title: {
        cursor: 'move',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    period: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '1em',
    },
    reason: {
        minWidth: '10em',
    }
})
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (EventDialog))
