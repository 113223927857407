import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withStyles, Typography,
        Card, CardHeader, CardContent, CardActions,
        Button, Tooltip, TextField,
        } from '@material-ui/core'
import CommunicationOffIcon from '../icons/ConnectionOffIcon'
import CommunicationOnIcon from '../icons/ConnectionOnIcon'
import DownloadIcon from '@material-ui/icons/GetApp'
import CloseButton from '../window/CloseButton'
import ButtonEdit from '../window/ButtonEdit'
import WaitingBlock from '../window/WaitBlock'
import Focus from '../window/Focus'
import {getUrlsInstall, updateTerminal,
        updateState, downloadFile} from '../../redux/actions/TerminalsActions'
import {resetData} from '../../redux/actions/DataActions'
import {BORNE_OPTIMAL_WEB, BORNE_PC_PERSONNEL} from '../Constants'
import SupportService from '../window/SupportService';

class TerminalForm extends Component {
  static propTypes = {
    term: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onRefresh: PropTypes.func,
  }

  state = {
      install: false,
      modeEdit: false,
      term: {
          id: 0,
          name: '',
          description: '',
          line1: '',
          line2: '',
          zipCode: '',
          city: '',
          nation: '',
          HS: false,
      }
  }

  componentDidMount() {
      this.initTerm()
  }

  componentDidUpdate(prevProps, prevState) {
    
    if ( (!this.state.term && this.props.term) || 
            (this.props.term.id !== this.state.term.id)
    ) {
        this.props.resetData()
        this.initTerm()
        this.setState({
            modeEdit: false,
        })
    }
    
    /*
     * ouverture de la fenêtre des installations
     */
    if (!prevState.install && this.state.install && !this.props.urls) {
        this.props.getUrlsInstall(this.props.term.id)
    }

    /*
     * fermeture fenêtre après validation
     */
    if (this.state.modeEdit &&
            prevProps.loading &&
            !this.props.loading) {
        this.props.onClose()
    }

    /*
     * changement de l'état de la borne
     */
    if ( (this.state.term.HS !== this.props.term.HS)
            && this.props.onRefresh
    ) { 
        this.props.onRefresh()
        let term = this.state.term
        term.HS = this.props.term.HS
        this.setState({
            term: term
        })
    }

  }

  initTerm = () => {

    const term = {
        id: this.props.term.id,
        name: this.props.term.nom,
        description: this.props.term.description,
        line1: this.props.term.ligne1Adresse,
        line2: this.props.term.ligne2Adresse,
        zipCode: this.props.term.codePostal,
        city: this.props.term.ville,
        nation: this.props.term.pays,
        HS: this.props.term.HS,
    }
    this.setState({
        term: term,
    }) 
  }
  handleEdit = () => {

    /*
     * bascule
     */
    this.setState({
        modeEdit: !this.state.modeEdit,
    })
  }
  handleUploads = () => {
      this.setState({
          install: !this.state.install
      })
  }

  onChange = (evt) => {
      
      const field = evt.target.name
      let term = this.state.term
      term[field] = evt.target.value
      
      this.setState({
          term: term,
      })

  }

  handleState = () => {
      const hs = this.props.term.HS ? 0 : 1             /* ! changement systématique de l'état ! */
      this.props.updateState (this.props.term.id, hs)
  }

  validate = () => {
    this.props.updateTerminal(this.state.term.id, this.state.term)
  }

  download = (nom, code) => {
        this.props.downloadFile(code, nom)
  }
  render() {
    const cardActions = 
        <>
            {!this.state.modeEdit && !this.props.loading &&
                <>
                    <ButtonEdit title='Modifier' onClick={this.handleEdit} />
                    <CloseButton onClick={() => this.props.onClose()} />
                </>
            }
           
        </>
    const installActions = <CloseButton onClick={this.handleUploads} />

    return (
        <>
        <Card className={this.props.classes.root}>
            <CardHeader 
                title={`Borne ${this.state.term?.name}`}
                action={cardActions} />
            <CardContent>
                <Typography variant="subtitle1" color="error" align="center">
                    {this.props.term.HS && 'Hors service'}
                </Typography>
                
                {this.state.modeEdit &&
                    <>
                        <TextField
                            name="name" 
                            label="Nom"
                            onChange={this.onChange}
                            value={this.state.term?.name} />
                        <br />
                        <TextField
                            name='description'
                            label='Description'
                            onChange={this.onChange}
                            value={this.state.term?.description} />
                        <br />
                        <TextField
                            name="line1" 
                            label="Adresse - ligne 1"
                            onChange={this.onChange}
                            value={this.state.term?.line1} />
                        <br />
                        <TextField
                            name="line2" 
                            label="Adresse - ligne 2"
                            onChange={this.onChange}
                            value={this.state.term?.line2} />
                        <br />
                        <TextField
                            name='zipCode'
                            label='Code postal'
                            onChange={this.onChange}
                            value={this.state.term?.zipCode} />
                        <br />
                        <TextField 
                            name='city'
                            label='Ville'
                            onChange={this.onChange}
                            value={this.state.term?.city} />
                        <br />
                        <TextField 
                            name='nation'
                            label='Pays'
                            onChange={this.onChange}
                            value={this.state.term?.nation} />
                    </>
                }
                {!this.state.modeEdit &&
                <>
                    <div className={this.props.classes.lineForm}>
                        {this.props.actualUser?.supportAthmo ?
                            <Tooltip title={`Borne ${this.props.term.id}`}>
                                <label>Description</label>
                            </Tooltip> :
                            <label>Description</label>
                        }
                        <span>{this.state.term?.description}</span>
                    </div>
                    <div className={this.props.classes.lineForm} >
                        <label>Adresse</label>
                        <span>
                            {this.state.term?.line1}<br /> 
                            {this.state.term?.line2 &&                       
                                <>{this.state.term.line2}<br /></>
                            }
                            {this.state.term?.zipCode}&nbsp;{this.state.term?.city}<br />
                            {this.props.term.pays}
                        </span>
                    </div>
                    <div className={this.props.classes.lineForm}>
                        <label>Type</label>
                        <span>{this.props.term.type}</span>
                    </div>
                    <div className={this.props.classes.lineForm}>
                        <label>État</label>
                        <span>{this.props.term.sEtat}</span>
                    </div>
                </>}
            </CardContent>
            <CardActions className={this.props.classes.actionsLine}>
                {this.props.loading ?
                    <WaitingBlock /> :                
                    this.state.modeEdit ?
                        <>
                            <Button onClick={this.handleEdit}>
                                Annuler
                            </Button>
                            <Button onClick={this.initTerm}>Réinitialiser</Button>
                            <Button onClick={this.validate}>Valider</Button>
                        </> :
                        <>
                            
                            {this.props.term.HS === false && [BORNE_OPTIMAL_WEB, BORNE_PC_PERSONNEL].includes(this.props.term.type) &&
                                <Button onClick={this.handleUploads} 
                                        variant="outlined"
                                        startIcon={<DownloadIcon />}>
                                    Installer
                                </Button>
                            }
                            
                            <Button onClick={this.handleState}
                                    variant="outlined"
                                    startIcon={this.props.term.HS ? <CommunicationOnIcon /> :  <CommunicationOffIcon />}>
                                {this.props.term.HS ? 'Mettre en service' : 'Mettre hors service'}
                            </Button>
                            
                        </>
                
                }
                
            </CardActions>
        </Card>

        {this.state.install &&
            <Focus>
                <Card className={this.props.classes.root}>
                    <CardHeader title={`Installation de la borne ${this.props.term.nom}`}
                            action={installActions} />
                    <CardContent>
                        <div>
                            <Typography variant="h6" gutterBottom>
                                Méthodologie
                            </Typography>
                            <ol>
                                <li>Téléchargez les deux fichiers et enregistrez les dans le même dossier.</li>
                                <li>Effectuez un double-clic sur le fichier <em>exe</em> pour démarrer l'installation.</li>
                                <li>Suivez les instructions.</li>
                            </ol>
                            <p>Si besoin, n'hésitez pas à contacter notre <SupportService />.</p>
                        </div>
                        <div>
                            <Typography variant="h6" gutterBottom>
                                Fichiers d'installation
                            </Typography>
                            { (this.props.loading || !this.props.urls) ?
                                <WaitingBlock /> :
                                <div>
                                    {this.props.urls.map( (infosUrl, i) => (
                                        <div key={infosUrl.nom}>
                                            Fichier&nbsp;{i + 1}&nbsp;:&nbsp; 
                                                <span className={this.props.classes.clickable}
                                                        onClick={() => this.download(infosUrl.nom, infosUrl.code)}>
                                                    {infosUrl.nom}
                                                </span>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </CardContent>

                </Card>
            </Focus>
        }
        </>
    )
  }
}

const mapStateToProps = state => ({
    loading: state.data.loading,
    urls: state.data.values,
    actualUser: state.identity.user,
})
const mapDispatchToProps = ({
    getUrlsInstall,
    updateTerminal,
    updateState,
    resetData,
    downloadFile,
})

const classes = theme => ({
    root: {
        marginTop: '0.5em',
    },
    lineForm: {
        display: 'block',
        marginLeft: '10em',
        '& label': {
            display: 'inline-block',
            marginLeft: '-10em',
            width: '10em',
        }
    },
    actionsLine: {
        justifyContent: 'space-around',
    },
    clickable: {
        cursor: 'pointer',
        backgroundColor: theme.palette.secondary.light,
    }
    
})
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (TerminalForm))
