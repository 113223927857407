import React, { Component } from 'react'
import {Container, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'
import RowingIcon from '@material-ui/icons/Rowing'

class Page404 extends Component {
  render() {
    return (
      <Container>
        <Typography align="center" variant="h2">
          Erreur 404
        </Typography>
        <Typography align="center">
             <RowingIcon /> Votre demande est incompréhensible...
        </Typography>
        <Link to="/">Retour vers l'accueil</Link>
      </Container>
    )
  }
}

export default Page404
