import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {DRIVER_TYPE} from '../../Constants'
import {withStyles,
        TableCell, Popover,
        Tooltip,
        } from '@material-ui/core'
import InformationsCard from './InformationsCard'
import DriverCardIcon from '../../icons/DriverCardIcon'
import VehicleIcon from '../../icons/VehicleIcon'
import DriverInfoIcon from '../../icons/DriverInfoIcon'
import VehicleInfoIcon from '../../icons/VehicleInfoIcon'
import clsx from 'clsx'

class CalendarCell extends Component {
  static propTypes = {
        colIndex: PropTypes.string.isRequired,
        descriptor: PropTypes.object.isRequired,
        refType: PropTypes.string.isRequired,
        onClick: PropTypes.func,                  /* clic sur cellule */
        elementType: PropTypes.string,            /* Type de l'élément géré (driver ou véhicule) */
        elementId: PropTypes.number,              /* id de l'élément géré */
  }

  state = {

    /*
     * affichage des informations de fichier
     */
    displayFilesInformations: false,
  }

  /**
   * Référence permettant d'accéder au DOM de la cellule affichée
   * et donc de positionner la popup
   */
  cellRef = null

  constructor(props) {
    super(props)
    this.cellRef = React.createRef()
  }
  onClick = (evt) => {
  
    /*
     * Cas 1 : présence de fichiers téléchargés => affichage infos
     */
    if (this.props.descriptor.files.length > 0) {
      this.setState({
        displayFilesInformations: !this.state.displayFilesInformations,
      })

    /*
     * Autres cas : informations diverses
     */
    } else { 
      const targetInfos = {
        day: this.props.descriptor.day,
        id: this.props.elementId,
        state: this.props.descriptor.actions,
      }
      this.props.onClick(targetInfos)
    }

  }

  render() {
    
    const today = new Date()
    const classRoot = clsx(
        this.props.classes.aDay,
        this.props.descriptor.day.toDateString () === today.toDateString() && this.props.classes.todayColor,
        this.props.descriptor.day.getDay() === 0 && this.props.classes.sundayColor
    )
    const classRootWithoutClick = clsx(
        this.props.classes.aDayWithoutClick,
        this.props.descriptor.day.toDateString() === today.toDateString() && this.props.classes.todayColor,
        this.props.descriptor.day.getDay() === 0 && this.props.classes.sundayColor
    )

    /*
     * Affichage des informations sur les fichiers téléchargés
     * pas de gestion des événements
     */
    if (this.props.descriptor.files.length > 0) {

      let pluriel=this.props.descriptor.files.length > 1 ? 's' : ''
      let text=`Fichier${pluriel} téléchargé${pluriel} - Cliquez pour plus d'informations.`

      const cell = (
        <TableCell key={this.props.colIndex}
          ref={this.cellRef}
          className={classRoot}
          onClick={this.onClick}
        >
          {this.props.descriptor.files.length > 0 && (
            <Tooltip title={text}>
              {this.props.refType === DRIVER_TYPE ?
                <DriverCardIcon className={this.props.classes.hover} color="green" /> :
                <VehicleIcon className={this.props.classes.hover} color="green" />
              }
            </Tooltip>
          )}
        </TableCell>
      )
      
      return (<>
        {cell}
        <Popover id={`${this.props.colIndex}_over`} 
            open={this.state.displayFilesInformations}
            onClose={this.onClick}
            anchorEl={this.cellRef.current}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'center'
            }}
            transformOrigin={{
              horizontal: 'center',
              vertical: 'center'
            }}
            >
          <InformationsCard 
              cellIndex={`${this.props.colIndex}_cell`} 
              files={this.props.descriptor.files} 
              refType={this.props.refType} />
        </Popover>
      </>)

    /*
     * Cas : hors contrat => pas de gestion des événements
     */
    } else if ( (this.props.descriptor.actions.includes('outBefore')
        || this.props.descriptor.actions.includes('outAfter'))
    ) {
      return (
        <TableCell key={this.props.colIndex} className={classRootWithoutClick} >
          {this.props.descriptor.actions.length > 0 && (
            this.props.descriptor.actions.map((action, index) => {
              return this.props.refType === DRIVER_TYPE ?
                <DriverInfoIcon key={`${this.props.colIndex}_Ico${index}`}
                  type={action}
                /> :
                <VehicleInfoIcon key={`${this.props.colIndex}_Ico${index}`}
                  type={action}
                />;
            })
          )}
        </TableCell>
      )
    /*
     * pas d'informations sur les fichiers => gestion des événements
     */
    } else {
      
      return (
        <TableCell key={this.props.colIndex} 
            className={classRoot} 
            onClick={this.onClick}        /* action non spécifique de clic */
            ref={this.cellRef}            
            >
          {this.props.descriptor.actions.length > 0 && (
            this.props.descriptor.actions.map ( (action, index) => {
              return this.props.refType === DRIVER_TYPE ?
                <DriverInfoIcon key={`${this.props.colIndex}_Ico${index}`} 
                    type={action} 
                    /> :
                <VehicleInfoIcon key={`${this.props.colIndex}_Ico${index}`} 
                    type={action}
                    />
            })
          )}
        </TableCell>
      )
    }
  }
}

const classes = theme=> ({

    aDay: {
      width: '2em',
      margin: 0,
      padding: '5px',
      textAlign: 'center',
      borderWidth: 'thin',
      borderStyle: 'solid',
      cursor: 'pointer',
    },
    aDayWithoutClick: {
      width: '2em',
      margin: 0,
      padding: '5px',
      textAlign: 'center',
      borderWidth: 'thin',
      borderStyle: 'solid',
    },
    sundayColor: {
        backgroundColor: 'Gainsboro',
    },
    todayColor: {
      backgroundColor: theme.palette.secondary.light,
    },
    hover: {
      cursor: 'pointer'
    }
})
export default withStyles(classes) (CalendarCell)
