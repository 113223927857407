import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withStyles, Typography,
      Button, Input, InputLabel, Card, 
      } from '@material-ui/core'
import CircularProgressWithLabel from '../window/CircularProgressWithLabel'
import LogoService from '../../../services/LogoService'
import {deleteLogo, getLogo} from '../../redux/actions/ClientActions'
class LogotypeParameter extends Component {

  state = {
    file: null,
    progress: 0,
    uploading: false,
    showSendBlock: false,
    errorType: false,
  }

  onChangeFile = (evt) => {
    const file = evt.target.files[0]
    
    if (file.type !== 'image/png') {
      this.setState({
        errorType: true,
      })
    } else {
      this.setState({
        file: file,
        errorType: false,
      })
    }

  }

  deleteLogo = () => {
   
    this.props.deleteLogo()

  }

  handleSend = () => {
    this.setState({
      showSendBlock: !this.state.showSendBlock,
    })
  }

  onProgress = (evtProgress) => {
    this.setState({
      progress: Math.round( (100 * evtProgress.loaded) / evtProgress.total)
    })
  }

  onSubmit = () => {

    /*
     * Contrôle de validité du fichier de logo
     */
    if (this.state.errorType) {
      return
    }

    /*
     * envoi
     */
    const service = new LogoService()
    this.setState({
      uploading: true,
      progress: 0,
    })
    
    service.uploadLogo(this.state.file, this.onProgress).then (response => {
      
      this.props.getLogo()
      this.setState({
        uploading: false,
        progress: 0,
        showSendBlock: false,
      })
    })
  }
  render() {
    
    return (
      <Card className={this.props.classes.root}>
          <Typography variant="h5">Logotype de votre société</Typography>
          <Typography variant="body2">Utilisé dans certains documents PDF créés pour votre usage</Typography>
          {this.props.logo.length > 0 ?
            <>
              <div>
                <img src={`data:image/png;base64,${this.props.logo}`}
                      alt="Logo actuel" 
                      className={this.props.classes.img} />
              </div>
              <div>
                <Button onClick={this.deleteLogo}>
                  Supprimer
                </Button>
                <Button onClick={this.handleSend}>
                  Modifier
                </Button>
              </div>
            </> :
            <>
              <Typography variant="caption"
                  className={this.props.classes.info}>Aucun logotype n'est défini pour la société</Typography>
              <Button onClick={this.handleSend}>
                {this.state.showSendBlock ? 'Annuler' : 'Définir le logotype'}
              </Button>
            </>
          }

          {this.state.showSendBlock &&
            (this.state.uploading ?
              <CircularProgressWithLabel value={this.state.progress} /> :
              <form className={this.props.classes.upload}>
                <Typography variant="subtitle1" className={this.props.classes.subTitle}>
                  Définition d'un nouveau logotype
                </Typography>
                <InputLabel error={this.state.errorType} >Choisir un logotype au format <em>png</em></InputLabel>
                <Input 
                    type="file"
                    inputProps={{accept:"image/png"}}
                    error={this.state.errorType}
                    onChange={this.onChangeFile} />
                <Button 
                    disabled={this.state.errorType}
                    onClick={this.onSubmit}>
                  Envoyer
                </Button>
              </form>
            )
          }
      </Card>
    )
  }
}

const mapStateToProps = state => ({
  logo: state.client.logo,
})
const mapDispatchToProps = ({
  deleteLogo,
  getLogo,
})

const classes = {
  root: {
    maxWidth: '30em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.5em 1em'
  },
  info: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  upload: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '25em',
  },
  subTitle: {
    marginBottom: '0.6em',
  },
  img: {
    maxWidth: '20em',
    margin: '0.7em',
  }
}
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (LogotypeParameter))
