import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HelpIcon from '@material-ui/icons/HelpOutline'
import {IconButton, Popover, Typography, withStyles} from '@material-ui/core'
import HelpCard from './HelpCard';
import SupportService from './SupportService';
class HelpButtonForTab extends Component {

    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
        value: PropTypes.string.isRequired,
    }

    state = {
        displayHelp: false,
    }

    handleDisplay = (evt) => {
        this.setState({
            displayHelp: !this.state.displayHelp,
            anchorEl: evt.target,
        });
    }
    
    render() {
        
        /*
         * sélection du bon panneau d'affichage en fonction de la valeur de l'onglet affiché
         */
        let children = (Array.isArray(this.props.children)) ?
                        this.props.children.find(elt => elt.props.value === this.props.value) :
                        this.props.children
        
        /*
         * définition d'une aide d'écran en cas d'absence d'aide spécifique
         */
        if (children === undefined) {
            children = <HelpCard title="Aide de l'écran">
                <Typography>Pour toute question, n'hésitez pas à contacter notre <SupportService />.</Typography>
            </HelpCard>
        }

        return (
            <>
                <IconButton 
                    onClick={this.handleDisplay}
                    >
                    <HelpIcon />
                </IconButton>
                <Popover
                    id='helpCard'
                    open={this.state.displayHelp}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleDisplay}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    classes={{
                        paper: this.props.classes.paper
                    }}>
                        {children}
                </Popover>
            </>
        )
    }
}

const classes = {
    paper: {
        backgroundColor: 'pink',
        minWidth: '20em',
    }
}

export default withStyles(classes) (HelpButtonForTab)
