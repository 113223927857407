import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Card, CardContent, withStyles} from '@material-ui/core'
import DriverCardIcon from '../../icons/DriverCardIcon'
import VehicleIcon from '../../icons/VehicleIcon'
import {DRIVER_TYPE} from '../../Constants'

class InformationsCard extends Component {
  static propTypes = {
    cellIndex: PropTypes.string.isRequired,
    files: PropTypes.array.isRequired,
    refType: PropTypes.string.isRequired,
  }
  render() {
    
    return (
       
        <Card className={this.props.classes.root}>
            <CardContent>
            {this.props.files.map( (file, index) => {
                return (
                    <div key={`${this.props.cellIndex}_cell${index}`}>
                        <label className={this.props.classes.label}>
                            <span className={this.props.classes.icone}>
                                {this.props.refType === DRIVER_TYPE ? (
                                    <DriverCardIcon size={1} color='green' />) : (
                                    <VehicleIcon size={1} color='green' />
                                )}
                                </span>{file.download}</label>
                        <ul><li>Première activité = {file.firstAct}</li>
                            <li>Dernière activité = {file.lastAct}</li>
                            <li>{file.name}</li>
                        </ul>
                    </div>
                )
            })}
           
            </CardContent>
        </Card>
        
    )
  }
}

const classes = {
    root: {
        maxWidth: '20em'
    },
    label: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    icone: {
        marginInlineEnd: '1em',
    }
}
export default withStyles (classes) (InformationsCard)
