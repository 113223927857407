import React, { Component } from 'react'
import {withTheme} from '@material-ui/core'
/**
 * @todo supprimer le title au profit du tooltip dans DriverInfoICon et VehicleInfoIcon
 */
export class OutIcon extends Component {

  render() {
    let {title, color, ...props} = this.props
    color = color ? color : this.props.theme.palette.primary.main
    
    return (
      <div title={title} {...props}>
        <svg width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
          <line x2="24" y2="24" stroke={color} key="dg" strokeWidth="2"/>
          <line x2="24" y1="24" stroke={color} key="gd" strokeWidth="2"/>
        </svg>
      </div>
    )
  }
}

export default withTheme(OutIcon)

