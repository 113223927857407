import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ButtonOw from '../../window/ButtonOw'

import {withTheme, withStyles, FormControl, 
        createMuiTheme, ThemeProvider, Select, MenuItem,
      } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import {fr} from 'date-fns/locale'
import { eachQuarterOfInterval, format as formatDate, 
    startOfQuarter, isSameQuarter, addQuarters } from 'date-fns';

class QuarterPicker extends Component {
  static propTypes = {
    minDate: PropTypes.instanceOf(Date).isRequired,
    maxDate: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,   
    reverse: PropTypes.bool,            /* indique de renverser la présentation des trimestres */ 
  }

  state = {
    maxDate: startOfQuarter(new Date()),
    minDate: startOfQuarter(new Date(2000, 1, 1, 0, 0, 0)),
    examinedDate: startOfQuarter(new Date()),
    previousQuarterDisabled: false,
    nextQuarterDisabled: false,
  }

  componentDidMount () {
    
    const today = new Date()
    const selectedDate = startOfQuarter( (this.props.value) ? this.props.value : today)
    this.setState({
      maxDate: startOfQuarter(this.props.maxDate ? this.props.maxDate : today),
      minDate: startOfQuarter(this.props.minDate ? this.props.minDate : new Date(2000, 1, 1, 0, 0, 0)),
      examinedDate: selectedDate,
    })
    if (this.props.onChange) {
      this.props.onChange(selectedDate)
    }

  }

  onChangeQuarter = (evt) => {
    
    const dateValue = startOfQuarter(new Date(evt.target.value))
    this.setState({
      examinedDate: dateValue,
      previousQuarterDisabled: this.isQuarterMin(dateValue),
      nextQuarterDisabled: this.isQuarterMax(dateValue)
    })

    if (this.props.onChange) {
      this.props.onChange(dateValue)
    }
  }

  selectPreviousQuarter = () => {
   
    const newDate = addQuarters(this.state.examinedDate, -1)
    this.setState({
      examinedDate: newDate,
      previousQuarterDisabled: this.isQuarterMin(newDate),
      nextQuarterDisabled: false,
    })

    if (this.props.onChange) {
        this.props.onChange(newDate)
    }
  }

  selectNextQuarter = () => {
    
    const nextQuarter = addQuarters(this.state.examinedDate, 1)

    this.setState({
      examinedDate: nextQuarter,
      previousQuarterDisabled: false,
      nextQuarterDisabled: this.isQuarterMax (nextQuarter),
    })
    
    if (this.props.onChange) {
        this.props.onChange(nextQuarter)
    }
  }

  selectLastQuarter = () => {
    this.setState({
      examinedDate: this.state.maxDate,
      previousQuarterDisabled: false,
      nextQuarterDisabled: true
    })
    if (this.props.onChange) {
      this.props.onChange(this.state.maxDate)
    }
  }

  selectFirstQuarter = () => {
    this.setState({
      examinedDate: this.state.minDate,
      previousQuarterDisabled: true,
      nextQuarterDisabled: false
    })
    if (this.props.onChange) {
      this.props.onChange(this.state.minDate)
    }
  }

  isQuarterMin = (newDate) => {

    let isMin = false
  
    if (isSameQuarter (this.state.minDate, newDate)
    ) {
        isMin = true
    }

    return isMin
  }

  isQuarterMax = (newDate) => {

    let isMax = false
    
    if (isSameQuarter (this.state.maxDate, newDate)
    ) {
        isMax = true
    }

    return isMax
  }

  componentDidUpdate (prevProps) {

    if (prevProps.value !== this.props.value) {
      this.setState({
        examinedDate: this.props.value
      })
    }

    /*
     *  gestion de l'affichage de la flèche "mois suivant"
     */
    if (this.state.nextQuarterDisabled
          && !this.isQuarterMax(this.state.examinedDate)) {
      this.setState({
        nextQuarterDisabled: false
      })
    } else if (!this.state.nextQuarterDisabled
          && this.isQuarterMax(this.state.examinedDate)) {
      this.setState({
        nextQuarterDisabled: true
      })
    }

    /*
     * gestion de l'afficahge du bouton "mois précédent"
     */
    if (this.state.previousQuarterDisabled
        && !this.isQuarterMin(this.state.examinedDate)) {
      this.setState({
        previousQuarterDisabled: false
      })
    } else if (!this.state.previousQuarterDisabled
        && this.isQuarterMin(this.state.examinedDate)) {
      this.setState({
        previousQuarterDisabled: true
      })
    }

  }
  render() {

    const muiAdaptedTheme = createMuiTheme({
      overrides: {
        MuiFormControl: {
          root: {
            // backgroundColor: 'red',
            minWidth: '7.4em',
            flexDirection: 'row',
            marginInline: '1em'
          }
        },
        MuiInputBase: {
          input: {
            textAlign: 'center',
            color: this.props.theme.palette.primary.main
          }
        },
        MuiTextField: {
          root: {
            minWidth: '9em',
          }
        }
      }
    })
  
    let quarters = eachQuarterOfInterval({start: this.state.minDate, end: this.state.maxDate})

    if (this.props.reverse === true) {
      quarters = quarters.reverse()
    }

    return (
      <ThemeProvider theme={muiAdaptedTheme}>
        <FormControl className={this.props.classes.root}>

              <ButtonOw title="Premier trimestre" onClick={this.selectFirstQuarter} disabled={this.state.previousQuarterDisabled}
                   className={this.props.classes.button} >
                  <FirstPage />
                </ButtonOw>
              <ButtonOw title="Trimestre précédent" onClick={this.selectPreviousQuarter} disabled={this.state.previousQuarterDisabled}
                  className={this.props.classes.button} >
                <KeyboardArrowLeft />
              </ButtonOw>

              <Select 
                  value={this.state.examinedDate.toUTCString()}
                  onChange={this.onChangeQuarter}>
                {quarters.map(aQuarter => 
                  <MenuItem key={aQuarter.toUTCString()} value={aQuarter.toUTCString()}>
                    {formatDate(aQuarter, 'QQQQ yyyy', {locale: fr})}
                  </MenuItem>
                  )}
              </Select>
           
              <ButtonOw title="Trimestre suivant" onClick={this.selectNextQuarter} disabled={this.state.nextQuarterDisabled}
                  className={this.props.classes.button} >
                <KeyboardArrowRight />
              </ButtonOw>
              <ButtonOw 
                title="Dernier trimestre" 
                onClick={this.selectLastQuarter} 
                disabled={this.state.nextQuarterDisabled}
                    className={this.props.classes.button} >
                <LastPage />
              </ButtonOw>

        </FormControl>
      </ThemeProvider>
    )
  }
}

const classes = theme => ({

  root: {
    
  },
  button: {
    maxWidth: '1em',
  }
})
export default withTheme (withStyles (classes) (QuarterPicker))
