import BaseService from './BaseService'

const URL_API = process.env.REACT_APP_URL_API
export default class LogoService extends BaseService {

    uploadLogo = (file, onUploadProgress) => {

        let formData= new FormData()
        let options = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: onUploadProgress,
        }

        formData.append('logo', file)

        return this.post(`${URL_API}/client/parameters/logo`, formData, options)

    }

}