import React, { Component } from 'react'
import {connect} from 'react-redux'
import {setTitle} from '../../core/redux/actions/WindowAppActions'
import {withStyles, Container, Toolbar, Typography, Grid,
				Switch, FormControl, } from '@material-ui/core'
import Selector from '../../core/components/forms/archivage/telechargements/IntervalSelector'
import Events from '../../core/components/optigestweb/Events';
import { EVENT_TYPE } from '../../core/components/Constants';
import HelpButton from '../../core/components/window/HelpButton'
import HelpCard from '../../core/components/window/HelpCard'
import SupportService from '../../core/components/window/SupportService';
export class Evenements extends Component {

	state = {
		selection:	null,
		typeFault: true,
	}
	componentDidMount() {

		/*
		 * design
		 */
		this.props.setTitle('Utilisations des véhicules')

	}

	handleSelection = (selection) => {
		this.setState({
			selection: selection,
		})
	}
	handleType = () => {
		this.setState({
			typeFault: !this.state.typeFault,
		})
	}
	render() {
		
		return (
			<Container className={this.props.classes.root} >
				<Toolbar>
					<FormControl className={this.props.classes.ctlType} >
						<Grid component="label" container alignItems="center" spacing={1}>
							<Grid item>Événements</Grid>
							<Grid item>
								<Switch checked={this.state.typeFault} onChange={this.handleType} />
							</Grid>
							<Grid item>Erreurs</Grid>
						</Grid>
					</FormControl>
					<Selector onChange={this.handleSelection} />
					<HelpButton>
						<HelpCard title="Aide de l'écran" >
							<div className={this.props.classes.helpLine}>
								<div>
									Cet écran permet d'afficher une liste des erreurs et événements 
									contenus dans les cartes de vos conducteurs ainsi que dans les chronotachygraphes.
								</div>

								<div>Lorsque le conducteur ou le véhicule impliqué est connu, son code personnalisé est affiché dans la colonne adéquate.</div>

								<div>
									<h4>Codes d'erreur</h4>
									<div>Généralement, les explications sont fournies.</div>
									<ul>
										<li>Code <em>13</em>&nbsp;: officiellement non documenté, il semble indiquer l'absence d'informations de positionnement en provenance du récepteur GNSS.</li>
									</ul>
								</div>

								<div>N'hésitez pas à contacter notre <SupportService/> pour toute question.</div>
							</div>
						</HelpCard>
					</HelpButton>
				</Toolbar>

				{(!this.state.selection || this.state.selection.id === 0) ?
					<Typography>Veuillez procéder à une sélection</Typography> :
					<Events type={this.state.selection.type}
							begin={this.state.selection.begin}
							end={this.state.selection.end}
							id={this.state.selection.id} 
							eventType={this.state.typeFault ? EVENT_TYPE.FAULT : EVENT_TYPE.EVENT}
							/>
				}

			</Container>
		)
	}
}

const mapDispatchToProps = ({
	setTitle,

})
const classes = {
	root: {

	},
	ctlType: {
		marginRight: '1em',
	}
}
export default connect(null, mapDispatchToProps) (withStyles(classes) (Evenements))
