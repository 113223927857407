import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withStyles,
        TableRow, TableCell, 
        Tooltip,
        } from '@material-ui/core'
import WarningIcon from '../icons/WarningIcon'
import Source from '../window/Source'
class DailyRow extends Component {

    static propTypes = {
        row: PropTypes.object.isRequired,
        dateRef: PropTypes.object.isRequired,
    }
  render() {
    const {row} = this.props
    let className

    for (const [key, value] of Object.entries(row)) {
        if (value === '??:??') {
            row[key] = <WarningIcon />
            className=this.props.classes.warnRow
        }
    }
    
    /*
     * affichage du récapitulatif final
     */
    if (row.index === 'total') {
        return (
            <TableRow key='totalDay' 
                    hover={true}
                    className={this.props.classes.totalDay}>
                <TableCell colSpan={2}>Totaux</TableCell>
                <TableCell><Tooltip title="Total travail effectif (CTDQ)" ><span>{row.heure_duree}</span></Tooltip></TableCell>
                <TableCell><Tooltip title="Total conduite" ><span>{row.conduite}</span></Tooltip></TableCell>
                <TableCell><Tooltip title="Total travail" ><span>{row.travail}</span></Tooltip></TableCell>
                <TableCell><Tooltip title="Total disponibilité" ><span>{row.dispo}</span></Tooltip></TableCell>
                <TableCell><Tooltip title="Total en équipage" ><span>{row.equipage}</span></Tooltip></TableCell>
                <TableCell><Tooltip title="Total du repos" ><span>{row.repos}</span></Tooltip></TableCell>
                <TableCell><Tooltip title="Total en kilomètres" ><span>{row.distance}</span></Tooltip></TableCell>
                <TableCell><Tooltip title="Vitesse moyenne" ><span>{row.vitesse}</span></Tooltip></TableCell>
            </TableRow>
        )

    /*
     * affichage des lignes standards
     */
    } else {
    
        return (
            <TableRow key={row.index}
                    hover={true} 
                    className={className}>
                <TableCell>{row.heure_debut}</TableCell>
                <TableCell>{row.heure_fin}</TableCell>
                <TableCell>{row.activite}</TableCell>
                <TableCell>{row.conduite}</TableCell>
                <TableCell>{row.travail}</TableCell>
                <TableCell>{row.dispo}</TableCell>
                <TableCell>{row.equipage}</TableCell>
                <TableCell>{row.repos}</TableCell>
                <TableCell>{row.distance}</TableCell>
                <TableCell>{row.vitesse}</TableCell>
                <TableCell>{row.source !== undefined && <Source idSource={row.source} />}</TableCell>
            </TableRow>  
        )
    }

  }
}

const classes = theme => ({
    root: {
        
    },
    warnRow: {
        backgroundColor: theme.palette.warning.light,
    },
    clickable: {
        cursor: 'pointer',
    },
    totalDay: {
        borderBlockColor: theme.palette.secondary.light,
        borderBlockStyle: 'double',
        borderBlockWidth: '5px',
        '& td': {
            color: theme.palette.primary.main,
            fontSize: '0.875rem',
            fontStyle: 'italic',
        }
    }
})

export default withStyles(classes) (DailyRow)
