import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withStyles, Typography,
        Card, CardHeader, CardContent, CardActions,
        Button, TextField, MenuItem,
        } from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import WaitingBlock from '../window/WaitBlock'
import {addTerminal} from '../../redux/actions/TerminalsActions'
import {resetData} from '../../redux/actions/DataActions'

class AddTerminalForm extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  }

  state = {
        term: {
            name: '',
            description: '',
            line1: '',
            line2: '',
            zipCode: '',
            city: '',
            nation: 'France',
            idGestionnaire: this.props.actualUser.identifiant
        },
        errors: [],
  }

  componentDidMount() {
      this.props.resetData()
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading === true && 
            this.props.loading === false &&
            !this.props.error ) {
        this.props.onClose()
    }
  }

  onChange = (evt) => {
      
      const field = evt.target.name
      const term = this.state.term
      term[field] = evt.target.value

      let errors = this.state.errors
      let index = errors.findIndex(elt => elt === field)
      
      if (index >= 0) {
        errors.splice(index, 1)
      }

      this.setState({
        term: term,
        errors: errors,
      })
  }

  validate = () => {
    let errors = []
    let nb = 0

    if (this.state.term.line1.length === 0) {
        nb = errors.push('line1')
    }
    if (this.state.term.zipCode.length === 0) {
        nb = errors.push('zipCode')
    }
    if (this.state.term.city.length === 0) {
        nb = errors.push('city')
    }
    
    this.setState({
        errors: errors,
    })
    
    if (nb === 0) {
        const newTerm = {
            nom: this.state.term.name,
            description: this.state.term.description,
            ligne1: this.state.term.line1,
            ligne2: this.state.term.line2,
            codePostal: this.state.term.zipCode,
            ville: this.state.term.city,
            pays: this.state.term.nation,
            idPersonne: this.state.term.idGestionnaire,
        }

        this.props.addTerminal(newTerm);
    }

  }
  render() {

    return (
        <Card className={this.props.classes.root}>
            <CardHeader 
                title="Nouvelle borne" />
            <CardContent>
                <div>
                    <TextField 
                            id="name"
                            name="name"
                            label="Nom"
                            type="text" 
                            value={this.state.term.name} 
                            onChange={this.onChange} />
                </div>
                <div>
                    <TextField 
                            id="description"
                            name="description"
                            label="Description"
                            type="text" 
                            value={this.state.term.description} 
                            onChange={this.onChange} />
                </div>
                <div>
                    <TextField 
                        id="manager"
                        name="idGestionnaire"
                        label="Gestionnaire"
                        select
                        value={this.state.term.idGestionnaire} 
                        onChange={this.changeSelect}
                        >
                            <MenuItem value={this.props.actualUser.identifiant}>{this.props.actualUser.nom} {this.props.actualUser.prenom}</MenuItem>
                    </TextField>
                    
                </div>
                <div>
                    <Typography variant="h6">Localisation</Typography>
                       <TextField 
                            id="line1"
                            name="line1"
                            label="Ligne 1 de l'adresse"
                            type="text"
                            required
                            onChange={this.onChange}
                            value={this.state.term.line1} 
                            error={this.state.errors.includes('line1')} /><br /> 
                        <TextField 
                            id="line2"
                            name="line2"
                            label="Ligne 2 de l'adresse"
                            type="text"
                            onChange={this.onChange}
                            value={this.state.term.line2} /><br /> 
                        <TextField 
                            id="zipCode"
                            name="zipCode"
                            label="Code postal"
                            type="text"
                            required
                            onChange={this.onChange}
                            value={this.state.term.zipCode} 
                            error={this.state.errors.includes('zipCode')} /><br /> 
                        <TextField 
                            id="city"
                            name="city"
                            label="Ville"
                            required
                            type="text"
                            onChange={this.onChange}
                            value={this.state.term.city}
                            error={this.state.errors.includes('city')} /><br /> 
                        <TextField 
                            id="nation"
                            name="nation"
                            label="Pays"
                            type="text"
                            onChange={this.onChange}
                            value={this.state.term.nation} /><br /> 
                </div>         
                {this.state.errors.length > 0 &&   
                    <Alert severity='error'>
                        Les renseignements sont incomplets.                    
                    </Alert>    
                }
            </CardContent>
            <CardActions>
                {this.props.loading ?
                    <WaitingBlock /> :
                    <>
                        <Button onClick={this.props.onClose} >
                            Annuler
                        </Button>
                        <Button onClick={this.validate} 
                                disabled={this.state.errors.length > 0}>
                            Créer
                        </Button>
                    </>
                }
            </CardActions>
        </Card>
    )
  }
}

const mapStateToProps = state => ({
    loading: state.data.loading,
    error: state.data.error,
    actualUser: state.identity.user,
})
const mapDispatchToProps = ({
    resetData,
    addTerminal,
})

const classes = theme => ({
    root: {
        marginTop: '0.5em',
    },
    
})
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (AddTerminalForm))
