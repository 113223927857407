import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {TableRow, TableCell, Tooltip,
        withStyles, Typography
        } from '@material-ui/core'
import CalendarCell from './CalendarCell'
import clsx from 'clsx'

class CalendarRow extends Component {

    /*
     *@see Tubular-react/BareBones/TbRow.tsx
     */
    static propTypes = {
        row: PropTypes.object.isRequired,
        rowIndex: PropTypes.number.isRequired,
        refType: PropTypes.string.isRequired,
        day31: PropTypes.bool,
        onClick: PropTypes.func,                /* clic sur une case du calendrier */
    }

  render() {
    let noDays=[]
    if (this.props.day31) {
        for (let i = this.props.row.days.length; i < 31; i++) {
            noDays.push({
                col: i,
                cell: 'noDay',
            })
        }
    }

    return (
        <TableRow key={this.props.rowIndex} className={this.props.classes.root}>
            <TableCell key={`${this.props.rowIndex}_name`} 
                    className={clsx(this.props.classes.root, this.props.classes.cellName)}
                    padding="default">
                <Tooltip title={this.props.row.label} >
                    <Typography>{this.props.row.label}</Typography>
                </Tooltip>
            </TableCell>
            {this.props.row.days.map ( (col, index) => {
                    const key= `${this.props.rowIndex}_d${index}`
                    return (
                        <CalendarCell key={key} 
                                colIndex={key} 
                                descriptor={col}
                                refType={this.props.refType} 
                                elementId={this.props.row.id}
                                onClick={this.props.onClick} />
                    )
            })}
            {noDays.map( (col, index) => {
                return (
                    <Tooltip key={`t_${index}`} title="Jour n'existant pas" >
                        <TableCell key={index}
                                className={this.props.classes.noDay} />
                    </Tooltip>
                )
            })}
        </TableRow>
    )
  }
}

const classes = theme => ({
    root: {
        borderWidth: 'thin',
        borderStyle: 'solid',
    },
    cellName: {
        margin: 0,
        padding: '5px',
        overflowX: 'hidden',
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '10em',
    },
    noDay: {
        backgroundColor: theme.palette.primary.main
    }
})

export default withStyles(classes) (CalendarRow)
