import React, { Component } from 'react'
import {withStyles,
        Container, Toolbar, 
        FormControl,
        Typography, Button,
        Card, CardHeader, CardContent, 
        } from '@material-ui/core'
import {Alert, AlertTitle} from '@material-ui/lab'
import { connect } from 'react-redux'
import {setTitle} from '../../core/redux/actions/WindowAppActions'

import {addMonths, startOfMonth} from 'date-fns'

import {DRIVER_TYPE} from '../../core/components/Constants'
import DatePickerFr from '../../core/components/forms/date/DatePickerFr'
import HelpCard from '../../core/components/window/HelpCard'
import WaitBlock from '../../core/components/window/WaitBlock'
import HelpButton from '../../core/components/window/HelpButton'
import CloseButton from '../../core/components/window/CloseButton'
import ElementDataGrid from '../../core/components/forms/select/ElementDataGrid'
import TransportCodeConfig from '../../core/components/parameters/TransportCode'
import DisplayGrid from '../../core/components/offenses/display/Results'

import DriverIcon from '@material-ui/icons/Face'

import {calculateOffenses} from '../../core/redux/actions/RegulationActions'

class Search extends Component {

  state = {
    beginDate: null,
    endDate: null,
    selection: [],
    displaySelection: true,
    periodExamined: {
      begin: null,
      end: null,
    },
    titleDisplayed: false,
  }

  componentDidMount (){

    this.props.setTitle('Recherche des infractions')

    const today = new Date()
    const begin = startOfMonth(addMonths(today, -1))
    this.setState({
      beginDate: begin,
      endDate: today,
      periodExamined: {
        begin: begin,
        end: today,
      }
    })
  } 

  changeDate = (field, newDate) => {
    let newState = {}
    newState[field] = newDate
    let state = Object.assign(this.state, newState)

    state = Object.assign(state, {
      titleDisplayed: ( (state.periodExamined.begin.valueOf() !== state.beginDate.valueOf())
                        || (state.periodExamined.end.valueOf() !== state.endDate.valueOf()))
    })
    this.setState(state)
    
  }

  displaySelection = () => {
    this.setState({
      displaySelection: !this.state.displaySelection
    })
  }

  changeSelection = (type, selection) => {
    this.setState({
      selection: selection
    })
  }
  calculate = () => {
    this.setState({
      displaySelection: false,
      periodExamined: {
        begin: this.state.beginDate,
        end: this.state.endDate,
      },
      titleDisplayed: false,
    })
    
    let drivers = this.state.selection.map(id => {
      let driver = this.props.drivers.find(driver => driver.id === id)
      return {
        id: id,
        code: driver.drv_code
      }
    })
    
    this.props.calculateOffenses(this.state.beginDate, this.state.endDate, drivers)
  
  }

  render() {

    const labelSelection = (this.state.selection.length > 1) ? `${this.state.selection.length} conducteurs` : `${this.state.selection.length} conducteur`

    return (
      <Container className={this.props.classes.root}>

        {(this.props.transportCode === null || this.props.transportCode === '') &&
          <>
              <Alert severity="warning" >
                  <AlertTitle>Configuration nécessaire</AlertTitle>
                  <Typography variant="caption" gutterBottom>
                    Préalablement à l'usage du module de réglementation, vous devez définir la réglementation applicable à votre entreprise.
                  </Typography>
                  <TransportCodeConfig />
              </Alert>
              
          </>
        }
          <Toolbar className={this.props.classes.toolbar} >
            <FormControl className={this.props.classes.searchTools}>
              <DatePickerFr 
                  label="Début de période" 
                  value={this.state.beginDate} 
                  minDate={this.props.companyCreation} 
                  maxDate={this.state.endDate} 
                  autoOk
                  onChange={newDate => this.changeDate('beginDate', newDate)} />
              <DatePickerFr 
                  label="Fin de période"
                  value={this.state.endDate} 
                  minDate={this.state.beginDate}
                  disableFuture 
                  autoOk
                  onChange={newDate => this.changeDate('endDate', newDate)} />
              <Button variant="outlined"
                  color='primary'
                  onClick={this.displaySelection}>
                <DriverIcon />{labelSelection}
              </Button>
              
              <Button variant="contained"
                  color="primary"
                  disabled={this.state.selection.length === 0 || this.props.loading || this.props.transportCode === ''}
                  onClick={this.calculate}
                  >
                {this.props.loading ? 'Calculs en cours...' : 'Calculer'}
              </Button>

            </FormControl>

            <HelpButton>
              <HelpCard title="Aide en ligne">
                <div className={this.props.classes.help}>
                  Cet écran permet de calculer les infractions à la réglementation du transport à partir des activités téléchargées par vos conducteurs.
                </div>
              </HelpCard>
            </HelpButton>
            
          </Toolbar>
          
          {this.state.displaySelection ?
            <Card >
              <CardHeader
                // avatar={<DriverIcon />}
                title="Sélection des conducteurs"
                action={
                  <CloseButton title="Fermer la fenêtre de sélection"
                    onClick={this.displaySelection} />
                } />
              <CardContent>
                <ElementDataGrid 
                    type={DRIVER_TYPE} 
                    elements={this.props.drivers}
                    selection={this.state.selection}
                    onChange={this.changeSelection}
                    />
              </CardContent>
              
            </Card> :
            this.state.selection.length === 0 ? 
              <Typography variant="caption">Veuillez définir vos critères de recherche.</Typography> :
                this.props.loading ? 
                  <WaitBlock text="Calculs en cours..." /> :
                  !this.props.data ? 
                    <span>Aucune donnée à afficher</span> :
                    <DisplayGrid 
                        period={this.state.periodExamined} 
                        titleDisplayed={this.state.titleDisplayed}
                        />
          }
        
      </Container>
    )
  }
}

const mapStateToProps = state => ({
    loading: state.data.loading,
    companyCreation: state.client.informations.ow.creation,
    drivers: state.client.drivers,
    data: state.data.values,
    transportCode: state.regulation.actualCode,
})

const mapDispatchToProps = ({
  setTitle,
  calculateOffenses,
})

const classes = theme => ({
    root: {
      color: theme.palette.primary.main,
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    searchTools: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      minWidth: '55em',
    },
    button: {
      color: theme.palette.primary.main
    },
    help: {
      textAlign: 'justify',
    }
})

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (Search))
