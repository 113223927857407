
/**
 * équivalent php = array_unique
 * @param {array} anArray 
 * @returns 
 */
export function arrayUnique(anArray) {

	if (!Array.isArray(anArray)) {
		throw new Error('Array mandatory')
	}

	return anArray.filter( (value, index, self) => {
		return self.indexOf(value) === index;
	})

}

