import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ButtonOw from './ButtonOw'

import { FileTableBoxOutline as TableIcon } from 'mdi-material-ui'

class TabButton extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,             /* désactivation possible du bouton */
  }

  render() {
    return (
        <ButtonOw {...this.props} disabled={this.props.disabled}>
          <TableIcon />
        </ButtonOw> 
    )
  }
}

export default TabButton
