import { applyMiddleware, combineReducers, createStore } from "redux";
import ReduxThunk from 'redux-thunk'
import PromiseMiddleware from 'redux-promise-middleware'
import {apiMiddleware} from './middleware/apiMiddleware'
import IdentityReducer from "./reducers/IdentityReducer";
import ClientReducer from './reducers/ClientReducer'
import DataReducer from './reducers/DataReducer'
import RegulationReducer from './reducers/RegulationReducer'
import TerminalsReducer from './reducers/TerminalsReducer'

/*
 * Console de débogage
 */
import { composeWithDevTools,
        // devToolsEnhancer
         } from 'redux-devtools-extension/developmentOnly';
import WindowAppReducer from "./reducers/WindowAppReducer";

export const store = createStore(combineReducers(
    {
        identity: IdentityReducer,
        windowApp: WindowAppReducer,
        client: ClientReducer,
        data: DataReducer,
        regulation: RegulationReducer,
        terminals: TerminalsReducer,
    }
), composeWithDevTools(applyMiddleware(ReduxThunk, PromiseMiddleware, apiMiddleware) ));
// devToolsEnhancer());