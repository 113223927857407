import {formatToTime} from '../../../components/tools/datetimeFunctions'
import DateFnsAdapter from '@date-io/date-fns'

const dataAdapter = data => {

    const dateFns = new DateFnsAdapter()
    let infos = []
    let dureeCTDQ = 0
    let dureeC = 0
    let dureeT = 0
    let dureeD = 0
    let dureeQ = 0
    let km = 0
    let vitesse = 0
    let dureeR = 0
    let dureeNuit = 0
    let totalAmplitude = 0
    const nbAct = data.length
    let nbSpeedDays = 0

    let weekNumber = 0
    let wDureeCTDQ = 0
    let wDureeC = 0
    let wDureeT = 0
    let wDureeD = 0
    let wDureeQ = 0
    let wKm = 0
    let wVitesse = 0
    let wDureeR = 0
    let wDureeNuit = 0
    let wtotalAmplitude = 0
    let wNbDays = 0

    data.forEach( (infosDay, index) => {

        let added = {
            index: index,
        }
        let day = new Date(infosDay.jour)
        let currentWeekNumber = dateFns.format(day, 'II')
    
        if (weekNumber === 0) {
            weekNumber = currentWeekNumber
        } else if ( (weekNumber > 0)
                && (currentWeekNumber !== weekNumber)
        ) {
            
            infos.push({
                index: `week${weekNumber}`,
                typeRow: 'weekInfos',
                debut: 'Total semaine ' + weekNumber,
                c: formatToTime(wDureeC),
                c_val: wDureeC,
                t: formatToTime(wDureeT),
                t_val: wDureeT,
                d: formatToTime(wDureeD),
                d_val: wDureeD,
                q: formatToTime(wDureeQ),
                q_val: wDureeQ,
                cdtq: formatToTime(wDureeCTDQ),
                cdtq_val: wDureeCTDQ,
                nuit: formatToTime(wDureeNuit),
                nuit_val: wDureeNuit,
                r: formatToTime(wDureeR),
                r_val: wDureeR,
                km: wKm,
                vitesse: (wVitesse / wNbDays).toFixed(2),
                amplitude: formatToTime(wtotalAmplitude),
                amplitude_val: wtotalAmplitude,
            })
            wNbDays = 0
            weekNumber = currentWeekNumber
            wDureeC = 0
            wDureeT = 0
            wDureeD = 0
            wDureeQ = 0
            wDureeCTDQ = 0
            wDureeNuit = 0
            wDureeR = 0
            wKm = 0
            wVitesse = 0
            wtotalAmplitude = 0
        }
        
        wDureeC += infosDay.c_val
        wDureeT += infosDay.t_val 
        wDureeD += infosDay.d_val
        wDureeQ += infosDay.q_val
        wDureeCTDQ += infosDay.cdtq_val 
        wDureeNuit += infosDay.nuit_val 
        wDureeR += infosDay.r_val
        if (typeof(infosDay.km) === 'number') {
            wKm += infosDay.km
            km += infosDay.km
        }
        if (typeof(infosDay.vitesse) === 'number') {
            wVitesse += infosDay.vitesse
            vitesse += infosDay.vitesse
            wNbDays++
            nbSpeedDays++
        }
        wtotalAmplitude += infosDay.amplitude_val
        
        dureeCTDQ += infosDay.cdtq_val
        dureeC += infosDay.c_val
        dureeT += infosDay.t_val
        dureeD += infosDay.d_val
        dureeQ += infosDay.q_val
        dureeR += infosDay.r_val
        dureeNuit += infosDay.nuit_val
        totalAmplitude += infosDay.amplitude_val
        
        Object.assign(infosDay, added)
        infos.push(infosDay)

    })
    
    if (nbAct > 0) {

        infos.push({
            index: `week${weekNumber}`,
            typeRow: 'weekInfos',
            debut: 'Total semaine ' + weekNumber,
            c: formatToTime(wDureeC),
            c_val: wDureeC,
            t: formatToTime(wDureeT),
            t_val: wDureeT,
            d: formatToTime(wDureeD),
            d_val: wDureeD,
            q: formatToTime(wDureeQ),
            q_val: wDureeQ,
            cdtq: formatToTime(wDureeCTDQ),
            cdtq_val: wDureeCTDQ,
            nuit: formatToTime(wDureeNuit),
            nuit_val: wDureeNuit,
            r: formatToTime(wDureeR),
            r_val: wDureeR,
            km: wKm,
            vitesse: (wVitesse / wNbDays).toFixed(2),
            amplitude: formatToTime(wtotalAmplitude),
            amplitude_val: wtotalAmplitude,
        })

        infos.push({
            index: 'month',
            debut: 'Total mois',
            c: formatToTime(dureeC),
            c_val: dureeC,
            t: formatToTime(dureeT),
            t_val: dureeT,
            d: formatToTime(dureeD),
            d_val: dureeD,
            q: formatToTime(dureeQ),
            q_val: dureeQ,
            cdtq: formatToTime(dureeCTDQ),
            cdtq_val: dureeCTDQ,
            nuit: formatToTime(dureeNuit),
            nuit_val: dureeNuit,
            r: formatToTime(dureeR),
            r_val: dureeR,
            km: km,
            vitesse: (vitesse/nbSpeedDays).toFixed(2),
            amplitude: formatToTime(totalAmplitude),
            amplitude_val: totalAmplitude,
        })
    }

    return infos
}

export default dataAdapter