import React, { Component } from 'react'
import PropTypes from 'prop-types' 
import {connect} from 'react-redux'
import DateAdapter from '@date-io/date-fns'
import {addMonths} from 'date-fns'
import ElementSelection from '../../select/ElementSelection'
import DatePickerFr from '../../date/DatePickerFr';
import {withStyles} from '@material-ui/styles'
import { FormControl } from '@material-ui/core';
import SearchResetIcon from '@material-ui/icons/YoutubeSearchedFor'
import ButtonOw from '../../../window/ButtonOw';

class IntervalSelector extends Component {

  static propTypes = {
    onChange: PropTypes.func.isRequired,    /* fonction de rappel lors du changement de la sélection */
    uniqueType: PropTypes.string,           /* éventuellement, unique type à utiliser */
  } 
  state = {

    isChanged : false,

    /*
    * élément sélectionné
    */
    selection : null,

    /*
    * Période examinée
    */
    begin : null,
    end : null,
    
  }
  
  datesInit = () => {

    const dateAdapter = new DateAdapter ()
        
    let begin = dateAdapter.startOfDay (new Date())
    let end = dateAdapter.endOfDay (new Date ())
    begin = addMonths (begin, -1)
    
    return {
      begin: begin,
      end: end,
    }

  }
  componentDidMount () {

    const {begin, end} = this.datesInit()
    this.setState ({
      begin : begin,
      end : end,
    })

  }

  componentDidUpdate(prevProps, prevState) {
    
    if (prevState !== this.state
        // && this.state.selection
        ) {

      if (this.state.selection) {
        this.props.onChange ({
          type: this.state.selection.type,
          id: this.state.selection.id,
          begin : this.state.begin,
          end : this.state.end,
        })
      } else {
        this.props.onChange({
          type: this.props.uniqueType,
          id: 0,
          begin: this.state.begin,
          end: this.state.end
        })
      }
    }
  }
  
  dateChange = (value, name) => {
    this.setState ({
      [name]: value
    })
  }

  handleSelection = (selection) => {

    this.setState({
        selection : selection
    })

  }

  reset = () => {
    const {begin, end} = this.datesInit()
    this.setState({
      isChanged : true,
      selection : null,
      begin: begin,
      end: end
    })
  }

  render() {
    
    return (
      <>
        <FormControl className={this.props.classes.selector}>
          <ElementSelection 
            onChange={this.handleSelection} 
            id={this.state.selection?.id} 
            uniqueType={this.props.uniqueType} 
            />
          <FormControl className={this.props.classes.intervalSelector}>
            <DatePickerFr
                id="begin-date-picker"
                name="beginDate"
                variant="inline" 
                label="Début de la période" 
                disableFuture={true}
                autoOk={true}
                value={this.state.begin}
                minDate={this.props.companyCreation}
                maxDate={this.state.end}
                onChange={(value) => {this.dateChange (value, 'begin')}}
                lang='FR'
                />
            <DatePickerFr
                name="end"
                id="end-date-picker"
                variant="inline" 
                label="Fin de la période"
                autoOk={true}
                value={this.state.end}
                minDate={this.state.begin}
                disableFuture={true}
                onChange={(value) => {this.dateChange (value, 'end')}} 
                />
          </FormControl>
        </FormControl>
        <FormControl>
          <ButtonOw title="Réinitialiser la recherche" onClick={this.reset}>
            <SearchResetIcon />
          </ButtonOw>
        </FormControl>
      </>
    )
  }
}

const classes = {
  selector : {
    color: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minWidth: '51em',
  },
  intervalSelector: {
    display: 'flex',
    flexDirection: 'row',
  }
}

const mapStateToProps = state => ({
  companyCreation: state.client.informations.ow.creation
})

export default connect(mapStateToProps)(withStyles(classes)(IntervalSelector))
