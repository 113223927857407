import React, { Component } from 'react'
import {Tooltip} from '@material-ui/core'
import SourceCreditCardIcon from '@material-ui/icons/CreditCard'

/**
 * Source = carte de conducteur
 * numéro = 2
 */
class CardSource extends Component {
  render() {
    return (
      <Tooltip title="Carte de conducteur">
          <SourceCreditCardIcon />
      </Tooltip>
    )
  }
}

export default CardSource
