import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withStyles, Paper, Toolbar,
        TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
        Checkbox, Tooltip, IconButton, Typography,
        } from '@material-ui/core'
import PrintButton from '../window/PrintButton'
import toPrint from '../tools/toPrint'
import ExportButton from '../window/ExportButton'
import {exportWithoutCardActivities} from './exportActivities'
import {format} from 'date-fns'
import localeFr from 'date-fns/locale/fr'
import AttributionIcon from '@material-ui/icons/HowToReg'

class WithoutCardActDataGrid extends Component {
  static propTypes = {
    data: PropTypes.array,                    /* potentiellement peut être undefined, en particulier lors du chargement */
    dateRef: PropTypes.object.isRequired,     /* date de référence du tableau */
    code: PropTypes.string.isRequired,        /* code du véhicule examiné */
    onSelect: PropTypes.func.isRequired,      /* fonction gérant les attributions */ 
  }

  columns = [
    {
      field: 'date',
      headerName: 'Date et heure',
      type: 'dateTime',
      flex: 2
    },
    {
      field: 'sActivity',
      headerName: 'Activité',
      flex: 1,
    },
    {
      field: 's_durat',
      headerName: 'Durée',
      flex: 1,
    },
    {
      field: 'distance',
      headerName: 'Distance',
      flex: 1
    }
  ]

  state = {
    data: [],
    selection: [],
  }

  componentDidMount() {
    if (typeof(this.props.data) === 'object' 
        && (this.props.data.length > 0)) {
      this.updateData()
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.updateData()
    }
  }

  updateData = () => {
    const data = this.props.data.map(row => {
      row.id = row.index
      row.date = new Date(row.datetime)
      row.sDate = format(row.date, 'Pp', {locale: localeFr})
      return row
    })
    this.setState({
      data: data,
      selection: [],
    })
  }

  handleAllChecked = () => {
    let newSelection = []
    if (this.state.selection.length !== this.state.data.length) {
      newSelection = this.state.data.map(elt => elt.index)
    } 
    this.setState({
      selection: newSelection,
    })
  }
  handleCheck = (evt) => {
    const index = Number.parseInt(evt.target.value)
    let newSelection = []
    
    if (this.state.selection.includes(index)) {
      newSelection = this.state.selection.filter( value => value !== index)
    } else {
      this.state.selection.push(index)
      newSelection = this.state.selection
    }
    this.setState({
      selection: newSelection
    })
    
  }

  handleAssignCard = () => {
    this.props.onSelect(this.state.selection.map(index => this.state.data[index]))
  }
  print = () => {
    console.info ('Print datagrid of activities without card ')
    
    const client = this.props.clients[this.props.idClient]
    const title = `${this.props.code} - Activités sans carte - ${format(this.props.dateRef, 'MMMM y', {locale: localeFr})}`
    
    let html = '<table>'
        + '<thead><tr>'
        + '<th>Date et heure</th>'
        + '<th>Activités</th>'
        + '<th>Durée</th>'
        + '<th>Distance</th>'
        + '</tr></thead>'
        + '<tbody>'
    this.state.data.forEach(row => {
      html += '<tr>'
          + '<td>' + row.sDate + '</td>'
          + '<td>' + row.sActivity + '</td>'
          + '<td>' + row.s_durat + '</td>'
          + '<td>' + row.distance + '</td>'
          + '</tr>'
    })
    html += '</tbody></table>'

    toPrint(html, client.nom_client, title, this.props.logo)
  }

  export = () => {
    exportWithoutCardActivities(this.state.data, 'activitesSansCarte')
  }
  render() {
    
    return (
      <Paper className={this.props.classes.root}>
        <Toolbar className={this.props.classes.toolbar} >
          <Typography variant="caption">Activités sélectionnées = {this.state.selection.length} / {this.state.data.length}</Typography>
          <div className={this.props.classes.selection}>
            {this.state.selection.length > 0 ?
              <Tooltip title="Attribuer à un conducteur">
                  <IconButton aria-label="attribution" onClick={this.handleAssignCard}>
                      <AttributionIcon />
                  </IconButton>
              </Tooltip> :
              <>
                <ExportButton title="Exporter en CSV" onClick={this.export} />
                <PrintButton title="Imprimer" onClick={this.print} />
              </>
            }
          </div>
        </Toolbar>
        <TableContainer id="actWithoutCard" >
            <Table size='small' >
                <TableHead>
                <TableRow>
                    <TableCell>
                      <Checkbox 
                          checked={this.state.selection.length === this.state.data.length && this.state.data.length > 0} 
                          indeterminate={this.state.selection.length > 0 && this.state.selection.length !== this.state.data.length} 
                          onChange={this.handleAllChecked}/>
                    </TableCell>
                    <TableCell>Date et heure</TableCell>
                    <TableCell>Activité</TableCell>
                    <TableCell>Durée</TableCell>
                    <TableCell>Distance</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.data.length === 0 && 
                    <TableRow>
                      <TableCell colSpan={5} className={this.props.classes.colspan}>
                        Aucune activité à attribuer
                      </TableCell>
                    </TableRow>
                  }
                  {this.state.data.map((act, i) => {
                    return <TableRow key={`act_${i}`} className={this.props.classes.row}>
                      <TableCell>
                        <Checkbox 
                            checked={this.state.selection.includes(act.index)}
                            onChange={this.handleCheck}
                            value={act.index}
                            />
                      </TableCell>
                      <TableCell>{act.sDate}</TableCell>
                      <TableCell>{act.sActivity}</TableCell>
                      <TableCell>{act.s_durat}</TableCell>
                      <TableCell>{act.distance}</TableCell>
                    </TableRow>
                  })}
                </TableBody>
            </Table>
        </TableContainer>
        
      </Paper>
    )
  }
}

const mapStateToProps = state => ({
  drivers: state.client.drivers,
  clients: state.identity.clients,
  idClient: state.identity.idClientEnCours,
  logo: state.client.logo,
})

const classes = theme => ({
  root: {
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selection: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  row: {
    '&:hover': {
      backgroundColor: '#0000000A',
    }
  },
  colspan: {
    textAlign: 'center'
  }
})
export default connect(mapStateToProps) (withStyles(classes) (WithoutCardActDataGrid))
