
import React, { Component } from 'react'
import {connect} from 'react-redux'

import {setTitle, setError, } from '../../core/redux/actions/WindowAppActions'
import {resetData} from '../../core/redux/actions/DataActions'
import {getUploads} from '../../core/redux/actions/ElementsActions'

import {Container,  Toolbar,
        withStyles
      } from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import WaitBlock from '../../core/components/window/WaitBlock'
import HelpButton from '../../core/components/window/HelpButton'
import HelpCard from '../../core/components/window/HelpCard'
import UploadsGrid from '../../core/components/archivage/UploadsGrid'


// import { DataGrid } from 'tubular-react'
import {format} from 'date-fns'
import localeFr from 'date-fns/locale/fr'

import Selector from '../../core/components/forms/archivage/telechargements/IntervalSelector'
import { DRIVER_TYPE, VEHICLE_TYPE } from '../../core/components/Constants';

class Telechargements extends Component {

    state = {
      selection: null,
      data: [],
    }

    componentDidMount() {
      this.props.setTitle ('Téléchargements de fichiers')
    }
    
    componentDidUpdate(prevProps, prevState) {
      if (prevProps.values !== this.props.values) {
        if (this.props.values) {
          this.setState({
            data: this.props.values.map(row => {
              row.id = row.id_fichier
              return row
            })
          })
        } else {
          this.setState({
            data: []
          })
        }
      }
    }
    loadData = (selection) => {
      
      if (selection.id > 0) {

        this.props.getUploads (selection.type, selection.id, selection.begin, selection.end)

      } else {
        this.props.resetData ()
      }

      this.setState({
        selection: selection
      })

    }
    
    getGridName = () => {

      if (!this.state.selection) {
        return 'grid'
      }

      let element
      let name = ''
      if (this.state.selection.type === DRIVER_TYPE) {
        element = this.props.drivers.find(driver => driver.id === this.state.selection.id)
        name = `${element.name} ${element.forename} - `
      } else {
        element = this.props.vehicles.find(vehicles => vehicles.id === this.state.selection.id)
        name = `${element.veh_reg_no} - `
      }
      name += format(this.state.selection.begin, 'P', {locale: localeFr})
          + ' au ' + format(this.state.selection.end, 'P', {locale: localeFr})

      return name
    }

    render() {
      
      let lbInfos = (<div className={this.props.classes.selectAsked}>Veuillez effectuer une sélection</div>)
      if (this.state.selection) {
        if (this.state.selection.type === DRIVER_TYPE
              && this.props.drivers.length === 0) {
            lbInfos = <Alert severity='warning'>Aucun conducteur à visualiser</Alert>
        } else if (this.state.selection.type === VEHICLE_TYPE
              && this.props.vehicles.length === 0) {
            lbInfos = <Alert severity='warning'>Aucun véhicule à visualiser</Alert>
        }
      }    
      
      return (
        <Container className={this.props.classes.root}>
          <Toolbar className={this.props.classes.toolbar}>
            <Selector onChange={this.loadData} />
            <HelpButton>
              <HelpCard title="Aide de l'écran">
                <div className={this.props.classes.memo}>
                Cet écran permet de visualiser les fichiers téléchargés pour une période donnée ainsi que leurs périodes d'activités.
                </div>
              </HelpCard>
            </HelpButton>
          </Toolbar>
          {this.props.loading ?
            <WaitBlock /> :
                this.props.values  ? (
                  <UploadsGrid 
                      data={this.state.data}
                      title={this.getGridName()} />
                ) :
                  lbInfos
                
          }
        </Container>
      )
    }
}

const styles = theme => ({
  root: {
    color: theme.palette.primary.main,
  },
  toolbar : {
    display : 'flex',
    justifyContent: 'space-between',
    alignItems : 'center',
    
  },
  selectAsked: {
    display: 'flex',
    minHeight: '8em',
    justifyContent: 'center',
    alignItems: 'center',
  },
  memo: {
    textAlign: 'justify',
  }
})

const mapStateToProps = state => ({
  loading: state.data.loading,
  values: state.data.values,
  drivers: state.client.drivers,
  vehicles: state.client.vehicles,
})

const mapDispatchToProps = ({
    setTitle,
    setError,
    resetData,
    getUploads,
})
export default connect (mapStateToProps, mapDispatchToProps) (withStyles (styles) (Telechargements))
