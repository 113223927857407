import React, { Component } from 'react'
import {connect} from 'react-redux'
import {setTitle} from '../../core/redux/actions/WindowAppActions'
import {downloadArchive} from '../../core/redux/actions/FilesActions'
import {resetData} from '../../core/redux/actions/DataActions'
import {withStyles, Container,
        Toolbar, Tab,
        Button, 
      } from '@material-ui/core'
import {TabContext, TabList, TabPanel, Alert} from '@material-ui/lab'
import DatePickerFr from '../../core/components/forms/date/DatePickerFr'
import {DRIVER_TYPE, VEHICLE_TYPE} from '../../core/components/Constants'
import ElementDataGrid from '../../core/components/forms/select/ElementDataGrid'
import HelpButton from '../../core/components/window/HelpButton'
import HelpCard from '../../core/components/window/HelpCard'

import {addMonths, startOfMonth} from 'date-fns'

import DriverIcon from '@material-ui/icons/Face'
import VehicleIcon from '@material-ui/icons/LocalShipping'

class Archives extends Component {

  idTabDriver = `tab_${DRIVER_TYPE}`
  idTabVehicle = `tab_${VEHICLE_TYPE}`

  sLabelButton = "Télécharger l'archive"
  sLabelButtonWaitingDownload = "Récupération en cours..."

  sLabelButtonOverflow = 'Sélection trop importante'
  state = {
    tab: '1',
    beginDate: null,
    endDate: null,
    driversSelected: [],
    vehiclesSelected: [],
    btDisabled: true,
    btLabel: this.sLabelButton,
    overflow: false,
  }

  componentDidMount () {
  
    this.props.setTitle ('Récupération des fichiers archivés')
    const today = new Date()
  
    this.setState({
      beginDate: startOfMonth(addMonths(today, -1)),
      endDate: today
    })

  }

  handleChangeTab = (evt, newValue) => {
    evt.preventDefault()
    evt.stopPropagation()
    this.setState({
      tab: newValue,
    })
  }

  handleChangeBeginDate = (newDate) => {

    const isDisabled = (this.state.driversSelected.length === 0 && this.state.vehiclesSelected.length === 0)

    this.setState({
      beginDate: newDate,
      btDisabled: isDisabled,
    })
  }

  handleChangeEndDate = (newDate) => {

    const isDisabled = (this.state.driversSelected.length === 0 && this.state.vehiclesSelected.length === 0)

    this.setState({
      endDate: newDate,
      btDisabled: isDisabled,
    })
  }

  changeSelection = (type, selection) => {

    const isOverflow = selection.length > 10
    const isDisabled = (selection.length === 0 || isOverflow)
    
    if (type === DRIVER_TYPE) {
      this.setState({
        driversSelected: selection,
        btDisabled: isDisabled,
        overflow: isOverflow,
        btLabel: (isOverflow ? this.sLabelButtonOverflow : this.sLabelButton)
      })
    } else {
      this.setState({
        vehiclesSelected: selection,
        btDisabled: isDisabled,
        overflow: isOverflow,
        btLabel: (isOverflow ? this.sLabelButtonOverflow : this.sLabelButton)
      })
    }

  }

  getLabelTabDrivers = () => {

    return (
      <div><DriverIcon /> Choix des conducteurs
        <div className={this.props.classes.infoSelection}>
          {this.state.driversSelected.length <= 1 ?
            <span>{this.state.driversSelected.length} conducteur</span>:
            <span>{this.state.driversSelected.length} conducteurs</span>
          }          
        </div>
      </div>
    )
  }

  getLabelTabVehicles = () => {

    return (
      <div><VehicleIcon /> Choix des véhicules
        <div className={this.props.classes.infoSelection}>
          {this.state.vehiclesSelected.length <= 1 ?
            <span>{this.state.vehiclesSelected.length} véhicule</span>:
            <span>{this.state.vehiclesSelected.length} véhicules</span>
          }          
        </div>
      </div>
    )
  }
  
  download = () => {

    this.setState({
      btDisabled: true,
    })
    const now = new Date();
    const fileName = 'ARCHIVE_' + now.toISOString() + '.zip' 
    
    this.props.downloadArchive(fileName, this.state.beginDate, this.state.endDate, this.state.driversSelected, this.state.vehiclesSelected)

  }
  render() {
    
    return (
      <Container className={this.props.classes.root}>
        <TabContext value={this.state.tab} >
          <Toolbar className={this.props.classes.toolbar}>
            <DatePickerFr 
              label="Début de période"
              value={this.state.beginDate}
              minDate={startOfMonth(this.props.companyCreation)} 
              maxDate={this.state.endDate} 
              onChange={this.handleChangeBeginDate}
              autoOk />
            <DatePickerFr 
              label='Fin de période'
              value={this.state.endDate}
              minDate={this.state.beginDate} 
              disableFuture
              onChange={this.handleChangeEndDate}
              autoOk />
            {this.props.downloadable ?
              <Button variant="contained" 
                  color='primary'
                  disabled={this.state.btDisabled}
                  onClick={this.download}>
                { this.props.loading ? this.sLabelButtonWaitingDownload : this.state.btLabel}
              </Button> :
              <Alert severity="warning">
                  Téléchargements désactivés. Veuillez contacter le support.
              </Alert>
              
            }

            <HelpButton>
              <HelpCard title="Aide de l'écran">
                <div className={this.props.classes.memo}>
                  <div>
                    Cet écran permet de récupérer les fichiers téléchargés pour une période donnée, en vue d'une sauvegarde interne ou d'un contrôle par exemples.
                  </div>
                  <div  >
                    Pour des raisons techniques de sécurité, le téléchargement est, pour le moment, limité à 10 conducteurs et 10 véhicules simultanément. 
                    N'hésitez pas à contacter le support technique.
                  </div>
                </div>
              </HelpCard>
            </HelpButton>
          </Toolbar>
          <TabList onChange={this.handleChangeTab} aria-label='Choix conducteurs et véhicules'>
                <Tab label={this.getLabelTabDrivers()} 
                value='1' 
                id={this.idTabDriver} 
                className={this.props.classes.labelTab} />
            <Tab label={this.getLabelTabVehicles()} 
                value='2'
                id={this.idTabVehicle} 
                className={this.props.classes.labelTab} />
          </TabList>

          <TabPanel value='1' >
              <ElementDataGrid type={DRIVER_TYPE} 
                  elements={this.props.drivers} 
                  selection={this.state.driversSelected} 
                  onChange={this.changeSelection} />
          </TabPanel>

          <TabPanel value='2'>
              <ElementDataGrid type={VEHICLE_TYPE} 
                  elements={this.props.vehicles}
                  selection={this.state.vehiclesSelected}
                  onChange={this.changeSelection} />
          </TabPanel>

        </TabContext>
      </Container>
      
    )
  }
}

const mapStateToProps = state => ({
  loading: state.data.loading,
  companyCreation: state.client.informations.ow.creation,
  drivers: state.client.drivers,
  vehicles: state.client.vehicles,
  downloadable: state.client.downloadable,
  archive: state.data.values,
})

const mapDispatchToProps = ({
  setTitle,
  downloadArchive,
  resetData,
})

const classes = theme => ({
  root: {

  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    color: theme.palette.primary.main,
  },
  labelTab: {
    color: theme.palette.primary.main
  },
  infoSelection: {
    color: theme.palette.secondary.main,
  },
  memo: {
    textAlign: 'justify',
    '&& div': {
      marginBottom: '0.5em',
    }
  }
})

export default connect (mapStateToProps, mapDispatchToProps) (withStyles(classes) (Archives))
