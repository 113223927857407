import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DateFnsAdapter from '@date-io/date-fns'

import ButtonOw from '../../window/ButtonOw'
import DatePickerFr from './DatePickerFr'

import {withTheme, withStyles, FormControl, 
        createMuiTheme, ThemeProvider,
      } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'

class MonthPicker extends Component {
  static propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
  }

  state = {
    maxDate: null,
    examinedDate: null,
    previousMonthDisabled: false,
    nextMonthDisabled: false,
  }

  componentDidMount () {
    
    this.setState({
      maxDate: this.props.disableFuture ? new Date() : this.props.maxDate,
      examinedDate: (this.props.value) ? this.props.value : new Date(),
    })

  }

  onChangeMonth = (value) => {
    
    this.setState({
      examinedDate: value,
      previousMonthDisabled: this.isMonthMin(value),
      nextMonthDisabled: this.isMonthMax(value)
    })

    if (this.props.onChange) {
        this.props.onChange(value)
    }
  }

  selectPreviousMonth = () => {
    const dateFns = new DateFnsAdapter()
    const newDate = dateFns.getPreviousMonth(this.state.examinedDate)
    this.setState({
      examinedDate: newDate,
      previousMonthDisabled: this.isMonthMin(newDate),
      nextMonthDisabled: false,
    })

    if (this.props.onChange) {
        this.props.onChange(newDate)
    }
  }

  selectNextMonth = () => {
    const dateFns = new DateFnsAdapter()
    const nextMonth = dateFns.getNextMonth(this.state.examinedDate)

    this.setState({
      examinedDate: nextMonth,
      previousMonthDisabled: false,
      nextMonthDisabled: this.isMonthMax (nextMonth),
    })
    
    if (this.props.onChange) {
        this.props.onChange(nextMonth)
    }
  }

  selectLastMonth = () => {
    this.setState({
      examinedDate: this.state.maxDate,
      previousMonthDisabled: false,
      nextMonthDisabled: true
    })
    if (this.props.onChange) {
      this.props.onChange(this.state.maxDate)
    }
  }

  selectFirstMonth = () => {
    this.setState({
      examinedDate: this.props.minDate,
      previousMonthDisabled: true,
      nextMonthDisabled: false
    })
    if (this.props.onChange) {
      this.props.onChange(this.props.minDate)
    }
  }
  isMonthMin = (newDate) => {

    let isMin = false
    const dateFns = new DateFnsAdapter()
  
    if ( (dateFns.isSameYear(this.props.minDate, newDate))
          && (dateFns.isSameMonth (this.props.minDate, newDate))
    ) {
        isMin = true
    }

    return isMin
  }
  isMonthMax = (newDate) => {

    let isMax = false
    const dateFns = new DateFnsAdapter()
  
    if ( (dateFns.isSameYear(this.state.maxDate, newDate))
          && (dateFns.isSameMonth (this.state.maxDate, newDate))
    ) {
        isMax = true
    }

    return isMax
  }

  componentDidUpdate (prevProps) {

    if (prevProps.value !== this.props.value) {
      this.setState({
        examinedDate: this.props.value
      })
    }

    if (prevProps.maxDate !== this.props.maxDate) {
      this.setState({
        maxDate: this.props.disableFuture ? new Date() : this.props.maxDate,
      })
    }

    /*
     * Ne pas dépasser les dates min et max
     */
    if (this.state.maxDate
        && this.state.examinedDate > this.state.maxDate) {
      this.setState({
        examinedDate: this.state.maxDate,
      })
    } else if (this.props.minDate
          && this.state.examinedDate < this.props.minDate) {
      this.setState({
        examinedDate: this.props.minDate
      })
    }

    /*
     *  gestion de l'affichage de la flèche "mois suivant"
     */
    if (this.state.nextMonthDisabled
          && !this.isMonthMax(this.state.examinedDate)) {
      this.setState({
        nextMonthDisabled: false
      })
    } else if (!this.state.nextMonthDisabled
          && this.isMonthMax(this.state.examinedDate)) {
      this.setState({
        nextMonthDisabled: true
      })
    }

    /*
     * gestion de l'afficahge du bouton "mois précédent"
     */
    if (this.state.previousMonthDisabled
        && !this.isMonthMin(this.state.examinedDate)) {
      this.setState({
        previousMonthDisabled: false
      })
    } else if (!this.state.previousMonthDisabled
        && this.isMonthMin(this.state.examinedDate)) {
      this.setState({
        previousMonthDisabled: true
      })
    }

  }
  render() {

    const muiAdaptedTheme = createMuiTheme({
      overrides: {
        MuiFormControl: {
          root: {
            // backgroundColor: 'red',
            minWidth: '7.4em',
            flexDirection: 'row',
          }
        },
        MuiInputBase: {
          input: {
            textAlign: 'center',
            color: this.props.theme.palette.primary.main
          }
        },
        MuiTextField: {
          root: {
            minWidth: '9em',
          }
        }
      }
    })
   
    const {id, label} = this.props

    return (
      <ThemeProvider theme={muiAdaptedTheme}>
        <FormControl className={this.props.classes.root}>
          <FormControl id={id} >
              {this.props.minDate && (
                <ButtonOw title="Premier mois" onClick={this.selectFirstMonth} disabled={this.state.previousMonthDisabled}
                   className={this.props.classes.button} >
                  <FirstPage />
                </ButtonOw>
              )}
              <ButtonOw title="Mois précédent" onClick={this.selectPreviousMonth} disabled={this.state.previousMonthDisabled}
                  className={this.props.classes.button} >
                <KeyboardArrowLeft />
              </ButtonOw>
              <DatePickerFr
                  value={this.state.examinedDate}
                  onChange={this.onChangeMonth} 
                  autoOk={true}
                  maxDate={this.state.maxDate}
                  minDate={this.props.minDate}
                  views={['year', 'month']}
                  label={label}
                  />
              <ButtonOw title="Mois suivant" onClick={this.selectNextMonth} disabled={this.state.nextMonthDisabled}
                  className={this.props.classes.button} >
                <KeyboardArrowRight />
              </ButtonOw>
              {this.state.maxDate && (
                <ButtonOw title="Dernier mois" onClick={this.selectLastMonth} disabled={this.state.nextMonthDisabled}
                    className={this.props.classes.button} >
                  <LastPage />
                </ButtonOw>
              )}

          </FormControl>
        </FormControl>
      </ThemeProvider>
    )
  }
}

const classes = theme => ({

  root: {
    
  },
  button: {
    maxWidth: '1em',
  }
})
export default withTheme (withStyles (classes) (MonthPicker))
