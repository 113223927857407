import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {getUser, resetPassword, } from '../../redux/actions/ClientActions'
import SelectAvailableRoll from '../forms/select/AvailableRoll'
import ButtonOw from '../window/ButtonOw'
import CloseButton from '../window/CloseButton'
import WaitBlock from '../window/WaitBlock'
import LabelRoll from '../tools/LabelRoll'
import {withStyles,
        Card, CardContent, CardHeader, CardActions, Button,
        TextField, Switch, MenuItem,
        Tooltip, Typography,
        Dialog, DialogTitle, DialogContent, DialogActions,
        } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import UserIcon from '@material-ui/icons/PersonOutline'
import IdentityIcon from '@material-ui/icons/AccountBox'

class UserForm extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    state = {
        initialUser: null,
        user: null,
        editMode: false,
        displayConfirmResetPassword: false,
    }

    componentDidMount() {
        this.props.getUser(this.props.id)
    }
    componentDidUpdate(prevProps, prevState) {
        
        if (prevProps.id !== this.props.id) {
            this.props.getUser(this.props.id)
        }
        if (prevProps.user !== this.props.user) {
            let user = this.props.user
            if (user) {
              user.alerte = user.alerte === 1  
            }            
            this.setState({
                initialUser: user,
                user: user,
            })
        }
    }

    switchEdit = () => {
        this.setState({
            editMode: !this.state.editMode,
        })
    }
    onChange = (evt) => {
        const field = {}
        const fieldName= evt.target.name
        field[fieldName] = evt.target.value
        const user = Object.assign({}, this.state.user, field)
        this.setState({
            user: user,
        })
    }

    onChangeAlert = () => {
        this.setState({
            user: Object.assign({}, this.state.user, {alerte: !this.state.user.alerte})
        })
    }

    onCancel = () => {
        this.setState({
            user: this.state.initialUser,
            editMode: false,
        })
        this.props.onClose()
    }
    resetEdit = () => {
        this.setState({
            user: this.state.initialUser,
        })
    }

    validate = () => {
        const changes = {}
        
        if (this.state.initialUser.nom !== this.state.user.nom) {
            changes.name = this.state.user.nom
        }
        if (this.state.initialUser.prenom !== this.state.user.prenom) {
            changes.forename = this.state.user.prenom
        }
        if (this.state.initialUser.email !== this.state.user.email) {
            changes.email = this.state.user.email
        }
        if (this.state.initialUser.fonction !== this.state.user.fonction) {
            changes.function = this.state.user.fonction
        }
        if (this.state.initialUser.alerte !== this.state.user.alerte) {
            changes.alert = this.state.user.alerte
        }
        if (this.state.initialUser.statut !== this.state.user.statut) {
            changes.roll = this.state.user.statut
        }
        if (this.state.initialUser.langue !== this.state.user.langue) {
            changes.language = this.state.user.langue
        }
        this.props.onChange(changes)
        this.switchEdit()
    }

    displayConfirmResetPassword = () => {
        this.setState({
            displayConfirmResetPassword: !this.state.displayConfirmResetPassword,
        })
    }

    confirmResetPassword = () => {
        this.props.resetPassword(this.props.id)
        this.displayConfirmResetPassword()
    }
    render() {
        
        const sTitle = this.props.actualUser.supportAthmo ? `Utilisateur id ${this.state.user?.id}` : ''
        const title = this.props.actualUser.identifiant === this.state.user?.id ?
                <span><IdentityIcon /> Moi</span> :
                <span><UserIcon />Utilisateur</span>
        const titleCard = 
            <Tooltip title={sTitle}>
                {title}
            </Tooltip>
        const cardActions = 
            <>
                {!this.state.editMode && 
                    <>
                    <ButtonOw title='Édition' onClick={this.switchEdit}><EditIcon /></ButtonOw>
                    <CloseButton onClick={this.props.onClose} />
                    </>
                }
                
            </>

        return (
            <>
            <Card className={this.props.classes.root}>
                <CardHeader title={titleCard}
                    action={cardActions} />
                <CardContent>
                    
                    { (this.props.loading || !this.state.user) ? 
                        <WaitBlock /> :
                        <>
                            <div className={this.props.classes.input}>
                                <span className={this.props.classes.label}>Nom</span> 
                                {this.state.editMode ? 
                                    <TextField name='nom' value={this.state.user.nom} onChange={this.onChange}/> :
                                    this.state.user.nom
                                }
                            </div>
                            <div className={this.props.classes.input}>
                                <span className={this.props.classes.label}>Prénom</span>
                                {this.state.editMode ? 
                                    <TextField name='prenom' value={this.state.user.prenom} onChange={this.onChange}/> :
                                    this.state.user.prenom
                                }
                            </div>
                            <div className={this.props.classes.input}>
                                <span className={this.props.classes.label}>Courriel</span>
                                {this.state.editMode ? 
                                    <TextField name='email' value={this.state.user.email} onChange={this.onChange}/> :
                                    this.state.user.email
                                }
                            </div>
                            <div className={this.props.classes.input}>
                                <span className={this.props.classes.label}>Langue</span>
                                {this.state.editMode ? 
                                    <TextField name='langue'
                                        select
                                        value={this.state.user.langue}
                                        onChange={this.onChange}>
                                        <MenuItem key="fr" value='fr'>Français</MenuItem>
                                    </TextField> :
                                    this.state.user.langue
                                }
                            </div>
                            <div className={this.props.classes.input}>
                                <span className={this.props.classes.label}>Rôle</span>
                                {this.state.editMode ?
                                    <SelectAvailableRoll name='statut' value={this.state.user.statut} onChange={this.onChange}/> :
                                    <LabelRoll label={this.state.user.statut} />
                                }
                            </div>
                            <div className={this.props.classes.input}>
                                <span className={this.props.classes.label}>
                                    {this.state.user.alerte ? 'Est alerté' : 'Sans alerte'}</span>
                                <Switch
                                    name='alerte'
                                    checked={this.state.user.alerte} 
                                    onChange={this.onChangeAlert}
                                    disabled={!this.state.editMode}
                                    />
                            </div>
                            <div className={this.props.classes.input}>
                                <Tooltip title="Fonction dans l'entreprise">
                                    <span className={this.props.classes.label}>
                                        Fonction
                                    </span>
                                </Tooltip>
                                {this.state.editMode ? 
                                    <TextField name='fonction' value={this.state.user.fonction} onChange={this.onChange}/> :
                                    this.state.user.fonction
                                }
                            </div>
                        </>
                        }
                    {/* @todo implémenter gestion sites user.sites */}
                </CardContent>
                <CardActions className={this.props.classes.cardActions}>
                    {this.state.editMode ?
                        <>
                            <Button onClick={this.onCancel}>Annuler</Button>
                            <Button onClick={this.resetEdit}>Réinitialiser</Button>
                            <Button onClick={this.validate}>Valider</Button>
                        </> :
                        <>
                            
                            <Button onClick={this.displayConfirmResetPassword}
                                    variant="outlined">
                                Renouveler le mot de passe
                            </Button>
                        </>
                    }
                </CardActions>
                
            </Card>
                <Dialog open={this.state.displayConfirmResetPassword}>
                    <DialogTitle >Renouvellement du mot de passe de l'utilisateur</DialogTitle>
                    <DialogContent dividers>
                        <Typography variant="body1">Cette action est irréversible.</Typography>
                        <Typography variant="body2">Le nouveau mot de passe est directement envoyé par courriel à l'utilisateur.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={this.displayConfirmResetPassword} color="primary">Annuler</Button>
                        <Button onClick={this.confirmResetPassword} color='primary'>Confirmer</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.data.loading,
    user: state.data.values,
    actualUser: state.identity.user
})
const mapDispatchToProps = ({
    getUser,
    resetPassword,
})

const classes = {
    root: {
        minWidth: '20em',
    },
    input: {
        lineHeight: '2rem',
    },
    label: {
        display: 'inline-block',
        width: '5em',
    },
    cardActions: {
        justifyContent: 'space-around',
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (UserForm))
