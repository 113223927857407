import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Card, CardHeader, CardContent,
        Select, MenuItem, Typography,
        } from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import {getTransports, getActualTransport, saveTransport} from '../../../core/redux/actions/RegulationActions'
import {sTypeTransport} from '../tools/regulationFunctions'
import WaitBlock from '../window/WaitBlock'
export class TransportCode extends Component {

    state = {
        errorCode: false,
    }

    componentDidMount() {
        if (!this.props.transports) {
            this.props.getTransports()
            this.props.getActualTransport()
        }
    }
    handleChangeTransport = (evt) => {
        const newCode = evt.target.value
        if (newCode !== -1) {
            this.props.saveTransport(newCode)
        } else {
            this.setState({
                errorCode: true
            })
        }
    }
    render() {

        const indexActualCode = (this.props.actualCodeTransport !== null && this.props.actualCodeTransport !== '') ?
                                        Number.parseInt(this.props.actualCodeTransport) : -1

        return (
            <Card>
                <CardHeader title="Type de transport de l'entreprise" />
                <CardContent>
                    { (!this.props.transports || this.props.loading) ?
                        <WaitBlock text="Chargement des informations en cours..." /> :
                        <>
                            {indexActualCode === -1 &&
                                <Alert severity="warning" >
                                    Vous devez obligatoirement définir le type de transport.
                                </Alert>
                            }
                            <Select value={indexActualCode} 
                                    onChange={this.handleChangeTransport}
                                    label="Type de transport" >
                                        
                                {/* cas particulier : aucun code défini actuellement */}
                                {indexActualCode === -1 &&
                                    <MenuItem key="aucun" value={-1}>
                                        <Typography variant="overline" color="error" >Aucun</Typography>
                                    </MenuItem>
                                }

                                {this.props.transports.map(elt => {
                                    return (
                                        <MenuItem key={elt.transp_code} value={Number.parseInt(elt.transp_code)}>
                                            {sTypeTransport(elt.transp_code)}
                                        </MenuItem>
                                    )
                                })}                                    
                            </Select>
                        </>
                    }
                </CardContent>
            </Card>
        )
  }

}

const mapStateToProps = state => ({
    transports: state.regulation.transports,
    actualCodeTransport: state.regulation.actualCode,
    loading: state.data.loading,
})
const mapDispatchToProps = ({
    getTransports,
    getActualTransport,
    saveTransport,
})
export default connect(mapStateToProps, mapDispatchToProps) (TransportCode)
