import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DataGrid } from '@material-ui/data-grid';
import { withStyles, CircularProgress } from '@material-ui/core';
import { URL_API } from '../../redux/actions/DataActions'
import {setError} from '../../redux/actions/WindowAppActions'
import {dateToString} from '../../redux/actions/adapters/dateAdapters'
import { connect } from 'react-redux';
import BaseService from '../../../services/BaseService'
import { format, isEqual } from 'date-fns';
import { DATE_HEURE_LOCALE } from '../Constants';

class Driving extends Component {
	static propTypes = {
		dateRef: PropTypes.objectOf(Date),
		id: PropTypes.number,
	}

	state = {
		loading: false,
		rows: []
	}

	componentDidMount() {
		if (this.props.id > 0) {
			this.loadInformations()
		}
	}
	componentDidUpdate(prevProps, prevState) {

		if ( (!this.state.loading)
				&& (this.props.id > 0) 
				&& ( (!isEqual(prevProps.dateRef, this.props.dateRef))
						|| (prevProps.id !== this.props.id)
					) 
		) {
			this.loadInformations()
		}
	}

	loadInformations = () => {

		const service = new BaseService();
		const sDate = dateToString(this.props.dateRef)
		const url = `${URL_API}/driver/${this.props.id}/driving/${sDate}`

		this.setState({
			loading: true,
		})

		service.get(url).then((data) => {

			/*
			 * formatage en heure locale, à la précision de la minute
			 */
			data.map(act => {
				const start = new Date(act.start)
				const end = new Date(act.end)
				act.start = format(start, DATE_HEURE_LOCALE)
				act.end = format(end, DATE_HEURE_LOCALE)
				return act
			})
			this.setState({
				rows: data,
				loading: false,
			})

		}).catch(error => this.props.setError(error))

	}
	render() {

		const columns = [
			{
				field: 'start',
				headerName: 'Début',
				width: 160,
			},
			{
				field: 'end',
				headerName: 'Fin',
				width: 160,
			},
			{
				field: 'veh_card_immat',
				headerName: 'Immatriculation',
				width: 170
			}
		]
		
		return (
			<div className={this.props.classes.paper}>
				{this.state.loading ?
					<CircularProgress /> :
					<DataGrid rows={this.state.rows} 
							columns={columns}
							autoHeight
							hideFooterPagination 
							hideFooterRowCount={false}
							disableColumnMenu
							/>
				}
			</div>
		)
	}
}

const mapDispatchToProps = ({
	setError,

})

const classes = {
	root: {

	},
	paper: {
		width: '31em'
		// height: '10em',
		// minHeight: '15em',
	}
}
export default connect(null, mapDispatchToProps) (withStyles(classes) (Driving))
