/**
 * initialisation des paramètres par défaut
 */
export default class AppInit {

    initialize () {

        /*
        * paramètres de recherches
        */
        const fields = [
            {   name : 'OW_Search_driverField',
                value : 'name',
            },
            {   name : 'OW_Search_vehicleField',
                value : 'veh_reg_no',
            },
            {   name : 'OW_Search_driverPriority',
                value : true, 
            },
            {
                name: 'CSV_SEPARATOR',
                value: ','
            }
        ]
        fields.forEach (field => {
            let value = localStorage.getItem(field.name)

            if (value === null) {
                localStorage.setItem(field.name, field.value)
            }
        })

        /*
         * Polyfill
         */
        if (!String.prototype.replaceAll) {
            // eslint-disable-next-line no-extend-native
            String.prototype.replaceAll = function(str, newStr){
        
                // If a regex pattern
                if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
                    return this.replace(str, newStr);
                }
        
                // If a string
                return this.replace(new RegExp(str, 'g'), newStr);
        
            };
        }
        
    }

}