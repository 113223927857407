import { Button, Card, CardActions, CircularProgress, Slider, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react'
import {connect} from 'react-redux'
import BaseService from '../../../services/BaseService';
import { URL_API } from '../../redux/actions/DataActions';
import {setError} from '../../redux/actions/WindowAppActions'

class ParkParameter extends Component {

	state = {
		initialDuration: 5,
		duration: 5,
		chg: false,
		loading: false,
	}

	componentDidMount () {
		this.loadInformation()
	}
	handleDuration = (evt, value) => {
		this.setState({
			duration: value,
			chg: true,
		})
	}

	loadInformation = () => {
		const service = new BaseService();
		const url = `${URL_API}/client/parameters/park`;

		this.setState({
			loading: true,
		});

		service.get(url).then((data) => {
			this.setState({
				initialDuration: data,
				duration: data,
				loading: false,
			});

		}).catch(error => this.props.setError(error))
	}

	saveInformation = () => {

		const service = new BaseService();
		const url = `${URL_API}/client/parameters/park/${this.state.duration}`;

		this.setState({
			loading: true,
		});

		service.put(url).then( () => {
			this.setState({
				initialDuration: this.state.duration,
				loading: false,
				chg: false,
			})
		}).catch(error => this.props.setError(error))

	}

	cancelAction = () => {
		this.setState({
			duration: this.state.initialDuration,
			chg: false,
		})
	}

	validate = () => {
		this.saveInformation()
	}
	render() {

		const marks = [
			{
				value: 0,
				label: '0 minute'
			},
			{
				value: 1,
				label: '1 minute'
			},
			{
				value: 2,
				label: '2 minutes'
			},
			{
				value: 3,
				label: '3 minutes'
			},
			{
				value: 4,
				label: '4 minutes'
			},
			{
				value: 5,
				label: '5 minutes'
			},

		]
		return (
			<Card className={this.props.classes.root}>
				<Typography variant="h5">Durée maximale de parking</Typography>
				<Typography>Toute activité de conduite inférieure à cette durée ne sera pas considérée comme significative.</Typography>
				{this.state.loading ?
					<CircularProgress /> :
					<Slider
						value={this.state.duration}
						onChange={this.handleDuration}
						step={1}
						valueLabelDisplay='off'
						marks={marks} 
						max={5}
						min={1} 
						className={this.props.classes.slider}/>
				}
				{this.state.chg && 
					<CardActions className={this.props.classes.actions}>
						<Button onClick={this.cancelAction}>Annuler</Button>
						<Button onClick={this.validate}>Valider</Button>
					</CardActions>
				}
			</Card>
		)
	}
}

const mapDispatchToProps = ({
	setError,
})

const classes = {
	root: {
		width: '29em',
		padding: '0.5em 1em',
	},
	slider: {
		marginInlineStart: '2em',
		maxWidth: '25em',
	},
	actions: {
		justifyContent: 'space-evenly',
	}
}
export default connect(null, mapDispatchToProps) (withStyles(classes) (ParkParameter))
