import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withStyles,
        Dialog, DialogTitle, DialogContent, DialogActions, 
        Button, Grid
        } from '@material-ui/core'
import { INCONNU, DRIVER_TYPE } from '../Constants'
import ElementSelection from '../forms/select/ElementSelection'
import {ReasonsSelector} from './Reasons'
import AttributionIcon from '@material-ui/icons/HowToReg'
import format from 'date-fns/format'
import localeFr from 'date-fns/locale/fr'
import {assignActivities} from '../../redux/actions/ElementsActions'
export class AssignDialog extends Component {
  static propTypes = {
    handle: PropTypes.func.isRequired,
    selectedAct: PropTypes.array.isRequired,
    code: PropTypes.string.isRequired,
  }

  state = {
    driverSelected: 0,
    reason:INCONNU,
  }

  handleDriverSelection = (data) => {
    this.setState({
      driverSelected: data,
    })
  }
  handleReason = (evt) => {
    this.setState({
      reason: evt.target.value,
    })
  }
  assignActivities = () => {
   
    let vehicle = this.props.vehicles.find (vehicle => vehicle.veh_code === this.props.code)
    let driver = this.props.drivers.find (drv => drv.id === this.state.driverSelected.id)
    let attributions = this.props.selectedAct.map(act => {
      const dateAct = new Date(act.datetime)
      return {
        dateTime: act.datetime,
        vehicleCode: vehicle.veh_code,
        idVehicule: vehicle.veh_code,                 // @see 
        date: format(dateAct, 'P', {locale: localeFr}),
        debut: format(dateAct, 'p', {locale: localeFr}),
        drv_code: driver.drv_code,
        motif: this.state.reason,
      }
    })

    this.props.handle ()
    this.props.assignActivities (attributions)

  }
  render() {
    return (
        <Dialog maxWidth='xl'
                open={true}>
            <DialogTitle>
                <Grid container>
                <Grid item><AttributionIcon /></Grid>
                <Grid item>{
                    this.state.editInfos?.length === 1 ?
                    <span>Attribuer l'activité sans carte</span> :
                    <span>Attribuer les activités sans carte</span>
                } 
                </Grid>
                </Grid>
            
            </DialogTitle>
            <DialogContent>
                <div className={this.props.classes.infoBlock}>
                <div className={this.props.classes.rowInfo}>
                    <div className={this.props.classes.rowInfoLabel}>Conducteur</div>
                    <div>
                    <ElementSelection 
                        uniqueType={DRIVER_TYPE} 
                        onChange={this.handleDriverSelection}
                            />
                    </div>
                </div>
                <div className={this.props.classes.rowInfo}>
                    <div className={this.props.classes.rowInfoLabel}>Motif</div>
                    <div>
                    <ReasonsSelector value={this.state.reason} onChange={this.handleReason} />
                    </div>
                </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.handle}>
                  Annuler
                </Button>
                <Button disabled={!this.state.driverSelected}
                        onClick={this.assignActivities}>
                   Attribuer
                </Button>
            </DialogActions>
        </Dialog>
    )
  }
}

const mapStateToProps = state => ({
    vehicles: state.client.vehicles,
    drivers: state.client.drivers,
})
const mapDispatchToProps = ({
    assignActivities
})

const classes = theme => ({
    infoBlock: {
        '&& h4': {
          marginBlockEnd: '0',
        }
    },
    rowInfo: {
        minWidth: '26em',
        '& div': {     
          display: 'inline-flex',
        }
      },
    rowInfoLabel: {
        width: '6em',
    },
})
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (AssignDialog))
