import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DataGrid } from '@material-ui/data-grid';
import { withStyles, CircularProgress, Toolbar, Typography, Grid } from '@material-ui/core';
import { URL_API } from '../../redux/actions/DataActions'
import {setError} from '../../redux/actions/WindowAppActions'
import {dateToString} from '../../redux/actions/adapters/dateAdapters'
import { connect } from 'react-redux';
import BaseService from '../../../services/BaseService'
import { format, isEqual } from 'date-fns';
import {fr} from 'date-fns/locale'
import ExportButton from '../window/ExportButton';
import { exportKilometers } from './exportKilometers';

class Kilometers extends Component {
	static propTypes = {
		selection: PropTypes.object,
	}

	state = {
		loading: false,
		rows: [],
		kmTotal: 0,
		fileName: '',
	}

	componentDidMount() {
	
		if (this.props.selection?.id > 0) {
			this.loadInformations()
		}

	}
	componentDidUpdate(prevProps, prevState) {

		if ( (!this.state.loading)
				&& (this.props.selection?.id > 0) 
				&& ( (!isEqual(prevProps.selection?.begin, this.props.selection?.begin))
						|| (!isEqual(prevProps.selection?.end, this.props.selection?.end))
						|| (prevProps.selection?.id !== this.props.selection?.id)
					) 
		) {
			this.loadInformations()
		} else if (prevProps.selection?.id > 0 && this.props.selection.id === 0) {
			this.setState({
				rows: [],
				kmTotal: 0,
				fileName: '',
			})
		}
	}

	loadInformations = () => {

		const service = new BaseService();
		const sBegin = dateToString(this.props.selection.begin)
		const sEnd = dateToString(this.props.selection.end)
		const url = `${URL_API}/vehicle/${this.props.selection.id}/kilometers/${sBegin}/${sEnd}`

		this.setState({
			loading: true,
		})

		service.get(url).then((data) => {
			
			let total = 0
			
			/*
			 * formatage en heure locale, à la précision de la minute
			 */
			data.map(act => {
				total += act.km
				const aDay = new Date(act.jour)
				act.id=act.jour
				act.day = format(aDay, 'eeee', {locale: fr})			// @todo prévoir d'autres langues
				act.aDay = aDay
				act.date = aDay.toLocaleDateString()
				return act
			})
			this.setState({
				rows: data,
				kmTotal: total,
				loading: false,
				fileName: `export_${format(this.props.selection.begin, 'uMMdd')}-${format(this.props.selection.end, 'uMMdd')}`
			})

		}).catch(error => this.props.setError(error))

	}

	export = () => {
		exportKilometers(this.state.rows, this.state.fileName)
	}
	renderToolbar = () => {

		if (this.state.rows.length > 0) {
			return (<Toolbar>
				<Grid container spacing={2} direction="row" className={this.props.classes.gridToolbar}>
					<Grid item xs={10}>
						<Typography variant="overline">
							Kilométrage total = {this.state.kmTotal}
						</Typography>
					</Grid>
					<Grid item xs={2} >
						<ExportButton
						title="Export en CSV"
						onClick={this.export} />
					</Grid>
				</Grid>
			</Toolbar>)
		} else {
			return null
		}
	}
	render() {

		const columns = [
			{
				field: 'day',
				headerName: 'Journée',
				width: 160,
			},
			{
				field: 'date',
				headerName: 'Jour',
				width: 160,
			},
			{
				field: 'km',
				headerName: 'Kilométrage',
				width: 170
			}
		]
				
		return (
			<div className={this.props.classes.paper}>
				{this.state.loading ?
					<CircularProgress /> :
					<DataGrid rows={this.state.rows} 
							columns={columns}
							autoHeight
							components={{
								Toolbar: this.renderToolbar
							}}
							/>
				}
			</div>
		)
	}
}

const mapDispatchToProps = ({
	setError,

})

const classes = {
	root: {

	},
	paper: {
		maxWidth: '30em',
		margin: 'auto'
	},
	gridToolbar: {
		alignItems: 'center'
	}
}
export default connect(null, mapDispatchToProps) (withStyles(classes) (Kilometers))
