import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {withStyles, Tooltip } from '@material-ui/core'
import {ToggleButtonGroup, ToggleButton,} from '@material-ui/lab'
import {VEHICLE_TYPE, DRIVER_TYPE} from '../../Constants'

import DriverIcon from '@material-ui/icons/Face'
import VehicleIcon from '@material-ui/icons/LocalShipping'
class TypeSelection extends Component {

    static propTypes = {
        type: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    render() {

        return (
            <ToggleButtonGroup 
                    value={this.props.type}
                    exclusive
                    onChange={this.props.onChange}
                    size="small"
                    aria-label="driver or vehicle"
                    className={this.props.classes.root}>

                <Tooltip title='Type &laquo;&nbsp;Conducteur&nbsp;&raquo;'>
                    <AthmoToogleButton value={DRIVER_TYPE} aria-label="driver" selected={this.props.type === DRIVER_TYPE}>
                        <DriverIcon />
                    </AthmoToogleButton>
                </Tooltip>

               <Tooltip title="Type &laquo;&nbsp;Véhicule&nbsp;&raquo;">
                    <AthmoToogleButton value={VEHICLE_TYPE} aria-label="vehicle" selected={this.props.type === VEHICLE_TYPE}>
                        <VehicleIcon />
                    </AthmoToogleButton>
                </Tooltip> 
                
            </ToggleButtonGroup>
        )
    }

}

const stylesButton = theme => ({
    root: {
        color: theme.palette.primary.main,
        '&.Mui-selected': {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
        }
    },
    
})
const AthmoToogleButton = withStyles(stylesButton) (ToggleButton)

const classes = theme => ({
    root: {
        color: theme.palette.primary.main,
        marginInlineEnd: '1em',
    }
})
export default withStyles (classes) (TypeSelection)
