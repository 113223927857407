import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DataGrid } from '@material-ui/data-grid';
import { withStyles, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { URL_API } from '../../redux/actions/DataActions'
import {setError} from '../../redux/actions/WindowAppActions'
import {dateToString} from '../../redux/actions/adapters/dateAdapters'
import { connect } from 'react-redux';
import BaseService from '../../../services/BaseService'
import {DRIVER_TYPE, EVENT_TYPE } from '../Constants';
import {isEqual} from 'date-fns'
import { getLabelEvent, getTypePurposeLabel} from '../tools/Labels'
import {arrayUnique} from '../tools/ArrayFunctions'

class Events extends Component {
	static propTypes = {
		begin: PropTypes.objectOf(Date).isRequired,
		end: PropTypes.objectOf(Date).isRequired,
		type: PropTypes.string.isRequired,
		eventType: PropTypes.oneOf([EVENT_TYPE.EVENT, EVENT_TYPE.FAULT]).isRequired,
		id: PropTypes.number,
	}

	state = {
		loading: false,
		rows: []
	}

	componentDidMount() {
		if (this.props.id > 0) {
			this.loadInformations()
		}
	}
	componentDidUpdate(prevProps, prevState) {

		/*
		 * actualistion des données
		 */
		if ( (!this.state.loading)
				&& (this.props.id > 0) 
				&& ( (!isEqual(prevProps.begin, this.props.begin))
						|| (!isEqual(prevProps.end, this.props.end))
						|| (prevProps.id !== this.props.id)
						|| (prevProps.eventType !== this.props.eventType)
					) 
		) {
			this.loadInformations()

		/*
		 * aucun élément choisi => pas de ligne à afficher
		 */
		} else if (prevProps.id !== 0 && this.props.id === 0) {
			this.setState({
				rows: [],
			})
		}
	}

	loadInformations = () => {

		const service = new BaseService();
		const isoBegin = dateToString(this.props.begin);
		const isoEnd = dateToString(this.props.end);
		const url = `${URL_API}/${this.props.type}/${this.props.id}/${this.props.eventType}s/${isoBegin}/${isoEnd}`

		this.setState({
			loading: true,
		})

		service.get(url).then((data) => {

			/*
			 * Transformation des données
			 */
			data.map( (act, index) => {

				/*
				 * formatage des données conducteur
				 */
				if (this.props.type === DRIVER_TYPE) {
						
					act.id = index

					/*
					* ajout libellé
					*/
					if (this.props.eventType === EVENT_TYPE.EVENT) {
						act.event = getLabelEvent(act.event_code)
					} else {
						act.event = getLabelEvent(act.fault_code)
					}
				
				/*
				 * formatage des données véhicule
				 */
				} else {
					
					act.id = index;

					/*
					* ajout libellé
					*/
					if (this.props.eventType === EVENT_TYPE.EVENT) {
						act.event = getLabelEvent(act.event_type)
					} else {
						act.event = getLabelEvent(act.fault_type)
					}

					/*
					 * cartes
					 */
					const cards = arrayUnique([act.card1_begin, act.card2_begin, act.card1_end, act.card2_end])
					act.cards = cards.join(' ')

				}

				return act
			})
			this.setState({
				rows: data,
				loading: false,
			})

		}).catch(error => this.props.setError(error))

	}

	dateFormatter = (params) => {
		const aDate = new Date(params.value)
		return aDate.toLocaleString()
	}
	
	endFormatter = (params) => {

		if (params.row.start === params.row.end) {
			return ''
		} else {
			return this.dateFormatter(params)
		}
	}

	
	getDriverColumns = () => {

		return [
			{
				field: 'start',
				headerName: 'Début',
				width: 170,
				valueFormatter: this.dateFormatter
			},
			{
				field: 'end',
				headerName: 'Fin',
				width: 170,
				valueFormatter: this.endFormatter
			}, {
				field: 'event',
				headerName: 'Événement',
				width: 350,
			}, 
			{
				field: 'veh_code',
				headerName: 'Véhicule',
				width: 120,
			},
			{
				field: 'reg_number',
				headerName: 'immatriculation',
				width: 160,
			},
		];
	};

	getVehicleColumns = () => {
		return [
			{
				field: 'start',
				headerName: 'Début',
				width: 170,
				valueFormatter: this.dateFormatter
			},
			{
				field: 'end',
				headerName: 'Fin',
				width: 170,
				valueFormatter: this.endFormatter
			}, {
				field: 'event',
				headerName: 'Événement',
				width: 350,
			}, {
				field: 'purpose',
				headerName: 'Objet',
				width: 100,
				renderCell: (params) => {
					const label = getTypePurposeLabel(params.value)
					return (
						<Tooltip title={label}>
							<Typography>{label.substring(0, 5)}...</Typography>
						</Tooltip>
					)
				} ,
			},{
				field: 'drv_code',
				headerName: 'Conducteur',
				width: 200,
			}, {
				field: 'cards',
				headerName: 'Carte(s) insérée(s)',
				width: 240,
			},
			// champs non utilisés :
			// event_purpose,
			// generation

		];
	}
	render() {

		let columns = [];
		if (this.props.type === DRIVER_TYPE) {
			columns = this.getDriverColumns();
		} else {
			columns = this.getVehicleColumns();
		}
		
		return (
			<div className={this.props.classes.paper}>
				{this.state.loading ?
					<CircularProgress /> :
					<DataGrid rows={this.state.rows} 
							columns={columns}
							autoHeight
							hideFooterPagination 
							hideFooterRowCount={false}
							disableColumnMenu
							disableSelectionOnClick
							/>
				}
			</div>
		)
	}
}

const mapDispatchToProps = ({
	setError,

})

const classes = {
	root: {

	},
	paper: {
		// width: '31em'
		// height: '10em',
		// minHeight: '15em',
	}
}
export default connect(null, mapDispatchToProps) (withStyles(classes) (Events))
