import { Container, Grid, Tab, Toolbar,
         Typography,
        withStyles } from '@material-ui/core';
import { TabContext, TabList, TabPanel,
        Alert, AlertTitle } from '@material-ui/lab';
import React, { Component } from 'react'
import {connect} from 'react-redux'
import {setTitle} from '../../core/redux/actions/WindowAppActions'
import {getActualRegulationAndParameters} from '../../core/redux/actions/RegulationActions'
import format from 'date-fns/format'
import HelpButton from '../../core/components/window/HelpButton'
import HelpCard from '../../core/components/window/HelpCard'
import {formatToTime} from '../../core/components/tools/datetimeFunctions'
import {sTypeTransport} from '../../core/components/tools/regulationFunctions'
import TransportCodeConfig from '../../core/components/parameters/TransportCode'
import SupportService from '../../core/components/window/SupportService';

class Parameters extends Component {

    state = {
        tabSelected: 'conduite',
        displayHelp: false,
    }

    componentDidMount(){
        
        if (this.props.transportCode) {
            this.props.getActualRegulationAndParameters()
        }
        this.props.setTitle('Infractions - Paramètres')
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.transportCode !== this.props.transportCode
                && this.props.transportCode !== '') {
            this.props.getActualRegulationAndParameters()
        }
    }
    displayHelp = () => {
        this.setState({
            displayHelp: !this.state.displayHelp
        })
    }
    handleChangeTab = (event, newValue) => {
        this.setState({
            tabSelected: newValue,
        })
    }

    render() {
        const data = this.props.data?.parametres
        let typeTransport = ''
        const validityDateParameter = (this.props.data?.dateReglement) ? new Date(this.props.data.dateReglement) : new Date()
        const sDateValidity = format(validityDateParameter, 'dd/MM/yyyy')
        
        typeTransport = sTypeTransport(this.props.data?.codeTransport)

        return (
            <Container>
                { (this.props.transportCode === null || this.props.transportCode === '') ?
                    <Alert severity="warning" >
                        <AlertTitle>Configuration nécessaire</AlertTitle>
                        <Typography variant="caption" gutterBottom>
                            Préalablement à l'usage du module de réglementation, vous devez définir la réglementation applicable à votre entreprise.
                        </Typography>
                        <TransportCodeConfig />
                    </Alert> :
                    <>
                    <Toolbar className={this.props.classes.toolbar}>

                        <Grid container spacing={5}>
                            <Grid item>
                                <Typography variant='subtitle1'>Type de transport</Typography>
                                <Typography variant='body1' >{typeTransport} </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='subtitle1'>Mise en service</Typography>
                                <Typography variant='body1' >{sDateValidity} </Typography>
                            </Grid>
                        </Grid>

                        <HelpButton>
                            <HelpCard title="Aide en ligne">
                                <div>
                                    <div className={this.props.classes.helpCardTxt}>
                                        Vous avez des doutes, des questions, un besoin de formation sur ces paramètres ?
                                    </div>
                                    <div>
                                        N'hésitez pas à contacter notre <SupportService />.
                                    </div>
                                </div>
                            </HelpCard>
                        </HelpButton>                    
                    </Toolbar>

                    <TabContext value={this.state.tabSelected}>
                        <TabList onChange={this.handleChangeTab} >
                            <Tab value='conduite' label='Conduite' />
                            <Tab value='travail' label='Travail' />
                            <Tab value='repos' label='Repos' />
                            {/* <Tab value='gravite' label='Gravité' /> @todo */}
                        </TabList>

                        <TabPanel value='conduite' id='tabConduite'>
                            <Grid container spacing={4}>
                                <Grid item>
                                    <Typography variant='subtitle1'>Conduite consécutive</Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Durée maximale de Conduite continue</span>
                                        <span>{formatToTime(data?.cd_max)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Interruption réglementaire (pour 4h30)</span>
                                        <span>{formatToTime(data?.inter_cd_max)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Interruption minimum</span>
                                        <span>{formatToTime(data?.inter_cd_min)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Nombre de jours de conduite par semaine</span>
                                        <span>{data?.nbd_consec_drv}</span>
                                    </Typography>
                                </Grid> 
                        
                                <Grid item>
                                    <Typography variant='subtitle1'>Conduite hebdomadaire</Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Conduite maximum par semaine</span>
                                        <span>{formatToTime(data?.drv_week)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Conduite maximum par quatorzaine</span>
                                        <span>{formatToTime(data?.drv_2week)}</span>
                                    </Typography>
                                </Grid>
                        
                                <Grid item>
                                    <Typography variant='subtitle1'>Conduite journalière</Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Durée maximale journalière</span>
                                        <span>{formatToTime(data?.dd_max)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Durée tolérée par jour</span>
                                        <span>{formatToTime(data?.dd_tolerat)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Nombre de jours de conduite toléré par semaine</span>
                                        <span>{data?.nb_dd_tolerat}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value='travail' id='tabTravail' >
                            <Grid container spacing={4}>
                                <Grid item>
                                    <Typography variant='subtitle1'>Temps</Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Travail maximum par semaine isolée</span>
                                        <span>{formatToTime(data?.suppl4)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Moyenne hedomadaire sur quatre mois</span>
                                        <span>{formatToTime(data?.suppl5)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Travail effectif max par jour</span>
                                        <span>{formatToTime(data?.ew_max_day)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Travail effectif max par semaine</span>
                                        <span>{formatToTime(data?.ew_max_1week)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Travail effectif max par quatorzaine</span>
                                        <span>{formatToTime(data?.ew_max_2week)}</span>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='subtitle1' >Consécutif</Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Durée de travail effectif consécutif</span>
                                        <span className={this.props.classes.divLabel}>Période de repos consécutif minimum</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Entre {formatToTime(data?.consec_work_0)} et {formatToTime(data?.consec_work_6)}</span>
                                        <span>{formatToTime(data?.rest_min_0)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Entre {formatToTime(data?.consec_work_6)} et {formatToTime(data?.consec_work_9)}</span>
                                        <span>{formatToTime(data?.rest_min_6)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>au delà de {formatToTime(data?.consec_work_9)}</span>
                                        <span>{formatToTime(data?.rest_min_9)}</span>
                                    </Typography>
                                    <Typography variant='body2'>Les périodes de repos peuvent être décomposées</Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Durée minimum d'une période</span>
                                        <span>{formatToTime(data?.rest_frac15)}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                            
                        </TabPanel>

                        <TabPanel value='repos' id='tabRepos' >
                            <Grid container spacing={4}>
                                <Grid item >
                                    <Typography variant='subtitle1'>Repos</Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Repos fractionné total</span>
                                        <span>{formatToTime(data?.tot_rf)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Repos fractionné réglementaire</span>
                                        <span>{formatToTime(data?.rf_min)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Repos fractionné consécutif</span>
                                        <span>{formatToTime(data?.consec_rf)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Nombre de fraction maximum</span>
                                        <span>{data?.nb_fract}</span>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='subtitle1'>Repos hebdomadaire</Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Repos normal</span>
                                        <span>{formatToTime(data?.weekly_rest)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Repos réduit au point d'attache</span>
                                        <span>{formatToTime(data?.wr_reduc1)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Repos réduit hors point d'attache</span>
                                        <span>{formatToTime(data?.wr_reduc2)}</span>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='subtitle1'>Repos journalier</Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Repos journalier minimum</span>
                                        <span>{formatToTime(data?.dr_min)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>repos journalier normal</span>
                                        <span>{formatToTime(data?.dr_norm)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Repos journalier toléré</span>
                                        <span>{formatToTime(data?.dr_tolerat)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Nombre de jours tolérés par semaine</span>
                                        <span>{data?.nb_dr_tolerat}</span>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='subtitle1'>Amplitude</Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Amplitude journée minimale</span>
                                        <span>{formatToTime(data?.ampl_min)}</span>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <span className={this.props.classes.divLabel}>Durée minimale entre deux repos journalier</span>
                                        <span>{formatToTime(data?.suppl1)}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        {/* <TabPanel value='gravite' id='tabGravite'>
                            Gravité
                        </TabPanel>                  */}

                    </TabContext>
                    </>
                }
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    transportCode: state.regulation.actualCode,
    data: state.data?.values,
})

const mapDispatchToProps = ({
    setTitle,
    getActualRegulationAndParameters,
})

const classes = ({
    root: {

    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    helpCardTxt: {
        textAlign: 'justify',
    },
    divLabel: {
        display: 'inline-block',
        width: '23em',
    }
})
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (Parameters))
