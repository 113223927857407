import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core'
import {isUserAllow} from '../tools/User'

class LigneMenu extends Component {
  static propTypes = {
    to : PropTypes.string.isRequired,
    children : PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    service : PropTypes.string.isRequired,
    minRoll: PropTypes.string,
    role: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),     /* provenance de redux.state, plusieurs rôles possibles */
  }

  state = {
    displayed: true,
  }

  componentDidMount() {
    this.isDisplayed()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.role !== this.props.role) {
      this.isDisplayed()
    }
  }
  isDisplayed () {
    
    const services = (this.props.services) ? this.props.services.map (elt => elt.nom) : []
    const concerne = 
        this.props.service==='*' || 
        (services.includes (this.props.service))
    const authorized = this.props.minRoll ? isUserAllow(this.props.role, this.props.minRoll) : true

    this.setState({
      displayed: (concerne && authorized)
    })

    return (concerne && authorized)
  }
  render() {
    
    const selectionne = (this.props.location.pathname === `${this.props.to}`)
    const targetBlank = this.props.to.startsWith('https://')
   
    if (this.state.displayed) {
      return (
        targetBlank ? 
          <a  href={this.props.to} target='_blank' rel="noreferrer"
              className={selectionne ? this.props.classes.selected : this.props.classes.root}>
            {this.props.children}
          </a> :
          <Link to={this.props.to}
                className={selectionne ? this.props.classes.selected : this.props.classes.root} >
            {this.props.children}
          </Link>
          
      )
    } else {
      return null
    }
  }

}

const classes = theme => ({
  root: {
    color: theme.palette.primary.main,
    minHeight: '2em',
    listStyleType: 'none',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.light,
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      paddingInlineStart: '0.5em',
    }
  },
  selected: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.light,
    fontStyle: 'italic',
    paddingInlineStart: '0.5em',
    minHeight: '2em',
    alignItems: 'center',
    display: 'flex',
    borderBottomLeftRadius: '4px',
    borderTopLeftRadius: '4px',
  }

})

const mapStateToProps = state => ({
  services : state.client.services,
  role: state.identity.user.roles,
})
export default connect (mapStateToProps) (withRouter (withStyles(classes) (LigneMenu)))
