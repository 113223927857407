import {sActivity} from '../../../components/tools/activitiesFunctions'
import {formatToTime} from '../../../components/tools/datetimeFunctions'

export const adaptDataForAttributionGrid = (data) => {

    /*
     * cas = aucune donnée
     */
    if (data.activities.length === 0) {
        return data
    }

    var activities = {}

    for (const code in data.activities) {
        activities[code] = data.activities[code].map(values => {
            const sAct = sActivity(values.activity)
            values.sActivity = sAct.substring (0, 1).toLocaleUpperCase() + sAct.substring(1)
            values.s_durat = formatToTime(values.activity_durat)
            return values
        })
    }
    
    let newData = {
        vehicles: data.vehicles,
        activities: activities,
    }

    return newData
}

export const fnPrepareDataBeforeAttributions = (orig, activities) => {

    let values = []
    const vehicleCode = activities[0].vehicleCode
    let tab = orig.activities[vehicleCode]

    values = tab.map (activity => {
         
        if (activities.findIndex (act => activity.datetime === act.dateTime) >= 0) {
            activity.attr = true
        }
        return activity
    })
    orig.activities[vehicleCode] = values
    
    return orig
}

/**
 * supprime toutes les valeurs préalablement marquées
 */
export const fnRemoveDataAfterAttributions = (orig, vehicleCode) => { 

    for (let code in orig.activities) {
        if (code === vehicleCode) {
            let act = orig.activities[code].filter(data => !data.attr)
            orig.activities[code] = act
        }
    }

    return orig
}