import React, { Component } from 'react'

/**
 * @todo supprimer le title au profit du tooltip dans DriverInfoICon et VehicleInfoIcon
 */
export class OverIcon extends Component {

  render() {
    const {title, color, ...props} = this.props
    return (
      <div title={title} {...props}>
        <svg width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
          <line y1="12" y2="12" x2="24" stroke={color} key="dg" strokeWidth="8"/>
        </svg>
      </div>
    )
  }
}

export default OverIcon