import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Redirect, Route, withRouter} from 'react-router'
import PropTypes from 'prop-types';
import * as rolls from '../Constants'
import { logout } from '../../../core/redux/actions/IdentityActions';
import {resetData} from '../../../core/redux/actions/DataActions'
import {setError} from '../../../core/redux/actions/WindowAppActions'

/**
 * Droits d'accès
 * énumération des droits, du plus petit au plus important
 */
const rights = [
  rolls.U_VISUALISEUR,
  rolls.U_POUVOIR,
  rolls.U_ADMINISTRATEUR,
  rolls.U_ADMINISTRATEUR_SOCIETE,
  rolls.U_ADMINISTRATEUR_ATHMO
]

/**
 * Gestion des accès restreints
 */
class PrivateRoute extends Component {

  static propTypes = {
    minRoll : PropTypes.string.isRequired
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    
    if (this.props.path !== nextProps.path) {         /* ie on change d'écran */
      
      /*
      * aucune donnée ne passe d'un écran à un autre
      * ou n'est utilisée en cas d'erreur
      */
     this.props.resetData()

     /*
      * pas de transmission des erreurs non plus
      */
     this.props.setError(null)

    }
    
    return true
  }

  render() {

    const {component: Component, minRoll, ...rest} = this.props;
    
    /*
     * redirection si non connecté
     */
    if (!this.props.isConnected) {
      return (
        <Route {...rest} >
          <Redirect to={{ pathname : '/login',
                          state: {
                            from: this.props.history?.location.pathname
                          }}} />
        </Route>);
    }

    /*
     * recherche des droits maximum de l'utilisateur
     */
    let maxRoll = 0
    if (this.props.roll) {
      if (typeof(this.props.roll) === 'string') {
        maxRoll = rights.indexOf(this.props.roll)
      } else {
        maxRoll = this.props.roll.reduce( (accumulator, currentValue) => {
          const numRoll = rights.indexOf(currentValue)
          return (numRoll > accumulator) ? numRoll : accumulator
        }, maxRoll)
      }
    }

    /*
     * Redirection si droits insuffisants
     */
    if (maxRoll < rights.indexOf(minRoll)) {
      console.error ('Restricted access (actuel / nécessaire)', this.props.roll, `${maxRoll}`, this.props.minRoll, `${rights.indexOf(minRoll)}`)
      return (
        <Route {...rest} >
            <Redirect to={{ pathname : '/restricted',
                            state: {
                              from: this.props.history?.location.pathname
                            }}} />
        </Route>);

    /*
     * Accès au composant désiré
     */
    } else {
           
      /*
       * conservation d'une trace dans la console
       */
      console.info ('-- Page --', this.props.location.pathname)
       
      return (
        <Route {...rest} >
            <Component />
        </Route>);
    }
    
  }
}

const mapStateToProps = (state) => ({
  isConnected : state.identity.isConnected,
  roll : state.identity.user?.roles,

})

const mapDismatchToProps = ({
  logout,
  resetData,
  setError,
})

export default connect (mapStateToProps, mapDismatchToProps)(withRouter (PrivateRoute));
