import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {SvgIcon} from '@material-ui/core'
import {HISTO_HEIGHT, getActProps} from '../optigestweb/graphics/DayActivitiesHistogram'

class ActivityIcon extends Component {
	static propTypes = {
		type: PropTypes.string.isRequired,			/* type de l'activité C, T, R, Q, D */
		duration: PropTypes.number.isRequired,	/* durée de l'activité en minutes */
	}

	
	render() {

		const props = getActProps({ activite: this.props.type})
		let label = '?'
		let plural = this.props.duration > 1 ? 's' : ''

		label = `${props.label} - ${this.props.duration} minute${plural}`
		
		const y = HISTO_HEIGHT - props.height

		return (
			<SvgIcon 
				titleAccess={label}
				htmlColor={props.color}
				width={this.props.duration * HISTO_HEIGHT}
				viewBox={`0 0 ${HISTO_HEIGHT} ${HISTO_HEIGHT}`}
				{...this.props} >
				<rect x="0" y={y} width={HISTO_HEIGHT} height={props.height} stroke="currentColor" fill="currentColor" strokeWidth="1"
						data-duration={this.props.duration} 
						data-type={this.props.type}
						onClick={this.onClick}/>
			</SvgIcon>
		)
	}
}

export default ActivityIcon
