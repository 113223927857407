import React from 'react'
import {Truck} from 'mdi-material-ui'

const VehicleIcon = React.forwardRef( (props, ref) => {
  
    let {size, color, ...propsComponent} = props 
    let htmlColor, themeColor
    size = size ? size : 1
    if (color) {
      htmlColor = color
    } else {
      themeColor = 'primary'
    }
    
    return (
        <Truck ref={ref} size={size} htmlColor={htmlColor} color={themeColor} {...propsComponent} />
    )
  }
)

export default VehicleIcon
