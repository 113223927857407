import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Tooltip, } from '@material-ui/core'
import VehicleIcon from './VehicleIcon'
import VehicleRepairIcon from './VehicleRepairIcon'
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff'
import OutIcon from './OutIcon'
import WarningIcon from '@material-ui/icons/Warning'
import OverIcon from './OverIcon'


class VehicleInfoIcon extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired
  }

  render() {
    let {type, ...props} = this.props

    switch(type) {

      case 'ooo':
        return (
          <Tooltip title="En réparation">
            <VehicleRepairIcon {...props} />
          </Tooltip>
        )

      case 'limit':
        return (
          <Tooltip title="Date limite légale">
            <VehicleIcon color="red" {...props} />
          </Tooltip>)

      case 'proposed':
        return (
          <Tooltip title="Date limite proposée">
            <VehicleIcon color='orange' {...props} />
          </Tooltip>
        )

      case 'selected':
        return (
          <Tooltip title="Date limite choisie">
            <VehicleIcon {...props} />
          </Tooltip>
        )

      case 'loan':
        return (
          <Tooltip title="Hors entreprise">
            <FlightTakeoffIcon {...props} />
          </Tooltip>
        )

      case 'outBefore':
        return (
          <OutIcon title="Avant contrat" {...props} />
        )

      case 'outAfter':
        return (
          <OutIcon title="Après contrat" {...props} />
        )
      
      case 'warningAfter':
        return (
          <OverIcon 
              title="Dépassement de la date limite proposée"
              color="orange" {...props} />
        )

      case 'alertAfter':
        return (
          <OverIcon 
              title="Dépassement de la date limite légale"
              color="red" {...props} />
        )

      case 'loss':
        return (
          <Tooltip title="Informations manquantes">
            <WarningIcon htmlColor="red" {...props} />
          </Tooltip>
        )

      default:
          console.warn('Vehicle icon type unreferenced', type)
          return <span>?</span>
    }
  }
}

export default VehicleInfoIcon
