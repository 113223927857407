import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withStyles, TextField, MenuItem, 
        } from '@material-ui/core'

export const INTRODUCTION = 'INTRODUCTION'
export const CONCLUSION = 'CONCLUSION'
class LetterModule extends Component {

    static propTypes = {
        type: PropTypes.string.isRequired,
        onChange: PropTypes.func,
    }
    modules = {
        INTRODUCTION : [
            {
                id: 0,
                label: 'Aucune',
                text: '',
                editable: false,
            },
            {
                id: 1,
                label: "Constatation d'infractions",
                text: "Nous avons constaté que vous avez fait l'objet d'infractions à la réglementation des transports routiers sur la période précisée ci-dessus. Les infractions sont les suivantes :",
                editable: false,
            },
            {
                id: 2,
                label: "Infractions renouvelées",
                text: "Nous avons encore relevé des infractions à la réglementation actuellement en vigueur.",
                editable: false,
            },
            {
                id: 3,
                label: "Texte libre",
                text: '',
                editable: true,
            },
        ],
        CONCLUSION: [
            {
                id: 0,
                label: 'Aucune',
                text: '',
                editable: false,
            },
            {
                id: 1,
                label: "Constatation d'infractions",
                text: "Nous vous rappelons que ces infractions sont sanctionnées par des amendes de 4e classe (750 euros) et 5e classe (3000 euros). "
                        + "Par conséquent, nous vous demandons d'être plus vigilant. Nous vous remercions de votre collaboration.",
                editable: false,
            },
            {
                id: 2,
                label: "Infractions renouvelées",
                text: "Afin d'éviter de nouvelles infractions, le responsable se tient à votre service pour tout renseignement sur la réglementation.",
                editable: false,
            },
            {
                id: 3,
                label: "Texte libre",
                text: '',
                editable: true,
            },
        ]
    }

    state = {
        id: 0,
        editable: false,
        text: '',
    }

    componentDidMount() {
        const types = [
            INTRODUCTION,
            CONCLUSION
        ]
        if (!types.includes(this.props.type)) {
            throw Error(`Unknown type ${this.props.type}`)
        }

        const form = this.modules[this.props.type]
        const elt = form.find(obj => obj.id === 1)
        this.setState({
            id: 1,
            text: elt.text,
            editable: elt.editable,
        })

        /*
         * propagation du texte vers le parent
         */
        if (this.props.onChange) {
            this.props.onChange(this.props.type, elt.text)
        }
        
    }
    handleChange = (evt) => {
        const id = Number.parseInt(evt.target.value)
        const form = this.modules[this.props.type]
        const elt = form.find(obj => obj.id === id)
        this.setState({
            id: id,
            text: elt.text,
            editable: elt.editable,
        })

        /*
         * propagation du texte vers le parent
         */
        if (this.props.onChange) {
            this.props.onChange(this.props.type, elt.text)
        }
    }

    onChangeText = (evt) => {
        this.setState({
            text: evt.target.value,
        })

        /*
         * propagation du texte vers le parent
         */
        if (this.props.onChange) {
            this.props.onChange(this.props.type, evt.target.value)
        }
    }
    render() {
        
        const labelType = (this.props.type === INTRODUCTION) ? 'Introduction' : 'Conclusion'

        return (<>
            <TextField
                    select
                    label={labelType}
                    value={this.state.id}
                    onChange={this.handleChange}>
                {this.modules[this.props.type].map(obj => {
                    return <MenuItem key={obj.id} value={obj.id}>{obj.label}</MenuItem>
                })}
            </TextField>
           
            <TextField
                    placeholder="Votre texte"
                    multiline
                    value={this.state.text}
                    onChange={this.onChangeText}
                    disabled={!this.state.editable}
                    fullWidth
                    />
        
        </>)
    }
}

const classes = {
    root: {
        
    }
}

export default withStyles(classes) (LetterModule)

