import { Popover, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react'

class SupportService extends Component {

	state = {
		anchorEl: null,
	}

	handlePopoverOpen = (evt) => {
		this.setState({
			anchorEl: evt.currentTarget,
		})
	}

	handlePopoverClose = () => {
		this.setState({
			anchorEl: null
		})
	}
	render() {

		const open = Boolean(this.state.anchorEl)
		return (
			<div className={this.props.classes.root}>
				<Typography
					aria-owns={open ? 'mouse-over-popover' : undefined}
					aria-haspopup="true"
					onMouseEnter={this.handlePopoverOpen}
					onMouseLeave={this.handlePopoverClose}
					variant="overline"
				>
					service d'assistance
				</Typography>
				<Popover
					id="card-support-service"
					className={this.props.classes.popover}
					classes={{
						paper: classes.paper,
					}}
					open={open}
					anchorEl={this.state.anchorEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					onClose={this.handlePopoverClose}
					disableRestoreFocus
				>
					<ul>
						<li>Courriel : supportweb@athmo.eu</li>
						<li>Téléphone : +33 4 83 12 00 09</li>
					</ul>
				</Popover>
			</div>
		)
	}
}

const classes = theme => ({
	root: {
		display: 'inline-flex',
	},
	popover: {
		pointerEvents: 'none',
		'& li': {
			paddingEnd: '2em'
		}
	},
	paper: {
		padding: theme.spacing(1),
	}
})

export default withStyles(classes) (SupportService)
