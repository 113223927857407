import DateFnsAdapter from '@date-io/date-fns'
import transformData from './transformData'

const dataAdapter = (props) => {

    const refDate = new Date()
    const dateFns = new DateFnsAdapter()

    refDate.setMonth(props.ref.month - 1)
    refDate.setFullYear(props.ref.year)
    
    const firstDay = dateFns.startOfMonth(refDate)
    const lastDay = dateFns.endOfMonth(refDate)
  
    let values = []
  
    /*
    * pour chaque conducteur/véhicule, on renseigne les informations du mois
    */
    props.data && props.data.forEach(elt => {

        if (    typeof(elt) === 'object'
                && typeof(elt[0]) === 'object'
                ) {                                 /* uniquement les informations pertinentes en provenance du serveur */

            /*
            * mémorisation du nom
            */
            let row = {
                id: elt[0][8],
                name: elt[0][0],
                label: elt[0][0],
                days: []
            }

            /*
            * création du calendrier
            */
            for (let d = firstDay; d <= lastDay; d = dateFns.addDays(d, 1)) {
                let aDay = {
                    day: d,
                    state: '',
                    files: [],
                    actions: [],
                }

                row.days.push (aDay)
            }

            /*
            * remplissage du calendrier
            */
            elt.forEach (infos => {

                if ( (typeof(infos) == 'object') && infos[3]) {   /* ie il y a une information à traiter */

                    row.days = transformData(row.days, 3, infos)

                }

            })

            /*
            * stockage des informations du conducteur/véhicule
            */
            values.push(row)

        }
    })
 
    /*
     * retour pour transmission
     */
    return values

}

export default dataAdapter