import React, { Component } from 'react'
import {connect} from 'react-redux'
import {FormControlLabel, Switch, CircularProgress} from '@material-ui/core'
import {saveAccountValidation} from '../../redux/actions/ClientActions'

class AccountValidationSwitch extends Component {

    state = {
        loading: false,
    }

    componentDidUpdate(prevProps, prevState) {
        
        /*
         *  changement d'état de la validité du compte
         */
        if (prevProps.downloadable !== this.props.downloadable) {
            this.setState({
                loading: false,
            })
        }
    }
    handleChange = () => {
        this.setState({
            loading: true,
        })
        this.props.saveAccountValidation(!this.props.downloadable)
    }
  render() {
    return (this.state.loading ?
            <CircularProgress /> :
            <FormControlLabel
                control={
                    <Switch checked={this.props.downloadable}
                        onChange={this.handleChange}
                        />
                }
                label={this.props.downloadable ? "Téléchargements actifs" : "Téléchargements désactivés"}
                />
            )
  }
}

const mapStateToProps = state => ({
    downloadable: state.client.downloadable,
})

const mapDispatchToProps = ({
    saveAccountValidation,
})
export default connect(mapStateToProps, mapDispatchToProps) (AccountValidationSwitch)
