import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ButtonOw from './ButtonOw'

import AddIcon from '@material-ui/icons/AddCircleOutlined'

class ButtonAdd extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  render() {
    return (
        <ButtonOw {...this.props}>
            <AddIcon />
        </ButtonOw> 
    )
  }
}

export default ButtonAdd
