import {U_ADMINISTRATEUR_SOCIETE, U_ADMINISTRATEUR_ATHMO,
        U_ADMINISTRATEUR, U_POUVOIR,
        U_VISUALISEUR, U_INACTIF
        } from '../Constants'
 
const rights = [
    U_INACTIF,
    U_VISUALISEUR,
    U_POUVOIR,
    U_ADMINISTRATEUR,
    U_ADMINISTRATEUR_SOCIETE,
    U_ADMINISTRATEUR_ATHMO
]

/**
 * Vérifie que le droit actuel est suffisant
 * @param {string} actualRoll 
 * @param {string} minRoll 
 * @return {bool}
 */
export function isUserAllow (actualRolls, minRoll) {

    /*
     * aucun utilisateur = aucun droit
     */
    if (!actualRolls) {
        return false
    }

    let idRollMax = 0
    if (typeof(actualRolls) === 'string') {
        idRollMax = rights.indexOf(actualRolls)
    } else {
        for (const roll of actualRolls) {
            const id = rights.indexOf(roll)
            
            if (id > idRollMax) {
                idRollMax = id
            }
        }
    }
    
    const idMin = rights.indexOf(minRoll)

    if (idRollMax < 0) {
        throw new Error(`Droit actuel ${actualRolls} inconnu.`)
    }
    if (idMin < 0) {
        throw new Error(`Droit minimum ${minRoll} inconnu.`)
    }
            
    return idRollMax >= idMin

}
