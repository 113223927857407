import React, { Component } from 'react'
import AthmoIcon from '../icons/AthmoIcon'
import ButtonOw from '../window/ButtonOw'
import {Card, CardHeader, CardContent,
        withStyles, 
        } from '@material-ui/core'

class Galaxy extends Component {

    state = {
        displayCard: false,
    }

    handleDisplay = () => {
        this.setState({
            displayCard: !this.state.displayCard,
        })
    }
    render() {
        const titleCard = (<span>Autres services <em>Athmo</em></span>)
        return (
            <>
            <ButtonOw title={titleCard}
                    onClick={this.handleDisplay}>
                <AthmoIcon />
            </ButtonOw>
            {this.state.displayCard && 
                <Card className={this.props.classes.card}>
                    <CardHeader title={titleCard} />
                    <CardContent>
                        <p>
                            <a href="https://map.athmo.eu" 
                                    target="_blank" rel="noreferrer"
                                    onClick={this.handleDisplay}>
                                Positel Map
                            </a>
                        </p>
                        <p>
                            <a href="https://ow.optimalweb.athmo.eu" 
                                    target="_blank" rel="noreferrer"
                                    onClick={this.handleDisplay}>
                                Autres fonctionnalités
                            </a>
                        </p>
                    
                    </CardContent>
                </Card>
            }
            </>
        )
    }

}

const classes = theme => ({
    card: {
        display: 'block',
        position: 'absolute',
        top: '70px',
        zIndex: theme.zIndex.appBar
    }
})
export default withStyles(classes) (Galaxy)
