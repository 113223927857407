import React, { Component } from 'react'
import {withStyles, Typography,
      } from '@material-ui/core'
import HelpCard from '../window/HelpCard'

class OthersParametersHelp extends Component {

  render() {
    
    return (
      <HelpCard title="Autres paramètres" >
        <>
          <Typography variant="h6">Usage du logotype</Typography>
          <Typography align="justify">
            Une fois défini, le logotype est ajouté par <em>Optimal Web</em> lors des impressions de différents documents 
            ainsi que sur certains fichiers <em>pdf</em> exportés.
          </Typography>
          
          <Typography variant="h6">Durée de parking</Typography>
          <Typography align="justify">
            L'usage nous a permis de définir une durée de conduite sans carte qui correspond à un usage du véhicule sur un parking.
            Ce type d'activité ne semble pas être sanctionné.
          </Typography>
        </>
      </HelpCard>
    )
  }
}

const classes = theme => ({
  root: {
   
  },
})

export default withStyles(classes)(OthersParametersHelp)