import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import ElementSelection from '../forms/select/ElementSelection'
import DayPicker from '../forms/date/DayPicker';
import MonthPicker from '../forms/date/MonthPicker'
import {withStyles} from '@material-ui/styles'
import { FormControl } from '@material-ui/core';
import SearchResetIcon from '@material-ui/icons/YoutubeSearchedFor'
import ButtonOw from '../window/ButtonOw'
import {DRIVER_TYPE} from '../Constants'
import {isEqual} from 'date-fns'

export const TYPE_DATE = {
  DAY: 'day',
  MONTH: 'month'
}
class Selector extends Component {

  static propsType = {
    type: PropTypes.string.isRequired,    // DRIVER_TYPE || VEHICLE_TYPE
    onChange: PropTypes.func,
    dateRef: PropTypes.object,
  }
  state = {

    /*
    * élément sélectionné
    */
    selection : null,

    /*
    * Date examinée
    */
    dateRef : new Date(),
    
  }

  componentDidUpdate(prevProps, prevState) {
    
    if (prevProps.dateRef !== this.props.dateRef) {
      this.setState({
        dateRef: this.props.dateRef,
      })
    }

    if (this.props.onChange                                         /* il existe une fonction de gestion onChange */
        && this.state.selection                                     /* une sélection existe */
        && this.state.dateRef                                       /* une date est définie */
        && (prevState.selection === null                            /* il n'y avait aps de sélection */
          || (prevState.selection.id !== this.state.selection.id)   /* ou changement de conducteur */
          || (!isEqual(prevState.dateRef, this.state.dateRef))      /* ou changement de date */
          ) 
        ) {
      let selection = this.state.selection ? this.state.selection : {id: 0, type: null} 
      
      this.props.onChange ({
        type: selection.type,
        id: selection.id,
        dateRef : this.state.dateRef,
      })
    }
  }
  
  dateChange = (value) => {
    this.setState ({
      dateRef: value
    })
  }

  handleSelection = (selection) => {

    this.setState({
        selection : selection
    })

  }

  reset = () => {
    
    this.setState({
      selection : null,
      dateRef: new Date(),
    })
  }

  render() {
    
    return (
        <FormControl className={this.props.classes.root} >
          <FormControl className={this.props.classes.selector}>
            <ElementSelection 
                onChange={this.handleSelection}
                id={this.state.selection?.id}
                uniqueType={DRIVER_TYPE} />
            {this.props.type === TYPE_DATE.DAY ?
              <DayPicker
                  id="date-ref-picker"
                  name="dateRef"
                  variant="inline" 
                  label="Date examinée" 
                  disableFuture={true}
                  autoOk={true}
                  value={this.state.dateRef}
                  minDate={this.props.management.begin}
                  onChange={this.dateChange}
                  lang='FR'
                  /> :
              <MonthPicker
                  id="month-ref-picker"
                  name="dateRef"
                  variant="inline" 
                  label="Mois examiné"
                  disableFuture={true}
                  autoOk={true}
                  value={this.state.dateRef}
                  minDate={this.props.management.begin}
                  onChange={this.dateChange}
                  lang='FR'
                  />
              
              
            }
          </FormControl>
          <FormControl>
            <ButtonOw title="Réinitialiser la recherche" onClick={this.reset}>
                <SearchResetIcon />
            </ButtonOw>
          </FormControl>
        </FormControl>
    )
  }
}

const classes = theme => ({
    root: {
        color: theme.palette.primary.main,
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    selector : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        minWidth: '51em',
    },
})

const mapStateToProps = state => ({
  management: state.client.informations.ow.limits
})

export default connect(mapStateToProps) (withStyles (classes) (Selector))
