
import {CANCELLED} from '../../../services/BaseService'
import BaseService from '../../../services/BaseService'
import {apiError, setLoading, setSourceToCancel} from '../actions/DataActions'
import {METHOD_GET, METHOD_POST, METHOD_PUT, METHOD_DELETE, METHOD_DOWNLOAD} from '../actions/DataActions'

const handleError = (dispatch, error) => {

    /*
     * on ne fait rien en cas d'annulation
     * autrement, l'erreur est propagée
     */
    if (error !== CANCELLED) {
        dispatch(apiError(error))
    }
    

}
export const apiMiddleware = store => next => action => {
   
    next(action)

    if (action.type !== 'API') {
        return
    }

    /*
     * Gestion des appels à l'API
     */
    const dispatch = store.dispatch
    const state = store.getState()
    const service = new BaseService()
    const {url, method, params, loadingEnd, cancelable, onSuccess, paramOnSuccess} = action.payload

    dispatch (setLoading (true))

    /*
     * annulation de tout appel existant annulable avant d'en commencer un autre
     */
    if (state.data.sourceToCancel !== null) {
        state.data.sourceToCancel.cancel(`Nouvel appel à API : ${url}`)
        if (!cancelable) {                      /* suppression de l'annulation si la prochaine requête n'utilise pas le système d'annulation */
            dispatch (setSourceToCancel(null))
        }
    }   

    /*
     * si demandé, stockage des éléments nécessaires à une annulation
     */
    if (cancelable) {
        dispatch (setSourceToCancel(service.getSourceToCancel()))
    }
    

    switch (method) {

        case METHOD_GET:
            service.get(url).then( (data) => {
                if (onSuccess) {
                    dispatch(onSuccess(data, paramOnSuccess))
                }
                
                if (loadingEnd !== false) {         /* ie chargement non terminé => probablement une analyse est à effectuer */
                    dispatch(setLoading(false))
                }

            }).catch(error => handleError(dispatch, error))
            break

        case METHOD_POST:
            service.post(url, params).then( (data) => {
                if (onSuccess) {
                    dispatch(onSuccess(data, paramOnSuccess))
                }
                if (loadingEnd !== false) {
                    dispatch(setLoading(false))
                }
            }).catch(error => handleError(dispatch, error))
            break

        case METHOD_PUT:
            service.put(url, params).then ( (data) => {
                if (onSuccess) {
                    dispatch(onSuccess(data, paramOnSuccess))
                }       
                if (loadingEnd !== false) {        
                    dispatch(setLoading(false))
                }
                
            }).catch(error => handleError(dispatch, error))
            break

        case METHOD_DELETE:
            service.delete(url).then ( (data) => {
                if (onSuccess) {
                    dispatch(onSuccess(data, paramOnSuccess))
                }
                if (loadingEnd !== false) {
                    dispatch(setLoading(false))
                }
            }).catch(error => handleError(dispatch, error))
            break
            
        case METHOD_DOWNLOAD:
            service.download(url, params).then ( () => {
                if (onSuccess) {
                    dispatch(onSuccess(null, paramOnSuccess))
                }
                if (loadingEnd !== false) {
                    dispatch(setLoading(false))
                }
            }).catch(error => handleError(dispatch, error))
            break

        default:
            throw new Error(`Method ${method} not implemented`)
    }


}