import React, { Component } from 'react'
import {withStyles, Typography,
      } from '@material-ui/core'
import HelpCard from '../window/HelpCard'

class GroupsParameterHelp extends Component {

  render() {
    
    return (
      <HelpCard title="Gestion par groupes" >
        <Typography align="justify">
          Activer la gestion par groupes vous permet d'organiser <em>Optimal Web</em> selon l'organisation physique de votre société.
        </Typography>
        <Typography align="justify">
          Les agences sont alors représentées au sein de l'application.
          Les conducteurs et véhicules doivent alors être liés à leur agence.
        </Typography>
        <Typography align="justify">
          Chaque utilisateur a ses droits limités sur l'agence gérée.
          Seul un administrateur de société a accès à tous les éléments composant la société.
        </Typography>
        <Typography className={this.props.classes.warning} align="justify">
            Activer la gestion par groupes a donc des conséquences importantes sur le fonctionnement de l'application. Ne le faites qu'à bon escient !
        </Typography>
        <Typography align="justify">
          N'hésitez pas à nous contacter pour tout renseignement ou aide au paramétrage.
        </Typography>
      </HelpCard>
    )
  }
}

const classes = theme => ({
  root: {
   
  },
  warning: {
    color: theme.palette.warning.main
  }
})

export default withStyles(classes) (GroupsParameterHelp)
