import axios from 'axios';
import {saveAs} from 'file-saver'
import srs from 'secure-random-string'

export const CANCELLED = 'CANCELLED'

class BaseService {

    /**
     * Jeton d'annulation
     */
    sourceToCancel = null

    constructor() {
        this.sourceToCancel = axios.CancelToken.source()
    }
   

    getSourceToCancel = () => {
        return this.sourceToCancel
    }

    getOptions = (options) => {
        let newOptions
        let token = sessionStorage.getItem('TOKEN')
        if (token) {
            let headers
            if (options) {
                headers = Object.assign({}, options.headers, {
                    'Authorization' : `Bearer ${token}` 
                })
            } else {
                headers = {
                    'Authorization' : `Bearer ${token}` 
                }
            }
            newOptions = Object.assign({}, options, {
                headers : headers,
                cancelToken: this.sourceToCancel.token
            })
        } else {
            newOptions = options
        }

        return newOptions;

    }

    /**
     * Conservation d'une trace des appels dans la console du client
     * Usage potentiel par le support
     * @param {string} url 
     * @param {string} method 
     * @param {*} data 
     */
    log (url, method, data) {
        console.log(`${method}: ${url}`, data);
    }
    get(url, options) {
        this.log(url, 'GET');
        return axios.get(url, this.getOptions (options)).then(this.promiseResolve).catch(this.promiseReject);
    }

    post(url, obj, options) {
        this.log(url, 'POST', obj);
        return axios.post(url, obj, this.getOptions (options)).then(this.promiseResolve).catch(this.promiseReject);
    }

    put(url, obj, options) {
        this.log (url, 'PUT', obj);
        return axios.put(url, obj, this.getOptions (options)).then(this.promiseResolve).catch(this.promiseReject);
    }

    delete(url, options) {
        this.log (url, 'DELETE');
        return axios.delete(url, this.getOptions (options)).then(this.promiseResolve).catch(this.promiseReject);
    }

    download = async (url, params, options) => {
        this.log (url, 'DOWNLOAD', params)
        const fileName = (params && params.fileName) ? params.fileName : srs({length: 16, alphanumeric: true})
        delete params.fileName

        return axios.post(url, params, Object.assign({}, this.getOptions(options), {
            responseType: 'blob',
        })).then(response => {
            saveAs(response.data, fileName)
            console.log(`  DOWNLOADed ${fileName} : ${response.data.size} bytes`)
            return Promise.resolve({
                fileName: fileName,
                size: response.data.size
            })
        }).catch(this.promiseReject);

    }

    /**
     * Gestion de l'erreur lors de l'appel de l'API
     * @param {string} err 
     */
    promiseReject(err) {
        
        /*
         * cas particulier : accès interdit car non authentifié
         */
        if (err.response && err.response.status === 401) {
            console.warn('Access denied. Redirect to /')
            sessionStorage.removeItem('TOKEN')
            sessionStorage.removeItem('idClient')
            window.location.assign('/');
        }
        
        /*
         * cas particulier = annulation
         */
        if (axios.isCancel(err)) {
            console.log(err.message)
            return Promise.reject('CANCELLED')    /* on indique que l'on ne doit pas tenir compte des données car annulation */
        }

        /*
         * erreur
         */
        return Promise.reject(err.message || err);
        
    }

    /**
     * Gestion du bon déroulement de l'appel à l'API
     * @param {*} resp 
     */
    promiseResolve(resp) {
        return Promise.resolve(resp.data);
    }

}
export default BaseService;