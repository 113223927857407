import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withStyles, Popover} from '@material-ui/core'
import {HISTO_HEIGHT, getActProps} from './DayActivitiesHistogram'

const MAGNIFICATION_COEF = 6
class ActivitiesMagnification extends Component {
	static propTypes = {
		open: PropTypes.bool.isRequired,
		data: PropTypes.array.isRequired,
		onClose: PropTypes.func.isRequired,
		onActivityRightClick: PropTypes.func.isRequired,
		anchorEl: PropTypes.object,

	}

	onRightClick = (evt) => {

		/*
		 * gestion du clic droit géré uniquement ici
		 */
		evt.stopPropagation()
		evt.preventDefault()

		if (this.props.onActivityRightClick) {
			this.props.onActivityRightClick(evt)
		}
	}
	renderActivity = (act, i) => {

		/*
		 * caractéristiques graphiques d'une activité
		 */
		const props = getActProps(act)
		const y = HISTO_HEIGHT - props.height
		const plural = act.duree > 1 ? 's' : '';
		const label = `${act.date.toLocaleDateString()} ${act.heure_debut} : ${props.label} (${act.duree} minute${plural})`;

		return (
			<g key={`act_${act.index}`} >
				<title>{label}</title>
				<rect
					x={act.x} y={y}
					width={act.duree * MAGNIFICATION_COEF} height={props.height}
					stroke={props.color} strokeWidth={0}
					fill={props.color}
					data-index={act.index}
					data-numero={act.numero}
					data-date={act.date}
					data-duration={act.duree}
					data-type={act.activite}
					data-source={act.source}
					data-begin={act.heure_debut}
					data-disque={act.id_disque}
					onContextMenu={this.onRightClick}
					cursor="pointer" />
			</g>
		);
	}
	renderScale = (begin, end, actsLength) => {
		
		/*
		 * création des références horaires
		 */
		const hours = [];
		for (let n = (Math.floor(begin / 60) + 1); n <= (Math.floor(end / 60)); n++) {
			hours.push(n);
		}

		const y = HISTO_HEIGHT;

		return hours.map(hour => {

			const plural = (hour > 1) ? 's' : '';
			const label = `${hour} heure${plural}`;
			let x = actsLength - ( (end - (hour * 60)) * MAGNIFICATION_COEF)
			
			return (
				<g key={`magn_h_${hour}`} >
					<title>{label}</title>
					<line x1={x} x2={x} y1={y} y2={y - 6} stroke='gray' strokeWidth={3} />
					<text x={x - 2} y={y + 9} fontSize={9}>{hour}</text>
				</g>);
		});
	}

	render() {

		const widthSvg = HISTO_HEIGHT + 9
		const screenPosition = {
			x: 0,
			y: 0,
		}
		let width = 0
		let posX = 0
		let beginX = 0			/* pour échelle : heure de début en minutes */
		let endX = 0				/* pour échelle : heure de fin en minutes */ 

		if (this.props.anchorEl) {
			screenPosition.x = this.props.anchorEl.pageX
			screenPosition.y = this.props.anchorEl.pageY - HISTO_HEIGHT
		}
		this.props.data.forEach( (act, i) => {
			if (i === 0) {
				beginX = act.debut
			} else if (i === (this.props.data.length - 1)) {
				endX = act.fin
			} 
			act.x = posX
			width += (act.duree * MAGNIFICATION_COEF)
			posX += (act.duree * MAGNIFICATION_COEF)
		}) 
		
		return (
			<Popover 
					open={this.props.open}
					onClose={this.props.onClose}
					anchorReference="anchorPosition"
					anchorPosition={{
						top: screenPosition.y,
						left: screenPosition.x,
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					classes={{
						paper: this.props.classes.paper
					}}>
				<svg width={width} height={widthSvg} 
						viewBox={`0 0 ${width} ${widthSvg}`}
						className={this.props.classes.svg}>
					{this.props.data.map((act, i) =>
						this.renderActivity(act, i)
					)}
					{this.renderScale(beginX, endX, posX)}
				</svg>
			</Popover>)
	}
}

const classes = {
	root: {
	
	},
	paper: {
		backgroundColor: 'Gainsboro',
	},
	svg: {
		margin: '0.2em 0.5em',
	}
}
export default withStyles(classes) (ActivitiesMagnification)
