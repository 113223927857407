import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withStyles,
        Container, Toolbar, Typography,
        FormControl, FormControlLabel, Select, MenuItem, InputLabel,
        RadioGroup, Radio, Switch, 
      } from '@material-ui/core'
import MonthPicker from '../../core/components/forms/date/MonthPicker'

import WaitBlock from '../../core/components/window/WaitBlock'
import HelpCard from '../../core/components/window/HelpCard'
import HelpButton from '../../core/components/window/HelpButton'
import VehicleIcon from '@material-ui/icons/LocalShipping'

import {setTitle, setError} from '../../core/redux/actions/WindowAppActions'
import { getActivitiesWithoutCard } from '../../core/redux/actions/ElementsActions'
import AssignedActDataGrid from '../../core/components/optigestweb/AssignedActDataGrid'
import format from 'date-fns/format'
import localeFr from 'date-fns/locale/fr'


import WithoutCard from '../../core/components/optigestweb/WithoutCard';
import BaseService from '../../services/BaseService';
import { URL_API } from '../../core/redux/actions/DataActions';

class SansCarte extends Component {

  state = {
    dateRef: null,
    assigned: false,
    code: null,
    displayEditCard: false,
    editInfos: null,
    driverSelected: null,
    significantDuration: false,
    parkDuration: null,
  }

  /**
   * Chargement par défaut du mois en cours
   */
  componentDidMount () {

    this.props.setTitle('Activités sans carte')
    this.loadCompanyInformation()
    this.setState({
      dateRef: new Date(),
      code: null,
    })
  }
  componentDidUpdate(prevProps, prevState) {

    if ( (prevState.dateRef !== this.state.dateRef)
        || (prevState.assigned !== this.state.assigned)
        || (prevState.significantDuration !== this.state.significantDuration)
        ) {

      this.props.getActivitiesWithoutCard(this.state.dateRef, this.state.assigned, this.state.significantDuration)

    /*
     * cas 1 : aucun véhicule sélectionné
     *   => affichage du premier de la liste
     */
    } else if (!this.state.code && 
          this.props.values &&
          this.props.values.vehicles &&
          this.props.values.vehicles.code.length  > 0) {

      this.setState({
        code: this.props.values.vehicles.code[0]
      })

    /*
     * Ce cas ne devrait pas se présenter tant que shouldComponentUpdate n'est pas "consultatif"
     *  @see https://fr.reactjs.org/docs/react-component.html#shouldcomponentupdate
     * 
     * cas 2 : nouvelles données mais le véhicule précédemment sélectionné n'est pas dans la liste
     *   => affichage du premier de la liste
     */
    } else if (this.props.values
      && this.props.values.vehicles
      && this.props.values.vehicles.code
      && this.props.values.vehicles.code.length > 0
      && !this.props.values.vehicles.code.includes(this.state.code)) {

      this.setState({
        code: this.props.values.vehicles.code[0]
      });

    }
    
  }

  loadCompanyInformation = () => {

    const service = new BaseService();
    const url = `${URL_API}/client/parameters/park`;

    this.setState({
      loading: true,
    });

    service.get(url).then((data) => {
      this.setState({
        parkDuration: data,
      });

    }).catch(error => this.props.setError(error));
  }
  monthHandler = (date) => {
    this.setState({
      dateRef: date,
    })
  }

  assignedHandler = () => {
    this.setState({
      assigned: !this.state.assigned,
      code: null,
    })
  }
  vehicleHandler = (evt) => {
    evt.preventDefault()
    evt.stopPropagation()
    this.setState({
      code: evt.target.value,
    })
  }

  significantDurationHandler = (evt) => {

    this.setState({
      significantDuration: !this.state.significantDuration,
    })

  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {

    let update = true

    /*
     * pas de rafraîchissement quand le véhicule déjà défini n'existe pas
     */
    if (this.state.code           /* un code véhicule est déjà défini */
      && !this.props.values       /* mais il y a eu un rafraîchissement des données */
      && nextProps.values
      && nextProps.values.vehicles
      && nextProps.values.vehicles.code
      && nextProps.values.vehicles.code.length > 0                      /* nouvelles données avec au moins un véhicule */
      && !nextProps.values.vehicles.code.includes(this.state.code)) {

        update = false

        /*
         * le véhicule précédemment sélectionné n'est pas dans la liste
         *   => affichage du premier de la liste
         */
        this.setState({
          code: nextProps.values.vehicles.code[0]
        })

    }

    return update

  }
  render() {

    let maintenant = new Date()
    const displayCode = (localStorage.getItem('OW_Search_driverPriority') === 'true')
    
    return (
      <>
        <Container className={this.props.classes.root} >
          <Toolbar className={this.props.classes.toolBar}>
            <FormControl className={this.props.classes.controls}>
              
              <MonthPicker 
                  id="month-picker"
                  // label="Mois examiné"
                  value={this.state.dateRef}
                  onChange={this.monthHandler} 
                  minDate={this.props.companyCreation} 
                  maxDate={maintenant} />
              
              <RadioGroup value={this.state.assigned}
                          onChange={this.assignedHandler} 
                          className={this.props.classes.grpRadio}>
                <FormControlLabel 
                    value={false}
                    control={<Radio />}
                    label='Activités à attribuer' />
                <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label='Activités déjà attribuées' />       
              </RadioGroup>

              {this.props.values && this.props.values.vehicles && this.props.values.vehicles.code.length > 0 && this.state.code && (
                <FormControl className={this.props.classes.selectVehicle}>
                  <InputLabel htmlFor="vehicle-select">
                    <VehicleIcon />
                  </InputLabel>
                  <Select id="vehicle-select"
                      value={this.state.code}
                      onChange={this.vehicleHandler} >
                    {this.props.values?.vehicles.code.map( (code, index) => {                  
                      return  <MenuItem key={code} value={code}>
                                {displayCode ? this.props.values.vehicles.code[index] : this.props.values.vehicles.num[index]}
                              </MenuItem>
                    })}
                  </Select>
                </FormControl>
              )}
              
              {!this.state.assigned &&
                <FormControlLabel
                  control={<Switch size="small" 
                                checked={this.state.significantDuration} 
                                onChange={this.significantDurationHandler}
                                />}
                  label={`Durée significative (${this.state.parkDuration ? this.state.parkDuration : '?'} minutes)`}
                  className={this.props.classes.durationLimit}
                />
              }

            </FormControl>

            <HelpButton>
              <HelpCard 
                title="Aide de l'écran" >
                <div>
                  <p className={this.props.classes.memo}>
                    Cet écran vous permet d'afficher les activités sans-carte enregistrées dans les tachygraphes numériques de vos véhicules.
                    Il vous permet aussi de les réaffecter aux conducteurs concernés.<br />
                    L'usage nous indique que seules les activités sans carte d'une durée significative sont sanctionnées.
                  </p>
                
                  <label htmlFor="typesActivites">Type d'activités</label>
                  <ul>
                    <li><em>C</em> = Conduite</li>
                    <li><em>T</em> = Travail</li>
                    <li><em>D</em> = Disponibilité</li>
                    <li><em>Q</em> = Équipage</li>
                    <li><em>R</em> = Repos</li>
                  </ul>

                </div>
              </HelpCard>
            </HelpButton>

          </Toolbar>

          {this.props.loading ?
              <WaitBlock /> : (
              (this.state.dateRef && this.props.values && this.props.values.activities && this.props.values.activities.length === 0) ?
                  <Typography className={this.props.classes.centralInfo} >
                    Aucune activité sans carte durant ce mois de {format(this.state.dateRef, 'MMMM y', {locale: localeFr})}.
                  </Typography> :
                  this.state.code && (
                    this.state.assigned ?
                      <AssignedActDataGrid data={this.props.values.activities[this.state.code]} /> :
                      <WithoutCard 
                          data={this.props.values.activities[this.state.code]} 
                          dateRef={this.state.dateRef}
                          code={this.state.code}/>
                  )
              )
          }
        </Container>
        
      </>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.data.loading,
  values: state.data.values,
  companyCreation: state.client.informations.ow.creation,
})

const mapDispatchToProps = ({
  setTitle,
  setError,
  getActivitiesWithoutCard,
})


const classes = theme => ({
  root: {
    color: theme.palette.primary.main,
    '&& .MuiTypography-colorInherit': {
      color: theme.palette.primary.light,
    }
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  controls: {
    display: 'inline-flex',
    flexDirection: 'row',
  },
  selectVehicle: {
    marginInlineStart: '5em',
  },
  centralInfo: {
    textAlign: 'center',
    marginBlockStart: '6%',
  },
  
  
  iconCard: {
    transform: 'scaleX(-1)',
  },
  grpRadio: {
    display: 'flex',
    flexDirection: 'column',
    marginInlineStart: '1em',
    marginInlineEnd: '1em',
    '&& span': {
      padding: 0,
      paddingLeft: '0.5em',
    }
  },
  memo: {
    textAlign: 'justify',
  },
  durationLimit: {
    marginInlineStart: '1em',
  }
})
export default connect (mapStateToProps, mapDispatchToProps) (withStyles(classes) (SansCarte))
