import React, { Component } from 'react'
import PersonIcon from '@material-ui/icons/Person'
class UserIcon extends Component {
  render() {
    return (
        <>
            <PersonIcon />
        </>
    )
  }
}

export default UserIcon
