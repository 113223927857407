/**
 * Transforme une ligne de tableau en une chaîne CS
 * @param {array} row 
 * @returns string
 */
 export function processRow (row) {
    let separateur = localStorage.getItem('CSV_SEPARATOR')
    if (!separateur) {
        localStorage.setItem('CSV_SEPARATOR', ';')
        separateur = ';'
    } 
    const regexPb = new RegExp(`("|${separateur}|\n)`, 'g')
    const regexFinal = new RegExp(`${separateur}$`, 'gm')
    const finalVal = row.reduce( (txt, value) => {
        let result = ''
        if (typeof(value) !== 'undefined') {
            result = value.toString()
            result = result.replace(/"/g, '""');
            if (result.search(regexPb) >= 0) {
                result = `"${result}"`
            }
        }
        return `${txt}${result}${separateur}`;
    }, '');
    
    return `${finalVal.replace(regexFinal, '')}\n`;
};

/**
 * Procède à l'export sous forme de fichier
 * @param {string} data 
 * @param {*} fileName 
 */
 export function exportCsvFile (data, fileName = 'data.csv') {
    
    const blob = new Blob(['\uFEFF' + data], {
        type: 'text/csv;charset=utf-8;',
    });

    fileName = `${fileName.replace(/\\|\//g, '')}`;     /* suppression des caractères interdit dans un nom de fichier */
   
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName);
    } else {
        const fileURL = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', fileURL);
        downloadLink.setAttribute('id', 'download');
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        URL.revokeObjectURL(fileURL);
    }

}