import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core'

/**
 * Hauteur de l'histogramme
 */
export const HISTO_HEIGHT = 24

export function getActProps (act) {

	const props = {
		label: '?',
		height: 0,
		color: 'black',
	};

	switch (act.activite) {
		case 'C':
			props.label = 'Conduite';
			props.initial = 'C'
			props.height = HISTO_HEIGHT;
			props.color = 'crimson';
			break;

		case 'T':
			props.label = 'Travail';
			props.initial = 'T'
			props.height = HISTO_HEIGHT - 4;
			props.color = 'gold';
			break;

		case 'R':
			props.label = 'Repos';
			props.initial = 'R'
			props.height = HISTO_HEIGHT - 8;
			props.color = 'LimeGreen';
			break;

		case 'D':
			props.label = 'Disponibilité';
			props.initial = 'D'
			props.height = HISTO_HEIGHT - 12;
			props.color = 'blue';
			break;

		case 'Q':
			props.label = 'Équipage';
			props.initial = 'Q'
			props.height = HISTO_HEIGHT - 8;
			props.color = 'LightSkyBlue';
			break;

		default:
			console.warn('Unknown activity');
			props.label = `?${act.activite}?`;
			props.height = HISTO_HEIGHT - 4;
			props.color = 'purple';
			break;
	}

	return props;

}
class DayActivitiesHistogram extends Component {

	static = {
		data: PropTypes.array.isRequired,
		magnification: PropTypes.number.isRequired,
		yInitial: PropTypes.number,										/* ordonnée de l'histogramme */
		onClick: PropTypes.func,											/* fonction d'appel lors du clic sur l'activité */
		onRightClick: PropTypes.func,									/* fonction d'appel lors du clic droit sur l'activité */
	}

	state = {
		activityEl: null,
	}

	onActivityClick = (evt) => {
		
		if (this.props.onClick) {
			this.props.onClick(evt)
		}
		
	}

	onActivityRightClick = (evt) => {
		
		/*
		 * gestion ici du clic droit
		 */
		evt.preventDefault()
		evt.stopPropagation()

		if (this.props.onRightClick) {
			this.props.onRightClick(evt)
		}

	}
	

	renderActivity = (act, i) => {

		/*
		 * caractéristiques graphiques d'une activité
		 */
		const props = getActProps(act)
		const yInitial = (Number.isInteger(this.props.yInitial)) ? this.props.yInitial : 0
		const y = yInitial + HISTO_HEIGHT - props.height		/* tient compte de l'ordonnée éventuelle de l'histogramme */
		const x = act.debut
		const plural = act.duree > 1 ? 's' : '';
		const label = `${act.date.toLocaleDateString()} ${act.heure_debut} : ${props.label} (${act.duree} minute${plural})`

		return (
			<g key={`act_${act.disque}_${act.heure_debut}`} >
				<title>{label}</title>
				<rect 
					x={x} y={y} 
					width={act.duree} height={props.height} 
					stroke={props.color} strokeWidth={0}
					fill={props.color}
					data-index={act.index}
					data-numero={act.numero}
					data-date={act.date}
					data-duration={act.duree}
					data-type={act.activite}
					data-source={act.source}
					data-begin={act.heure_debut}
					data-disque={act.id_disque}
					onClick={this.onActivityClick} 
					onContextMenu={this.onActivityRightClick}
					cursor="pointer"/>
			</g>
		)
	}

	renderScale = () => {

		/*
		 * création des références horaires
		 */
		const hours = [];
		for (let n = 0; n <= 24; n++) {
			hours.push(n);
		}

		const yInitial = (Number.isInteger(this.props.yInitial)) ? this.props.yInitial : 0;
		const y = yInitial + HISTO_HEIGHT;		/* tient compte de l'ordonnée éventuelle de l'histogramme */

		return hours.map(hour => {
			const plural = (hour > 1) ? 's' : '';
			const label = `${hour} heure${plural}`;
			return (
				<g key={`h_${hour}`} >
					<title>{label}</title>
					<line x1={hour * 60} x2={hour * 60} y1={y} y2={y - 6} stroke='gray' strokeWidth={3} />
					{hour === 0 ?
						<g>
							<title>{this.props.data[0]?.date.toLocaleDateString()}</title>
							<text y={y + 9} fontSize={10} fontWeight="bold">{this.props.data[0]?.date.toLocaleDateString()}</text> 
						</g>:
						<text x={hour * 60 - 2} y={y + 9} fontSize={9}>{hour}</text>
					}
					
				</g>);
		})
	}

	render() {
		
		return (
			<g >				
				{this.props.data.map( (act) => {
					if (act.activite) {
						return this.renderActivity(act)
					} else {
						return null
					}
				})}
				{this.renderScale()}
			</g>

		)
	}
}

const classes = {
	root: {
		height: '45px',
		width: '1280px',
		overflow:'scroll',
		borderStyle: 'outset',
		borderWidth: 'thin',
		padding: '1px',
	}
}
export default withStyles(classes)(DayActivitiesHistogram)
