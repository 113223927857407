import React, { Component } from 'react'
import {withTheme} from '@material-ui/core'
import {Tools} from 'mdi-material-ui'

class VehicleRepairIcon extends Component {
  render() {
    let {size, color, ...props} = this.props 
    let htmlColor, themeColor
    size = size ? size : 1
    if (color) {
      htmlColor = color
    } else {
      themeColor = 'primary'
    }
    
    return (
        <Tools size={size} htmlColor={htmlColor} color={themeColor} {...props} />
    )
  }
}

export default withTheme(VehicleRepairIcon)
