import React, {Component} from "react";

import frLocale from "date-fns/locale/fr";
import DateFnsUtils from "@date-io/date-fns";
import format from 'date-fns/format'
import { DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { withTheme, createMuiTheme, ThemeProvider} from "@material-ui/core";

/**
 * Localisation en français
 * @see https://material-ui-pickers.dev/guides/formats
 * 
 * @todo ne pas mettre à jour les dépendances @date-io/date-fns et conserver la version 1 https://www.npmjs.com/package/@date-io/date-fns
 * @see https://material-ui-pickers.dev/getting-started/installation
 * 
 */
class LocalizedFrUtils extends DateFnsUtils {
    getDateTimePickerHeaderText (date) {
        return format(date, 'd MMM', {locale: this.locale})
    }

} 
class DateTimePickerFr extends Component {

    state = {
        simpleDate : false
    }

    componentDidMount() {
        
        if (    !this.props.format
                && !this.props.views) {
            this.setState({
                simpleDate: true
            })

        }

       
    }
    render () {

        let {format, theme, ...props} = this.props

        /*
         * adaptations minimales : format par défaut = dd/MM/yyyy
         */
        if (this.state.simpleDate) {
            theme = createMuiTheme({
                overrides: {
                    MuiFormControl: {
                        root: {
                            maxWidth: '9em',
                        }
                    },
                    MuiInputBase: {
                        input: {
                            textAlign: 'center',
                            color: this.props.theme.palette.primary.main
                        }
                    }
                }
            })

            format = 'dd/MM/yyyy HH:mm'

        } else {
            theme = this.props.theme
        }        

        return (
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={LocalizedFrUtils} locale={frLocale} >
                    <DateTimePicker format={format} ampm={false} {...props} />
                </MuiPickersUtilsProvider>
            </ThemeProvider>
            
        )

    }
}

export default withTheme(DateTimePickerFr);