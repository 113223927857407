import {processRow, exportCsvFile} from '../tools/exportFunctions'
export function exportUploads (data, name) {

    let textExport = processRow([
        'Nom',
        'Date de téléchargement',
        'Début des activités',
        'Fin des activités',         
    ])
    textExport += data.reduce( (text, row) => {
        
        const txtRow = processRow([
            row.nom,
            row.pointage,
            row.debut_activites,
            row.fin_activites,
        ])
        return `${text}${txtRow}`
    }, '')

    
    exportCsvFile(textExport, `${name}.csv`)
}
