import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Card, CardHeader, CardContent,
        withStyles} from '@material-ui/core'

/**
 * Légende
 * 
 * @param {string}      title
 * @param {func}        onClose
 */
class HelpCard extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
  }

  render() {
    return (
        <Card   id="helpCard"
                className={this.props.classes.root}>
            <CardHeader title={this.props.title} />
            <CardContent>
                {this.props.children}
            </CardContent>
        </Card>
    )
  }
}

const classes = {
    root: {
        minWidth: '10vw',
        maxWidth: '40vw',
        minHeight: '10vh',
        display: 'grid',
        gridTemplateRows: '3em auto 3em',
    
    }
}
export default withStyles(classes) (HelpCard)
