import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {IconButton, Tooltip, } from '@material-ui/core'
import CachedIcon from '@material-ui/icons/Cached'

/**
 * Bouton représentant le rafraîchissement du cache
 * 
 * @param   {string}    title   texte de la bulle d'aide
 * @param   {func}      onClick
 */
class RefreshButton extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  render() {
    return (
        <Tooltip title={this.props.title}>
            <IconButton onClick={this.props.onClick}>
                <CachedIcon />
            </IconButton>
        </Tooltip>
    )
  }
}

export default RefreshButton
