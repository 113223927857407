import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Table, TableHead, TableBody, TableRow, TableCell,
        withStyles,
      } from '@material-ui/core'
import CalendarRow from './CalendarRow'
import {DRIVER_TYPE} from '../../Constants'

export class YearCalendar extends Component {
  static propTypes = {
    refDate: PropTypes.object.isRequired,     /* date de référence du mois examiné */
    refType: PropTypes.string.isRequired,     /* type de la cible des données */
    values: PropTypes.array.isRequired,
    onClick: PropTypes.func,                  /* clic sur un case représentant une journée */
  }

  render() {
    const columns = []

    for(let i = 1; i<=31; i++) {
      columns.push(i)
    }

    return (
        <Table className={this.props.classes.root}>
            <TableHead key="tHead">
                <TableRow key='labels'>
                    <TableCell key="month" className={this.props.classes.colName} >
                      Mois
                    </TableCell>
                    {columns.map( (num, index) => {
                      return <TableCell key={`d${index}`} className={this.props.classes.colDays} >
                            {num}
                          </TableCell>
                    })}
                </TableRow>
            </TableHead>

            <TableBody key="tBody" className={this.props.classes.borderTable}>
              {this.props.values.length > 0 ? (
                this.props.values.map( (row, index) => <CalendarRow key={`cr_${index}`} 
                                                            rowIndex={index} 
                                                            row={row} 
                                                            refType={this.props.refType} 
                                                            day31={true} 
                                                            onClick={this.props.onClick}
                                                            />)
              ) : (
                <TableRow key="noElement">
                  <TableCell key='sNoElement' className={this.props.classes.emptyLine}
                      colSpan={31}>
                    <span>{this.props.refType === DRIVER_TYPE ? 'Aucun conducteur' : 'Aucun véhicule'}</span>
                  </TableCell>
                </TableRow>
              )
              }
            </TableBody>
        </Table>
    )
  }
}

const classes = theme => ({
  root: {
    color: theme.palette.primary.main,
    margin: 0,
    padding: 0,
    boxSizing: 'content-box',
    borderStyle: 'solid',
    borderWidth: '2px',
  },
  colName: {
    margin: 0,
    padding: '5px',
    textAlign: 'center',
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderColor: theme.palette.primary.main,    
  },
  colDays: {
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderColor: theme.palette.primary.main,
  },
  sundayColumn: {
    backgroundColor: 'Gainsboro',
  },
  todayColumn: {
    backgroundColor: theme.palette.secondary.light,
  },
  emptyLine: {
    textAlign: 'center',
  },
  
})

export default withStyles(classes) (YearCalendar)
