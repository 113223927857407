import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ButtonOw from './ButtonOw'
import EditIcon from '@material-ui/icons/Edit'
class ButtonEdit extends Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    }
    render() {
        return (
            <ButtonOw
                    title={this.props.title}
                    onClick={this.props.onClick}>
                <EditIcon />
            </ButtonOw>
        )
    }

}

export default ButtonEdit
