import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withStyles, Container, Grid, Paper,
        } from '@material-ui/core'
import SearchParametersSettings from '../../core/components/window/SearchParametersTool'
import ExportParameters from '../../core/components/parameters/ExportParameters'
import {setTitle} from '../../core/redux/actions/WindowAppActions'

class PersonalSettings extends Component {

    componentDidMount() {
        this.props.setTitle('Paramètres personnels')
    }
    render() {
        return (
            <Container>
                <Grid container 
                        className={this.props.classes.root} 
                        spacing={2}>
                    <Grid item >
                        <Paper className={this.props.classes.paper}>
                            <SearchParametersSettings />
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Paper className={this.props.classes.paper}>
                            <ExportParameters />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        )
    }

}

const mapDispatchToProps = ({
    setTitle,
})

const classes = theme => ({
    root: {
        '&& h1': {
            fontSize: '2em',
        }
    },
    paper: {
        padding: '0.4em 1em',
    },
    title: {
        display: 'inline-flex',
        marginTop: '0.3em',
        marginBottom: '0.3em',
    },
    avatar: {
        backgroundColor: theme.palette.primary.light,
        marginRight: '1em',
    }
})
export default connect(null, mapDispatchToProps) (withStyles(classes) (PersonalSettings))
