import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Tooltip, IconButton} from '@material-ui/core'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
class PdfButton extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }

  render() {
    return (
      <Tooltip title={this.props.title}>
          <IconButton onClick={this.props.onClick} disabled={this.props.disabled}>
              <PictureAsPdfIcon />
          </IconButton>
      </Tooltip>
    )
  }
}

export default PdfButton
