import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withStyles,
        TableRow, TableCell, 
        Tooltip,
        } from '@material-ui/core'
import WarningIcon from '../icons/WarningIcon'
import dateFormat from 'date-fns/format'
import localeFr from 'date-fns/locale/fr'

class MonthlyRow extends Component {

    static propTypes = {
        row: PropTypes.object.isRequired,
        dateRef: PropTypes.object.isRequired,
        onRowClick: PropTypes.func,
    }
  render() {
    const {row} = this.props
    let className
    const dateName = dateFormat(this.props.dateRef, 'MMMM yyyy', {locale: localeFr})
    let onClick

    if ( (typeof(row.index) === 'string')
            && (row.index.startsWith('week'))
    ) {
        className = this.props.classes.sumWeekRow
    } else {
        className = this.props.classes.root
        onClick = () => this.props.onRowClick(row.jour)
    }

    for (const [key, value] of Object.entries(row)) {
        if (value === '??:??') {
            row[key] = <WarningIcon />
            className=this.props.classes.warnRow
        }
    }
    
    /*
     * affichage du récapitulatif final
     */
    if (row.index === 'month') {
        return (
            <TableRow key='totalMonth' 
                    hover={true}
                    className={this.props.classes.totalMonth}>
                <TableCell colSpan={2}>Récapitulatif du mois de {dateName}</TableCell>
                <TableCell><Tooltip title="Total conduite" ><span>{row.c}</span></Tooltip></TableCell>
                <TableCell><Tooltip title="Total travail" ><span>{row.t}</span></Tooltip></TableCell>
                <TableCell><Tooltip title="Total disponibilité" ><span>{row.d}</span></Tooltip></TableCell>
                <TableCell><Tooltip title="Total en équipage" ><span>{row.q}</span></Tooltip></TableCell>
                <TableCell><Tooltip title="Total des activités (CDTQ)" ><span>{row.cdtq}</span></Tooltip></TableCell>
                <TableCell><Tooltip title="Amplitude moyenne"><span>{row.amplitude}</span></Tooltip></TableCell>
                <TableCell><Tooltip title="Total du repos" ><span>{row.r}</span></Tooltip></TableCell>
                <TableCell><Tooltip title="Total en kilomètres" ><span>{row.km}</span></Tooltip></TableCell>
                <TableCell><Tooltip title="Vitesse moyenne" ><span>{row.vitesse}</span></Tooltip></TableCell>
                <TableCell><Tooltip title="Nombre d'heures de nuit"><span>{row.nuit}</span></Tooltip></TableCell>
                <TableCell>&nbsp;</TableCell>
            </TableRow>
        )

    /*
     * affichage des lignes standards
     */
    } else {
    
        return (
            <TableRow key={row.index}
                    hover={true}
                    className={className}
                    onClick={onClick} >
                <TableCell>{row.debut}</TableCell>
                <TableCell>{row.fin}</TableCell>
                <TableCell>{row.c}</TableCell>
                <TableCell>{row.t}</TableCell>
                <TableCell>{row.d}</TableCell>
                <TableCell>{row.q}</TableCell>
                <TableCell>{row.cdtq}</TableCell>
                <TableCell>{row.amplitude}</TableCell>
                <TableCell>{row.r}</TableCell>
                <TableCell>{row.km}</TableCell>
                <TableCell>{row.vitesse}</TableCell>
                <TableCell>{row.nuit}</TableCell>
                <TableCell>{row.pb ? <WarningIcon title="Données incohérentes" /> : <span>&nbsp;</span>}</TableCell>
            </TableRow>  
        )
    }

  }
}

const classes = theme => ({
    root: {
        cursor: 'pointer',
    },
    sumWeekRow: {
        borderBlockColor: theme.palette.secondary.light,
        borderBlockStyle: 'solid',
        borderBlockWidth: '2px',
        fontStyle: 'italic',
    },
    warnRow: {
        backgroundColor: theme.palette.warning.light,
        cursor: 'pointer',
    },
    clickable: {
        cursor: 'pointer',
    },
    tdDate: {
        minWidth: '9em',
    },
    totalMonth: {
        borderBlockColor: theme.palette.secondary.light,
        borderBlockStyle: 'double',
        borderBlockWidth: '5px',
        '& td': {
            color: theme.palette.primary.main,
            fontSize: '0.875rem',
            fontStyle: 'italic',
        }
    }
})

export default withStyles(classes) (MonthlyRow)
