
import IdentityService from '../../../services/OwIdentiteService'
import AuthService from '../../../services/AuthenticationService' 
import {getInformations, getServices, getElements, getAuthorizationDownload, getGroupsInfos, getLogo} from './ClientActions'
import {resetRegulation} from './RegulationActions'
import {setError} from './WindowAppActions'

/**
 * Suffixes utilisés par redux-promise-middleware
 */
export const PENDING = '_PENDING'
export const FULFILLED = '_FULFILLED'
export const REJECTED = '_REJECTED'

/**
 * Actions liées à l'identité
 */
export const IDENT_LOGIN = 'IDENT_LOGIN'
export const IDENT_GET_IDENTITY = 'IDENT_GET_IDENTITY';
export const IDENT_GET_CLIENTS = 'IDENT_GET_CLIENTS'
export const IDENT_SET_ID_CLIENT = 'IDENT_SET_ID_CLIENT'
export const IDENT_SET_CLIENT = 'IDENT_SET_CLIENT'
export const IDENT_GET_INFOS_CLIENT = 'IDENT_GET_INFOS_CLIENT'
export const IDENT_RECONNECT = 'IDENT_RECONNECT'
export const IDENT_SET_STATUS = 'IDENT_SET_STATUS'
export const IDENT_LOGOUT = 'IDENT_LOGOUT';

const serviceAuth = new AuthService()
const serviceIdent = new IdentityService()
export const login = (payload) => {

    return dispatch => {
        
        return dispatch ({
            type : IDENT_LOGIN,
            payload : serviceAuth.login (payload)

        }).then ( () => {
            return dispatch (getIdentity ())           /* récupère l'identité de l'utilisateur en cours */
            
        }, (error) => {
            if (error === 401) {
                return Promise.reject(401)
            } else {
              dispatch (setError(error))  
            }
        }).then ( value => {           
            return value
        })
    }
    
}

export const getIdentity = () => {

    return dispatch => {

        return dispatch ({
            type : IDENT_GET_IDENTITY,
            payload : serviceIdent.getIdentity ()
        }).then ( () => {
            return dispatch (getClients ())                /* récupère la liste des clients gérables */
        }).then ( ({value, action}) => {
            let nbClients = value.length
            if (nbClients === 1) {
                var idClient = 0
                delete value.length
                
                for (const client of Object.values(value)) {
                    idClient = client.id_client
                }
                console.info ('== unique société ==', idClient)
                dispatch (choisirClient (idClient))
            }
            return nbClients
        })
    }
    
}

export const getClients = () => ({

    type : IDENT_GET_CLIENTS,
    payload : () => {
        return serviceIdent.getClients ()
    }
})

export const choisirClient = (idClient) => dispatch => {

    dispatch(resetRegulation())
    dispatch ({
        type : IDENT_SET_ID_CLIENT,
        payload : idClient
    })
    return dispatch ({
            type: IDENT_SET_CLIENT,
            payload: serviceIdent.setClient (idClient)
    }).then ( () => {
        dispatch(getStatus())
        return dispatch (getInfosClient ())
    })

}

const getStatus = () => ({
    type: IDENT_SET_STATUS,
    payload: serviceIdent.getStatus()
})

export const getInfosClient = () => {

    return dispatch => {
        return dispatch ({
            type : IDENT_GET_INFOS_CLIENT,
            payload : Promise.all([
                dispatch (getInformations()),
                dispatch (getAuthorizationDownload()),
                dispatch (getServices ()),
                dispatch (getElements ()),
                dispatch (getGroupsInfos()),
                dispatch (getLogo()),
            ])
        })
    }
}

export const logout = (payload) => dispatch => {

    sessionStorage.removeItem('TOKEN')
    sessionStorage.removeItem('idClient')

    dispatch(resetRegulation())

    return dispatch ({
        type: IDENT_LOGOUT,
        payload: serviceIdent.logout ()
    })

}

export const reconnect = (idClientEnCours) => {

    return (dispatch) => {

        return dispatch (getIdentity ()).then ( () => {
            return dispatch (getClients ())
        }).then ( () => {
            console.info('-- reconnect --', idClientEnCours)
            return dispatch (choisirClient (idClientEnCours))
        })

    }
    
}