import React, { Component } from 'react'
import {withStyles} from '@material-ui/core'

class LignesMenu extends Component {

  render() {
    return (
      <div className={this.props.classes.root}>
        {this.props.children}
      </div>
    )
  }
}

const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    overflow: 'hidden',
    width: '100%',
    wordWrap: 'anywhere',
    paddingInlineStart: '0.5em',
    paddingBlock: '0.3em',
    '& a': {
      textDecoration: 'none',
    }
  }
}

export default withStyles(classes) (LignesMenu)
