import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withStyles, Popover, Button, CircularProgress, Divider} from '@material-ui/core'
import ChangeActivityType from './ChangeActivityType'
import AddNewAct from '../AddNewAct'

class ContextWindow extends Component {
	static propTypes = {
		open: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		refresh: PropTypes.func.isRequired,				/* actualisation des données depuis le serveur */
		anchorEl: PropTypes.object,								/* référence de l'ancrage de la fenêtre (activité) */
		addActivity: PropTypes.bool.isRequired,		/* indique qu'une activité pourrait être ajoutée */
		selection: PropTypes.object,							/* sélection actuelle */
		monthHistogram: PropTypes.bool.isRequired,			/* histogramme affichant le mois */
	}

	state = {
		displayNewAct: false,
		actRef: null,
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.anchorEl !== this.props.anchorEl && this.props.anchorEl) {
			this.setState({
				actRef: this.props.anchorEl.dataset,
			})
		}
	}
	handleDisplayNewActivity = () => {
		this.setState({
			displayNewAct: !this.state.displayNewAct,
		})
	}

	render() {

		const duration = this.state.actRef ? parseInt(this.state.actRef.duration) : 0
		return (
			<Popover
				open={this.props.open}
				onClose={this.props.onClose}
				anchorReference="anchorEl"
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				anchorEl={this.props.anchorEl}
				classes={{
					paper: this.props.classes.paper
				}}
			>
				{!this.state.actRef ?
					<CircularProgress color="secondary" /> :
					this.state.displayNewAct ?
						<AddNewAct 
							actRef={this.state.actRef} 
							onClose={this.handleDisplayNewActivity}
							selection={this.props.selection}
							refresh={this.props.refresh}
							/> :
						<>
							<ChangeActivityType
								actRef={this.state.actRef}
								memorizeChanges={this.props.memorizeChanges}
								monthMode={this.props.monthHistogram}
								/>
							{this.props.addActivity && duration >= 2 &&
								<>
									<Divider />
									<Button onClick={this.handleDisplayNewActivity}>
										Insérer une activité
									</Button>
								</>
							}
						</>
				}
			</Popover>
		)
	}
}

const classes = {
	root: {
		backgroundColor: 'pink',
		padding: '0.5em 1em',
		borderBottomRightRadius: '0.5em',
		borderBottomLeftRadius: '0.5em'
	},
	paper: {
		padding: '0.5em 1em',
	}
}
export default withStyles(classes) (ContextWindow)
