
import React, { Component } from 'react'
import { withStyles, Container, FormControl, Toolbar, Grid, Typography} from '@material-ui/core';
import HelpButton from '../../core/components/window/HelpButton';
import HelpCard from '../../core/components/window/HelpCard';
import {setTitle, setError} from '../../core/redux/actions/WindowAppActions'
import { connect } from 'react-redux';
import {VEHICLE_TYPE} from '../../core/components/Constants'
import TicpeInfos from '../../core/components/optigestweb/TicpeInfos'
import QuarterPicker from '../../core/components/forms/date/QuarterPicker';
import ElementSelection from '../../core/components/forms/select/ElementSelection';
import {subYears} from 'date-fns'

class Ticpe extends Component {

	state = {
		selection: null,
		dateRef: new Date(),
	}

	componentDidMount() {
		this.props.setTitle('TICPE')
	}
	handleSelection = (selection) => {
		
		this.setState({
			selection: selection,
		})
	}

	handleQuarter = (quarter) => {
		this.setState({
			dateRef: quarter,
		})
	}
	render() {

		let beginDate = subYears(new Date(), 3)
		if (beginDate < this.props.beginLimit) {
			beginDate = this.props.beginLimit
		}
		return (
			<Container >
				<Toolbar className={this.props.classes.toolbar}>
					<FormControl>
						<Grid container>
							<Grid item>
								<ElementSelection
									onChange={this.handleSelection}
									id={this.state.selection?.id}
									uniqueType={VEHICLE_TYPE}
								/>
							</Grid>
							<Grid item>
								<QuarterPicker
									minDate={beginDate}
									reverse
									onChange={this.handleQuarter}
								/>
							</Grid>
						</Grid>
					</FormControl>

					<HelpButton>
						<HelpCard title="Aide de  l'écran">
							<div>
								Informations issues du compteur kilométrique enregistrées quotidiennement.
							</div>
						</HelpCard>
					</HelpButton>
				</Toolbar>
				{this.state.selection ?
					<TicpeInfos
						selection={this.state.selection}
						dateRef={this.state.dateRef}
						/> :
					<Typography>Veuillez effectuer une sélection</Typography>
				}

			</Container>
		)
	}
}

const mapStateToProps = state => ({
	beginLimit: state.client.informations.ow.limits.begin,
})
const mapDispatchToProps = ({
	setTitle,
	setError,
})

const classes = {
	root: {

	},
	toolbar: {
		justifyContent: 'space-between',
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(classes) (Ticpe))
