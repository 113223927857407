import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux';
import {Route} from 'react-router';
import {Switch } from 'react-router-dom'
import PrivateRoute from '../authentication/PrivateRoute'

import Error from '../../../layout/Error'
import HomePage from '../../../pages/Home/HomePage';
import AuthenticationPage from '../../../pages/Authentication/AuthenticationPage';
import RestrictedPage from '../../../pages/Authentication/RestrictedPage'
import * as rolls from '../Constants'
import WaitBlock from '../window/WaitBlock'

import ChoixEntreprise from '../../../pages/Home/ChoixEntreprise'
import Telechargements from '../../../pages/archivage/Telechargements'
import CalendrierMensuel from '../../../pages/archivage/CalendrierMensuel'
import CalendrierAnnuel from '../../../pages/archivage/CalendrierAnnuel'
import Archives from '../../../pages/archivage/Archives'

import OffensesSearch from '../../../pages/offenses/Search'
import OffensesParameters from '../../../pages/offenses/Parameters'

import ResourcesUsers from '../../../pages/resources/Users'
import ResourcesDrivers from '../../../pages/resources/Drivers'
import ResourcesVehicles from '../../../pages/resources/Vehicles'
import ResourcesTerminals from '../../../pages/resources/Terminals'

import PersonalSettings from '../../../pages/parameters/PersonalSettings'
import CompanySettings from '../../../pages/parameters/CompanySettings'

import Cartes from '../../../pages/optigestweb/Cartes'
import SansCarte from '../../../pages/optigestweb/SansCarte'
import Utilisations from '../../../pages/optigestweb/Utilisations';
import Evenements from '../../../pages/optigestweb/Evenements'
import Kilometrages from '../../../pages/optigestweb/Kilometrages';
import Ticpe from '../../../pages/optigestweb/Ticpe';

import Page404 from '../../../layout/Page404';




/**
 * Routes accessibles dans l'application
 * 
 * Le droit minimal d'accès à un composant privé doit être spécifié via minRoll
 * Le droit est défini par les constantes rolls.U_
 */ 
class Routes extends Component {

  state = {
    loading : true
  }
  componentDidMount () {
    if (this.props.isConnected 
        && this.props.idClientEnCours > 0
        && ( !this.props.clients || !this.props.servicesSouscrits)
    ) {
      this.props.reconnecter (this.props.idClientEnCours).then ( (retour) => {
        this.setState ({
          loading : false
        })
      })
     
    } else {
      this.setState ({
        loading : false
      })
    }
  }

  render() {
    return (
      <>
        {this.props.error && <Error />}
        <Suspense fallback={(<WaitBlock text="Chargement en cours..." />)}>
          <Switch>
            <Route path="/login" exact component={AuthenticationPage} />
            <Route path="/restricted" exact component={RestrictedPage} />

            <Route path="/choixEntreprise" exact component={ChoixEntreprise} />
            <PrivateRoute path="/" exact component={HomePage} minRoll={rolls.U_VISUALISEUR} />

            <PrivateRoute path="/archivage/telechargements" exact component={Telechargements} minRoll={rolls.U_VISUALISEUR} />
            <PrivateRoute path="/archivage/calendrierMensuel" exact component={CalendrierMensuel} minRoll={rolls.U_VISUALISEUR} />
            <PrivateRoute path="/archivage/calendrierAnnuel" exact component={CalendrierAnnuel} minRoll={rolls.U_VISUALISEUR} />
            <PrivateRoute path="/archivage/archives" exact component={Archives} minRoll={rolls.U_ADMINISTRATEUR} />

            <PrivateRoute path="/optigestweb/activitesCartes" exact component={Cartes} minRoll={rolls.U_VISUALISEUR} /> 
            <PrivateRoute path="/optigestweb/activitesSansCarte" exact component={SansCarte} minRoll={rolls.U_VISUALISEUR} />
            <PrivateRoute path="/optigestweb/utilisations" exact component={Utilisations} minRoll={rolls.U_VISUALISEUR} />
            <PrivateRoute path="/optigestweb/evenements" exact component={Evenements} minRoll={rolls.U_VISUALISEUR} />
            <PrivateRoute path="/optigestweb/kilometrages" exact component={Kilometrages} minRoll={rolls.U_VISUALISEUR} />
            <PrivateRoute path="/optigestweb/ticpe" exact component={Ticpe} minRoll={rolls.U_VISUALISEUR} />

            <PrivateRoute path="/offenses/search" exact component={OffensesSearch} minRoll={rolls.U_POUVOIR} />
            <PrivateRoute path="/offenses/parameters" exact component={OffensesParameters} minRoll={rolls.U_POUVOIR} />

            <PrivateRoute path='/resources/users' exact component={ResourcesUsers} minRoll={rolls.U_ADMINISTRATEUR} />
            <PrivateRoute path='/resources/drivers' exact component={ResourcesDrivers} minRoll={rolls.U_ADMINISTRATEUR} />
            <PrivateRoute path='/resources/vehicles' exact component={ResourcesVehicles} minRoll={rolls.U_ADMINISTRATEUR} />
            <PrivateRoute path='/resources/terminals' exact component={ResourcesTerminals} minRoll={rolls.U_ADMINISTRATEUR}  />

            <PrivateRoute path="/parameters/personalSettings" exact component={PersonalSettings} minRoll={rolls.U_VISUALISEUR} />
            <PrivateRoute path="/parameters/companySettings/:tabName" component={CompanySettings} minRoll={rolls.U_ADMINISTRATEUR_SOCIETE} />
            <PrivateRoute path="/parameters/companySettings" component={CompanySettings} minRoll={rolls.U_ADMINISTRATEUR_SOCIETE} />

            <Route path='*' component={Page404} />
          </Switch>
        </Suspense>
      </>
    )
  }
}
const mapStateToProps = state => {
    return {
      error: state.windowApp.error,
    }
}    

export default connect (mapStateToProps) (Routes);
