import { Grid, withStyles } from '@material-ui/core';
import React, { Component } from 'react'
import ParkParameter from './ParkParameter';
import LogotypeParameter from './LogotypeParameter';

class OthersParameters extends Component {
	render() {
		return (
			<Grid container spacing={1} className={this.props.classes.root}>
				<Grid item>
					<LogotypeParameter />
				</Grid>
				<Grid item>
					<ParkParameter />
				</Grid>
			</Grid>
		)
	}
}

const classes = {
	root: {
		// flexDirection: 'row',
	}
}
export default withStyles(classes) (OthersParameters)
