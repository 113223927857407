import {URL_API, 
        METHOD_GET, METHOD_POST, METHOD_PUT, METHOD_DOWNLOAD,
        API, setData, } from "./DataActions"

export const TERM_SAVE_LIST = 'TERM_SAVE_LIST'
export const TERM_UPDATE = 'TERM_UPDATE'
export const TERM_UPDATE_STATE = 'TERM_UPDATE_STATE'

export const getList = () => ({
    type: API,
    payload: {
        url: `${URL_API}/bornes`,
        method: METHOD_GET,
        cancelable: true,
        params: null,
        onSuccess: saveTerminalsList,
    }
})

const saveTerminalsList = (list) => ({
    type: TERM_SAVE_LIST,
    payload: list
})

export const getUrlsInstall = (id) => ({
    type: API,
    payload: {
        url: `${URL_API}/bornes/${id}/install`,
        method: METHOD_GET,
        cancelable: true,
        params: null,
        onSuccess: setData,
    }
})

export const addTerminal = (infos) => ({
    type: API,
    payload: {
        url: `${URL_API}/bornes/add`,
        method: METHOD_POST,
        params: infos,
        onSuccess: getList,
    }
})

export const updateTerminal = (id, infos) => dispatch => {
    dispatch(updateTerminalToApi(id, infos))
    dispatch(updateTerminalLocal(id, infos))
}

const updateTerminalToApi = (id, infos) => ({
    type: API,
    payload: {
        url: `${URL_API}/bornes/${id}`,
        method: METHOD_PUT,
        params: infos,
    }
}) 

const updateTerminalLocal = (id, infos) => ({
    type: TERM_UPDATE,
    payload: infos,
})

export const updateState = (id, hs) => ({
    type: API,
    payload: {
        url: `${URL_API}/bornes/${id}/HS/${hs}`,
        method: METHOD_PUT,
        paramOnSuccess: {
            id: id,
            hs: hs
        },
        onSuccess: updateLocalState
    }
})

const updateLocalState = (data, infos) => ({
    type: TERM_UPDATE_STATE,
    payload: infos,
})

/**
 * Procède au téléchargement d'un fichier d'installation
 * @param {string} code 
 * @param {string} fileName 
 */
export const downloadFile = (code, fileName) => ({
    type: API,
    payload: {
        url: `${URL_API}/borne/download/${code}`,
        method: METHOD_DOWNLOAD,
        params: {
            fileName: fileName,
        },
        cancelable: true,
        // onSuccess: () => {},
        // paramOnSuccess: {},
        // loadingEnd: true,
    }
})