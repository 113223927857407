import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HelpIcon from '@material-ui/icons/HelpOutline'
import {withStyles, IconButton, Popover} from '@material-ui/core'
class HelpButton extends Component {

    static propTypes = {
        children: PropTypes.element,
    }

    state = {
        displayHelp: false,
        anchorEl: null,
    }

    handleDisplay = (evt) => {
        this.setState({
            displayHelp: !this.state.displayHelp,
            anchorEl: evt.target,
        })
    }
    setDisplay = (display) => {
        this.setState({
            displayHelp: display,
        })
    }
    render() {
        
        return (
            <>
                <IconButton onClick={this.handleDisplay}
                    // onMouseEnter={ () => this.setDisplay(true)}
                    // onMouseLeave={ () => this.setDisplay(false)}
                    >
                    <HelpIcon />
                </IconButton>
                <Popover
                    id='helpCard'
                    open={this.state.displayHelp}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleDisplay}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    classes={{
                        paper: this.props.classes.paper
                    }}>
                        {this.props.children}
                </Popover>
            </>
        )
    }
}

const classes = {
    paper: {
        backgroundColor: 'pink',
        minWidth: '20em',
    }
}
export default withStyles(classes) (HelpButton)
