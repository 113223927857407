import { Container, Typography } from '@material-ui/core';
import React, { Component } from 'react'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import {Link} from '@material-ui/core'
import SupportService from '../../core/components/window/SupportService';

class RestrictedPage extends Component {
    
    state = {
        connecting : false
    }
    reload = (e) => {
        e.preventDefault ();
       this.props.history.push (this.props.history.location.state.from);
    }
  render() {
    
    return (
        <Container>
            {this.state.connecting ?
                (<Typography component="h2" color="primary" >
                    Reconnexion en cours. Veuillez patienter...
                </Typography>) :
                (<>
                    <Typography component="h2" color="primary" >
                        Accès restreint
                    </Typography>
                    <Typography color="textPrimary">
                    Vos droits d'accès sont insuffisants pour la ressource demandée.
                    </Typography>
                    <Typography color="textSecondary" >
                        S'il ne s'agit pas d'une erreur de votre part, veuillez contacter votre responsable ; 
                            à défaut notre <SupportService />.
                    </Typography>
                    {this.props.history.location.state?.from && (
                        <Typography color="textPrimary">
                        <Link href="/" onClick={this.reload}>
                            Vous pouvez aussi renouveller la tentative d'accès à la ressource en cliquant sur ce lien.
                        </Link>
                        </Typography>)}
                </>)
            }
            
        </Container>
    )
  }
}

const mapStateToProps = state => ({
     isConnected : state.identity.isConnected,
     user : state.identity.user,
})

export default connect (mapStateToProps) (withRouter(RestrictedPage))
