import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withStyles, Tooltip, TextField} from '@material-ui/core'

import TypeSelection from './TypeSelection'
import {DRIVER_TYPE, VEHICLE_TYPE} from '../../Constants'
import AutoCompleteSelection, {beautifyItem} from './AutoCompleteSelection'
import DriverIcon from '@material-ui/icons/Face'
import VehicleIcon from '@material-ui/icons/LocalShipping'
import EmptyIcon from '@material-ui/icons/Block'

/**
 * Sélection d'un conducteur ou d'un Véhicule
 * 
 * @param   {func}      onChange    renvoi d'un object contenant les éléments pour effectuer un travail
 * @param   {string}    uniqueType  indique quel type unique doit être utilisé
 */
class ElementSelection extends Component {

    /*
     * Séparateur utilisé dans le composant
     */
    static SEPARATOR = '|'
    static propTypes = {
        onChange : PropTypes.func.isRequired,
        uniqueType: PropTypes.string,           /* Pas de changement de type possible */
        id: PropTypes.number,                   /* Id de l'élément à sélectionner */
        uniqueValue: PropTypes.bool,            /* Une seule valeur doit être affichée */
    }
    state = {
        driverPriority : this.props.uniqueType ? (this.props.uniqueType === DRIVER_TYPE) : (localStorage.getItem('OW_Search_driverPriority') === 'true'),
        driverField : localStorage.getItem ('OW_Search_driverField'),
        vehicleField : localStorage.getItem('OW_Search_vehicleField'),
        eltId : 0,
    }
    
    componentDidMount () {
        
        if (this.props.value) {
            throw new Error('Value forbidden. Use id')
        }
        
        /*
         * en cas de valeur unique, 
         * l'information a déjà été transmise
         */
        if (!this.checkUniqueValue ()) {
            
            this.setState({
                eltId: this.props.id,
            })

        }

    }

    componentDidUpdate (prevProps, prevState) {

        /*
         * Changement de référence du formulaire
         */
        if (prevProps.id !== this.props.id) {
            this.setState({
                eltId: this.props.id
            })
        }
        
        /*
         * changement du type sélectionné
         */
        if (prevState.driverPriority !== this.state.driverPriority) {
            this.checkUniqueValue()
        }
    
    }

    /**
     * initialisation auto quand présence d'un unique élément
     * 
     * @return  {boolean}   indique si un chargement a été effectué
     * 
     */
    checkUniqueValue () {
    
        let elt

        if ( (this.state.driverPriority)
            && (this.props.drivers.length === 1)
        ) {
            elt = this.props.drivers[0]

        } else if ( (!this.state.driverPriority)
                && (this.props.vehicles.length === 1)
        ) {
            elt = this.props.vehicles[0]          
        }

        if (elt) {
            
            this.setState({
                eltId : elt.id
            })
            this.props.onChange ({
                type : (this.state.driverPriority) ? DRIVER_TYPE : VEHICLE_TYPE,
                id : elt.id,
            })

            return true

        } else {
            
            return false
        
        }

    }
    handleChangeType = (event, value) => {
        event.preventDefault ()
        
        this.setState ({
            driverPriority : (value === DRIVER_TYPE),
            value: null,
        })
        this.props.onChange ({
            type : value,
            id : 0,
        })
    }

    handleChangeValue = (elt) => {

        /*
         * transmission du changement de valeur
         */
        this.props.onChange ({
            type : (this.state.driverPriority) ? DRIVER_TYPE : VEHICLE_TYPE,
            id : elt.id,
        })
        
    }

    
  render() {

    const itemType = this.state.driverPriority ? DRIVER_TYPE : VEHICLE_TYPE
    const items = (this.state.driverPriority) ? this.props.drivers : this.props.vehicles
    const fieldName = (this.state.driverPriority) ? this.state.driverField : this.state.vehicleField
    
    return (
        <div className={this.props.classes.root}>
            {!this.props.uniqueType &&
                <TypeSelection type={itemType} onChange={this.handleChangeType} />
            }
            {items.length === 0  && 
                <Tooltip title="Aucun élément sélectionnable">
                    <EmptyIcon />
                </Tooltip>
            }
            {(items.length === 1 || this.props.uniqueValue) ?
                <TextField key={`tf${this.props.type}`} 
                    value={beautifyItem(itemType, fieldName, items.find(item => item.id === this.state.eltId)).label} 
                    label={itemType === DRIVER_TYPE ? <DriverIcon /> : <VehicleIcon />}
                    variant="outlined" /> :
                this.state.eltId ?
                    <AutoCompleteSelection
                        type={itemType}
                        list={items}
                        onChange={this.handleChangeValue}
                        valueId={this.state.eltId}
                        /> :         
                    <AutoCompleteSelection
                        type={itemType}
                        list={items}
                        onChange={this.handleChangeValue}
                    />
            }
        </div>)
  }
}

const styles = {
    root : {
        display : 'flex',
        flexDirection: 'row',
        alignItems : 'center',
        color: 'inherit',

    },
    selection : {
        minWidth : '16em',
    },
    parametersTool : {
        width : '4em',
        '&:hover' : {
            cursor : 'pointer',
        }
    },
}
const mapStateToProps = state => ({
    drivers : state.client.drivers,
    vehicles : state.client.vehicles,
})
export default connect (mapStateToProps) (withStyles (styles) (ElementSelection))
