import React, { Component } from 'react'
import {withStyles, Button, ButtonGroup} from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

/**
 * @todo le droit et gauche ne fonctionne pas car non détection du dépassement...
 */
class Navigation extends Component {

    state = {
        right: false,
        left: false,
        down: false,
        up: false,
    }

    resizeObserver = null

    componentDidMount() {
        this.setState({
            right: false,
            left: false,
            down: false,
            up: false,
        })
        this.resizeObserver = new ResizeObserver(() => {
            this.updateDisplay()
        })
        this.resizeObserver.observe(document.body)

        window.onscroll= this.updateDisplay
    }

    componentWillUnmount() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect()
        }
    }
    updateDisplay = () => {
       
        this.setState({
            right: (document.body.scrollWidth > document.body.clientWidth && document.body.scrollWidth > (document.body.clientWidth + window.scrollX)),
            left: window.scrollX > 0,
            down: window.innerHeight < (document.body.clientHeight - window.scrollY),
            up: window.scrollY > 0,
        })
    }
    goDown () {
        
        window.scrollBy({
            top: window.innerHeight,
            behavior: 'smooth',
        })
    }

    goUp () {
        window.scrollBy({
            top: -window.innerHeight,
            behavior: 'smooth',
        })
    }

    goLeft () {
        window.scrollBy({
            left: -window.innerWidth,
        })
    }

    goRight () {
        window.scrollBy({
            left: window.innerWidth,
        })
    }

  render() {

    if (this.state.up || this.state.down || this.state.left || this.state.right) {
    
        return (
            <div className={this.props.classes.root} >

                {(this.state.right || this.state.left) &&
                <ButtonGroup 
                        color="secondary"
                        size="small"
                        variant="contained"
                        className={this.props.classes.horizontalGroup}>
                    <Button onClick={this.goLeft}
                            disabled={!this.state.left}>
                        <ArrowBackIcon />
                    </Button>                    
                    <Button onClick={this.goRight}
                            disabled={!this.state.right}>
                        <ArrowForwardIcon />
                    </Button>                    
                </ButtonGroup>
                }

                {(this.state.up || this.state.down) &&
                <ButtonGroup
                        color="secondary"
                        size="small"
                        variant="contained"
                        orientation="vertical">
                
                    <Button onClick={this.goUp}
                            disabled={!this.state.up}>
                        <ArrowUpwardIcon />
                    </Button>                    
                    <Button onClick={this.goDown}
                            disabled={!this.state.down}>
                        <ArrowDownwardIcon />
                    </Button>                                    
                </ButtonGroup>
                }
            </div>
        )

    } else {
        return null
    }
  }
}

const classes = theme => ({
    root: {
        zIndex: 1050,
        position: 'fixed',
        margin: '0.5em',
        right: 0,
        bottom: 0,
    },
    horizontalGroup: {
        position: 'fixed',
        bottom: 0,
        right: '4em',
        marginBottom: '0.5em',
    }

})

export default withStyles(classes) (Navigation)
