import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HelpCard from '../window/HelpCard'
import {Typography} from '@material-ui/core'

class AlertsParametersHelp extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
  }

  render() {
    return (
        <HelpCard value={this.props.value} title="Alertes">
          <>
            <Typography align="justify">
                Optimal Web vous alerte par courriel lorsqu'il est temps de procéder au téléchargement des données de la mémoire de masse ou des cartes des conducteurs.
            </Typography>
            <div>
                <Typography>Il est possible de programmer quatre alertes. Elles seront envoyées :</Typography>
                <ol>
                    <li><Typography>N1 jours avant la définition de la mi-période</Typography></li>
                    <li><Typography>N2 jours avant la définition de la mi-période</Typography></li>
                    <li><Typography>N3 jours avant la date limite légale théorique</Typography></li>
                    <li><Typography>N4 jours avant la date limite légale théorique</Typography></li>
                </ol>
            </div>
            <Typography align="justify">
                Vous pouvez désactiver les deux alertes de mi-période.
            </Typography>
          </>
        </HelpCard>
    )
  }
}

export default AlertsParametersHelp
