import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withStyles, Paper, Checkbox, Toolbar,
        Table, TableHead, TableBody, TableRow, TableCell,
        
      } from '@material-ui/core'
import PrintButton from '../window/PrintButton'
import ExportButton from '../window/ExportButton'
import {exportDriverOffenses} from './exportOffenses'
import toPrint from '../tools/toPrint'

class OffensesDriver extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    select: PropTypes.bool,
    all: PropTypes.bool,
    onSelect: PropTypes.func,
    buttons: PropTypes.arrayOf(PropTypes.element),
    noButtons: PropTypes.bool,
  }

  state = {
    data: [],     /* copie des données incluant une référence interne (id) */
    selection: [],

  }

  componentDidMount() {

    /*
     * création d'un identifiant pour chaque infraction
     */
    let data = []
    this.props.data.forEach(obj => {
      obj.id = Math.random().toString()
      data.push(obj)
    })
    this.setState({
      data: data,
    })

    /*
     * initialisation de la sélection complète par défaut
     */
    if (this.props.select &&
        this.props.all) {
      let newSelection = []
      data.forEach(obj => {
        newSelection.push(obj.id)
      })
      this.setState({
        selection: newSelection,
      })

      if (this.props.onSelect) {
        this.props.onSelect(data)
      }
    }

  }
  componentDidUpdate(prevProps, prevState) {

    /*
     * renvoie au parent les infractions sélectionnées
     */
    if (this.props.onSelect) {
      let offenses = []
      this.state.selection.forEach(ref => {
        offenses.push(this.state.data.find(elt => elt.id === ref))
      })
      this.props.onSelect(offenses)
    }
  }
  handleCheckbox = (evt) => {
    const ref = evt.target.value
    let newSelection = []

    if (this.state.selection.includes(ref)) {      
      newSelection = this.state.selection.filter(value => value !== ref)
    } else {
      newSelection = this.state.selection
      newSelection.push(ref)
    }
    
    this.setState({
      selection: newSelection,
    })

  }

  handleAllCheckbox = () => {
    let newSelection = []
    if (this.state.selection.length !== this.state.data.length) {
      newSelection = this.state.data.map(elt => elt.id)
    } 
    this.setState({
      selection: newSelection,
    })

  }

  print = () => {
    console.info ('Print datagrid of driver offenses')
    
    const client = this.props.clients[this.props.idClient]
    const supprClass = /class="[a-zA-Z- 0-9]+"/gm;
    let html = document.getElementById('offensesTable').innerHTML.replace(supprClass, '')
    html = `<table>${html}</table>`

    toPrint(html, client.nom_client, this.props.title, this.props.logo)
  }

  export = () => {
    exportDriverOffenses(this.state.data, this.props.title)
  }
  render() {
    
    return (
      <Paper>
        {!this.props.noButtons &&
          <Toolbar className={this.props.classes.toolbar}>
            {this.props.buttons && (this.props.buttons.map((elt,index) => {
              return <div key={`bt_${index}`}>
                {elt}
              </div>
            }))}
            <ExportButton title="Exporter en CSV" onClick={this.export} />
            <PrintButton title="Imprimer" onClick={this.print} />
          </Toolbar>
        }
        <Table id="offensesTable" size='small'>
            <TableHead>
                <TableRow key={`driver_head`}>
                    {this.props.select &&
                    <TableCell className={this.props.classes.cell1}>
                      <Checkbox 
                          checked={this.state.selection.length === this.state.data.length}
                          indeterminate={this.state.selection.length > 0 && this.state.selection.length !== this.state.data.length}
                          onClick={this.handleAllCheckbox}
                      />
                    </TableCell>
                    }
                    <TableCell className={this.props.classes.cell2}>Début</TableCell>
                    <TableCell className={this.props.classes.cell3}>Fin</TableCell>
                    <TableCell className={this.props.classes.cell4}>Durée ou quantité</TableCell>
                    <TableCell className={this.props.classes.cell6}>Gravité</TableCell>
                    <TableCell>Libellé</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data.map( (driver, i) => {
                return <TableRow key={`driver_${i}`}>
                  {this.props.select &&
                    <TableCell className={this.props.classes.cell1}>
                      <Checkbox 
                          checked={this.state.selection.includes(driver.id)}
                          value={driver.id} 
                          onClick={this.handleCheckbox} />
                    </TableCell>
                  }
                  <TableCell className={this.props.classes.cell2}>{driver.szDebut}</TableCell>
                  <TableCell className={this.props.classes.cell3}>{driver.szFin}</TableCell>
                  <TableCell className={this.props.classes.cell4}>{driver.szValue}</TableCell>
                  <TableCell className={this.props.classes.cell6}>{driver.Grav}</TableCell>
                  <TableCell>{driver.Lib}</TableCell>
                </TableRow>
              })}
            </TableBody>
        </Table>
      </Paper>
    )
  }
}

const mapStateToProps = state => ({
  clients: state.identity.clients,
  idClient: state.identity.idClientEnCours,
  logo: state.client.logo,
})

const classes = ({
  root: {

  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cell1: {
    width: '3em',
  },
  cell2: {
    width: '8em',
  },
  cell3: {
    width: '8em'
  },
  cell4: {
    width: '9em',
    textAlign: 'center',
  },
  cell5: {
    width: '2em',
    textAlign: 'center',
  },
  cell6: {
    width: '3em',
    textAlign: 'center',
  }
})
export default connect(mapStateToProps) (withStyles(classes) (OffensesDriver))
