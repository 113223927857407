import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withStyles, Container, Toolbar,
        Grid, Typography,
        } from '@material-ui/core'
import {DataGrid, ToolbarOptions,} from 'tubular-react'
import WaitBlock from '../../core/components/window/WaitBlock'
import HelpCard from '../../core/components/window/HelpCard'
import HelpButton from '../../core/components/window/HelpButton'
import Focus from '../../core/components/window/Focus'
import VehicleForm from '../../core/components/resources/VehicleForm'
import AddVehicleForm from '../../core/components/resources/AddVehicleForm'
import ButtonAdd from '../../core/components/window/ButtonAdd'
import {U_ADMINISTRATEUR} from '../../core/components/Constants'
import {isUserAllow} from '../../core/components/tools/User'

import {setTitle, setError} from '../../core/redux/actions/WindowAppActions'
import {updateVehicle, updateCodeVehicle} from '../../core/redux/actions/ElementsActions'
import { createColumn, ColumnDataType, ColumnSortDirection } from 'tubular-common';


class Vehicles extends Component {

    state = {
        displayEditVehicle: false,
        displayAddVehicle: false,
        editVehicleId: 0,
        refreshDataGrid: 0,
    }
    componentDidMount() {
        this.props.setTitle('Véhicules')
    }

    componentDidUpdate(prevProps, prevState) {

        /*
         * actualisation grille 
         * si l'édition ou la création d'un véhicule est terminée
         */
        if ( (prevState.displayEditVehicle && !this.state.displayEditVehicle) 
              || (prevState.displayAddVehicle && !this.state.displayAddVehicle)  
        ) {           
           this.refreshDataGrid()
        }

    }
    refreshDataGrid = () => {
        this.setState({
            refreshDataGrid: this.state.refreshDataGrid + 1
        });
    }
    getColumns = () => {
        return [
            createColumn('id', {
                isKey: true,
                dataType: ColumnDataType.Numeric,
                visible: false,
            }),
            createColumn('veh_reg_no', {
                label: 'Immatriculation',
                sortOrder: 1,
                sortable: true,
                sortDirection: ColumnSortDirection.Ascending,
                searchable: true,
            }),
            createColumn('veh_code', {
                label: 'Code',
                sortable: true,
                searchable: true,
            }),
            createColumn('actif', {
                label: 'Contrat',
                sortable: true,
                sortOrder: 4,
                dataType: ColumnDataType.Boolean,
            })
        ]
    }

    onRowClick = (vehicle) => {
        this.setState({
            displayEditVehicle: true,
            displayAddVehicle: false,
            editVehicleId: vehicle.id
        })
    }

    onCloseEditVehicle = () => {
        this.setState({
            displayEditVehicle: !this.state.displayEditVehicle,
        })
    }

    onChangeEditVehicle = (changes) => {
          
        if (changes.immatriculation || changes.hasOwnProperty('numerique')) {
            this.props.updateVehicle(this.state.editVehicleId, changes)
        }
        
        if (changes.code) {
            this.props.updateCodeVehicle(this.state.editVehicleId, changes.code)
        }

        /*
        * actualisation grille (refresh)
        */
        this.refreshDataGrid()
        
    }

    handleAddVehicle = () => {
        this.setState({
            displayAddVehicle: !this.state.displayAddVehicle,
            displayEditVehicle: false,
            refreshDataGrid: this.state.refreshDataGrid+1,      /* rafraichissement de la liste */
        })
    }

    render() {
        
        const btAddUser = isUserAllow(this.props.actualUser.roles, U_ADMINISTRATEUR) &&
                <ButtonAdd key='btAdd' title='Ajouter un véhicule' onClick={this.handleAddVehicle} />

        const toolbarOptions = new ToolbarOptions({
            enablePagination: false,
            itemsPerPage: 10000,
            title: 'Véhicules gérés',
            customItems:[btAddUser]
        });

        return (
            <Container>
                <Toolbar className={this.props.classes.toolbar}>
                    <HelpButton>
                        <HelpCard title="Aide de l'écran" >
                            <>
                            <div className={this.props.classes.memo}>
                                Cet écran permet de visualiser et gérer les véhicules.
                            </div>
                            <div>
                                <Typography variant='body2' paragraph align='justify'>
                                    Les données issues d'un document officiel ne sont pas modifiables.
                                </Typography>
                                <Typography variant='body2' paragraph align='justify'>
                                     Le code personnalisé comporte 10 caractères au maximum.
                                </Typography>
                                <Typography variant="body2" paragraph align="justify">
                                    Toute modification d'un contrat n'est visible qu'après reconnexion à l'application.
                                </Typography>
                            </div> 
                            </>                          
                        </HelpCard>
                    </HelpButton>
                </Toolbar>
                {!this.props.vehicles ? 
                    <WaitBlock text='Chargement en cours' /> :
                    <Grid container spacing={2}>
                        <Grid item lg className={this.props.classes.grid}>
                            <DataGrid 
                                columns={this.getColumns()} 
                                dataSource={this.props.vehicles}  
                                gridName="Véhicules" 
                                onError={(err) => {
                                    console.warn('Datagrid error', err)
                                    this.props.setError ('Datagrid error')
                                }}
                                deps={[this.state.refreshDataGrid]}
                                onRowClick={this.onRowClick}
                                toolbarOptions={toolbarOptions}
                                langKey="fr" 
                                 />
                                                
                        </Grid>
                        <Grid item md>
                        {this.state.displayEditVehicle && 
                            <Focus>
                                <VehicleForm id={this.state.editVehicleId}
                                    onClose={this.onCloseEditVehicle} 
                                    onChange={this.onChangeEditVehicle} 
                                    onRefresh={this.refreshDataGrid} />
                            </Focus>
                        }
                        </Grid>
                        <Grid item xs>
                        {this.state.displayAddVehicle &&
                            <Focus>
                                <AddVehicleForm onClose={this.handleAddVehicle} /> 
                            </Focus>
                        }
                        </Grid>
                    </Grid>
                    
                }
                
            </Container>
        )
    }

}

const mapStateToProps = state => ({
    vehicles: state.client.vehicles,
    actualUser: state.identity.user,
})
const mapDispatchToProps = ({
    setTitle,
    setError,
    updateVehicle,
    updateCodeVehicle,
})

const classes = theme => ({
    root: {

    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyItems: 'space-between',

    },
    grid: {
        '&& .MuiTableRow-hover': {
            cursor: 'pointer',
        }
    },
    memo: {
        textAlign: 'justify',
        marginBottom: '1.3em',
    }
})

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (Vehicles))
