import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DateFnsAdapter from '@date-io/date-fns'
import {addYears,
       startOfYear, endOfYear
      } from 'date-fns'

import ButtonOw from '../../window/ButtonOw'
import DatePickerFr from './DatePickerFr'

import {withStyles, FormControl,
        withTheme, ThemeProvider, createMuiTheme,
        } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'


class YearPicker extends Component {
  static propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
  }

  state = {
    examinedDate: new Date(),
    previousYearDisabled: false,
    nextYearDisabled: false,
    minDate: null,
    maxDate: null,
  }

  componentDidMount() {

    this.setState({
      previousYearDisabled: this.isYearMin(this.state.examinedDate),
      nextYearDisabled: this.isYearMax(this.state.examinedDate),
      minDate: startOfYear (this.props.minDate),
      maxDate: endOfYear(this.props.maxDate),
    })

  }

  onChangeYear = (value) => {
    
    this.setState({
      examinedDate: value,
      previousYearDisabled: this.isYearMin(value),
      nextYearDisabled: this.isYearMax(value),
    })

    if (this.props.onChange) {
        this.props.onChange(value)
    }
  }

  selectPreviousYear = () => {
    
    const newDate = addYears(this.state.examinedDate, -1)
    this.setState({
      examinedDate: newDate,
      previousYearDisabled: this.isYearMin(newDate),
      nextYearDisabled: false,
    })

    if (this.props.onChange) {
        this.props.onChange(newDate)
    }
  }

  selectNextYear = () => {
    
    const nextYear = addYears(this.state.examinedDate, 1)

    this.setState({
      examinedDate: nextYear,
      previousYearDisabled: false,
      nextYearDisabled: this.isYearMax (nextYear),
    })
    
    if (this.props.onChange) {
        this.props.onChange(nextYear)
    }
  }

  isYearMin = (newDate) => {

    let isMin = false
    const dateFns = new DateFnsAdapter()
  
    if (this.props.minDate
        && dateFns.isSameYear(this.props.minDate, newDate)) {
      isMin = true
    }

    return isMin
  }
  isYearMax = (newDate) => {

    let isMax = false
    const dateFns = new DateFnsAdapter()
  
    if (this.props.maxDate
        && dateFns.isSameYear(this.props.maxDate, newDate)) {
        isMax = true
    }

    return isMax
  }

  componentDidUpdate () {

    if ( this.props.maxDate
          && this.state.examinedDate > this.props.maxDate) {
      this.setState({
        examinedDate: this.props.maxDate,
      })
    }

    /*
     * gestion de l'affichage du bouton "année suivante"
     */
    if (this.state.nextYearDisabled
          && !this.isYearMax(this.state.examinedDate)) {
      this.setState({
        nextYearDisabled: false
      })
    } else if (!this.state.nextYearDisabled
          && this.isYearMax(this.state.examinedDate)) {
      this.setState({
        nextYearDisabled: true
      })
    }

    /*
     * gestion de l'affichage du bouton "année précédente"
     */
    if (this.state.previousYearDisabled
        && !this.isYearMin(this.state.examinedDate)) {
      this.setState({
        previousYearDisabled: false
      })
    } else if (!this.state.previousYearDisabled
          && this.isYearMin(this.state.examinedDate)) {
      this.setState({
        previousYearDisabled: true
      })
    }

  }
  render() {
    const adaptedTheme = createMuiTheme({
      overrides: {
        MuiTextField: {
          root: {
            width: '4em',
          }
        },
        MuiInput: {
          input: {
            textAlign: 'center',
            color: this.props.theme.palette.primary.main,
          }
        }

      }
    })
    return (
      <ThemeProvider theme={adaptedTheme} >
        <FormControl className={this.props.classes.root}>
            <ButtonOw title="Année précédente" onClick={this.selectPreviousYear} disabled={this.state.previousYearDisabled}>
            <KeyboardArrowLeft />
            </ButtonOw>
            <DatePickerFr
                value={this.state.examinedDate}
                onChange={this.onChangeYear} 
                autoOk={true}
                maxDate={this.state.maxDate}
                minDate={this.state.minDate}
                views={['year']}
                />
            <ButtonOw title="Année suivante" onClick={this.selectNextYear} disabled={this.state.nextYearDisabled}>
            <KeyboardArrowRight />
            </ButtonOw>
        </FormControl>
      </ThemeProvider>
    )
  }
}

const classes = {
  root: {
    flexDirection: 'row',
  }
}
export default withTheme (withStyles(classes) (YearPicker))
