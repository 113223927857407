import DateFnsAdapter from '@date-io/date-fns'
import LocaleFr from 'date-fns/locale/fr'
import transformData from './transformData'

const dataAdapter = (props) => {
    
    const dateFns = new DateFnsAdapter({locale: LocaleFr})
    let refDate = new Date(props.ref.year, 0, 1)
    let values = []
  
    /*
    * pour chaque mois, on renseigne les informations liées au conducteur/véhicule
    */
    props.data && props.data.forEach(elt => {

            /*
             * Initialisations
             */
            let firstDay = dateFns.startOfMonth(refDate)
            let lastDay = dateFns.endOfMonth(refDate)

            /*
            * mémorisation du nom
            */
            let row = {
                month: refDate,
                label: dateFns.getMonthText(refDate),
                days: []
            }

            /*
            * création du calendrier
            */
            for (let d = firstDay; d <= lastDay; d = dateFns.addDays(d, 1)) {
                let aDay = {
                    day: d,
                    state: '',
                    files: [],
                    actions: [],
                }

                row.days.push (aDay)
            }

            /*
            * remplissage du calendrier
            * avec les données transmises
            */
            if (elt !== 'null') {               /* ie existe des informations dans le mois */
                elt.forEach (infos => {

                    if ( (typeof(infos) == 'object') && (infos[1] || infos[3])) {   /* ie il y a une information à traiter */

                        row.days = transformData(row.days, 0, infos)

                    }

                })
            }

            /*
             * mois suivant
             */
            refDate = dateFns.getNextMonth(refDate)

            /*
            * stockage des informations du conducteur/véhicule
            */
            values.push(row)

    })
 
    /*
     * retour pour transmission
     */
    return values

}

export default dataAdapter