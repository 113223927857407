import React, { Component } from 'react'
import {connect} from 'react-redux'
import {reconnect} from '../core/redux/actions/IdentityActions'
import WaitBlock from '../core/components/window/WaitBlock'
import Routes from '../core/components/router/Routes'
import MenuModules from './MenuModules'
class DynamicApp extends Component {

    state = {
        loading: true,
    }
    componentDidMount () {
        if (this.props.isConnected 
            && this.props.idClientEnCours > 0
            && ( !this.props.clients || !this.props.servicesSouscrits)
        ) {
          this.props.reconnect (this.props.idClientEnCours).then ( (retour) => {
            this.setState ({
              loading : false
            })
          })
         
        } else {
          this.setState ({
            loading : false
          })
        }
    }
    render() {
        return (
            (this.state.loading || this.props.loading) ? 
                (<WaitBlock text="Récupération des informations en cours..." />) :
                (   <div className='CentreApplication'>
                        <MenuModules />
                        <Routes />       
                    </div>
                )
        )
    }
}

const mapStateToProps = state => {
    return {
        loading : state.client.loading,
        isConnected: state.identity.isConnected,
        idClientEnCours : state.identity.idClientEnCours,
        clients: state.identity.clients,
        servicesSouscrits : state.client.services,
    }
}
const mapDispatchToProps = ({
    reconnect
})
export default connect(mapStateToProps, mapDispatchToProps) (DynamicApp)
