
import * as act from "../actions/IdentityActions";

/**
 * État initial
 */
let idClientInitial = sessionStorage.getItem ('idClient')
const INITIAL_STATE = {
    error : null,
    loading : true,
    isConnected: sessionStorage.getItem('TOKEN') !== null,
    token: sessionStorage.getItem('TOKEN'),
    user: null,
    clients : null,
    idClientEnCours : (idClientInitial) ? parseInt(idClientInitial) : 0
}

const reducer = (state = INITIAL_STATE, action) => {

    if (action.type.endsWith (act.REJECTED)) {
        console.warn('REJECTED', action.payload)
        return Object.assign ({}, state, {
            error : action.payload,
            loading: false
        })

    } else {
        switch (action.type) {

            // case action.type.endsWith (act.REJECTED):
            //     console.info('REJECTED', action.payload)
            //     return Object.assign ({}, state, {
            //         error : action.payload,
            //         loading: false
            //     })

            /*
            * Sauvegarde du token
            * en vue d'une réutilisation dans une autre application
            */
            case act.IDENT_LOGIN + act.PENDING:
                return Object.assign({}, state, {
                    loading: true,
                    isConnected: false,
                    token: null,
                    user: null,
                    clients: null,
                    idClientEnCours : 0,
                })

            case act.IDENT_LOGIN + act.FULFILLED:
                sessionStorage.setItem('TOKEN', action.payload.token);
                sessionStorage.setItem('idClient', 0)

                return Object.assign({}, state, {
                    loading: false, 
                    isConnected: action.payload.token.length > 0,
                    token : action.payload.token,
                });
                
            /*
            * conservation de l'identité
            */
            case act.IDENT_GET_IDENTITY + act.PENDING:
                return Object.assign({}, state, {
                    loading: true,
                    user: null,
                    clients: null
                })

            case act.IDENT_GET_IDENTITY + act.FULFILLED:
                console.info('-- Connected --', action.payload.utilisateur.courriel, action.payload.utilisateur.identifiant)
                console.info('- initial status -', action.payload.utilisateur.roles)
                return Object.assign ({}, state, {
                    loading : false,
                    user : action.payload.utilisateur,
                    clients : null,
                });

            /*
            * conservation de la liste des clients accessibles
            */
            case act.IDENT_GET_CLIENTS + act.PENDING:
                return Object.assign ({}, state, {
                    loading : true,
                    clients : null,
                })
            case act.IDENT_GET_CLIENTS + act.FULFILLED:

                return Object.assign ({}, state, {
                    loading: false,
                    clients: action.payload,
                })

            /*
            * conservation de l'id du client en cours de traitement
            */
            case act.IDENT_SET_ID_CLIENT:
                sessionStorage.setItem ('idClient', action.payload)
                return Object.assign({}, state, {
                    loading: true,
                    idClientEnCours: action.payload,
                })

            case act.IDENT_SET_CLIENT + act.FULFILLED:

                /*
                * mise à jour fenêtre application
                */
                if (state.clients) {
                    const nomClient = state.clients[state.idClientEnCours].nom_client
                    window.document.title = `Optimal Web - ${nomClient}`
                } else {
                    window.document.title = 'Optimal Web'
                }
                
                return state

            /*
             * 
             */
            case act.IDENT_SET_STATUS + act.FULFILLED:
                const userWithStatus = Object.assign({}, state.user, {
                    roles: action.payload,
                })
                console.info('-- status in company --', userWithStatus.roles)
                return Object.assign({}, state, {
                    user: userWithStatus
                })
                
            /*
             * récupération des informations liées au client
             */
            case act.IDENT_GET_INFOS_CLIENT + act.PENDING:
                return Object.assign({}, state, {
                    loading: true,
                })
            case act.IDENT_GET_INFOS_CLIENT + act.FULFILLED:
                return Object.assign({}, state, {
                    loading: false,
                })
            /*
            * déconnexion totale
            */
            case act.IDENT_LOGOUT + act.PENDING:
                return Object.assign({}, state, {
                    loading: true
                })
            case act.IDENT_LOGOUT + act.FULFILLED:
                
                return { 
                    isConnected: false,
                    token : null,
                    user: null,
                    clients : [],
                    idClientEnCours : 0,
                };

            default:
                return state;
        }
    }
}

export default reducer