import React, { Component } from 'react'
import HelpCard from '../window/HelpCard'
import {Typography} from '@material-ui/core'
export class HelpChoixEntreprise extends Component {
  render() {
    return (
      <HelpCard title="Sélection d'une entreprise">
        <div>
            <Typography>
                Pour sélectionner une entreprise, veuillez cliquer sur la ligne décrivant cette entreprise.
            </Typography>
            <Typography>
                Pour filtrer les entreprises, indiquez les caractères recherchés (les majuscules et minuscules sont des caractères différents).
                Lorsque vous fermez les filtres, la sélection est réinitialisée.
            </Typography>
        </div>
      </HelpCard>
    )
  }
}

export default HelpChoixEntreprise
