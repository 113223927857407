import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Table, TableHead, TableBody, TableRow, TableCell, Tooltip,
        withStyles,
      } from '@material-ui/core'
import DateFnsAdapter from '@date-io/date-fns'
import localeFr from 'date-fns/locale/fr'
import CalendarRow from './CalendarRow'
import {DRIVER_TYPE} from '../../Constants'
import clsx from 'clsx'
export class MonthCalendar extends Component {
  static propTypes = {
    refDate: PropTypes.object.isRequired,     /* date de référence du mois examiné */
    refType: PropTypes.string.isRequired,     /* référence du type affiché : conducteur ou véhicule */
    values: PropTypes.array.isRequired,
    onClick: PropTypes.func,
  }

  createCalendarColumns = () => {
    const columns = []
    const dateFns = new DateFnsAdapter({locale: localeFr })
    const firstDay = dateFns.startOfMonth(this.props.refDate)
    const lastDay = dateFns.endOfMonth(this.props.refDate)

    for (let i = firstDay; i <= lastDay; i = dateFns.addDays(i, 1)) {
  
      columns.push({
        label: dateFns.format(i, 'iii dd'),
        day: i,
      })

    }

    return columns
  }

  render() {
    
    const calendar = this.createCalendarColumns()
    const today = new Date()

    return (
        <Table className={this.props.classes.borderTable}>
            <TableHead key="tHead">
                <TableRow key='labels'>
                    <TableCell key="name" className={this.props.classes.colName} >
                      {this.props.refType === DRIVER_TYPE ? 'Nom' : 'Immatriculation'}
                    </TableCell>
                    {calendar.map( (aDay, index) => {
                      let isToday = aDay.day.toDateString() === today.toDateString()
                      let colorClass = clsx(
                          this.props.classes.colName,
                          isToday && this.props.classes.todayColumn,
                          aDay.day.getDay() === 0 && this.props.classes.sundayColumn
                      )          
                      const elt = 
                          <TableCell key={`d${index}`} 
                              className={colorClass}>
                            {aDay.label}
                          </TableCell>
                      if (isToday) {
                        return <Tooltip key={`td_d${index}`} title="Aujourd'hui">{elt}</Tooltip>
                      }  else {         
                        return elt
                      }

                    })}
                </TableRow>
            </TableHead>

            <TableBody key="tBody" className={this.props.classes.borderTable}>
              {this.props.values.length > 0 ? (
                this.props.values.map( (row, index) => <CalendarRow key={`cr_${index}`} 
                                                                    rowIndex={index} 
                                                                    row={row} 
                                                                    refType={this.props.refType}
                                                                    onClick={this.props.onClick}
                                                                    />)
              ) : (
                <TableRow key="noElement">
                  <TableCell key='sNoElement' className={this.props.classes.emptyLine}
                      colSpan={calendar.length +1}>
                    {this.props.refType === DRIVER_TYPE ? 'Aucun conducteur' : 'Aucun véhicule'}
                  </TableCell>
                </TableRow>
              )
              }
            </TableBody>
        </Table>
    )
  }
}

const classes = theme => ({
  root: {

  },
  colName: {
    margin: 0,
    padding: '5px',
    textAlign: 'center',
    borderStyle: 'solid',
    borderWidth: 'thin',
  },
  sundayColumn: {
    backgroundColor: 'Gainsboro',
  },
  todayColumn: {
    backgroundColor: theme.palette.secondary.light,
  },
  emptyLine: {
    textAlign: 'center',
  },
  borderTable: {
    borderStyle: 'solid',
    borderWidth: '2px',
  }
})
export default withStyles(classes) (MonthCalendar)
