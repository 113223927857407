import React, { Component } from 'react'

class Focus extends Component {

    constructor(props) {
        super(props)
        this.ref = React.createRef()
    }

    componentDidUpdate(prevProps, prevState) {

        /*
         * focus sur le formulaire
         */
        if (this.ref.current) {
            this.ref.current.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            })
        }

    }
    render() {

        

        return (
        <div ref={this.ref}>
            {this.props.children}
        </div>
        )
    }

}

export default Focus
