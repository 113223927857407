import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, CircularProgress, 
			Typography, Card, CardContent, CardActions, Grid, Link, CardHeader,
			} from '@material-ui/core';
import { URL_API } from '../../redux/actions/DataActions'
import {setError} from '../../redux/actions/WindowAppActions'
import {getVehicle} from '../../redux/actions/ElementsActions'
import { connect } from 'react-redux';
import BaseService from '../../../services/BaseService'
import { isEqual, getQuarter } from 'date-fns';
import { Alert } from '@material-ui/lab';
import VehicleIcon from '../icons/VehicleIcon'
import {OpenInNew as OpenIcon} from '@material-ui/icons'

class TicpeInfos extends Component {
	static propTypes = {
		selection: PropTypes.object.isRequired,
		dateRef: PropTypes.instanceOf(Date).isRequired,
	}

	state = {
		loading: false,
		noData: false,
		infos: {
			day: null,
			km: 0,
		}
	}

	componentDidMount() {
		
		if (this.props.selection?.id > 0) {
			this.loadInformations()
		}

	}
	componentDidUpdate(prevProps, prevState) {

		if ( (!this.state.loading)
				&& (this.props.selection?.id > 0) 
			&& ( (!isEqual(prevProps.dateRef, this.props.dateRef))
						|| (prevProps.selection?.id !== this.props.selection?.id)
					) 
		) {
			this.loadInformations()

		}

		/*
		 * vérification cohérence
		 */
	}

	loadInformations = () => {

		const service = new BaseService();
		const year = this.props.dateRef.getUTCFullYear()
		const quarter = getQuarter(this.props.dateRef)
		const url = `${URL_API}/vehicle/${this.props.selection.id}/ticpe/${year}/${quarter}`

		this.setState({
			loading: true,
		})

		this.props.getVehicle(this.props.selection.id)

		service.get(url).then((data) => {
			
			switch (data.length) {

				case 0:
					this.setState({
						loading: false,
						noData: true,
						gap: false,
					})
					break;

				case 1:
					this.setState({
						loading: false,
						noData: false,
						infos: {
							day: new Date(data[0].jour),
							km: data[0].km
						},
						gap: (data[0].ecart >= 90),						/* valeur arbitraire pour afficher une erreur */
					})
					break

				default:
					console.warn('Trop de données', data)
					this.props.setError({
						message: 'Trop de données.'
					})
					break
			}

		}).catch(error => this.props.setError(error))

	}
	render() {
				
		return (
			<div className={this.props.classes.paper}>
				{this.state.loading ?
					<CircularProgress /> :
					<Card>
						<CardHeader 
							avatar={<VehicleIcon />}
							title="Véhicule" 
							/>
						<CardContent>
							<Grid container direction="row" spacing={2}>

								<Grid item container direction="column" sm={6}>
									<Grid item>
										<Typography>Immatriculation = {this.props.infos?.immatriculation}</Typography>
									</Grid>
									<Grid item>
										<Typography>VIN = {this.props.infos?.serie}</Typography>
									</Grid>
								</Grid>

								<Grid item container direction="column" sm={6}>
									{this.state.noData ?
										<Alert severity="info">Aucune information disponible</Alert> :									
										<>
											{this.state.gap &&
												<Grid item>
													<Alert severity="warning">Il manque très probablement des données !</Alert>
												</Grid>
											}
											<Grid item >
												<Typography >Compteur kilométrique = {this.state.infos.km}</Typography>
											</Grid>
											<Grid item >
												<Typography >Jour de référence = {this.state.infos.day?.toLocaleDateString()}</Typography>
											</Grid>
										</>
									}
								</Grid>

							</Grid>
						</CardContent>

						<CardActions className={this.props.classes.actions}>
							<Link
								href="https://www.douane.gouv.fr/service-en-ligne/demande-de-remboursement-de-la-ticpe-sidecar-web"
								target="_blank"
								rel="noreferrer"
								>
								Accéder au service de déclaration en ligne de la DGDDI &nbsp;<OpenIcon />
							</Link>
						</CardActions>
					</Card>
					
						
					
				}
			</div>
		)
	}
}

const mapStateToProps = state => ({
	infos: state.data.values
})
const mapDispatchToProps = ({
	setError,
	getVehicle,
})

const classes = {
	root: {

	},
	paper: {
		margin: 'auto',
	},
	gridToolbar: {
		alignItems: 'center'
	},
	actions: {
		justifyContent: 'center'
	},
	icon: {
		verticalAlign: 'text-bottom'
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(classes)(TicpeInfos))
