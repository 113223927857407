import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {getVehicle, updateUseToAPI,
        addVehicleContract} from '../../redux/actions/ElementsActions'
import {resetDataError} from '../../redux/actions/DataActions'
import ButtonOw from '../window/ButtonOw'
import CloseButton from '../window/CloseButton'
import WaitBlock from '../window/WaitBlock'
import ButtonAdd from '../window/ButtonAdd'
import {withStyles,
        Card, CardContent, CardHeader, CardActions, Button,
        TextField,
        Tooltip, Switch,
        TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
        Snackbar,
        } from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import EditIcon from '@material-ui/icons/Edit'
import VehicleIcon from '@material-ui/icons/LocalShipping'
import SubscriptionsIcon from '@material-ui/icons/Subscriptions'

import EditVehicleContractForm from './EditVehicleContractForm'
import Focus from '../window/Focus'
import {format, addMinutes} from 'date-fns'
import {ZONE_PUBLIQUE, DATE_INFINIE} from '../Constants'


class VehicleForm extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onRefresh: PropTypes.func,
    }
    state = {
        initialVehicle: null,
        vehicle: null,
        editMode: false,
        displayContracts: false,
        editContract: null,
        displayContract: false,
        refreshUses: 0,
        modeAddContract: false,
        firstDayForNewContract: this.props.limits.begin,
        alertNothingToDo: false,
        existingCode: false,
    }
    componentDidMount() {
        this.props.getVehicle(this.props.id)
        this.props.resetDataError()
    }
    componentDidUpdate(prevProps, prevState) {
        
        /*
         * chargement initial des infos
         */
        if (prevProps.id !== this.props.id) {
            this.props.getVehicle(this.props.id)
        }

        /*
         * chargement dans le formulaire des nouvelles données
         */
        if (prevProps.vehicle !== this.props.vehicle) {

            const vehicle = this.props.vehicle

            if (vehicle) {

                /*
                * recherche du premier jour après le dernier jour dans un contrat
                */
                this.setFirstDayForNewContract(this.props.vehicle.utilisations)
            }

            this.setState({
                vehicle: vehicle,
                displayContract: false,
                editContract: null,
            })

        }

        /*
         * lors du passage en mode édition, sauvegarde des données initiales
         */
        if (!prevState.editMode && this.state.editMode) {
            this.setState({
                initialVehicle: this.state.vehicle,
            })
        }

        

        /*
         * validation du formulaire effectuée
         */
        if (prevProps.loading && !this.props.loading     /* ie chargement terminé, */
        ) {
            if (this.state.editMode                      /* alors que modif en cours */
                && !this.props.existCode                    /* et sans erreur */
            ) {
                this.setState({
                    editMode: false
                })
            }

            /*
             * rafraîchissement du parent
             */
            if (this.props.onRefresh) {
                this.props.onRefresh()
            }
        }
    }

    /**
     * recherche du premier jour sans contrat, 
     * après le dernier jour contenu dans un contrat
     */
    setFirstDayForNewContract = (contrats) => {
       
        let firstDayAvailable = this.props.limits.begin

        contrats.forEach(contrat => {
            if (contrat.fin === null) {
                firstDayAvailable = DATE_INFINIE
            } else if (contrat.fin > firstDayAvailable) {
                firstDayAvailable = addMinutes(contrat.fin, 1)
            }
        })

        this.setState({
            firstDayForNewContract: firstDayAvailable
        })
        
    }
    
    handleAlertNothingTodo = () => {
        this.setState({
            alertNothingToDo: false,
        })
    }
    handleContracts = () => {
        this.setState({
            displayContracts: !this.state.displayContracts,
            displayContract: false,
        })
    }
    switchEditMode = () => {
        this.setState({
            editMode: !this.state.editMode,
        })
    }

    checkCode = (code) => {
        
        const index = this.props.vehicles.findIndex(veh => (veh.veh_code === code && veh.id !== this.props.id))

        this.setState({
            existingCode: index !== -1
        })

    }
    onChange = (evt) => {
        
        const field = {}
        const fieldName= evt.target.name
        field[fieldName] = evt.target.value
        const vehicle = Object.assign({}, this.state.vehicle, field)
        this.setState({
            vehicle: vehicle,
        })

        /*
         * dès que changement de code, il n'y a plus d'erreur affichée
         */
        if (fieldName === 'code') {
            this.props.resetDataError()

            /*
             * vérification que le code n'existe pas déjà
             */
            this.checkCode(evt.target.value)
        }

    }

    onChangeTypeTach = (evt) => {
        const vehicle = Object.assign({}, this.state.vehicle, {
            numerique: evt.target.checked
        })
        this.setState({
            vehicle: vehicle
        })
    } 

    displayAddContract = (value) => {
        
        /*
         * cas 1 : validation de la création
         */
        if (value?.debut) {
            this.setState({
                displayContract: false,
                modeAddContract: false,
            })
            this.props.addVehicleContract(this.props.id, value)

        /*
         * cas 2 : annulation
         */
        } else if (typeof(value) === 'undefined') {
            this.setState({
                displayContract: false,
                modeAddContract: false,
            })
        
        /*
         * cas 3 : ouverture du formulaire de création
         */
        } else {
            this.setState({
                displayContract: true,
                modeAddContract: true,
            })
        }
        
    }

    displayContract = (contract) => {
        
        this.setState({
            displayContract : (contract?.id_utilisation_vehicule > 0),
            editContract : contract,
            modeAddContract: false,
        })

        /*
         * sauvegarde des données suite à demande de fermeture
         */
        if ( contract && this.state.displayContract === true) {

            /*
             * Mise à jour API
             */
            this.props.updateUseToAPI(this.state.vehicle.id, contract)

            /*
             * mise à jour locale
             */
            let vehicle = this.state.vehicle
            let id = vehicle.utilisations.findIndex(c => c.id_utilisation_vehicule === contract.id_utilisation_vehicule)
            vehicle.utilisations[id]=contract
            this.setState({
                displayContract: false,
                vehicle: vehicle,
                refreshUses: this.state.refreshUses + 1
            })

             /*
             * mise à jour locale des contraintes de gestion
             */

            /*
             * recherche du premier jour après le dernier jour dans un contrat
             */
            this.setFirstDayForNewContract(vehicle.utilisations)

        }

    }
    onCancel = () => {
        this.setState({
            vehicle: this.state.initialVehicle,
            editMode: false,
        })
    }
    resetEdit = () => {
        this.setState({
            vehicle: this.state.initialVehicle,
        })
        this.setState({
            existingCode: false,
        })
    }

    validate = () => {
        const changes = {}
        
        /*
         * si le code est en erreur, 
         * aucune validation possible
         */
        if (this.state.existingCode) {
            return
        }

        if (this.state.initialVehicle.code !== this.state.vehicle.code) {
            changes.code = this.state.vehicle.code
        }
        if (this.state.initialVehicle.immatriculation !== this.state.vehicle.immatriculation) {
            changes.immatriculation = this.state.vehicle.immatriculation
        }
        if (this.state.initialVehicle.numerique !== this.state.vehicle.numerique) {
            changes.numerique = this.state.vehicle.numerique
        }

        if (Object.entries(changes).length === 0) {
            this.setState({
                editMode: false,
                alertNothingToDo: true,
            })
        } else {
            this.props.onChange(changes)
        }

    }

    render() {
        
        const sTitle = (this.props.actualUser.supportAthmo  && this.state.vehicle) ? `Véhicule id ${this.state.vehicle.id}` : ''
        const title = <span><VehicleIcon />Véhicule</span>
        const titleCard = 
                <Tooltip title={sTitle}>
                    {title}
                </Tooltip>
        const cardActions = 
            <>
                {!this.state.editMode && 
                    <>
                    <ButtonOw 
                            title='Édition' 
                            onClick={this.switchEditMode}>
                        <EditIcon />
                    </ButtonOw>
                    <CloseButton onClick={this.props.onClose} />
                    </>
                }
                
            </>
        const contractsActions = 
            <>
                {this.state.firstDayForNewContract !== DATE_INFINIE &&
                    <ButtonAdd 
                        title="Ajouter un contrat"
                        disabled={this.state.modeAddContract}
                        onClick={this.displayAddContract}
                        />
                }
                <CloseButton onClick={this.handleContracts} />   
            </>
        return (
            <>
            <Card className={this.props.classes.root} >
                <CardHeader title={titleCard} 
                        action={cardActions} />
                { (this.props.loading || !this.state.vehicle) ? 
                    <CardContent>
                        <WaitBlock /> 
                    </CardContent> :
                    <>
                        <CardContent>
                            <div className={this.props.classes.input} >
                                <span className={this.props.classes.label}>Immatriculation</span> 
                                { (this.state.editMode && this.state.vehicle.changes.immatriculation) ? 
                                    <TextField name='immatriculation' value={this.state.vehicle.immatriculation} onChange={this.onChange}/> :
                                    this.state.vehicle.immatriculation
                                }
                            </div>
                            <div className={this.props.classes.input}>
                                <span className={this.props.classes.label}>Numéro de série</span>
                                {this.state.vehicle.serie}
                            </div>
                            {this.props.existCode && 
                                <Alert severity='error' >Le code existe déjà !</Alert>
                            }
                            <div className={this.props.classes.input}>
                                <span className={this.props.classes.label}>Code</span>
                                {this.state.editMode ? 
                                    <TextField name='code' 
                                            value={this.state.vehicle.code}
                                            onChange={this.onChange}
                                            inputProps={{
                                                maxLength: 10
                                            }}
                                            error={this.props.existCode || this.state.existingCode}
                                            helperText={this.state.existingCode ? 'Le code existe déjà.' : ''} /> :
                                    this.state.vehicle.code
                                }
                            </div>
                            
                            <div className={this.props.classes.input}>
                                <span className={this.props.classes.label}>Type tachygraphe</span>
                                { (this.state.editMode && this.state.vehicle.changes.numerique) ? 
                                    <span>Disques&nbsp;
                                        <Switch checked={this.state.vehicle.numerique}
                                                name='numerique'
                                                onChange={this.onChangeTypeTach} /> 
                                        Numérique</span> :
                                    this.state.vehicle.numerique ? 'Numérique' : "Disques papier"
                                }
                                
                            </div>
                            
                        </CardContent>

                    {/* @todo implémenter gestion sites vehicle.sites */}
                    
                        <CardActions className={this.props.classes.cardActions}>
                            {this.state.editMode ?
                                <>
                                    <Button onClick={this.onCancel}>Annuler</Button>
                                    <Button onClick={this.resetEdit}>Réinitialiser</Button>
                                    <Button onClick={this.validate}
                                            disabled={this.state.existingCode}>
                                        Valider
                                    </Button>
                                </> :
                                <>
                                    <Button onClick={this.handleContracts}
                                            variant='outlined'
                                            startIcon={<SubscriptionsIcon />} >
                                        Contrats&nbsp;<em>Optimal Web</em>
                                    </Button>
                                </>
                            }
                        </CardActions>
                    </>
                }
                
            </Card>
                
            {this.state.displayContracts &&
            <Focus>
                <Card className={this.props.classes.contracts}>
                    <CardHeader
                        title="Contrats Optimal Web" 
                        action={contractsActions}
                        />
                    <CardContent>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        {this.props.actualUser?.supportAthmo &&
                                            <TableCell>Entreprise</TableCell>
                                        }
                                        <TableCell>Début</TableCell>
                                        <TableCell>Fin</TableCell>
                                        <TableCell>Zone</TableCell>
                                    </TableRow>
                                </TableHead>
                                {this.state.refreshUses >= 0 &&
                                    <TableBody>
                                        {this.state.vehicle.utilisations.map(contrat => 
                                        <TableRow 
                                                key={contrat.id_utilisation_vehicule}
                                                onClick={() => {
                                                    if (!this.state.displayContract) {
                                                        this.displayContract(contrat)
                                                    }
                                                }}
                                                className={!this.state.displayContract ? this.props.classes.clickable : null}
                                                >
                                            {this.props.actualUser?.supportAthmo &&
                                                <TableCell>{contrat.societe}</TableCell>
                                            }
                                            <TableCell>{format(contrat.debut, 'dd/MM/yyyy HH:mm')}</TableCell>
                                            <TableCell>{contrat.fin ? format(contrat.fin, 'dd/MM/yyyy HH:mm') : <>&infin;</>}</TableCell>
                                            <TableCell>{contrat.type_zone === ZONE_PUBLIQUE ? 'publique' : 'privée'}</TableCell>
                                        </TableRow>)}
                                    </TableBody>
                                }
                            </Table>
                        </TableContainer>
                    </CardContent>
                    
                </Card>
            </Focus>
            }

            {this.state.displayContract && 
                <EditVehicleContractForm 
                    contract={this.state.editContract} 
                    modeAdd={this.state.modeAddContract}
                    firstDayForNewContract={this.state.firstDayForNewContract}
                    onClose={this.state.modeAddContract ? this.displayAddContract : this.displayContract}
                    />
            }

            <Snackbar open={this.state.alertNothingToDo}
                    autoHideDuration={6000}
                    onClose={this.handleAlertNothingTodo}>
                <Alert  onClose={this.handleAlertNothingTodo}
                        severity="info">
                    Aucune modification effectuée !
                </Alert>
            </Snackbar>
            </>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.data.loading,
    vehicle: state.data.values,
    actualUser: state.identity.user,
    existCode: state.data.error,
    limits: state.client.informations.ow.limits,
    vehicles: state.client.vehicles,
})
const mapDispatchToProps = ({
    getVehicle,
    updateUseToAPI,
    resetDataError,
    addVehicleContract,
})

const classes = {
    root: {
        minWidth: '30em',
    },
    input: {
        lineHeight: '2rem',
    },
    label: {
        display: 'inline-block',
        width: '12em',
    },
    label2: {
        display: 'inline-block',
        marginLeft: '1em',
        width: '11em',
    },
    blockContrat: {
        '&& span': {
            width: '4em',
        }
    },
    clickable: {
        cursor: 'pointer',
    },
    contracts: {
        marginTop: '0.5em',
    },
    headerContracts: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&& div': {
            display: 'inline-flex',
            alignItems: 'center',
        }
    },
    cardActions: {
        justifyContent: 'space-around',
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (VehicleForm))
