import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withStyles, Container, Toolbar,
        Grid, Typography,
        } from '@material-ui/core'
import {DataGrid, ToolbarOptions,} from 'tubular-react'
import WaitBlock from '../../core/components/window/WaitBlock'
import HelpCard from '../../core/components/window/HelpCard'
import HelpButton from '../../core/components/window/HelpButton'
import DriverForm from '../../core/components/resources/DriverForm'
import AddDriverForm from '../../core/components/resources/AddDriverForm'
import ButtonAdd from '../../core/components/window/ButtonAdd'
import {U_ADMINISTRATEUR} from '../../core/components/Constants'
import {isUserAllow} from '../../core/components/tools/User'
import Focus from '../../core/components/window/Focus'

import {setTitle, setError} from '../../core/redux/actions/WindowAppActions'
import {updateCodeDriver, updateDriver} from '../../core/redux/actions/ElementsActions'
import { createColumn, ColumnDataType, ColumnSortDirection } from 'tubular-common';


class Drivers extends Component {

    state = {
        displayEditDriver: false,
        displayAddDriver: false,
        editDriverId: 0,
        refreshDataGrid: 0,
    }

    componentDidMount() {
        this.props.setTitle('Conducteurs')
    }

    componentDidUpdate(prevProps, prevState) {

        /*
         * actualisation grille 
         * si l'édition ou la création d'un conducteur est terminée
         */
        if ( (prevState.displayEditVehicle && !this.state.displayEditVehicle) 
              || (prevState.displayAddVehicle && !this.state.displayAddVehicle)  
        ) {           
            this.refreshDataGrid()
        }

    }

    refreshDataGrid = () => {
        this.setState({
            refreshDataGrid: this.state.refreshDataGrid + 1
        })
    }
    getColumns = () => {
        return [
            createColumn('id', {
                isKey: true,
                dataType: ColumnDataType.Numeric,
                visible: false,
            }),
            createColumn('name', {
                label: 'Nom',
                sortOrder: 1,
                sortable: true,
                sortDirection: ColumnSortDirection.Ascending,
                searchable: true,
            }),
            createColumn('forename', {
                label: 'Prénom',
                sortOrder: 2,
                sortable: true,
                sortDirection: ColumnSortDirection.Ascending,
                searchable: true,
            }),
            createColumn('drv_code', {
                label: 'Code',
                sortable: true,
                sortOrder: 3,
                visible: true,
                searchable: true,
            }),
            createColumn('card_number', {
                label: 'Carte',
                sortable: true,
                sortOrder: 3,

                searchable: true,
            }),
            createColumn('actif', {
                label: 'Contrat',
                sortable: true,
                sortOrder: 4,
                dataType: ColumnDataType.Boolean,
            })
        ]
    }

    onRowClick = (driver) => {
        this.setState({
            displayEditDriver: true,
            displayAddDriver: false,
            editDriverId: driver.id
        })
    }

    onCloseEditDriver = () => {
        this.setState({
            displayEditDriver: !this.state.displayEditDriver,
        })
    }
    onChangeEditDriver = (changes) => {

        if (changes.code) {
            this.props.updateCodeDriver(this.state.editDriverId, changes.code)
        }
                
        if (changes.nom || changes.prenom || changes.permis || changes.dateNaissance
        ) {
            this.props.updateDriver(this.state.editDriverId, changes)
        }
    

        /*
         * actualisation grille (refresh)
         */
       this.refreshDataGrid()

    }

    handleAddDriver = () => {
        this.setState({
            displayAddDriver: !this.state.displayAddDriver,
            displayEditDriver: false,
        })
    }
    render() {
        
        const btAddDriver = isUserAllow(this.props.actualUser.roles, U_ADMINISTRATEUR) &&
                <ButtonAdd key='btAdd' title='Ajouter un conducteur' onClick={this.handleAddDriver} />

        const toolbarOptions = new ToolbarOptions({
            enablePagination: false,
            itemsPerPage: 10000,
            title: 'Conducteurs gérés',
            customItems:[btAddDriver]
        });
        
        return (
            <Container className={this.props.classes.root}>
                <Toolbar className={this.props.classes.toolbar}>
                    <HelpButton>
                        <HelpCard title="Aide de l'écran" >
                            <div>
                                <div className={this.props.classes.memo}>
                                    Cet écran permet de visualiser et gérer les conducteurs.
                                </div>
                                <div>
                                    <Typography variant='body2' paragraph align='justify'>
                                        Les données issues d'un document officiel ne sont pas modifiables.
                                    </Typography>
                                    <Typography variant='body2' paragraph align='justify'>
                                        Le code personnalisé comporte 10 caractères au maximum.
                                    </Typography>
                                </div> 
                            </div>
                        </HelpCard>
                    </HelpButton>
                </Toolbar>
                {!this.props.drivers ? 
                    <WaitBlock text='Chargement en cours' /> :
                    <Grid container spacing={3}>
                        <Grid item lg className={this.props.classes.grid}>
                            <DataGrid 
                                columns={this.getColumns()} 
                                dataSource={this.props.drivers}  
                                gridName="Conducteurs" 
                                onError={(err) => {
                                    console.warn('Datagrid error', err)
                                    this.props.setError ('Datagrid error')
                                }}
                                deps={[this.state.refreshDataGrid]}
                                onRowClick={this.onRowClick}
                                toolbarOptions={toolbarOptions}
                                langKey="fr" 
                                 />
                                                
                        </Grid>
                        
                        {this.state.displayEditDriver && 
                            <Focus>
                                <Grid item md>
                                    <DriverForm id={this.state.editDriverId}
                                                onClose={this.onCloseEditDriver} 
                                                onChange={this.onChangeEditDriver}
                                                onRefresh={this.refreshDataGrid} />
                                </Grid>
                            </Focus>
                        }

                        <Grid item xs>
                        {this.state.displayAddDriver &&
                            <Focus>
                                <AddDriverForm onClose={this.handleAddDriver} /> 
                            </Focus>
                        }
                        </Grid>
                    </Grid>
                    
                }
                
            </Container>
        )
    }

}

const mapStateToProps = state => ({
    loading: state.data.loading,
    drivers: state.client.drivers,
    actualUser: state.identity.user,
})
const mapDispatchToProps = ({
    setTitle,
    setError,
    updateCodeDriver,
    updateDriver,
})

const classes = theme => ({
    root: {
        scrollBehavior: 'smooth',
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyItems: 'space-between',

    },
    grid: {
        '&& .MuiTableRow-hover': {
            cursor: 'pointer',
        }
    },
    memo: {
        textAlign: 'justify',
        marginBottom: '1.3em',
    }
})

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (Drivers))
