import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withStyles,
        TableHead, TableRow, TableCell, } from '@material-ui/core'
class Header extends Component {

  static propTypes = {
    title: PropTypes.string,
    titleDisplayed: PropTypes.bool,
  }
  render() {
    
    return (
        <TableHead className={this.props.classes.root}>
            {this.props.titleDisplayed &&
              <TableRow>
                <TableCell colSpan={5}>{this.props.title}</TableCell>
              </TableRow>
            }
            <TableRow>
                <TableCell className={this.props.classes.cell}>Code</TableCell>
                <TableCell className={this.props.classes.cell}>Nom</TableCell>
                <TableCell className={this.props.classes.cell}>Prénom</TableCell>
                <TableCell className={this.props.classes.cell}>Carte</TableCell>
                <TableCell className={this.props.classes.cell}>Nombre infractions</TableCell>
            </TableRow>
        </TableHead>
    )
  }
}

const classes = theme => ({
  root: {

  },
  cell: {
    fontWeight: 'bolder',
  }
})

export default withStyles(classes) (Header)
