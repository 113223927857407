import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Tooltip, } from '@material-ui/core'
import DriverCardIcon from './DriverCardIcon'
import DriverHolidayIcon from './DriverHolidayIcon'
import DriverOooIcon from './DriverOooIcon'
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff'
import WarningIcon from '@material-ui/icons/Warning'
import OutIcon from './OutIcon'
import OverIcon from './OverIcon'
import { DRIVER_TYPE } from '../Constants';

class DriverInfoIcon extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }

  render() {

    let {type, onClick, targetday, targetid, ...props} = this.props
    const target = {
      day: targetday,
      id: targetid,
      reason: type,
      elementType: DRIVER_TYPE,
    }

    switch(type) {

      case 'ooo':
        return (
          <Tooltip title="Arrêt maladie">
            <DriverOooIcon targetinfos={target} {...props} onClick={onClick} />
          </Tooltip>
        )

      case 'holiday':
        return (
          <Tooltip title="En vacances" >
            <DriverHolidayIcon targetinfos={target} {...props} onClick={onClick} />
          </Tooltip>
        )

      case 'limit':
        return (
          <Tooltip title="Date limite légale">
            <DriverCardIcon color="red" {...props} />
          </Tooltip>
        )

      case 'proposed':
        return (
          <Tooltip title="Date limite proposée">
            <DriverCardIcon color='orange' {...props} />
          </Tooltip>
        )

      case 'selected':
        return (
          <Tooltip title="Date limite choisie">
            <DriverCardIcon {...props} />
          </Tooltip>
        )
  
      case 'loan':
        return (
          <Tooltip title="Hors entreprise">
            <FlightTakeoffIcon {...props} targetinfos={target} onClick={onClick} />
          </Tooltip>
        )

      case 'outBefore':
        return (
          <OutIcon title="Avant contrat" {...props} />  
        )

      case 'outAfter':
        return (
          <OutIcon title="Après contrat" {...props} />
        )
        
      /*
       * Dépassement de la date proposée pour le déchargement
       */
      case 'warningAfter':
        return (
          <OverIcon 
              title="Dépassement de la date proposée"
              color="orange" {...props} />
        )

      /*
       * dépassement de la date limite légale
       */
      case 'alertAfter':
        return (
          <OverIcon
              title="Dépassement de la date limite légale"
              color="red" {...props} />
        )

      case 'loss':
        return (
            <WarningIcon 
                title="Information manquante" 
                htmlColor="red" {...props} />
        )

      default:
          console.warn('Driver info type unreferenced', type)
          return <span>?</span>
          
    }
  }
}

export default DriverInfoIcon
