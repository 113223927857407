import React, { Component } from 'react'
import {connect} from 'react-redux'
import {setTitle, setError, } from '../../core/redux/actions/WindowAppActions'
import {resetData} from '../../core/redux/actions/DataActions'
import {withStyles, 
        Toolbar, FormControl, FormControlLabel, 
        RadioGroup, Radio, 
        Typography,
        Container, 
        } from '@material-ui/core'
import {Alert} from '@material-ui/lab'

import Selector, {TYPE_DATE} from '../../core/components/optigestweb/Selector'
import MonthlyDataGrid from '../../core/components/optigestweb/MonthlyDataGrid'
import DailyDataGrid from '../../core/components/optigestweb/DailyDataGrid'

import {TYPES_RAPPORT, DISPLAY_TYPE} from '../../core/components/Constants'
import HelpButton from '../../core/components/window/HelpButton'
import HelpCard from '../../core/components/window/HelpCard'
import RefreshButton from '../../core/components/window/RefreshButton'
import WaitBlock from '../../core/components/window/WaitBlock'
import PaperDiscSource from '../../core/components/window/sources/PaperDiscSource'
import CardSource from '../../core/components/window/sources/CardSource'
import VehicleSource from '../../core/components/window/sources/VehicleSource'
import EmbeddedSource from '../../core/components/window/sources/EmbeddedSource'
import ManualSource from '../../core/components/window/sources/ManualSource'

import {getDailyReport, getMonthlyReport} from '../../core/redux/actions/ElementsActions'
import {format as dateFormat, isEqual} from 'date-fns'
import localeFr from 'date-fns/locale/fr'
import WarningIcon from '../../core/components/icons/WarningIcon';
import ActivityLabel from '../../core/components/optigestweb/graphics/ActivityLabel';
import SupportService from '../../core/components/window/SupportService';

/**
 * Rapport d'activités contenues dans les cartes des conducteurs
 */
class Cartes extends Component {

    state = {
        typeReport: TYPES_RAPPORT.RAPPORT_JOURNEE_TRAVAIL,      /* QUOTIDIEN ou JOURNEE_TRAVAIL */
        selection: null,
        inconsistentData: false,
        dateRef: null,
        displayType: DISPLAY_TYPE.TAB,                          /* mode d'affichage : tableau ou histogramme */
        monthHistogram: false,                                  /* affichage de l'histogramme : mois complet ? */
    }
    componentDidMount(){
        this.props.setTitle('Activités contenues dans les cartes')
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.selection &&
                this.state.selection.id > 0 &&
                (prevState.selection !== this.state.selection ||
                    prevState.typeReport !== this.state.typeReport)
                ) {
                    
            this.loadData()
            
            /*
             * en fonction des conditions,
             * conservation de certains éléments d'affichage 
             */
            if (prevState.selection 
                && prevState.typeReport === this.state.typeReport                   /* même type de rapport */
                && prevState.selection.id === this.state.selection.id               /* même conducteur */
                && !isEqual(prevState.selection.date, this.state.selection.date)) { /* uniquement changement de date */

                /*
                 * réinitialisation partielle de l'affichage
                 */
                this.setState({
                    monthHistogram: false,
                })

            } else {

                /*
                * réinitialisation de l'affichage
                */
                this.setState({
                    displayType: DISPLAY_TYPE.TAB,
                    monthHistogram: false,
                })

            }
        }

        if (this.props.values && prevProps.values !== this.props.values) {
            
            const inconsistentData = this.props.values.findIndex(elt => {
                return (elt.r_val < 0 || elt.t_val < 0 || elt.nuit_val < 0 || elt.d_val < 0 || elt.c_val < 0 || elt.amplitude_val < 0)
            })
            this.setState({
                inconsistentData: (inconsistentData > -1)
            })
            
        }
    }

    handleDisplayType = () => {
        const display = (this.state.displayType === DISPLAY_TYPE.HISTO) ? DISPLAY_TYPE.TAB : DISPLAY_TYPE.HISTO;
        this.setState({
            displayType: display,
        });
    }

    handleMonthHistogram = () => {
        this.setState({
            monthHistogram: !this.state.monthHistogram,
        });
    }
    handleChangeType = (evt) => {
        this.setState ({
            typeReport: evt.target.value,
        })
        this.props.resetData()
    }

    handleChange = (selection) => {
        this.setState({
            selection: selection,
        })
    }

    onRowClick = (newDate) => {
        this.setState({
            dateRef: new Date(newDate),
            typeReport: TYPES_RAPPORT.RAPPORT_QUOTIDIEN,
        })
    }
    loadData = (refresh) => {
        if (this.state.typeReport === TYPES_RAPPORT.RAPPORT_QUOTIDIEN) {
            this.props.getDailyReport(this.state.selection.type, this.state.selection.id, this.state.selection.dateRef, refresh)
        } else {
            this.props.getMonthlyReport(this.state.selection.type, this.state.selection.id, this.state.selection.dateRef, refresh)
        }
    }
    render() {

        let data = []
        const options = {
            gridName: '',
            buttons: [],
            informations: []
        }
        let dateName = ''
        
        if (this.state.selection) {
            if (this.state.typeReport === TYPES_RAPPORT.RAPPORT_QUOTIDIEN) {
                dateName = dateFormat(this.state.selection.dateRef, 'eeee d MMMM yyyy', {locale: localeFr})
            } else {
                dateName = dateFormat(this.state.selection.dateRef, 'MMMM yyyy', {locale: localeFr})
            }
        }

        if (this.props.isCached) {
            options.buttons.push(<RefreshButton 
                                    title="Actualiser les informations, en procédant à un nouveau calcul"
                                    onClick={() => this.loadData(true)} />
            )
        } 

        if (this.state.inconsistentData) {
            options.informations.push(
                 <Alert severity="warning">Présence de données incohérentes</Alert>
            )
        }

        if (this.state.selection && this.props.values && this.props.values.length > 0) {
            
            data = this.props.values.slice()    /* clone des valeurs afin de laisser le tableau initial en lecture seule */          

        } 

        const sTypeRapport = (this.state.typeReport === TYPES_RAPPORT.RAPPORT_QUOTIDIEN) ? `Activités du ${dateName}` : `Activités de ${dateName}`
        if (this.state.selection) {
            const element = this.props.drivers.find(element => element.id === this.state.selection.id)
            options.gridName = (element) ? `${sTypeRapport} \u2014 ${element.name} ${element.forename}` : sTypeRapport
        } else {
            options.gridName = sTypeRapport
        }

        return (
        <Container className={this.props.classes.root} maxWidth={false}>
            <Toolbar className={this.props.classes.toolbar}>
                <FormControl className={this.props.classes.selectorType}>
                    <RadioGroup name="type" value={this.state.typeReport} onChange={this.handleChangeType} row >
                        <FormControlLabel value={TYPES_RAPPORT.RAPPORT_QUOTIDIEN} control={<Radio color="primary" />} label="Quotidien" />
                        <FormControlLabel value={TYPES_RAPPORT.RAPPORT_JOURNEE_TRAVAIL} control={<Radio color="primary"/>} label="Mensuel (par journée de travail)" />
                        {/* <FormControlLabel value={TYPES_RAPPORT.MENSUEL} control={<Radio />} label="Mensuel (par jour)" /> */}
                    </RadioGroup>
                    <HelpButton>
                        <HelpCard title="Aide de l'écran" >
                            <div className={this.props.classes.helpLine}>
                                <div>
                                    Cet écran permet d'afficher et d'imprimer les rapports d'activités contenues dans les cartes de vos conducteurs.
                                </div>
                            
                                <h4>
                                    <RefreshButton onClick={() => {}} title="Procéder à un nouveau calcul. Cf explications ci-dessous"/>
                                    Bouton d'actualisation</h4>
                                <div>Les informations contenues dans les tableaux ne sont pas systématiquement calculées. </div>
                                <div>Ce bouton force le système à procéder à un nouveau calcul.</div>
                                <div>Attention, cela peut être très, très long...</div>

                                <div>
                                    <h4>Sources de données possibles</h4>
                                    <ul>
                                        <li><PaperDiscSource />&nbsp;disque papier numérisé</li>
                                        <li><CardSource />&nbsp;carte de conducteur</li>
                                        <li><EmbeddedSource />&nbsp;informatique embarquée</li>
                                        <li><VehicleSource />&nbsp;chronotachygraphe numérique</li>
                                        <li><ManualSource />&nbsp;entrée manuelle</li>
                                    </ul>
                                    <h4>Autre icône</h4>
                                    <ul>
                                        <li><WarningIcon />&nbsp;Présence de données incohérentes</li>
                                    </ul>
                                </div>

                                <div>
                                    <h4>Types d'activités</h4>
                                    <ul>
                                        <li><ActivityLabel type="C" /></li>
                                        <li><ActivityLabel type="T" /></li>
                                        <li><ActivityLabel type="D" /></li>
                                        <li><ActivityLabel type="Q" /></li>
                                        <li><ActivityLabel type="R" /></li>
                                    </ul>
                                </div>

                                <div>N'hésitez pas à contacter notre <SupportService/> pour toute question.</div>
                            </div>
                        </HelpCard>
                    </HelpButton>            
                </FormControl>
                <Selector 
                    dateRef={this.state.dateRef}
                    onChange={this.handleChange}    
                    type={this.state.typeReport === TYPES_RAPPORT.RAPPORT_QUOTIDIEN ? TYPE_DATE.DAY : TYPE_DATE.MONTH} />
            </Toolbar>
            <Container className={this.props.classes.container}>
                { this.props.loading ?
                    <WaitBlock text={this.state.typeReport === TYPES_RAPPORT.RAPPORT_JOURNEE_TRAVAIL ? 
                                        'Les calculs, côté serveurs, peuvent être très longs...' :
                                        ''} /> :
                    (!this.state.selection ||
                            this.state.selection.id === 0 ||
                            this.props.values === null) ? 
                        <Typography className={this.props.classes.selectAsked}>Veuillez effectuer votre choix.</Typography> :
                    
                        this.state.typeReport === TYPES_RAPPORT.RAPPORT_QUOTIDIEN ?
                            <DailyDataGrid 
                                    data={data}
                                    options={options}
                                    selection={this.state.selection} 
                                    displayType={this.state.displayType}
                                    handleDisplayType={this.handleDisplayType}
                                    refresh={() => this.loadData(true)} 
                                    monthHistogram={this.state.monthHistogram}
                                    handleMonthHistogram={this.handleMonthHistogram}
                                    /> :
                            <MonthlyDataGrid 
                                    data={data}
                                    options={options}
                                    dateRef={this.state.selection.dateRef}
                                    onRowClick={this.onRowClick} />
                }
            </Container>
            
        </Container>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.data.loading,
    isCached: state.data.isCached,
    values: state.data.values,
    drivers: state.client.drivers,
    vehicles: state.client.vehicles,
  })

const mapDispatchToProps = ({
    setTitle,
    getDailyReport,
    getMonthlyReport,
    resetData,
    setError,
})

const classes = theme => ({
    root: {
        color: theme.palette.primary.main,
        width: '100%',
    },
    toolbar: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    selectorType: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    selectAsked: {
        display: 'flex',
        minHeight: '8em',
        justifyContent: 'center',
        alignItems: 'center',
    },
    helpLine: {
        textAlign: 'justify',
    },
    waiting: {
        textAlign: 'center',
        marginTop: '10vh',
    },
    container: {
        marginTop: '1em',
    }
    
})
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (Cartes))
