import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Header from './layout/Header';
import DynamicApp from './layout/DynamicApp'
import Navigation from './core/components/window/Navigation'
import { Provider } from 'react-redux';
import { store } from './core/redux/store';
import ErrorBoundary from './core/components/window/ErrorBoundary'

import {ThemeProvider, createMuiTheme, responsiveFontSizes} from '@material-ui/core'
import {frFR} from '@material-ui/core/locale'
import {frFR as localeFrDataGrid} from '@material-ui/data-grid'

import Initialization from './core/components/Init'
import {LightTheme} from './layout/AthmoTheme'

class App extends Component {

  
  render () {
    
    const init = new Initialization ()
    init.initialize()
    const devEnv = process.env.NODE_ENV === 'development'
    devEnv && console.info ('environnement de développement')
    let theme = createMuiTheme(LightTheme, Object.assign(frFR, localeFrDataGrid));
    theme = responsiveFontSizes(theme);

    const app =  (
        <ThemeProvider theme={theme}>
          <Provider store={store} >
            <BrowserRouter>
              <Header />
              <DynamicApp />
              <Navigation />   
            </BrowserRouter>
          </Provider>
        </ThemeProvider>
    )

    if (devEnv) {
      return app 
    } else {
      return <ErrorBoundary>{app}</ErrorBoundary>
    }
    
  }

}

export default App;
