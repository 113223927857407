import React, { Component } from 'react'
import {connect} from 'react-redux'
import {setTitle} from '../../core/redux/actions/WindowAppActions'
import {getYearUploads} from '../../core/redux/actions/ElementsActions'
import {Container, FormControl, Toolbar,
        withStyles,
        } from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import ElementSelection from '../../core/components/forms/select/ElementSelection'
import YearPicker from '../../core/components/forms/date/YearPicker'
import WaitBlock from '../../core/components/window/WaitBlock'
import {DRIVER_TYPE, VEHICLE_TYPE, EVENT_TYPE } from '../../core/components/Constants'
import HelpButton from '../../core/components/window/HelpButton'
import HelpCard from '../../core/components/archivage/calendriers/CalendarsHelpCard'
import YearCalendar from '../../core/components/archivage/calendriers/YearCalendar'
import addDays from 'date-fns/addDays'
import EventButton from '../../core/components/window/EventButton';
import EventDialog from '../../core/components/archivage/EventDialog'
import { reasonsDriver, reasonsVehicle } from '../../core/components/archivage/EventDialog'
class CalendrierAnnuel extends Component {

  state = {
    search : {
      id: 0,
      type: DRIVER_TYPE,
    },
    year: new Date(),
    eventDialog: false,
    targetDay: null,
  }
  componentDidMount () {
    this.props.setTitle ('Calendrier annuel')
  }

  componentDidUpdate (prevProps, prevState) {

    if ( (this.state.search.id > 0)
        && ( (prevState.search.id !== this.state.search.id)
          || (prevState.year !== this.state.year))
        ) {
      this.refresh()
    }
  }

  refresh = () => {
    if (this.state.search.id > 0) {
      this.props.getYearUploads(this.state.search.type, this.state.search.id, this.state.year)
    }
  }
  handleEventDialog = (refresh) => {
    this.setState({
      eventDialog: !this.state.eventDialog,
    })

    /*
     * actualisation du calendrier (si demandée) lors de la fermeture de la fenêtre
     */
    if (typeof(refresh) === 'boolean' && refresh) {
      this.refresh()
    }

  }
   onChangeSearch = (elt) => {
 
    this.setState({
      search: elt
    })
  }

  onYearChange = (date) => {
    this.setState({
      year: date
    })
  }

  onCalendarClick = (targetInfos) => {
    
    let reason = '';

    /*
     * récupération d'un motif valide
     */
    if (targetInfos.state) {
      let validReason;
      if (this.state.search.type === DRIVER_TYPE) {
        validReason = reasonsDriver.filter(aReason => targetInfos.state.includes(aReason.value));
      } else {
        validReason = reasonsVehicle.filter(aReason => targetInfos.state.includes(aReason.value));
      }
      if (validReason.length > 0) {
        reason = validReason[0].value;
      }
    }
 
    let targetElement = {
      reason: reason,
      type: this.state.search.type,
      id: this.state.search.id,
    }

    this.setState({
      targetDay: new Date(targetInfos.day),
      targetElement: targetElement,
      eventDialog: true,
    })
    
  }
  render() {

    const today = new Date()
    const maxEndDate = this.state.search.type === DRIVER_TYPE ? addDays(today, 28) : addDays(today, 90)

    let lbInfos = 'Veuillez effectuer un choix'
    if (this.state.search.type === DRIVER_TYPE
          && this.props.nbDrivers === 0) {
        lbInfos = <Alert severity='warning'>Aucun conducteur à visualiser</Alert>
    } else if (this.state.search.type === VEHICLE_TYPE
          && this.props.nbVehicles === 0) {
        lbInfos = <Alert severity='warning'>Aucun véhicule à visualiser</Alert>
    }

    return (
      <Container className={this.props.classes.root} maxWidth={false}>
        <Toolbar className={this.props.classes.toolbar}>
          <FormControl className={this.props.classes.selector}>
            <ElementSelection onChange={this.onChangeSearch} />
            <YearPicker 
                value={this.state.year}
                minDate={this.props.companyCreation}
                maxDate={maxEndDate}
                onChange={this.onYearChange}
            />
          </FormControl>
          <FormControl className={this.props.classes.selector}>
            <EventButton 
              eventType={EVENT_TYPE.ABSENCE}
              onClick={this.handleEventDialog} />
            <HelpButton>
              <HelpCard type={this.state.search.type} />
            </HelpButton>
          </FormControl>
        </Toolbar>
        
        <Container maxWidth={false}>
          {this.state.search.id === 0 ?
              lbInfos :
              (this.props.loading || this.props.values === null )? 
                <WaitBlock /> :
                <YearCalendar 
                    refDate={this.state.year} 
                    refType={this.state.search.type} 
                    values={this.props.values}
                    onClick={this.onCalendarClick} />
          } 
        </Container>

        <EventDialog
            open={this.state.eventDialog}
            onClose={this.handleEventDialog}
            eventType={EVENT_TYPE.ABSENCE}
            type={this.state.search.type}
            element={this.state.targetElement}
            reason={this.state.targetElement?.reason}
            year={this.state.year}
            day={this.state.targetDay}
            />

      </Container>
    )
  }
}

const mapStateToProps = state => ({
  companyCreation: state.client.informations.ow.creation,
  loading: state.data.loading,
  values: state.data.values,
  nbDrivers : state.client.drivers.length,
  nbVehicles : state.client.vehicles.length,
})

const mapDispatchToProps = ({
  setTitle,
  getYearUploads,
})

const classes = theme => ({
  root: {
    color: theme.palette.primary.main,
  },
  toolbar: {
    justifyContent: 'space-between'
  },
  selector: {
    display: 'inline-flex',
    flexDirection: 'row',
    
  }
})

export default connect (mapStateToProps, mapDispatchToProps) (withStyles (classes) (CalendrierAnnuel))
