import { Container, Toolbar, withStyles } from '@material-ui/core';
import React, { Component } from 'react'
import Selector, {TYPE_DATE} from '../../core/components/optigestweb/Selector';
import HelpButton from '../../core/components/window/HelpButton';
import HelpCard from '../../core/components/window/HelpCard';
import { connect } from 'react-redux';
import {setTitle, setError} from '../../core/redux/actions/WindowAppActions'
import Driving from '../../core/components/optigestweb/Driving';
class Utilisations extends Component {

	state = {
		dateRef: null,
		driverId: 0,
	}

	componentDidMount() {

		/*
		 * design
		 */
		this.props.setTitle('Utilisations des véhicules')

		/*
		 * Valeurs par défaut de l'écran
		 */
		this.setState({
			dateRef: new Date(),
			driverId: 0
		})
	}

	componentDidUpdate(prevProps, prevState) {

		if ( (this.state.id > 0)
				&& ((this.state.id !== prevState.id)
					|| (this.state.dateRef !== prevState.dateRef)
					)
		) {
			this.refresh()
		}
	}
	handleChange = (newValue) => {
		this.setState({
			dateRef: newValue.dateRef,
			driverId: newValue.id,
		})
	}
  render() {
	return (
	  <Container className={this.props.classes.root} >
		  <Toolbar >
			  <Selector
					dateRef={this.state.dateRef}
					onChange={this.handleChange}
					type={TYPE_DATE.DAY}
					 />
			  <HelpButton>
				  <HelpCard title="Aide de l'écran">
						<p>Vous pouvez visualiser les véhicules conduits par un conducteur au cours d'une journée.</p>
				  </HelpCard>
			  </HelpButton>
		  </Toolbar>
			
			<Driving dateRef={this.state.dateRef} id={this.state.driverId} />
	  </Container>
	)
  }
}

const mapDispatchToProps = ({
	setTitle,
	setError,
})

const classes= {
	root: {
		display: 'flex',
		justifyContent: 'start',
		flexDirection: 'column',
		alignItems: 'center',

	}
}
export default connect(null, mapDispatchToProps) (withStyles(classes) (Utilisations))
