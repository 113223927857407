import {exportCsvFile, processRow} from '../tools/export'

/**
 * Export des activités du mois
 * @param {array} data 
 */
 export function exportDriverOffenses (data, gridName) {

    let textExport = processRow([
        'Début',
        'Fin',
        'Durée ou quantité',
        'Gravité',
        'Libellé',
    ])
    textExport += data.reduce( (text, row) => {
        const txtRow = processRow([
            row.szDebut,
            row.szFin,
            row.szValue,
            row.Grav,
            row.Lib,
           
        ])

        return `${text}${txtRow}`
    }, '')

    
    exportCsvFile(textExport, `${gridName}.csv`)

}