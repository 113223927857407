import React from 'react'
import {CardAccountDetailsOutline} from 'mdi-material-ui'

const DriverCardIcon = React.forwardRef( (props, ref) => {

    let {size, color, ...propsComponent} = props 

    let htmlColor, themeColor
    size = size ? size : 1
    if (color) {
      htmlColor = color
    } else {
      themeColor = 'primary'
    }
    
    return (
        <CardAccountDetailsOutline ref={ref}  size={size} htmlColor={htmlColor} color={themeColor} {...propsComponent} />
    )
  }
)

export default DriverCardIcon
