import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, 
					RadioGroup, Radio, FormControlLabel,
					Typography
				} from '@material-ui/core';
import ActivityLabel from './ActivityLabel';

class ChangeActivityType extends Component {
	static propTypes = {
		actRef: PropTypes.object.isRequired,
		memorizeChanges: PropTypes.func.isRequired,
		monthMode: PropTypes.bool.isRequired,
	}

	state = {
		newType: '',
	}

	componentDidMount() {	
		this.setState({
			newType: this.props.actRef.type,
		})
	}
	componentDidUpdate(prevProps, prevState) {

		if (prevProps.actRef !== this.props.actRef) {
			this.setState({
				newType: this.props.actRef.type,
			})
		}
	}
	handleNewType = (evt) => {

		const newType = evt.target.value
		this.setState({
			newType: newType,
		})

		this.props.memorizeChanges(this.props.actRef, newType)

	}

	render() {
		let label = ''
		if (this.props.monthMode) {
			const date = new Date(this.props.actRef.date)
			label = `${date.toLocaleDateString()} ${this.props.actRef.begin.substr(0,5)} – ${this.props.actRef.duration} minutes`
		} else {
			label = `${this.props.actRef.begin.substr(0, 5)} – ${this.props.actRef.duration} minutes`
		}

		return (
			<>
				<Typography>{label}</Typography>
				<RadioGroup name='newActivityType' 
						value={this.state.newType}
						onChange={this.handleNewType}>
					<FormControlLabel value='T' control={<Radio />} label={<ActivityLabel type="T"/>} />
					<FormControlLabel value='C' control={<Radio />} label={<ActivityLabel type="C" />} />
					<FormControlLabel value='D' control={<Radio />} label={<ActivityLabel type="D" />} />
					<FormControlLabel value='Q' control={<Radio />} label={<ActivityLabel type="Q" />} />
					<FormControlLabel value='R' control={<Radio />} label={<ActivityLabel type="R" />} />
				</RadioGroup>
			</>
		)
	}
}

const classes = {
	root: {

	}
}

export default withStyles(classes) (ChangeActivityType)
