import { DRIVER_TYPE, VEHICLE_TYPE } from "../../../components/Constants";

const transform = (days, indexDay, infos) => {

    let numDay = 0
    let typeTableau = (indexDay === 3) ? DRIVER_TYPE : VEHICLE_TYPE
    let indexColumn = 0

    if (typeTableau === DRIVER_TYPE) {         /* ie tableau de conducteurs */
        numDay = Number.parseInt(infos[3].substring(0, 2)) - 1
    } else {                                   /* ie tableau de véhicules */
        numDay = Number.parseInt(infos[0]) - 1
    }
    
    switch (infos[1]) {
    
        case 'enService':
        case '':
            break
        
        case 'enVacances':
            days[numDay].actions.push('holiday');
            break

        case 'enArretMaladie':
        case 'enReparation':
            days[numDay].actions.push('ooo')
            break

        case 'horsSociete':
        case 'pret':
            days[numDay].actions.push('loan')
            break
        
        case 'avantContrat':
            days[numDay].actions.push('outBefore')
            break
        case 'apresContrat':
            days[numDay].actions.push('outAfter')
            break

        case 'avantUtilisation':
            days[numDay].actions.push('outBefore')
            break
            
        case 'apresUtilisation':
            days[numDay].actions.push('outAfter')
            break

        default:
            console.warn('state unknow', infos)

    }

    indexColumn = (typeTableau === DRIVER_TYPE) ? 4 : 3
    if (infos[indexColumn]) {
        switch (infos[indexColumn]) {
            
            case 'pointageDesire':
                days[numDay].actions.push('selected')
                break

            case 'pointagePropose':
                days[numDay].actions.push('proposed')
                break

            case 'pointageLimite':
                days[numDay].actions.push('limit')
                break

            case 'depassementDateChoisie':
                days[numDay].actions.push('warningAfter')
                break
            
            case 'depassementDateLimiteLegale':
                days[numDay].actions.push('alertAfter')
                break

            case 'nonContinuite':
                days[numDay].actions.push('loss')
                break

            default:
                let file = {
                    name: infos[indexColumn],
                    download: infos[indexColumn + 1],
                    firstAct: infos[indexColumn + 2],
                    lastAct: infos[indexColumn + 3]
                }
                days[numDay].files.push(file)

        }
        
    }

    /*
     * renvoie les informations adaptées à la journée
     */
    return days

}

export default transform