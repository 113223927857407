import BaseService from './BaseService'

const URL_API = process.env.REACT_APP_URL_API

/**
 * Service d'accès aux informations liées à l'identité de l'utilisateur
 * 
 * @todo à réorganiser pour utiliser le même modèle d'accès à l'API que ElementsAction
 * 
 */
export default class OwIdentiteService extends BaseService {

    /**
     * Récupère l'identité complète
     */
    getIdentity () {
        return this.get(`${URL_API}/identite`);
    }
    
    /**
     * Liste des clients accessibles
     */
    getClients () {
        return this.get (`${URL_API}/clients`)
    }

    /**
     * Détermination du client en cours de gestion
     * @param {Number} idClient 
     */
    setClient (idClient) {
        return this.put(`${URL_API}/identite/client/${idClient}`)
    }

    getStatus () {
        return this.get(`${URL_API}/identite/status`)
    }
    
    /**
     * Déconnexion 
     */
    logout () {
        return this.put (`${URL_API}/identite/deconnecter`);
    }
}