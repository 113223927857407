import React, { Component } from 'react'
import FaceIcon from '@material-ui/icons/Face'
class DriverIcon extends Component {
  render() {
    return (
        <>
            <FaceIcon />
        </>
    )
  }
}

export default DriverIcon
