import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import WaitBlock from '../window/WaitBlock'
import {withStyles,
        Card, CardContent, CardHeader, CardActions, Button,
        TextField, Switch, 
        } from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import VehicleIcon from '../icons/VehicleIcon'
import {resetDataError} from '../../redux/actions/DataActions'
import {addVehicle, checkVehicle} from '../../redux/actions/ElementsActions'
import DatePickerFr from '../forms/date/DatePickerFr'
import {startOfMonth} from 'date-fns'

class AddVehicleForm extends Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
    }

    state = {
        vehicle: {
            immatriculation: '',
            code: '',
            numerique: true,
            serie: '',
            debut: new Date(),           
        },
        error: {
            immatriculation: false,
            code: false,
            debut: false,
        },
        autoCode: true,
        creating: false,
        checking: false,
    }

    componentDidMount() {
        this.props.resetDataError()
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.checking && this.props.exist === 0) {
            this.setState({
                creating: true,
                checking: false,
            })
            this.props.addVehicle(this.state.vehicle)
        }
        /*
         * fermeture de la fenêtre si la création est terminée
         */
        if (this.state.creating 
                && prevProps.clientUpdating && !this.props.clientUpdating ) {
            this.props.onClose()
        }
    }
    onChange = (evt) => {
        const field = {}
        const fieldName= evt.target.name
        field[fieldName] = evt.target.value
        const vehicle = Object.assign({}, this.state.vehicle, field)

        /*
         * plus d'erreur si on change l'immatriculation
         */
        if (fieldName==='immatriculation' && this.props.exist) {
            this.props.resetDataError()
            this.setState({
                checking: false
            })
        }

        /*
         * pas de remplissage du code si remplissage manuel
         */
        if (fieldName === 'code') {
            this.setState({
                autoCode: false,
            })
            this.checkCode(evt.target.value)
        
        /*
         * génération automatique du code
         */
        } else if (this.state.autoCode && fieldName === 'immatriculation') {
            vehicle.code = evt.target.value.replaceAll(' ', '').substring(0, 10)
        }

        /*
         * sauvegardes
         */
        this.setState({
            vehicle: vehicle,
        })

    }

    onChangeBoolean = (evt) => {
        const vehicle = this.state.vehicle
        const fieldName = evt.target.name

        vehicle[fieldName] = evt.target.checked

        this.setState({
            vehicle: vehicle
        })
    }
    onChangeDate = (newDate) => {
        const vehicle = this.state.vehicle
        vehicle.debut = newDate
        this.setState({
            vehicle: vehicle
        })
    }

    checkCode = (code) => {
        
        const index = this.props.vehicles.findIndex(veh => veh.veh_code === code)

        this.setState({
            error: Object.assign({}, this.state.error, {code: (index !== -1)})
        })
    }
    validate = () => {

        const error = {
            immatriculation: !this.state.vehicle.immatriculation,
            code: !this.state.vehicle.code || this.state.error.code,
            debut: !this.state.vehicle.debut,
        }
        let isNotValid = false

        this.setState({
            error: error
        })

        for (const [key, value] of Object.entries(error)) {
            isNotValid = isNotValid || value
        }

        if (!isNotValid) {
            this.setState({
                checking: true,
            })
            this.props.checkVehicle(this.state.vehicle.immatriculation)
            
        } else {
            console.info('Invalid form', this.state.vehicle, error)
        }
    
    }

    render() {
        
        const today = new Date()
        const titleCard = <span><VehicleIcon />Nouveau véhicule</span>

        return (
            <>
            <Card className={this.props.classes.root}>
                <CardHeader title={titleCard} />
                <CardContent>                    
                { (this.props.dataLoading || this.props.clientUpdating || !this.state.vehicle || this.state.creating) ? 
                    <WaitBlock /> :
                    <>
                        {this.props.exist === 1 &&
                            <Alert severity='error'>
                                Le véhicule existe déjà !
                            </Alert>
                        }
                        <div className={this.props.classes.input}>
                            <span className={this.props.classes.label}>Immatriculation</span> 
                            <TextField required 
                                    name='immatriculation' 
                                    value={this.state.vehicle.immatriculation} 
                                    error={this.state.error.immatriculation}
                                    onChange={this.onChange}/>
                        </div>
                        <div className={this.props.classes.input}>
                            <span className={this.props.classes.label}>Code</span>
                            <TextField required 
                                    name='code' 
                                    value={this.state.vehicle.code} 
                                    inputProps={{
                                        maxLength: 10
                                    }}
                                    error={this.state.error.code}
                                    helperText={this.state.error.code ? 'Le code existe déjà.' : ''}
                                    onChange={this.onChange}/>
                        </div>
                        <div className={this.props.classes.input} >
                            <span className={this.props.classes.label}>{this.state.vehicle.numerique ? 'Tachygraphe numérique' : 'Disque papier'}</span>
                            <Switch name='numerique'
                                    checked={this.state.vehicle.numerique}
                                    onChange={this.onChangeBoolean} />
                        </div>
                        <div className={this.props.classes.input}>
                            <span className={this.props.classes.label}>Numéro de série</span>
                            <TextField 
                                    name='serie'
                                    value={this.state.vehicle.serie}
                                    error={this.state.error.serie}
                                    onChange={this.onChange}/>
                        </div>
                        <div className={this.props.classes.input}>
                            <span className={this.props.classes.label}>Début d'utilisation</span>
                            <DatePickerFr
                                name='debut'
                                value={this.state.vehicle.debut}
                                onChange={this.onChangeDate}
                                minDate={startOfMonth(today)}
                                />
                        </div>
                    </>
                }
                </CardContent>
                { !this.state.creating &&
                    <CardActions className={this.props.classes.cardActions}>
                        <>
                            <Button onClick={this.props.onClose}>Annuler</Button>
                            {!this.state.checking && 
                                <Button onClick={this.validate}
                                        disabled={this.props.exist === 1}>
                                    Créer
                                </Button>
                            }
                        </>
                    </CardActions>
                }                
            </Card>
            </>
        )
    }
}

const mapStateToProps = state => ({
    dataLoading: state.data.loading,
    clientUpdating: state.client.updating,
    actualUser: state.identity.user,
    exist: state.data.error,
    vehicles: state.client.vehicles,
})
const mapDispatchToProps = ({
    resetDataError,
    checkVehicle,
    addVehicle,
})

const classes = {
    root: {
        minWidth: '25em',
    },
    input: {
        lineHeight: '2rem',
    },
    label: {
        display: 'inline-block',
        width: '11em',
    },
    labelIdent: {
        width: '19em',
        display: 'inline-block',
        
    },
    cardActions: {
        justifyContent: 'space-around',
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (AddVehicleForm))
