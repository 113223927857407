import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {    OUBLICARTE, PERTECARTE,
            PASDECARTE, CARTEHS,
            PARC, REPARATION,
            INCONNU,
            CONTROL, HS,
            } from '../Constants'
import {Select, MenuItem} from '@material-ui/core'

export const reasons = [
    {
      value: OUBLICARTE,
      label: 'Oubli carte',
    },
    {
      value: PERTECARTE,
      label: 'Perte carte', 
    },
    {
      value: PASDECARTE,
      label: 'Pas de carte',
    },
    {
      value: CARTEHS,
      label: 'Carte HS',
    },
    {
      value: PARC,
      label: 'Parc',
    },
    {
      value: REPARATION,
      label: 'Réparation',
    },
    {
      value: CONTROL,
      label: 'Contrôle',
    },
    {
      value: HS,
      label: 'Lecteur HS'
    },
    {
      value: INCONNU,
      label: 'Motif inconnu',
    },
    
]

export const getLabel = (value) => {

  if (value) {
    const reason = reasons.find(elt => elt.value === value)
    return reason.label
  } else {
    return ''
  }
}

export class ReasonsSelector extends Component {

    static propTypes = {
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired, 
    }
  render() {
    return (
        <Select value={this.props.value} onChange={this.props.onChange}>
            {reasons.map(aReason => <MenuItem key={aReason.value} value={aReason.value}>
                {aReason.label}
            </MenuItem>)}
        </Select>
    )
  }
}

