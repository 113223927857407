/**
 * Personnalisation du thème
 * 
 * @see https://material.io/design/color/the-color-system.html#tools-for-picking-colors
 * @see https://material.io/develop/web
 */

const primaryColor = '#092F6A' 
const secondaryColor = '#ffc400'
const primaryColorLight = '#3a5887'
const primaryColorDark = '#06204a'
const secondaryColorLight = '#FFEDB3'
const secondaryColorDark = '#FFEDB3'

export const LightTheme = {
    palette: {
      primary: {
        main: primaryColor, 
        light: primaryColorLight,
        dark: primaryColorDark,
      },
      secondary: {
        main: secondaryColor,
        light: secondaryColorLight,
        dark: secondaryColorDark,
      }
    },
    overrides: {
      MuiContainer: {
        root: {
          backgroundColor: 'white',
        }
      },
      MuiButtonBase: {
        root: {
            color: primaryColor,
        }
      },
      MuiGrid: {
        root: {
          '&& span': {
            color: primaryColor,
          }
        }
      },
      MuiIconButton: {
        root: {
          color: primaryColorLight,
        }
      },
      MuiPaper: {
        root: {
          color: primaryColor,
        }
      },
      MuiTypography: {
        root: {
          color: primaryColor,
        },
        subtitle1: {
          backgroundColor: secondaryColorLight,
        }
      },
      MuiInputBase: {
        root: {
          color: primaryColor,
        }
      },
      MuiRadio: {
        root: {
          color: primaryColorLight,

        }
      },
      MuiTab: {
        root: {
          color: primaryColor,
        },
        wrapper: {
          color: primaryColor,
        }
      },
      MuiTable: {
        root: {
          color: primaryColor,
        },
      },
      MuiTableCell: {
        root: {
          color: primaryColor,
        },
        body: {
          color: primaryColor,
        },
        head: {
          color: primaryColor,
          fontWeight: 'bold',
        }
      },
      MuiTableSortLabel: {
          root: {
              color: primaryColor,
              '&.MuiTableSortLabel-active': {
                color: primaryColor,
              },
              '&:focus': {
                color: primaryColor,
              }
          },
          icon: {
            color: primaryColor,
          }
      },
      MuiTablePagination: {
          root: {
              color: primaryColor,
          }
      },
      MuiDataGrid: {
        root: {
          color: primaryColor,
        }
      }
    }
  }