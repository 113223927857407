import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as rolls from '../Constants'

/**
 * Transformation de la constante définissant le rôle de l'utilisateur en une chaîne de caractères affichable 
 */
export function sLabelRoll (label) {

    if (typeof (label) === 'object') {
        let string = ''
        string += label.map(s => sLabelRoll(s))
        return string
    }
    switch (label) {

        case rolls.U_INACTIF:
            return 'inactif';

        case rolls.U_VISUALISEUR:
            return 'visualiseur';

        case rolls.U_POUVOIR:
            return 'utilisateur avec pouvoirs';

        case rolls.U_ADMINISTRATEUR:
            return 'administrateur';

        case rolls.U_ADMINISTRATEUR_SOCIETE:
            return 'administrateur de société';
        
        case rolls.U_ADMINISTRATEUR_ATHMO:
            return 'administrateur Athmo';

        case rolls.U_COMPTABLE:
            return 'comptable';

        case rolls.U_COMPTABLE_ATHMO:
            return 'comptable Athmo';

        case rolls.U_RH:
            return 'ressources humaines';

        default:
            return `!!${label}!!` 
    }

}

export class LabelRoll extends Component {
    static propTypes = {
        label   : PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array,
        ])
    }

    /**
     * procède à la transformation de la constante dans la langue utilisée
     * @param {string} label 
     */
    render() {

        let retour = ''

        if (typeof (this.props.label) === 'string') {
            retour = sLabelRoll(this.props.label)
        } else if (this.props.label) {
            retour += this.props.label.map(role => sLabelRoll(role)) + ' '
        }

        return (
            <>{retour}</>
        )
    }
}

export default LabelRoll