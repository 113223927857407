import {URL_API, API, METHOD_GET, METHOD_PUT, METHOD_POST, setData,} from './DataActions'
import {DRIVER_TYPE, ROLLS, VEHICLE_TYPE} from '../../components/Constants'

export const CLIENT_UPDATING = 'CLIENT_UPDATING'
export const CLIENT_SAVE_INFORMATIONS = 'CLIENT_SAVE_INFORMATIONS'
export const CLIENT_UPDATE_LOGO = 'CLIENT_UPDATE_LOGO'
export const CLIENT_GET_ELEMENTS = 'CLIENT_GET_ELEMENTS'
export const CLIENT_SET_SERVICES = 'CLIENT_SET_SERVICES'
export const CLIENT_SET_DRIVERS = 'CLIENT_SET_DRIVERS'
export const CLIENT_ADD_DRIVER = 'CLIENT_ADD_DRIVER'
export const CLIENT_SET_VEHICLES = 'CLIENT_SET_VEHICLES'
export const CLIENT_ADD_VEHICLE = 'CLIENT_ADD_VEHICLE'
export const CLIENT_SET_USERS = 'CLIENT_SET_USERS'
export const CLIENT_UPDATE_USER = 'CLIENT_UPDATE_USER'
export const CLIENT_ADD_USER = 'CLIENT_ADD_USER'
export const CLIENT_SET_AUTHORIZATION_DOWNLOAD = 'CLIENT_SET_AUTHORIZATION_DOWNLOAD'
export const CLIENT_UPDATE_DRIVER = 'CLIENT_UPDATE_DRIVER'
export const CLIENT_UPDATE_VEHICLE = 'CLIENT_UPDATE_VEHICLE'
export const CLIENT_SET_PARAMETERS = 'CLIENT_SET_PARAMETERS'
export const CLIENT_SET_VALIDATION = 'CLIENT_SET_VALIDATION'
export const CLIENT_SET_LOGO = 'CLIENT_SET_LOGO'
export const CLIENT_SET_GROUPS = 'CLIENT_SET_GROUPS'
export const CLIENT_ENABLE_GROUPS = 'CLIENT_ENABLE_GROUPS'
export const setClientUpdating = (etat) => ({
    type: CLIENT_UPDATING,
    payload: etat
})

// @todo les actions PUT sont implémentés à l'envers :
// il y a une mise à jour locale avant le retour avec succès de la mise à jour via API
// ceci provoque un risque d'erreur si l'aPI est en erreur.
export const getElements = () => dispatch => {

    dispatch (getDrivers ())
    dispatch (getVehicles ())

}

export const getInformations = () => ({
    type: API,
    payload: {
        url: `${URL_API}/client/informations`,
        method: METHOD_GET,
        params: null,
        onSuccess: saveInformations,
    }
})

export const saveInformations = data => ({
    type: CLIENT_SAVE_INFORMATIONS,
    payload: data
})

/**
 * Récupération de la liste des services souscrits par l'entreprise en cours de gestion
 */
export const getServices = () => {
    
    return {
        type: API,
        payload: {
            url: `${URL_API}/client/services`,
            method: METHOD_GET,
            params: null,
            onSuccess: setServices,
        }
    }

}

export const setServices = data => ({
    type: CLIENT_SET_SERVICES,
    payload: data
})

/**
 * Récupération de la liste des conducteurs gérables par l'utilisateur
 */
export const getDrivers = () => ({
    type: API,
    payload: {
        url: `${URL_API}/client/drivers`,
        method: METHOD_GET,
        params: null,
        onSuccess: setDrivers,
    }
})
export const setDrivers = data => ({
    type: CLIENT_SET_DRIVERS,
    payload: data
})

/**
 * Récupération de la liste des véhicules gérables par l'utilisateur
 */
export const getVehicles = () => ({
    type: API,
    payload: {
        url: `${URL_API}/client/vehicles`,
        method: METHOD_GET,
        params: null,
        onSuccess: setVehicles,
    }
})
export const setVehicles = data => ({
    type: CLIENT_SET_VEHICLES,
    payload: data
})

export const setAuthorization = data => ({
    type: CLIENT_SET_AUTHORIZATION_DOWNLOAD,
    payload: data
})
export const getAuthorizationDownload = () => ({
    type: API,
    payload: {
        url: `${URL_API}/files/authorization`,
        method: METHOD_GET,
        params: null,
        onSuccess: setAuthorization,
    }
})

export const sRoll = (roll) => {

    let sRoll = ''
    switch (roll) {
        case 'administrateurSociete':
            sRoll = 'Administrateur de société'
            break

        case 'utilisateurAvecPouvoir':
            sRoll = 'Utilisateur avec pouvoirs'
            break

        case 'utilisateurVisualiseur':
            sRoll = 'Visualiseur'
            break

        default:
            sRoll = roll
            break
    }

    return sRoll
}

export const beautifyUsers = (data) => {

    let users = data.map(user => {
        user.sRoles = sRoll(user.role)        
        return user
    })
    return {
        type: CLIENT_SET_USERS,
        payload: users
    }
}
export const getUsers = () => ({
    type: API,
    payload: {
        url: `${URL_API}/users`,
        method: METHOD_GET,
        params: null,
        cancelable: true,
        onSuccess: beautifyUsers,
    }
})

export const getUser = (id) => ({
    type: API,
    payload: {
        url: `${URL_API}/users/${id}`,
        method: METHOD_GET,
        params: null,
        cancelable: true,
        onSuccess: setData,
    }
})

export const updateLocalUser = (id, field, value) => ({
    type: CLIENT_UPDATE_USER,
    payload: {
        id: id,
        field: field,
        value: value,
    }
})
export const updateNameUser = (id, newName) => (dispatch) => {
    dispatch(updateNameUserToApi(id, newName))
    dispatch(updateLocalUser(id, 'nom', newName))
}
export const updateNameUserToApi = (id, newName) => ({
    type: API,
    payload: {
        url: `${URL_API}/users/${id}/name/${newName}`,
        method: METHOD_PUT,
        params: null,
    }
})

export const updateForenameUser = (id, newName) => (dispatch) => {
    dispatch(updateForenameUserToApi(id, newName))
    dispatch(updateLocalUser(id, 'prenom', newName))
}
export const updateForenameUserToApi = (id, newForename) => ({
    type: API,
    payload: {
        url: `${URL_API}/users/${id}/forename/${newForename}`,
        method: METHOD_PUT,
        params: null,
    }
})

export const updateEmailUser = (id, newEmail) => dispatch => {
    dispatch(updateEmailUserToApi(id, newEmail))
    dispatch(updateLocalUser(id, 'email', newEmail))
}
export const updateEmailUserToApi = (id, newEmail) => ({
    type: API,
    payload: {
        url: `${URL_API}/users/${id}/email/${newEmail}`,
        method: METHOD_PUT,
        params: null,
    }
})

export const updateFunctionUser = (id, newFunction) => dispatch => {
    dispatch(updateFunctionUserToApi(id, newFunction))
    dispatch(updateLocalUser(id, 'fonction', newFunction))
}
export const updateFunctionUserToApi = (id, newFunction) => ({
    type: API,
    payload: {
        url: `${URL_API}/users/${id}/function/${newFunction}`,
        method: METHOD_PUT,
        params: null,
    }
})
export const updateAlertUser = (id, value) => dispatch => {
    dispatch(updateAlertUserToApi(id, value))
    dispatch(updateLocalUser(id, 'alerte', value))
}
export const updateAlertUserToApi = (id, value) => {

    const number = (value) ? 1 : 0
    return {
        type: API,
        payload: {
            url: `${URL_API}/users/${id}/alert/${number}`,
            method: METHOD_PUT,
            params: null,
        }
    }
}

export const updateRollUser = (id, value) => dispatch => {
    dispatch(updateRollUserToApi(id, value))
    dispatch(updateLocalUser(id, 'role', value))
    dispatch(updateLocalUser(id, 'sRoles', sRoll(value)))
}
export const updateRollUserToApi = (id, value) => {
    const number = ROLLS.findIndex(roll => roll === value)
    return {
        type: API,
        payload: {
            url: `${URL_API}/users/${id}/roll/${number}`,
            method: METHOD_PUT,
            params: null,
        }
    }
}

export const resetPassword = (id) => ({
    type: API,
    payload: {
        url: `${URL_API}/users/${id}/password/reset`,
        method: METHOD_PUT,
    }
})

export const addUser = (user) => dispatch => {
    dispatch(addUserToApi(user))
    user.sRoles = sRoll(user.role)
    dispatch(addUserToLocal(user))
}
export const addUserToLocal = (user) => ({
    type: CLIENT_ADD_USER,
    payload: user
})

export const addUserToApi = (user) => {

    const newUser = {
        name: user.nom,
        forename: user.prenom,
        email: user.email,
        language: user.langue,
        status: user.role,
        alert: user.alerte,
    }
    return {
        type: API,
        payload: {
            url: `${URL_API}/users/add`,
            method: METHOD_POST,
            params: newUser,
            // cancelable: true,
            onSuccess: setData,
        }
    }
}

const setParameters = (data, type) => ({
    type: CLIENT_SET_PARAMETERS,
    payload: {
        type: type,
        data: data,
    }
})

/**
 * Traduit le type (driver / vehicle) en une chaîne compréhensible par API
 * @param {string} type 
 * @returns 
 */
function getApiType(type) {

    let sType = 'inconnu'
    switch(type) {
        case DRIVER_TYPE:
            sType='conducteur'
            break
        case VEHICLE_TYPE:
            sType='vehicule'
            break
        default:
            throw new Error(`Type ${type} inconnu`)
    }

    return sType
}
export const getAlertsParameters = (type) => ({
    type: API,
    payload: {
        url: `${URL_API}/client/parameters/alerts/${getApiType(type)}`,
        method: METHOD_GET,
        cancelable: false,
        onSuccess: setParameters,
        paramOnSuccess: type,
    }
})

const returnOfSaveAlertsParametersToAPI = (success, data) => {
    let {originalType, ...params} = data   
    return {
        type: CLIENT_SET_PARAMETERS,
        payload: {
            type: originalType,
            data: params,
        }
    }
}
export const saveAlertsParametersToAPI = (leType, params) => {

    const data = Object.assign({}, params, {
        type: getApiType(leType),
        originalType: leType,
    })
    return {
        type: API,
        payload: {
            url: `${URL_API}/client/parameters/alerts`,
            method: METHOD_POST,
            params: data,
            cancelable: false,
            onSuccess: returnOfSaveAlertsParametersToAPI,
            paramOnSuccess: data,
        }
    }
}

export const saveAccountValidation = status => {

    const iStatus = (status) ? 1 : 0
    return {
        type: API,
        payload: {
            url: `${URL_API}/client/parameters/validation/${iStatus}`,
            method: METHOD_PUT,
            cancelable: false,
            onSuccess: setValidationParameter,
         }
    }

}

const setValidationParameter = returnData => ({
    type: CLIENT_SET_VALIDATION,
    payload: returnData,
})

export const getLogo = () => ({
    type: API,
    payload: {
        url: `${URL_API}/client/parameters/logo`,
        method: METHOD_GET,
        cancelable: false,
        onSuccess: setLogo,
    }
})

const setLogo = (data) => ({
    type: CLIENT_SET_LOGO,
    payload: data
})

export const deleteLogo = () => dispatch => {

    this.delete(`${URL_API}/client/parameters/logo`)

    dispatch(setLogo(''))

}
export const getGroupsInfos = () => ({
    type: API,
    payload: {
        url: `${URL_API}/client/parameters/groups`,
        method: METHOD_GET,
        cancelable: false,
        onSuccess: setGroups,
    }
})

const setGroups = (data) => ({
    type: CLIENT_SET_GROUPS,
    payload: data,
})
export const enableGroups = (enable) => {

    const sValue = (enable) ? '1' : '0'

    return {
        type: API,
        payload: {
            url: `${URL_API}/client/parameters/groups/${sValue}`,
            method: METHOD_PUT,
            cancelable: true,
            onSuccess: setEnableGroups,
        }
    }
}

const setEnableGroups = (data) => ({
    type: CLIENT_ENABLE_GROUPS,
    payload: data,
})