import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {getDriver, updateContractDriverToAPI,
        addDriverContract} from '../../redux/actions/ElementsActions'
import {resetDataError} from '../../redux/actions/DataActions'
import ButtonOw from '../window/ButtonOw'
import ButtonAdd from '../window/ButtonAdd'
import CloseButton from '../window/CloseButton'
import WaitBlock from '../window/WaitBlock'
import {withStyles,
        Card, CardContent, CardHeader, CardActions, Button,
        TextField, Tooltip,
        TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
        Snackbar,
        } from '@material-ui/core'
import {Alert, } from '@material-ui/lab'
import EditIcon from '@material-ui/icons/Edit'
import DriverIcon from '@material-ui/icons/Face'
import HistoCardIcon from '@material-ui/icons/FormatListNumbered'
import CheckIcon from '@material-ui/icons/Check'
import SubscriptionsIcon from '@material-ui/icons/Subscriptions'

import EditDriverContractForm from './EditDriverContractForm'
import {format, addDays, subYears} from 'date-fns'
import DatePicker from '../forms/date/DatePickerFr';
import { DATE_INFINIE } from '../Constants';

class DriverForm extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onRefresh: PropTypes.func,
    }

    state = {
        initialDriver: null,
        driver: null,
        editMode: false,
        displayHistoCard: false,
        displayContracts: false,
        editContract: null,
        displayContract: false,
        refreshUses: 0,
        modeAddContract: false,
        firstDayForNewContract: this.props.limits.begin,
        alertNothingToDo: false,
        existingCode: false,
    }
    componentDidMount() {
        this.props.getDriver(this.props.id)
        this.props.resetDataError()        
    }
    componentDidUpdate(prevProps, prevState) {
        
        /*
         * chargement initial des infos
         */
        if (prevProps.id !== this.props.id) {
            this.props.getDriver(this.props.id)
        }

        /*
         * adaptations des données chargées pour le formulaire
         */
        if (prevProps.driver !== this.props.driver) {

            const driver = this.props.driver
            
            if (driver) {

                /*
                 * aucune propriété ne doit être nulle
                 */
                Object.entries(driver).forEach(([clef, valeur]) => {
                    if ( (clef !== 'dateNaissance')
                            && (valeur === null)
                    ) {
                        driver[clef] = ''
                    }
                })

                /*
                 * recherche du premier jour après le dernier jour dans un contrat
                 */
                this.setFirstDayForNewContract(driver.contrats)
            }

            this.setState({
                driver: driver,
                displayContracts: false,
                displayContract: false,
                editContract: null,
                displayHistoCard: false,
            })        
            

            
        }

        /*
         * lors du passage en mode édition, sauvegarde des données initiales
         */
        if (!prevState.editMode && this.state.editMode) {
            this.setState({
                initialDriver: this.state.driver,
            })
        }
        
        /*
         * validation du formulaire effectuée
         */
        if (prevProps.loading && !this.props.loading     /* ie chargement terminé, */
        ) {

            if (this.state.editMode                      /* alors que modif en cours */
                && !this.props.existCode                    /* et sans erreur */
            ) {
                this.setState({
                    editMode: false
                })
            }
                        
            /*
             * rafraîchissement du parent
             */
            if (this.props.onRefresh) {
                this.props.onRefresh();
            }
        }

    }

    /**
     * recherche du premier jour sans contrat, 
     * après le dernier jour contenu dans un contrat
     */
    setFirstDayForNewContract = (contrats) => {
       
        let firstDayAvailable = this.props.limits.begin

        contrats.forEach(contrat => {
            if (contrat.fin === null) {
                firstDayAvailable = DATE_INFINIE
            } else if (contrat.fin > firstDayAvailable) {
                firstDayAvailable = addDays(contrat.fin, 1)
            }
        })

        this.setState({
            firstDayForNewContract: firstDayAvailable
        })
        
    }

    displayHistoCard = () => {
        this.setState({
            displayHistoCard: !this.state.displayHistoCard,
        })
    }   
    
    switchEdit = () => {
        this.setState({
            editMode: !this.state.editMode,
        })
    }

    handleAlertNothingTodo = () => {
        this.setState({
            alertNothingToDo: false,
        })
    }
    onChange = (evt) => {
        
        const field = {}
        const fieldName= evt.target.name
        field[fieldName] = evt.target.value

        /*
         * vérification que le code n'existe pas déjà
         */
        if (fieldName === 'code') {
            this.checkCode(evt.target.value)
        }

        const driver = Object.assign({}, this.state.driver, field)
        this.setState({
            driver: driver,
        })

        /*
         * dès que changement de code, il n'y a plus d'erreur affichée
         */
        if (fieldName === 'code') {
            this.props.resetDataError()
        }
    }

    onChangeDate = (newDate) => {
        this.setState({
            driver: Object.assign({}, this.state.driver, {dateNaissance: newDate})
        })
    }
    checkCode = (code) => {
        
        const index = this.props.drivers.findIndex(drv => (drv.drv_code === code && drv.id !== this.props.id))

        this.setState({
            existingCode: index !== -1
        })

    }
    displayAddContract = (value) => {
        
        /*
         * cas 1 : validation de la création
         */
        if (value?.debut) {
            this.setState({
                displayContract: false,
                modeAddContract: false,
            })
            this.props.addDriverContract(this.props.id, value)

        /*
         * cas 2 : annulation
         */
        } else if (typeof(value) === 'undefined') {
            this.setState({
                displayContract: false,
                modeAddContract: false,
            })
        
        /*
         * cas 3 : ouverture du formulaire de création
         */
        } else {
            this.setState({
                displayContract: true,
                modeAddContract: true,
            })
        }
        
    }

    handleContracts = () => {
        this.setState({
            displayContracts: !this.state.displayContracts,
            modeAddContract: false,
            displayContract: false,
        })
    }
    displayContract = (contract) => {
        
        this.setState({
            displayContract : (contract?.id_contrat > 0),
            editContract : contract,
            modeAddContract: false,
        })

        /*
         * sauvegarde des données suite à demande de fermeture, en mode édition
         */
        if ( contract && this.state.displayContract === true && this.state.modeAddContract === false) {

            /*
             * Mise à jour API
             */
            this.props.updateContractDriverToAPI(this.state.driver.id, contract)

            /*
             * mise à jour locale
             */
            let driver = this.state.driver
            let id = driver.contrats.findIndex(c => c.id_contrat === contract.id_contrat)
            driver.contrats[id]=contract
            this.setState({
                displayContract: false,
                driver: driver,
                refreshUses: this.state.refreshUses + 1
            })

            /*
             * mise à jour locale des contraintes de gestion
             */

            /*
             * recherche du premier jour après le dernier jour dans un contrat
             */
            this.setFirstDayForNewContract(driver.contrats)
        }

    }
    onCancel = () => {
        this.setState({
            driver: this.state.initialDriver,
            editMode: false,
        })
    }
    resetEdit = () => {
        this.setState({
            driver: this.state.initialDriver,
        })
    }

    validate = () => {
        const changes = {}
        
        /*
         * aucune validation si erreur de code
         */
        if (this.state.existingCode) {
            return;
        }

        if (this.state.initialDriver.code !== this.state.driver.code) {
            changes.code = this.state.driver.code
        }
        
        if (this.state.initialDriver.nom !== this.state.driver.nom) {
            changes.nom = this.state.driver.nom
        }
        if (this.state.initialDriver.prenom !== this.state.driver.prenom) {
            changes.prenom = this.state.driver.prenom
        }
        if (this.state.initialDriver.permis !== this.state.driver.permis) {
            changes.permis = this.state.driver.permis
        }
        if (this.state.initialDriver.dateNaissance !== this.state.driver.dateNaissance) {
            changes.dateNaissance = this.state.driver.dateNaissance
        }

        if (Object.entries(changes).length === 0) {
            this.setState({
                editMode: false,
                alertNothingToDo: true,
            })
        } else {
            this.props.onChange(changes)
        }
        
    }

    render() {
        
        const sTitle = this.props.actualUser.supportAthmo ? `Conducteur id ${this.state.driver?.id}` : ''
        const title = <span><DriverIcon />Conducteur</span>
        const titleCard = 
                <Tooltip title={sTitle}>
                    {title}
                </Tooltip>
        const lastCard = this.state.driver?.historiqueCarte ? this.state.driver.historiqueCarte[this.state.driver.historiqueCarte.length - 1] : null

        /*
         * Bloc actions du haut
         */
        const cardActions = 
        <>
            {!this.state.editMode &&
                <ButtonOw 
                        title='Édition' 
                        onClick={this.switchEdit}>
                    <EditIcon />
                </ButtonOw>
            }
            <CloseButton onClick={this.props.onClose} />
        </>

        const contractsActions = 
        <>  
            {this.state.firstDayForNewContract !== DATE_INFINIE &&
                <ButtonAdd 
                    title="Ajouter un contrat"
                    disabled={this.state.modeAddContract}
                    onClick={this.displayAddContract}
                    />
            }
            <CloseButton onClick={this.handleContracts} />
        </>

        /*
         * rendu
         */
        return (
            <>
                <Card className={this.props.classes.root} >
                    <CardHeader title={titleCard} action={cardActions} />
                    { (this.props.loading || !this.state.driver) ? 
                        <CardContent>
                            <WaitBlock />
                        </CardContent> :
                        <>
                            <CardContent>
                                <div className={this.props.classes.input}>
                                    <span className={this.props.classes.label}>Nom</span> 
                                    { (this.state.editMode && !this.state.driver.carteOfficielle) ? 
                                        <TextField name='nom' value={this.state.driver.nom} onChange={this.onChange}/> :
                                        this.state.driver.nom
                                    }
                                </div>
                                <div className={this.props.classes.input}>
                                    <span className={this.props.classes.label}>Prénom</span>
                                    { (this.state.editMode  && !this.state.driver.carteOfficielle)? 
                                        <TextField name='prenom' value={this.state.driver.prenom} onChange={this.onChange}/> :
                                        this.state.driver.prenom
                                    }
                                </div>
                                {this.props.existCode && 
                                    <Alert severity='error' >Le code existe déjà !</Alert>
                                }
                                <div className={this.props.classes.input}>
                                    <span className={this.props.classes.label}>Code</span>
                                    {this.state.editMode ? 
                                        <TextField name='code' 
                                                value={this.state.driver.code}
                                                onChange={this.onChange}
                                                inputProps={{
                                                    maxLength: 10
                                                }}
                                                error={this.props.existCode || this.state.existingCode} 
                                                helperText={this.state.existingCode ? 'Le code existe déjà.' : '' }/> :
                                        this.state.driver.code
                                    }
                                </div>
                                <div className={this.props.classes.input}>
                                    <span className={this.props.classes.label}>Date de naissance</span>
                                    { (this.state.editMode  && !this.state.driver.carteOfficielle) ? 
                                        <DatePicker
                                            value={this.state.driver.dateNaissance} 
                                            disableFuture
                                            initialFocusedDate={subYears(new Date(), 20)}
                                            onChange={this.onChangeDate}/> :
                                        this.state.driver.dateNaissance ? format(this.state.driver.dateNaissance, 'dd/MM/yyyy') : ''
                                    }
                                </div>
                                <div className={this.props.classes.input}>
                                    <span className={this.props.classes.label}>Numéro de permis</span>
                                    { (this.state.editMode && !this.state.driver.carteOfficielle) ? 
                                        <TextField name='permis' value={this.state.driver.permis} onChange={this.onChange}/> :
                                        this.state.driver.permis
                                    }
                                </div>
                                <div className={this.props.classes.input}>
                                    <span className={this.props.classes.label}>{this.state.driver.aCarte ? 'Carte' : "N'a pas de carte"}</span>
                                    {/* {(!this.state.editMode || this.state.driver.carteOfficielle) ? this.state.driver.carte : 
                                        <TextField name="carte" value={this.state.driver.carte} onChange={this.onChange}/>
                                    } */}
                                    {this.state.driver.carte}
                                </div>
                                { (this.state.driver.aCarte && lastCard) && <>
                                        <div className={this.props.classes.input}>
                                            <span className={this.props.classes.label2}>Date d'expiration</span>
                                            {lastCard.expiration}
                                        </div>
                                        {this.state.driver.historiqueCarte.length > 0 &&
                                            <div className={this.props.classes.input}>
                                                <span className={this.props.classes.label2}>Historique des cartes</span>
                                                <Button 
                                                        onClick={this.displayHistoCard}
                                                        title={this.state.displayHistoCard ? "Camoufler l'historique des cartes" : "Afficher l'historique des cartes"}
                                                        >
                                                    <HistoCardIcon />
                                                </Button>
                                            </div>
                                        }
                                        {this.state.displayHistoCard &&
                                            this.state.driver.historiqueCarte.map(elt => 
                                                <div key={elt.numero} className={this.props.classes.blockHistoCard}>
                                                    <div>
                                                        <span className={this.props.classes.label2}>Carte</span>
                                                        {elt.numero}
                                                    </div>
                                                    <div>
                                                        <span className={this.props.classes.label2}>Émise le</span>
                                                        {elt.date_emission}
                                                    </div>
                                                    <div>
                                                        <span className={this.props.classes.label2}>Par</span>
                                                        {elt.autorite_emission} ({elt.code})
                                                    </div>
                                                    <div>
                                                        <span className={this.props.classes.label2}>Validité</span>
                                                        {elt.debut_validite} - {elt.expiration}
                                                    </div>
                                                </div>

                                            )
                                        }                                    
                                    </>
                                }
                            
                                {/* @todo implémenter gestion sites user.sites */}
                        </CardContent>
                            <CardActions className={this.props.classes.cardActions}>
                                {this.state.editMode ?
                                    <>
                                        <Button onClick={this.onCancel}>Annuler</Button>
                                        <Button onClick={this.resetEdit}>Réinitialiser</Button>
                                        <Button onClick={this.validate}>Valider</Button>
                                    </> :
                                    <>
                                        <Button onClick={this.handleContracts}
                                                variant="outlined"
                                                startIcon={<SubscriptionsIcon />}>
                                            Contrats&nbsp;<em>Optimal Web</em>
                                        </Button>
                                    </>
                                }
                            </CardActions>
                        </>
                    }
                </Card>
           
            {this.state.displayContracts &&
                <Card className={this.props.classes.card}>
                    <CardHeader title="Contrats Optimal Web" 
                            action={contractsActions} />
                    <CardContent>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        {this.props.actualUser?.supportAthmo &&
                                            <TableCell>Entreprise</TableCell>
                                        }
                                        <TableCell>Début</TableCell>
                                        <TableCell>Fin</TableCell>
                                        <TableCell>Intérimaire</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.driver.contrats.map(contrat => 
                                    <TableRow key={contrat.id_contrat}
                                            onClick={() => {
                                                if (!this.state.displayContract) {
                                                    this.displayContract(contrat)
                                                }
                                            }}
                                            className={!this.state.displayContract ? this.props.classes.clickable : null}>
                                        {this.props.actualUser?.supportAthmo &&
                                            <TableCell>{contrat.societe}</TableCell>
                                        }
                                        <TableCell>{format(contrat.debut, 'dd/MM/yyyy')}</TableCell>
                                        <TableCell>{contrat.fin ? format(contrat.fin,'dd/MM/yyyy') : <>&infin;</>}</TableCell>
                                        <TableCell>{contrat.interimaire ? <CheckIcon />: ''}</TableCell>
                                    </TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            }

            {this.state.displayContract && 
                <EditDriverContractForm 
                    contract={this.state.editContract} 
                    modeAdd={this.state.modeAddContract}
                    firstDayForNewContract={this.state.firstDayForNewContract}
                    onClose={this.state.modeAddContract ? this.displayAddContract : this.displayContract} 
                    />
            }

            <Snackbar open={this.state.alertNothingToDo}
                    autoHideDuration={6000}
                    onClose={this.handleAlertNothingTodo}>
                <Alert  onClose={this.handleAlertNothingTodo}
                        severity="info">
                    Aucune modification effectuée !
                </Alert>
            </Snackbar>
            </>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.data.loading,
    driver: state.data.values,
    actualUser: state.identity.user,
    existCode: state.data.error,
    limits: state.client.informations.ow.limits,
    drivers: state.client.drivers,
})

const mapDispatchToProps = ({
    getDriver,
    updateContractDriverToAPI,
    resetDataError,
    addDriverContract,
})

const classes = {
    root: {
        minWidth: '30em',
    },
    card: {
        marginTop: '0.5em',
    },
    input: {
        lineHeight: '2rem',
    },
    label: {
        display: 'inline-block',
        width: '12em',
    },
    label2: {
        display: 'inline-block',
        marginLeft: '1em',
        width: '11em',
    },
    blockHistoCard: {
        paddingTop: '1rem',
    },
    contracts: {
        marginTop: '0.5em',
    },
    blockContrat: {
        '&& span': {
            width: '4em',
        }
    },
    clickable: {
        cursor: 'pointer',
    },
    headerContracts: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&& div': {
            display: 'inline-flex',
            alignItems: 'center',
        }
    },
    cardActions: {
        justifyContent: 'space-around',
    },
}
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (DriverForm))
