import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withStyles, Paper, Toolbar,
        TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
        } from '@material-ui/core';
import {getLabel as getReasonLabel} from './Reasons' 
import PrintButton from '../window/PrintButton'
import toPrint from '../tools/toPrint'
import ExportButton from '../window/ExportButton'
import {exportAssignedActivities} from './exportActivities'
import {format} from 'date-fns'
import localeFr from 'date-fns/locale/fr'

class AssignedActDataGrid extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
  }

  getDriver = (driverCode) => {
    const typeField = localStorage.getItem ('OW_Search_driverField')
    let label = '?'

    if (driverCode) {
      const driver = this.props.drivers.find(elt => elt.drv_code === driverCode)
      label = `${driverCode} (? ?)`
      if (driver) {
        switch (typeField) {
          case 'name' :
            label = `${driver.name} ${driver.forename}`
            break
          case 'forename' :
            label = `${driver.forename} ${driver.name}`
            break
          default:
            label = `${driver.drv_code} ${driver.name}`
        }
      }
    }

    return label
  }

  print = () => {
    console.info ('Print datagrid of activities without card ')
    
    const client = this.props.clients[this.props.idClient]
    const title = (this.props.data.length > 1) ? 'Activités sans carte attribuées' : 'Activité sans carte attribuée'
    const supprClass = /class="[a-zA-Z- 0-9]+"/gm;
    let html = document.getElementById('actWithoutCard').innerHTML.replace(supprClass, '')

    toPrint(html, client.nom_client, title, this.props.logo)
  }

  export = () => {
    const data = this.props.data.map(row => {
      const begin = new Date(row.datetime)
      row.driver = this.getDriver(row.drv_code)
      row.s_reason = getReasonLabel(row.reason)
      row.s_begin = format(begin, 'Pp', {locale: localeFr})
      return row
    })
    exportAssignedActivities(data, 'activitesSansCarte')
  }
  render() {
    return (
      <Paper>
        <Toolbar className={this.props.classes.toolbar} >
          <ExportButton title="Exporter en CSV" onClick={this.export} />
          <PrintButton title="Imprimer" onClick={this.print} />
        </Toolbar>
        <TableContainer id="actWithoutCard" >
            <Table size='small' >
                <TableHead>
                <TableRow>
                    <TableCell>Date et heure</TableCell>
                    <TableCell>Activité</TableCell>
                    <TableCell>Durée</TableCell>
                    <TableCell>Distance</TableCell>
                    <TableCell>Conducteur</TableCell>
                    <TableCell>Motif</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {this.props.data.map((act, i) => {
                    const begin = new Date(act.datetime)
                    return <TableRow key={`act_${i}`} className={this.props.classes.row}>
                      <TableCell>{format(begin, 'Pp', {locale: localeFr})}</TableCell>
                      <TableCell>{act.sActivity}</TableCell>
                      <TableCell>{act.s_durat}</TableCell>
                      <TableCell>{act.distance}</TableCell>
                      <TableCell>{this.getDriver(act.drv_code)}</TableCell>
                      <TableCell>{getReasonLabel(act.reason)}</TableCell>
                    </TableRow>
                })}
                </TableBody>
            </Table>
        </TableContainer>
      </Paper>
    )
  }
}

const mapStateToProps = state => ({
  drivers: state.client.drivers,
  clients: state.identity.clients,
  idClient: state.identity.idClientEnCours,
  logo: state.client.logo,
})

const classes = theme => ({
  root: {

  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  row: {
    '&:hover': {
      backgroundColor: '#0000000A',
    }
  }
})
export default connect(mapStateToProps) (withStyles(classes) (AssignedActDataGrid))
