import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {CircularProgress, withStyles,
        Typography,
        } from '@material-ui/core'

/**
 * Affiche un bloc indiquant l'attente
 * Éventuellement, un texte complémentaire peut être ajouté
 * 
 * @param   {string}    text    texte à afficher durant l'attente
 */
class WaitBlock extends Component {

    static propTypes = {
        text: PropTypes.string
    }
    render() {
        return (
            <div className={this.props.classes.root}>
                <div><CircularProgress /></div>
                {this.props.text && <Typography variant='caption'>{this.props.text}</Typography>}
            </div>
        )
    }
}

const classes = theme => ({
    root: {
        color: theme.palette.primary.main,
        width: '100%',
        textAlign: 'center',
        marginTop: '10vh',
    }
})
export default withStyles(classes)(WaitBlock)
