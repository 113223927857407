import React, { Component } from 'react'
import CloudOnIcon from '@material-ui/icons/Cloud'

class ConnectionOnIcon extends Component {
  render() {
    return (
      <CloudOnIcon />
    )
  }
}

export default ConnectionOnIcon
