import { Tooltip, Dialog, IconButton, Button} from '@material-ui/core';
import {Alert} from '@material-ui/lab'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import ResetIcon from '@material-ui/icons/RotateLeft'
import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react';
import {connect} from 'react-redux'
import './Assistance.css';
import HelpIcon from '../icons/HelpIcon'//'@material-ui/icons/LocalHospital'
import CopyToClipboard from 'react-copy-to-clipboard'
import {detect} from 'detect-browser'

class Assistance extends Component {

    state = {
        open: false,
        width: 0,
        reset: false,
    }

    componentDidMount() {
        
        if (typeof (window.onresize) === 'function') {
            this.prevOnResize = window.onresize
        }
        window.onresize = this.onResize
    }
    changeDisplay = () => {
        this.setState({open : !this.state.open});
    } 

    copyInfo = (info) => {

        let text = 'Données techniques\n'

        for( const [key, value] of Object.entries (info)) {
            text += `${key} = ${value}\n`
        }

        return text
    }

    createMailto = (info) => {

        let text = 'Données techniques\n'

        for( const [key, value] of Object.entries (info)) {
            text += `${key} = ${value}\r`
        }

        return `mailto:supportweb@athmo.eu?subject=ow3%20-%20technical%20data&body=${encodeURIComponent(text)}` 
    }

    onResize = () => {
        this.setState({
            width: window.innerWidth,
        })
        if (this.prevOnResize) {
            this.prevOnResize()
        }
        
    }

    resetNav = () => {
        localStorage.clear()
        sessionStorage.clear()
        this.setState({
            reset: true,
        })
    }
    render() {
        const browser = detect()
        const maintenant = new Date ();
        const regexFuseau = /\((.+)\)/gm
        let eltsDate = regexFuseau.exec(maintenant.toString())

        const technicalData = {
            env: process.env.NODE_ENV,
            urlApi: process.env.REACT_APP_URL_API,
            version: `${process.env.REACT_APP_VERSION}`,
            navigator: `${browser.name} ${browser.version} on ${browser.os}`,
            plateform: navigator.platform,
            cookies: navigator.cookieEnabled ? 'true' : 'false',
            onLine: navigator.onLine ? 'true' : 'false',
            timeZone: eltsDate[1],
            language: navigator.language,
            window: `${window.innerWidth} x ${window.innerHeight}`,
            hourFormat: maintenant.toLocaleString(),
        }
        if (process.env.REACT_APP_ENV) {
            technicalData.typeVersion = process.env.REACT_APP_ENV
        } else {
            technicalData.typeVersion = ''
        }

    return (
      <>
        <Tooltip title="Informations concernant l'assistance à votre disposition">
            <IconButton onClick={this.changeDisplay} 
                    variant="text"
                    color="primary">
                <HelpIcon />
            </IconButton>
        </Tooltip>
        <Dialog open={this.state.open}
                onClose={this.changeDisplay}
                aria-labelledby="display-identity"
                aria-describedby="Affichage de l'identité"
                maxWidth="sm">
            <MuiDialogTitle >
               Assistance <em>Optimal Web</em>
               <IconButton aria-label="close" 
                        onClick={this.changeDisplay}
                        className={this.props.classes.closeButton}
                        title="Fermer">
                   <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogContent dividers>
                    <h5>Documentations</h5>
                    <div> 
                        <a href='/documentations/Manuel-Optimal-Web-v3.pdf' target='_blank'>
                            Manuel d'utilisation de la version 3 d'<em>Optimal Web</em>.
                        </a>
                    </div>
                    <h5>Contacts <em>Athmo</em></h5>
                    <div>Vous pouvez contacter <ul>
                        <li>l'assistance technique : <ul>
                            <li>par courriel <a href="mailto:supportweb@athmo.eu">supportweb@athmo.eu</a></li>
                            <li>par téléphone <a href="tel:+33483120009">+33 4 83 12 00 09</a></li>
                            </ul></li>
                        <li>notre société <ul>
                            <li>par courriel <a href="mailto:contact@athmo.eu">contact@athmo.eu</a></li>
                            <li>par téléphone <a href="tel:+33483120006">+33 4 83 12 00 06</a></li>
                            </ul></li>
                        </ul></div>
                    <div>
                        <h5>Détails techniques</h5>
                        
                        <label htmlFor="owApp">Optimal Web</label>
                        <ul id="owApp">
                           
                            <li>Environnement = {technicalData.env}</li>
                            {this.props.actualUser?.supportAthmo &&
                                <li>URL API = {technicalData.urlApi}</li>
                            }
                            <li>Version = {technicalData.version}{technicalData.typeVersion}</li>
                        </ul>

                        <label htmlFor="navApp">Navigateur</label>
                        <ul id="navApp">
                            <li>Nom = {technicalData.navigator} </li>
                            <li>Cookies = {technicalData.cookies}</li>
                            <li>En ligne = {technicalData.onLine}</li>
                            <li>Affichage = {technicalData.window}</li>
                            <li>Langue = {technicalData.language}</li>
                        </ul>                        
                        <ul>
                            <label>Heure</label>
                            <li>{technicalData.hourFormat}</li>
                            <li>Fuseau horaire : {technicalData.timeZone}</li>
                        </ul>
                    </div>
                    <div>
                        <h5>Actions</h5>
                        <div>
                            <label>Données techniques&nbsp;:</label>
                            <CopyToClipboard text={this.copyInfo(technicalData)}>
                                <Button>Copier</Button>
                            </CopyToClipboard>
                            
                            &nbsp;ou&nbsp;
                            <a href={this.createMailto(technicalData)}>les envoyer courriel</a>
                        </div>
                        <div>
                            {this.state.reset ?
                                <Alert severity="info" >Application réinitialisée</Alert> :   
                                <Button onClick={this.resetNav}
                                        variant="contained" 
                                        startIcon={<ResetIcon />}>
                                    Réinitialiser l'application dans ce navigateur
                                </Button>
                            }
                        </div>
                    </div>
                    <div>
                        <h5>Prise en main par l'<em>assistance</em></h5>
                        <div>
                            <a href="https://anydesk.com" target="_blank" rel="noopener noreferrer">
                                Anydesk
                            </a>
                        </div>
                    </div>
            </MuiDialogContent>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = state => ({
    actualUser: state.identity
})

const styles = (theme) => ({
    closeButton : {
        position : 'absolute',
        right : theme.spacing(1),
        top : theme.spacing(1),
    }
});
export default connect(mapStateToProps) (withStyles (styles) (Assistance))
