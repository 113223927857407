import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import SelectAvailableRoll from '../forms/select/AvailableRoll'
import WaitBlock from '../window/WaitBlock'
import {withStyles,
        Card, CardContent, CardHeader, CardActions, Button,
        TextField, Switch, MenuItem,
        Tooltip,
        } from '@material-ui/core'
import UserIcon from '@material-ui/icons/PersonOutline'
import {U_VISUALISEUR} from '../Constants'
import {addUser} from '../../redux/actions/ClientActions'
import EmailValidator from 'email-validator'

class AddUserForm extends Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
    }

    state = {
        user: {
            nom: '',
            prenom: '',
            email: '',
            langue: 'fr',
            role: U_VISUALISEUR,
            alerte: true,
            fonction: '',
        },
        error: {
            nom: false,
            prenom: false,
            email: false,
        },
        create: false,
    }

    onChange = (evt) => {
        const field = {}
        const fieldName= evt.target.name
        field[fieldName] = evt.target.value
        const user = Object.assign({}, this.state.user, field)
        this.setState({
            user: user,
        })
    }

    onChangeAlert = () => {
        this.setState({
            user: Object.assign({}, this.state.user, {alerte: !this.state.user.alerte})
        })
    }

    validate = () => {

        const emailValide = this.state.user.email && EmailValidator.validate(this.state.user.email)
        const error = {
            nom: !this.state.user.nom,
            prenom: !this.state.user.prenom,
            email: !emailValide,
        }
        let isNotValid = false

        this.setState({
            error: error
        })

        for (const [key, value] of Object.entries(error)) {
            isNotValid = isNotValid || value
        }

        if (!isNotValid) {
            this.props.addUser(this.state.user)
            this.setState({
                create: true,
            })
        } else {
            console.info('Invalid form', this.state.user, error)
        }
    
    }

    render() {
        
        const titleCard = <span><UserIcon />Nouvel utilisateur</span>

        return (
            <>
            <Card className={this.props.classes.root}>
                <CardHeader title={titleCard} />
                <CardContent>                    
                    { this.state.create ? 
                        <div>
                            <div>
                                <span className={this.props.classes.labelIdent}>Identifiant</span>
                                {this.state.user.email}
                            </div>
                            <div>
                                <span className={this.props.classes.labelIdent}>Mot de passe</span>
                                {this.props.password}
                            </div>
                        </div> :

                        (this.props.loading || !this.state.user) ? 
                            <WaitBlock /> :
                            <>
                                <div className={this.props.classes.input}>
                                    <span className={this.props.classes.label}>Nom</span> 
                                    <TextField required 
                                            name='nom' 
                                            value={this.state.user.nom} 
                                            error={this.state.error.nom}
                                            onChange={this.onChange}/>
                                </div>
                                <div className={this.props.classes.input}>
                                    <span className={this.props.classes.label}>Prénom</span>
                                    <TextField required 
                                            name='prenom' 
                                            value={this.state.user.prenom} 
                                            error={this.state.error.prenom}
                                            onChange={this.onChange}/>
                                </div>
                                <div className={this.props.classes.input}>
                                    <span className={this.props.classes.label}>Courriel</span>
                                    <TextField required 
                                            name='email' 
                                            type='email'
                                            value={this.state.user.email}
                                            error={this.state.error.email}
                                            onChange={this.onChange}/>
                                </div>
                                <div className={this.props.classes.input}>
                                    <span className={this.props.classes.label}>Langue</span>
                                    <TextField name='langue'
                                        required
                                        select
                                        value={this.state.user.langue}
                                        onChange={this.onChange}>
                                        <MenuItem key="fr" value='fr'>Français</MenuItem>
                                    </TextField>
                                </div>
                                <div className={this.props.classes.input}>
                                    <span className={this.props.classes.label}>Rôle</span>
                                    <SelectAvailableRoll name='role' value={this.state.user.role} onChange={this.onChange}/>
                                </div>
                                <div className={this.props.classes.input}>
                                    <span className={this.props.classes.label}>
                                        {this.state.user.alerte ? 'Est alerté' : 'Sans alerte'}</span>
                                    <Switch
                                        name='alerte'
                                        checked={this.state.user.alerte} 
                                        onChange={this.onChangeAlert}
                                        />
                                </div>
                                <div className={this.props.classes.input}>
                                    <Tooltip title="Fonction dans l'entreprise">
                                        <span className={this.props.classes.label}>
                                            Fonction
                                        </span>
                                    </Tooltip>
                                    <TextField name='fonction' value={this.state.user.fonction} onChange={this.onChange}/>
                                </div>
                            </>
                        }
                    {/* @todo implémenter gestion sites user.sites */}
                </CardContent>
                <CardActions>
                    { this.state.create ?
                        <Button onClick={this.props.onClose}>Fermer</Button> :
                        <>
                            <Button onClick={this.props.onClose}>Annuler</Button>
                            <Button onClick={this.validate}>Valider</Button>
                        </>
                    }
                </CardActions>
                
            </Card>
            </>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.data.loading,
    user: state.data.values,
    actualUser: state.identity.user,
    password: state.data.values,
})
const mapDispatchToProps = ({
    addUser,
})

const classes = {
    root: {
        minWidth: '20em',
    },
    input: {
        lineHeight: '2rem',
    },
    label: {
        display: 'inline-block',
        width: '5em',
    },
    labelIdent: {
        width: '7em',
        display: 'inline-block',
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (AddUserForm))
