import React, { Component } from 'react'
import {Tooltip} from '@material-ui/core'
import ContactlessIcon from '@material-ui/icons/Contactless'

/**
 * Source informatique embarquée (Tachytel)
 * numéro = 5
 */
class EmbeddedSource extends Component {
  render() {
    return (
      <Tooltip title="Activité sans carte">
          <ContactlessIcon />
      </Tooltip>
    )
  }
}

export default EmbeddedSource
