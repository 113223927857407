/**
 * Traduit en texte intelligible l'activité transmise via son abréviation
 * @param {string} abbr 
 */
export const sActivity = (abbr) => {

    let text = '';

    switch (abbr) {
        case 'C':
            text = 'conduite';
            break;

        case 'T':
            text = 'travail'
            break

        case 'D':
            text = 'disponibilite'
            break
        
        case 'Q':
            text = 'équipage'
            break

        case 'R':
            text = 'repos'
            break
            
        default:
            text = `??${abbr}??`
            break
    }

    return text

}