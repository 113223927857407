/**
 * Transforme un code "transport" en un libellé
 */
export const sTypeTransport = (code) => {

    let sTypeTransport = ''

    if (code) {
        
        switch (Number.parseInt(code)) {
            case 0:
                sTypeTransport = 'marchandises grand routier';
                break;
            case 1:
                sTypeTransport = 'voyageurs'
                break;
            case 2:
                sTypeTransport = 'marchandises courtes distances'
                break
            case 3:
                sTypeTransport = 'Spécifique'   // spécial Brenntag
                break
            default:
                sTypeTransport = `??${code}??`
                break
        }
    }

    return sTypeTransport
}