import * as act from '../actions/WindowAppActions'

const INITIAL_STATE = {
    title : '',
    error : '',
}

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case act.W_APP_SET_ERROR:
            return Object.assign({}, state, {
                error : action.payload
            })

        case act.W_APP_SAVE_TITLE: 
            return Object.assign({}, state, {
               title : action.payload 
            });

        default :
            return state
    }
}

export default reducer 