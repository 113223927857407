import React, { Component } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import { FormControl, InputLabel, Select, MenuItem, 
				Grid, Slider, Typography, withStyles, Button,
			CircularProgress } 
			from '@material-ui/core';
import add from 'date-fns/add'
import ActivityLabel from './graphics/ActivityLabel';
import BaseService from '../../../services/BaseService';
import { setError } from '../../redux/actions/WindowAppActions'
import {JUSTIFICATIONS} from '../Constants'

export class AddNewAct extends Component {
	static propTypes = {
		actRef: PropTypes.object.isRequired,			/* activité de référence */
		onClose: PropTypes.func.isRequired,
		selection: PropTypes.object.isRequired,		/* sélection actuelle */
		refresh: PropTypes.func.isRequired,				/* actualisation des données depuis le serveur */
	}

	state = {
		loading: false,
		disk: '',
		numero: 0,
		begin: null,
		duration: 0,
		type: '',
		newType: '',
		comment: 'MANIP',			// légendé visée = JUSTIFICATIONS.MANIP
	}

	componentDidMount() {
		this.initialize(this.props.actRef)
	}
	initialize = (actRef) => {

		const beginDate = new Date(actRef.date);
		const hour = actRef.begin.split(':');
		beginDate.setHours(hour[0]);
		beginDate.setMinutes(hour[1]);

		let valueNew = Math.floor(parseInt(actRef.duration) / 2)
		let beginNew = new Date(beginDate.valueOf())
		
		beginNew = add(beginNew, {minutes: valueNew})

		this.setState({

			/*
			 * références pour la nouvelle activité
			 */
			disk: actRef.disque,
			numero: parseInt(actRef.numero),
			begin: beginNew,
			duration: 1,
			newType: '',

			/* 
			 * informations nécessaires aux calculs
			 */
			beginValue: valueNew,
			endValue: valueNew + 1,
			beginDate: beginDate,			
			minValue: 0,
			maxValue: parseInt(actRef.duration),
			type: actRef.type,			
		})

	}
	onChange = (evt, newValue) => {

		this.setState({
			beginValue: newValue[0],
			endValue: newValue[1],
			begin: add(this.state.beginDate, {minutes: newValue[0]}),
			duration: Math.abs(newValue[1] - newValue[0]),
		})
	}

	getValueText = (value, index) => {
		
		let newDate = add(this.state.beginDate, {minutes: value})
		let label = newDate.toLocaleTimeString().substr(0, 5)

		return label
	}

	handleType = (evt) => {
		this.setState({
			newType: evt.target.value,
		})
	}

	handleComment = (evt) => {
		this.setState({
			comment: evt.target.value,
		})
	}
	validate = () => {

		if (this.state.duration > 0) {

			const data = {
				id: this.props.selection.id,
				type:	this.state.newType,
				begin:	this.state.begin.toISOString(),
				duration:	this.state.duration,
				comment:	this.state.comment,
			}

			this.saveNewActivity(data)

		}
		
	}

	saveNewActivity = (data) => {

		/*
		 * chargement en cours
		 */
		this.setState({
			loading: true,
		});

		const URL_API = process.env.REACT_APP_URL_API;
		const url = `${URL_API}/driver/activity/insert`;
		const service = new BaseService();

		service.post(url, data).then((resp) => {

			/*
			 * cas normal = aucune erreur
			 */
			if (resp === 0) {

				this.setState({
					loading: false,
				});

				/*
				 * rechargement des données
				 */
				this.props.refresh()

			} else {
				this.props.setError('Erreur produite lors de la sauvegarde.')
			}

		}).catch(error => {

			/*
			 *  notification de l'erreur
			 */
			this.props.setError(error);

			/*
			 * rechargement des données
			 */
			this.props.refresh();
		})
	}
	render() {
		const typesLabels = [
			{
				value: 'T',
				label: 'Travail'
			},
			{
				value: 'C',
				label: 'Conduite'
			},
			{
				value: 'D',
				label: 'Disponibilité'
			},
			{
				value: 'Q',
				label: 'Équipage'
			}, 
			{
				value: 'R',
				label: 'Repos'
			}
		]
		
		// le slider a été mis en dehors de la grid 
		// pour éviter l'écrasement de la couleur de fond du thumb par le span de la grid
		return (
			<>
				<Typography id="newActivity">Nouvelle activité ({this.state.duration} minute{this.state.duration > 1 ? 's' : ''})</Typography>
				<Slider
					value={[this.state.beginValue, this.state.endValue]}
					min={this.state.minValue}
					max={this.state.maxValue}
					step={1}
					valueLabelDisplay="auto"
					aria-labelledby="newActivity"
					onChange={this.onChange}
					getAriaValueText={this.getValueText}
					valueLabelFormat={this.getValueText}
					marks={true}
					color="primary"
				/>
				<Grid container direction="column" alignItems="flex-start">
					<Grid item>
						<FormControl>
							<InputLabel>Type</InputLabel>
							<Select
								value={this.state.newType}
								onChange={this.handleType}
								>
								{typesLabels.map(elt => {
									if (elt.value !== this.state.type) {
										return <MenuItem key={`itemSelect_${elt.value}`} value={elt.value}>
											<ActivityLabel type={elt.value} />
										</MenuItem>
									} else {
										return null
									}
								})}
								</Select>
						</FormControl>
					</Grid>

					<Grid item>
						<FormControl>
							<InputLabel>Motif</InputLabel>
							<Select
								value={this.state.comment}
								onChange={this.handleComment}
								>
									{Object.entries(JUSTIFICATIONS).map(elt => {
										const key = elt[0]
										const label = elt[1]
										return <MenuItem key={`just_${key}`} value={key}>{label}</MenuItem>
									})}
							</Select>
						</FormControl>
					</Grid>

					<Grid item container justify="space-around">
						{this.state.loading ?
							<Grid item>
								<CircularProgress />
							</Grid> :
							<>
								<Grid item>
									<Button onClick={this.props.onClose}>
										Annuler
									</Button>
								</Grid>
								<Grid item>
									<Button onClick={this.validate}
										disabled={this.state.duration === 0 || this.state.newType === '' || this.state.duration === parseInt(this.props.actRef.duration)}>
											Créer
									</Button>
								</Grid>
							</>
						}
					</Grid>

				</Grid>
			</>
		)
	}
}

const mapDispatchToProps = ({
	setError,
})

const classes = {
	root: {
	}, 
}
export default connect(null, mapDispatchToProps) (withStyles(classes) (AddNewAct))
