import React, { Component } from 'react'
import {SvgIcon} from '@material-ui/core'

/**
 * Source du dessin 
 * @see https://material.io/resources/icons/?search=help&icon=help_center&style=baseline
 */
class HelpIcon extends Component {
  render() {
    return (
        <SvgIcon {...this.props}>
            <g>
                <rect fill="none" height="24" width="24"/>
                <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M12.01,18 c-0.7,0-1.26-0.56-1.26-1.26c0-0.71,0.56-1.25,1.26-1.25c0.71,0,1.25,0.54,1.25,1.25C13.25,17.43,12.72,18,12.01,18z M15.02,10.6 c-0.76,1.11-1.48,1.46-1.87,2.17c-0.16,0.29-0.22,0.48-0.22,1.41h-1.82c0-0.49-0.08-1.29,0.31-1.98c0.49-0.87,1.42-1.39,1.96-2.16 c0.57-0.81,0.25-2.33-1.37-2.33c-1.06,0-1.58,0.8-1.8,1.48L8.56,8.49C9.01,7.15,10.22,6,11.99,6c1.48,0,2.49,0.67,3.01,1.52 C15.44,8.24,15.7,9.59,15.02,10.6z"/>
            </g>
        </SvgIcon>
      
    )
  }
}

export default HelpIcon
