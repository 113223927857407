import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withStyles, TableRow, TableCell,
        Collapse,
      } from '@material-ui/core'
import ButtonOw from '../../window/ButtonOw'
import OffensesGrid from './OffensesGrid'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

class Row extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    driver: PropTypes.object.isRequired,
    offenses: PropTypes.array.isRequired,
    period: PropTypes.object.isRequired,
  }

  state = {
    open: false,
  }

  setOpen = () => {
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    
    const nbOffenses = this.props.offenses.length
    const lbBt = this.state.open ? `Camoufler les ${nbOffenses} infractions` : `Voir les ${nbOffenses} infractions`
    const key = `${this.props.id}_display`
    const keyRowOffenses = `${key}_rowOffenses`

    return (
      <>
      <TableRow key={this.props.id} className={this.props.classes.root}>
        <TableCell>{this.props.driver.drv_code}</TableCell>
        <TableCell>{this.props.driver.name}</TableCell>
        <TableCell>{this.props.driver.forename}</TableCell>
        <TableCell>{this.props.driver.card_number}</TableCell>
        <TableCell>{nbOffenses}
          {nbOffenses > 0 &&
            <ButtonOw 
                title={lbBt}
                onClick={this.setOpen}>
              {this.state.open ?
                <KeyboardArrowDownIcon /> :
                <KeyboardArrowUpIcon />
              }
            </ButtonOw>
          }
        </TableCell>
      </TableRow>
      <TableRow key={keyRowOffenses}>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={5} >
          <Collapse in={this.state.open} unmountOnExit >
            <OffensesGrid key={key} offenses={this.props.offenses} period={this.props.period} driver={this.props.driver} />
          </Collapse>
        </TableCell>
      </TableRow>
      </>
    )
  }
}

const classes = theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  }
})
export default withStyles(classes) (Row)
