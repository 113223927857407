import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core'
import {DataGrid, GridToolbarContainer, 
        // GridFilterToolbarButton,
       } from '@material-ui/data-grid'
import ExportButton from '../window/ExportButton'
import PrintButton from '../window/PrintButton'
import toPrint from '../tools/toPrint'
import {exportUploads} from './exportUploads'
import {format} from 'date-fns'
import localeFr from 'date-fns/locale/fr'
class UploadsGrid extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
  }

  dateTimeFormatter = (params) => {
    const dt = new Date(params.value);
    return format(dt, 'Pp', {locale: localeFr})
  }
  columns = [
      {field: 'nom', headerName: 'Nom du fichier', type: 'string', flex: 1},
      {field: 'pointage', headerName: 'Date du téléchargement', type: 'dateTime', valueFormatter: this.dateTimeFormatter, flex: 1},
      {field: 'debut_activites', headerName: 'Début des activités', type: 'dateTime', valueFormatter: this.dateTimeFormatter, flex: 1},
      {field: 'fin_activites', headerName: 'Fin des activités', type: 'dateTime', valueFormatter: this.dateTimeFormatter, flex: 1}
  ]

  
  print = () => {
    console.info ('Print datagrid of uploaded files ')
    
    const client = this.props.clients[this.props.idClient]
    let html = '<table>'
    
    html += `<caption>Fichiers téléchargés</caption>`
    html += '<thead><th>Nom</th><th>Date du téléchargement</th><th>Début des activités</th><th>Fin des activités</th></thead>'
    html += '<tbody>'
    html += this.props.data.reduce((txt, value) => {
      let row = `<td>${value.nom}</td>`
      row += `<td>${this.dateTimeFormatter({value: value.pointage})}</td>`
      row += `<td>${this.dateTimeFormatter({value: value.debut_activites})}</td>`
      row += `<td>${this.dateTimeFormatter({value: value.fin_activites})}</td>`
      
      return `${txt}<tr>${row}</tr>`
    }, '')
    html += '</tbody></table>'

    toPrint(html, client.nom_client, this.props.title, this.props.logo)
  }
    
  export = () => {
    exportUploads(this.props.data, 'uploads')
  }
    
  myToolBar = () => {
    return (
      <GridToolbarContainer className={this.props.classes.toolbar}>
        {/* <GridFilterToolbarButton /> */}
        <ExportButton title="Exporter en CSV" onClick={this.export} />
        <PrintButton title="Imprimer" onClick={this.print} />
      </GridToolbarContainer>
    )
  }
  render() {

    return (
      <div style={{display: 'flex', height:'90%'}}>
        <div style={{flexGrow: 1}}>
            <DataGrid
                columns={this.columns}
                rows={this.props.data}
                components={{
                    Toolbar: this.myToolBar
                }}
                />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  clients: state.identity.clients,
  idClient: state.identity.idClientEnCours,
  logo: state.client.logo,
})

const classes = theme => ({
  root: {
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'right',
  }
})
export default connect(mapStateToProps) (withStyles(classes) (UploadsGrid))
