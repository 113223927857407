import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PaperDiscSource from './sources/PaperDiscSource'
import CardSource from './sources/CardSource'
import VehicleSource from './sources/VehicleSource'
import EmbeddedSource from './sources/EmbeddedSource'
import ManualSource from './sources/ManualSource'

class Source extends Component {
  static propTypes = {
    idSource: PropTypes.number.isRequired,
  }

  render() {

    let elt = '?'

    switch (this.props.idSource) {

        /*
        * Disque papier
        */
        case 0:
            elt = <PaperDiscSource />
            break

        /*
        * Carte conducteur
        */
        case 2:
            elt = <CardSource />
            break

        /*
        * informatique embarquée
        */
        case 5:
            elt = <EmbeddedSource />
            break

        /*
        * Activité sans carte issue du chronotachygraphe
        */
        case 8:
            elt = <VehicleSource />
            break

        /*
         * Activité entrée manuellement
        */
        case 9:
            elt = <ManualSource />
            break

        default:
            elt = `??${elt}??`     // @todo contacter support
            break

    }
    
    return elt
  }

}

export default Source
