import React, { Component } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {withStyles,
        Card, CardHeader, CardContent, CardActions,
        Button, Switch,
        } from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import DatePicker from '../forms/date/DatePickerFr'
import { DATE_INFINIE } from '../Constants'
import {endOfMonth} from 'date-fns'

class EditDriverContractForm extends Component {
  static propTypes = {
    contract: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    modeAdd: PropTypes.bool,
    firstDayForNewContract: PropTypes.objectOf(Date)
  }

  state = {
      contract: null,
      overlap: false,
  }

  componentDidMount() {
      this.initValues()
  }

  initValues = () => {
    let contract

    if (this.props.modeAdd) {
        contract = {
            debut: this.props.firstDayForNewContract,
            fin: null,
            interimaire: false
        }
    } else {
        contract = Object.assign({}, this.props.contract)
    }
    this.setState({
        contract: contract,
    })  
    
  }

  componentDidUpdate(prevProps, prevState) {
      if (prevProps.modeAdd !== this.props.modeAdd) {
        this.initValues()
      }
  }

  checkContractsOverlap = () => {
    let overlap = false
    this.props.contracts.forEach(aContract => {
        if ( (this.state.contract.id_contrat !== aContract.id_contrat) /* pas de comparaison pour le même contrat */
                && (aContract.id_societe === this.props.idCompany)
                && (
                    ( (aContract.fin !== null)
                        && (this.state.contract.debut <= aContract.fin)
                        && (this.state.contract.fin >= aContract.debut)
                    ) 
                    || ( (aContract.fin === null)
                        && (this.state.contract.fin >= aContract.debut)
                        )
                    || ( (this.state.contract.fin === null) 
                        && (this.state.contract.debut <= aContract.fin)
                    )
                    || ( (this.state.contract.fin === null)
                        && (aContract.fin === null)
                    )
                )
                 
        ) {
            overlap = true
        }
    })
    this.setState({
        overlap: overlap,
    })
  }
  onChangeDate = (field, newDate) => {
      const contract = this.state.contract

      if ( (newDate !== null) && (field === 'fin')) {
          newDate.setHours(23, 59, 59)
      }

      contract[field] = newDate
      this.setState({
          contract: contract
      })
      this.checkContractsOverlap()
  }

  onChange = (evt) => {
      const contract = this.state.contract
      contract.interimaire = evt.target.checked
      
      this.setState({
          contract: contract
      })
  }

  reset = () => {
      this.initValues()
  }

  validate = () => {
    this.props.onClose(this.state.contract)
  }
  render() {
   
    return (
      <Card className={this.props.classes.root} >
          <CardHeader title={this.props.modeAdd ? "Création d'un contrat" : "Édition d'un contrat"} />
          <CardContent>
            {this.state.contract && <>
                {this.state.overlap && 
                    <Alert severity="warning" >Ce contrat chevauche un autre contrat dans l'entreprise !</Alert>
                }
                <div>
                    <span className={this.props.classes.label}>Début du contrat</span>
                    <DatePicker 
                        value={this.state.contract.debut} 
                        onChange={value => this.onChangeDate('debut', value)}
                        autoOk
                        minDate={this.props.supportAthmo ? this.props.management.begin : this.props.firstDayForNewContract}
                        maxDate={this.state.contract.fin ? this.state.contract.fin : DATE_INFINIE}
                        />

                </div>
                <div>
                    <span className={this.props.classes.label}>Fin du contrat</span>
                    <DatePicker 
                        value={this.state.contract.fin} 
                        initialFocusedDate={endOfMonth(new Date())}
                        onChange={value => this.onChangeDate('fin', value)}
                        autoOk
                        minDate={this.state.contract.debut}
                        maxDate={DATE_INFINIE}
                        />
                    <Button 
                        title='Pas de fin de contrat précisée.'
                        onClick={() => this.onChangeDate('fin', null)}>&infin;</Button>
                </div>
                <div>
                    <span className={this.props.classes.label}>{this.state.contract.interimaire ? 'Est intérimaire' : 'CDD/CDI'}</span>
                    <Switch checked={this.state.contract.interimaire}
                            onChange={this.onChange}
                    />
                </div>
            </>}
          </CardContent>
          <CardActions className={this.props.classes.cardActions}>
            <Button onClick={() => this.props.onClose()}>Annuler</Button>
            <Button onClick={this.reset} >Réinitialiser</Button>
            <Button onClick={this.validate} 
                    disabled={this.state.overlap}>
                Valider
            </Button>
          </CardActions>
      </Card>
    )
  }
}

const mapStateToProps = state => ({
    supportAthmo: state.identity.user.supportAthmo,
    management: state.client.informations.ow.limits,
    contracts: state.data.values.contrats,
    idCompany: state.client.informations.ow.company.id,
})

const classes = theme => ({
    root: {
        marginBlock: '1em',
    },
    label: {
        display: 'inline-block',
        minWidth: '10em',
    },
    cardActions: {
        justifyContent: 'space-around',
    }
})
export default connect(mapStateToProps) (withStyles(classes) (EditDriverContractForm))
