import React, { Component } from 'react'
import CloudOffIcon from '@material-ui/icons/CloudOff'

class ConnectionOffIcon extends Component {
  render() {
    return (
      <CloudOffIcon />
    )
  }
}

export default ConnectionOffIcon
