import { processRow, exportCsvFile } from '../tools/exportFunctions';
export function exportKilometers(data, name) {

	let textExport = processRow([
		'Journée',
		'Date',
		'Kilomètres',
	]);
	textExport += data.reduce((text, row) => {

		const txtRow = processRow([
			row.day,
			row.date,
			row.km,
		]);
		return `${text}${txtRow}`;
	}, '');


	exportCsvFile(textExport, `${name}.csv`);
}