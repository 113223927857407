import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import {connect } from 'react-redux'
import {Container, TableContainer, Table, TableHead, TableRow, 
        TableCell, TableBody, TableSortLabel, Paper, Toolbar, 
        InputBase,
        } from '@material-ui/core'
import {withRouter} from 'react-router'
import {getClients, choisirClient} from '../../core/redux/actions/IdentityActions'
import {setTitle} from '../../core/redux/actions/WindowAppActions'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import HelpButton from '../../core/components/window/HelpButton'
import HelpCard from '../../core/components/home/HelpChoixEntreprise'
import ButtonOw from '../../core/components/window/ButtonOw'
import './ChoixEntreprise.css'

class ChoixEntreprise extends Component {

    /**
     * Champs avec un filtre
     */
    headerCells = [
        {
            field : 'code',
            label : 'Code compta.', 
        },
        {
            field : 'nom_client',
            label : 'Nom',
        },
        {
            field : 'code_societe',
            label : 'Code',
        },
        {
            field : 'holding',
            label : 'Groupe'
        }
    ]

    /**
     * Liste des entreprises (stockage temporaire des entreprises)
     */
    valuesRef = []

    state = {
        choice: false,
        displayedHelp : false,
        initialsValues: [],
        values : [],
        order : 'desc',
        columnSort : 'code',
        filtersEnable : true,
        filters : {
            code: '',
            nom_client: '',
            code_societe: '',
            holding: '',
            sous_contrat: true,
        },
        columnContract: true,
    }

    constructor () {
        super ();
        this.onClickRow = this.onClickRow.bind (this)
        this.handlerSort = this.handlerSort.bind (this)
                
    }

    componentDidMount() {

        /*
         * Contrôle accès
         */
        if (!this.props.isConnected) {
            console.error('Not connected')
            this.props.history.push ('/')
        }


        this.props.setTitle ("Choix de l'entreprise")

        if (!this.props.clients) {
            
            this.props.getClients ()

        } else {
            this.hydrate ()
        }

    }


    /**
     * Travail sur une copie des données
     */
    hydrate = () => {
        
        let clients = JSON.parse(JSON.stringify(this.props.clients))
        if (clients.length) {
            delete clients.length
        }
        let values =  Object.values (clients)
        
        /*
         * uniquement les clients avec un contrat Optimal Web en cours
         */
        values = values.filter(elt => elt.sous_contrat)

        /*
         * si aucun client, affichage de tous les clients
         */
        if (values.length === 0) {
            values = Object.values(clients)
            this.setState({
                columnContract: false,
                filtersEnable: false,
            })
        }

        /*
         * uniquement les clients avec une carte entreprise
         */
        values = values.filter(elt => elt.code_societe)

        /*
         * tri par défaut
         */
        this.triAscendant ('code', values)

        this.setState ({
            order : 'asc',
            columnSort : 'code',
            values : values,
            initialsValues : values.concat([]),
        })
        
        /*
         * si une seule société disponible, 
         * redirection
         */
        if (values.length === 1) {
            this.setState({
                choice: true,
            })
            const idClient = values.pop().id_client
            console.info('== selection client ==', idClient)
            this.props.choisirClient (idClient)
        }
    }

    handlerSort = (property) => {
        
        /*
         * inversion de l'ordre de tri de la propriété précédemment sélectionnée
         */
        if (this.state.columnSort === property) {            
            
            this.setState ({
                order : (this.state.order === 'asc') ? 'desc' : 'asc',
                values : this.state.values.reverse ()
            })

        /*
         * Changement de propriété de tri
         */
        } else {
            this.triAscendant (property, this.state.values)
            this.setState ({            
                order : 'asc',
                columnSort : property,
            })
        }
        
    }

    triAscendant = (property, values) => {

        values.sort ((a, b) => {
            
            if (a[property] < b[property]) {
                return -1
            } else if (a[property] > b[property]) {
                return 1
            } else {
                return 0
            }
        })
    }

    handlerFilters = () => {

        this.setState ({
                filtersEnable : !this.state.filtersEnable,                
        })
    }

    componentDidUpdate(prevProps, prevState) {
                 
        /*
         * quand un client a été choisi, redirection vers la page de gestion
         */
        if ( (this.state.choice)
                && (!this.props.clientLoading)
                && (this.props.idClientEnCours > 0)
         ) {
            /*
            * redirection vers la page demandée initialement
            */
            if ( (this.props.history.action === 'REPLACE')
                    && (this.props.history.location.state.from)
            ) {
                this.props.history.push('' + this.props.history.location.state.from);
            } else {
                this.props.history.push ('/');
            }

        }

        /*
         * désactivation des filtres
         */
        if (prevState.filtersEnable && !this.state.filtersEnable) {

            /*
             * récupération du jeu de données initial
             */
            this.hydrate ()

            /*
             * Réinitialisation des filtres
             */
            let filters = {};
            for (let field in this.state.filters) {
                if (typeof(this.state.filters[field]) === 'string') {
                    filters[field] = ''
                } else {
                    filters[field] =  this.state.filters[field]
                }
            }
            this.setState({
                filters: filters
            })
 
         }
    }
    onClickRow (id, event) {
        event.preventDefault ();
        this.setState({
            choice: true
        })

        let infosClient =  this.props.clients[id]
        console.info('== Choix client ==', infosClient.code, infosClient.nom_client)
        
        this.props.choisirClient (id)
    }

    handlerSearch = (field, evt) => {
        
        evt.preventDefault ()
        let filters = this.state.filters
        filters[field] = evt.target.value
               
        // let values = this.valuesRef.concat([])
        let values = JSON.parse(JSON.stringify(this.state.initialsValues))

        for (let fieldName in filters) {
            let search = filters[fieldName]
            
            if (search && typeof(search) === 'string' && search !== '') {
                
                values = values.filter (elt => {
                    return elt[fieldName].includes(search)
                })
                
            }
        }
                   
        this.setState ({
            filters : filters,
            values : values
        })
    }

    displayHelp = () => {
        this.setState ({
            displayedHelp : !this.state.displayedHelp
        })
    }
    render() {
        if ( (!this.props.clients) 
                || this.props.identityLoading
                || this.props.clientLoading
                ) {
            return (<Container><div>Chargement en cours</div></Container>)
        } else {
                    
            return (
                <Container>
                    <Paper>
                    <TableContainer component={Paper} className="container">
                        <Toolbar className="contenuToolbar">
                            <ButtonOw title="Filtres"
                                    onClick={this.handlerFilters}>
                                <FilterListIcon />
                            </ButtonOw>
                            <HelpButton>
                                <HelpCard />
                            </HelpButton>
                        </Toolbar>

                        <Table stickyHeader aria-label="choix entreprise">
                            <TableHead>
                                <TableRow >
                                    {
                                        this.headerCells.map ( (cell) => {
                                            return (
                                                <TableCell key={cell.field}
                                                        sortDirection={this.state.order}
                                                        align='left'>
                                                    <div>
                                                        <TableSortLabel active={this.state.columnSort === cell.field} 
                                                                direction={this.state.order}
                                                                hideSortIcon
                                                                onClick={() => {this.handlerSort (cell.field)}}
                                                                >
                                                            {cell.label}       
                                                        </TableSortLabel>
                                                    </div>
                                                    {this.state.filtersEnable && (
                                                        <div className="outlined">
                                                            <SearchIcon />
                                                            <InputBase 
                                                                value={this.state.filters[cell.field]}
                                                                placeholder="Filtrer..."
                                                                onChange={(event) => {this.handlerSearch (cell.field, event)}} />
                                                        </div>
                                                    )}  
                                                </TableCell>
                                            )
                                        })
                                    }
                                    {this.state.columnContract &&
                                        <TableCell align="center">Contrat <em>Optimal Web</em></TableCell>
                                    }
                                </TableRow>
                                
                            </TableHead>
                            <TableBody>
                            {this.state.values.length === 0 ?
                                <TableRow key='vide'>
                                    <TableCell>Aucun choix possible.</TableCell>
                                </TableRow> :
                                this.state.values.map( (client) => (
                                    <TableRow key={client.id_client} 
                                            hover 
                                            className="selectionEntreprise"
                                            onClick={(evt) => {this.onClickRow (client.id_client, evt)}}>
                                        <TableCell component="th" scope="row">
                                            {client.code}
                                        </TableCell>
                                        <TableCell align="left">{client.nom_client}</TableCell>
                                        <TableCell align="left">{client.code_societe}</TableCell>
                                        <TableCell align="left">{client.holding}</TableCell>
                                        {this.state.columnContract &&
                                            <TableCell align="center">{(client.sous_contrat) && <CheckCircleIcon />}</TableCell>
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Paper>
                </Container>
            
            )
        }

    }

}

const mapStateToProps = state => ({
    isConnected : state.identity.isConnected,
    idClientEnCours : state.identity.idClientEnCours,
    clients : state.identity.clients,
    identityLoading : state.identity.loading,
    clientLoading : state.client.loading,
})

const mapDispatchToProps = dispatch => {
    return {
        getClients : bindActionCreators (getClients, dispatch),
        choisirClient : bindActionCreators (choisirClient, dispatch),
        setTitle : bindActionCreators (setTitle, dispatch),
    }
}
export default connect (mapStateToProps, mapDispatchToProps) (withRouter (ChoixEntreprise))