import * as act from '../actions/ClientActions'

const INITIAL_STATE = {
    loading : false,
    updating: false,
    informations: {
        ow: {
            limits: {
                begin: null,
                end: null,
            },
            company: {
                id: 0,
            }
        },
    },
    services : null,
    drivers : [],
    vehicles : [],
    users: [],
    downloadable: false,
    parameters: {
        driver: null,
        vehicle: null,
    },
    logo: '',
    groups: null,               // enabled: false, configured: false,
}

const clientReducer = (state = INITIAL_STATE, action) => {

    let field

    switch (action.type) {

        case act.CLIENT_UPDATING:
            return Object.assign({}, state, {
                updating: action.payload,
            })

        /*
         * Informations sur l'entreprise
         */
        case act.CLIENT_SAVE_INFORMATIONS:
            let formula = action.payload.formules.map (formule => {
                return {
                    label:  formule.libelle,
                    begin:  new Date(formule.debut),
                    end:    new Date(formule.fin)
                }
            })
            return Object.assign({}, state, {
                informations: {
                    address: {
                        line1: action.payload.adresse.ligne1,
                        line2: action.payload.adresse.ligne2,
                        zipCode: action.payload.adresse.codePostal,
                        city: action.payload.adresse.ville,
                        country: action.payload.adresse.pays,
                    },
                    dematerialized: action.payload.dematerialise,
                    invoicesRecipients: action.payload.factures.destinataires,
                    formula: formula,
                    ow: {
                        creation: new Date(action.payload.ow.creation),
                        limits: {
                            begin: new Date(action.payload.ow.limitesGestion.debut),
                            end: new Date(action.payload.ow.limitesGestion.fin),
                        },
                        actuals: {
                            drivers: action.payload.ow.enCours.conducteurs,
                            vehicles: action.payload.ow.enCours.vehicules,
                        },
                        company: {
                            id: action.payload.ow.societe.id,
                        }
                        
                    },
                }                
            })

        /*
         * Liste des services 
         */
        case act.CLIENT_SET_SERVICES:
            return Object.assign ({}, state, {
                services : action.payload
            })
        /*
         * liste des conducteurs
         */
        case act.CLIENT_SET_DRIVERS:
            return Object.assign ({}, state, {
                drivers : action.payload
            })

        /**
         * Ajout d'un conducteur à la liste locale
         */
        case act.CLIENT_ADD_DRIVER:
            const drivers = state.drivers
            drivers.push({
                id: action.payload.id,
                name: action.payload.nom,
                forename: action.payload.prenom,
                drv_code: action.payload.code,
                card_number: action.payload.carte,
            })
            return Object.assign({}, state, {
                drivers: drivers
            })

        /**
         * liste des véhicules
         */
        case act.CLIENT_SET_VEHICLES:
            return Object.assign ({}, state, {
                vehicles : action.payload
            })

        /**
         * Ajout d'un véhicule à la liste locale
         */
        case act.CLIENT_ADD_VEHICLE:
            const vehicles = state.vehicles
            vehicles.push({
                id: action.payload.id,
                veh_reg_no: action.payload.immatriculation,
                veh_code: action.payload.code,
            })
            return Object.assign({}, state, {
                vehicles: vehicles,
                updating: false,
            })

        /**
         * Liste des utilisateurs gérés
         */
        case act.CLIENT_SET_USERS:
            return Object.assign ({}, state, {
                users: action.payload,
            })

        case act.CLIENT_UPDATE_USER:
            field = action.payload.field
            let updatedUser = state.users.find(user => user.id === action.payload.id)
            updatedUser[field] = action.payload.value
            return Object.assign({}, state, {
                users:  state.users,
            })

        case act.CLIENT_ADD_USER:
            const users  = state.users
            users.push(action.payload)
            return Object.assign({}, state, { 
                users: users
            })

        /**
         * 
         */
        case act.CLIENT_SET_AUTHORIZATION_DOWNLOAD:
            return Object.assign({}, state,  {
                downloadable: Boolean(action.payload)
            })

        /**
         * Mise à jour d'un champ d'un conducteur
         */
        case act.CLIENT_UPDATE_DRIVER:
            field = action.payload.field
            let updatedDriver = state.drivers.find(driver => driver.id === action.payload.id)
            updatedDriver[field] = action.payload.value
            return Object.assign({}, state, {
                drivers:  state.drivers,
            })

        /**
         * Mise à jour d'un champ d'un véhicule
         */
        case act.CLIENT_UPDATE_VEHICLE:
            field = action.payload.field
            let updatedVehicle = state.vehicles.find(vehicle => vehicle.id === action.payload.id)
            updatedVehicle[field] = action.payload.value
            return Object.assign({}, state, {
                vehicles:  state.vehicles,
            })

        case act.CLIENT_SET_PARAMETERS:
            let params = Object.assign({}, state.parameters, {
                [action.payload.type]: action.payload.data
            })
            return Object.assign({}, state, {
                parameters: params
            })

        case act.CLIENT_SET_VALIDATION:

            return Object.assign({}, state, {
                downloadable: Boolean(action.payload),
            }) 
            
        case act.CLIENT_SET_LOGO:
            return Object.assign({}, state, {
                logo: action.payload,
            })

        case act.CLIENT_SET_GROUPS:
            return Object.assign({}, state, {
                groups: action.payload,
            })
        
        case act.CLIENT_ENABLE_GROUPS:
            const groups = Object.assign({}, state.groups, {
                enabled: action.payload,
            })
            return Object.assign({}, state, {
                groups: groups,
            })

        default :
            return state;

    }

} 

export default clientReducer