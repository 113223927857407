import React, { Component } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {withStyles, Paper, Toolbar,
        TableContainer, Table, TableHead, TableBody,
        TableRow, TableCell,
        } from '@material-ui/core'
import MonthlyRow from './MonthlyRow'
import WarningIcon from '../icons/WarningIcon'
import PrintButton from '../window/PrintButton'
import toPrint from '../tools/toPrint'
import ExportButton from '../window/ExportButton'
import {exportMonthsActivities} from './exportActivities'
class MonthlyDataGrid extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    dateRef: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    onRowClick: PropTypes.func.isRequired,
  }

  state = {
      buttons: [],
  }
  componentDidMount () {
    const printButton = <PrintButton 
            title="Imprimer" 
            onClick={this.print} />
    const exportButton = <ExportButton 
                title="Exporter en CSV"
                onClick={this.export} />
    this.setState({
        buttons: this.state.buttons.concat([printButton, exportButton], this.props.options.buttons)
    })
  }

  componentDidUpdate(prevProps, prevState) {

    if ( (prevProps.data !== this.props.data)
            && (this.props.data.length > 0)
    ) {
        const totalRow = this.props.data.find(elt => elt.index === 'month')
        for (const [key, value] of Object.entries(totalRow)) {
            if (value === '??:??') {
               totalRow[key] = <WarningIcon />
            }
        }
    }
  }

  print = () => {
    
    console.info ('Print datagrid', this.props.options.gridName)
    
    const client = this.props.clients[this.props.idClient]
    const supprClass = /class="[a-zA-Z- 0-9]+"/gm;
    let html = document.getElementById('tableAct').innerHTML.replace(supprClass, '')
    
    toPrint(html, client.nom_client, this.props.options.gridName, this.props.logo)

  }

  export = () => {
    exportMonthsActivities(this.props.data, this.props.options.gridName)
  }
  render() {
   
    return (
        <Paper>
            {this.state.buttons.length > 0 &&
                <Toolbar className={this.props.classes.toolbar}>
                    {this.state.buttons.map( (bt, i) => {
                        return <div key={`bt${i}`}>{bt}</div>
                    })}
                </Toolbar>
            }
            <TableContainer id="tableAct">
                <Table
                    size="small">
                    <TableHead>
                        {
                            this.props.options.informations.length > 0 && 
                                this.props.options.informations.map( (info, i) => 
                                    (<TableRow key={`informations${i}`} >
                                        <TableCell key={`informations${i}_info`} colSpan={12}>
                                            {info}
                                        </TableCell>
                                    </TableRow>)
                                )                     
                        }
                        <TableRow key="headers">
                            <TableCell key='d'>Début</TableCell>
                            <TableCell>Fin</TableCell>
                            <TableCell>Conduite</TableCell>
                            <TableCell>Travail</TableCell>
                            <TableCell>Disponibilité</TableCell>
                            <TableCell>Équipage</TableCell>
                            <TableCell>CDTQ</TableCell>
                            <TableCell>Amplitude</TableCell>
                            <TableCell>Repos</TableCell>
                            <TableCell>Km</TableCell>
                            <TableCell>Vitesse</TableCell>
                            <TableCell>Nuit</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.data.length > 0 ?
                            this.props.data.map( (row, i) => <MonthlyRow 
                                                                key={`row_${i}`}
                                                                row={row} 
                                                                dateRef={this.props.dateRef}
                                                                onRowClick={this.props.onRowClick} />) :
                            <TableRow key="noData">
                                <TableCell key="cellNoData"
                                        className={this.props.classes.noData}
                                        colSpan={12}>
                                    <WarningIcon />&nbsp;Aucun enregistrement présent
                                </TableCell>
                            </TableRow>
                            }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
  }
}

const mapStateToProps = state => ({
    clients: state.identity.clients,
    idClient: state.identity.idClientEnCours,
    logo: state.client.logo,
})

const classes = {
    root: {

    },
    gridNameLine: {
        display: 'none',       
    },
    titleGrid: {
        textAlign: 'center',
        fontSize: 'large',
    },
    noData: {
        textAlign: 'center',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
    }
}

export default connect(mapStateToProps) (withStyles(classes) (MonthlyDataGrid))