import {URL_API, API, METHOD_GET, METHOD_POST, 
        DATA_SET_ERROR, SET_DATA,
        setData, setDataMonthCalendar, setDataYearCalendar, 
        setDataDailyReport, setDataMonthlyReport,
        resetData, setDataError,
        setDataAttributions, 
        prepareDataBeforeAttributions, updateDataAfterAttributions, METHOD_PUT,
        } from './DataActions'
import {setClientUpdating, CLIENT_ADD_VEHICLE, CLIENT_ADD_DRIVER,
        CLIENT_UPDATE_DRIVER, CLIENT_UPDATE_VEHICLE,
        } from './ClientActions'
import {DRIVER_TYPE, ZONE_PUBLIQUE, ZONE_PRIVEE} from '../../components/Constants'
import {stringToDate, dateToString} from './adapters/dateAdapters'

export const GET_UPLOADS = 'ELEMENTS_GET_UPLOADS'
export const getDriver = (id) => ({
    type: API,
    payload: {
        url: `${URL_API}/driver/${id}`,
        method: METHOD_GET,
        params: null,
        cancelable: true,
        onSuccess: adaptersGetDrivers,
    }
})

const adaptersGetDrivers = (data) => {
    
    data.contrats.forEach( contrat => {
        contrat.debut = stringToDate(contrat.debut)
        contrat.fin = stringToDate(contrat.fin)
    })
    data.dateNaissance = stringToDate(data.naissance)
    
    return {
        type: SET_DATA,
        payload: data
    }
}

export const getVehicle = (id) => ({
    type: API,
    payload: {
        url: `${URL_API}/vehicle/${id}`,
        method: METHOD_GET,
        params: null,
        cancelable: true,
        onSuccess: adaptersGetVehicle,
    }
})

const adaptersGetVehicle = (data) => {
    
    data.utilisations.forEach( use => {
        use.debut = stringToDate(use.debut)
        use.fin = stringToDate(use.fin)
        use.type_zone = use.type_zone === 'publique' ? ZONE_PUBLIQUE : ZONE_PRIVEE
    })
    
    return {
        type: SET_DATA,
        payload: data
    }
}
export const checkVehicle = (number) => ({
    type: API,
    payload: {
        url: `${URL_API}/vehicle/exist/${number}`,
        method: METHOD_GET,
        cancelable: true,
        onSuccess: setDataError
    }
})
export const addVehicle = (vehicle) => dispatch => {
    dispatch(setClientUpdating(true))
    dispatch(addVehicleToAPI(vehicle))
}

const addVehicleLocal = (id, vehicle) => ({
    type: CLIENT_ADD_VEHICLE,
    payload: {
        id: id,
        immatriculation: vehicle.immatriculation,
        code: vehicle.code,
    }
})

const addVehicleToAPI = (vehicle) => ({
    type: API,
    payload: {
        url: `${URL_API}/vehicle/add`,
        method: METHOD_POST,
        params: {
            immatriculation: vehicle.immatriculation,
            serie: vehicle.serie,
            numerique: vehicle.numerique,
            debut: vehicle.debut.toISOString(),
            code: vehicle.code,
        },
        cancelable: false,
        loadingEnd: false,
        onSuccess: addVehicleLocal,
        paramOnSuccess: vehicle,
    }
})

export const updateUseToAPI = (id, contract) => {

    const sBegin = dateToString(contract.debut) 
    const sEnd = dateToString(contract.fin)
    let sZone = contract.type_zone === ZONE_PUBLIQUE ? 'publique' : 'privee'
    const now = new Date()
    const params = {
        id: id,
        field: 'actif',
        value: (contract.debut <= now && (contract.fin === null || contract.fin >= now))
    }
    return {
        type: API,
        payload: {
            url: `${URL_API}/vehicle/${id}/use/${contract.id_utilisation_vehicule}/${sBegin}/${sEnd}/${sZone}`,
            method: METHOD_PUT,
            onSuccess: updateLocalUse,
            paramOnSuccess: params,
        }
    }
}

export const updateLocalUse = (apiReturn, params) => dispatch => {
    dispatch(updateLocalVehicle(params.id, params.field, params.value))
}
export const verifyContractDriver = (card, date) => {

    const debut = date.toISOString()
    return {
        type: API,
        payload: {
            url: `${URL_API}/driver/exist/${debut}/${card}`,
            method: METHOD_GET,
            params: null,
            cancelable: true,
            onSuccess: checkDuplicate,
        }
    }
}

const checkDuplicate = (exist) => ({
    type: DATA_SET_ERROR,
    payload: exist
})

export const addDriver = driver => dispatch => {
    dispatch(addDriverToAPI(driver))
}

const addDriverLocal = (id, driver) => ({
    type: CLIENT_ADD_DRIVER,
    payload: {
        id: id,
        nom: driver.nom,
        prenom: driver.prenom,
        code: driver.code,
        carte: driver.carte
    }
})

const addDriverToAPI = driver => ({
    type: API,
    payload: {
        url: `${URL_API}/driver/add`,
        method: METHOD_POST,
        params: {
            nom: driver.nom,
            prenom: driver.prenom,
            avecCarte: driver.avecCarte,
            carte: driver.carte,
            code: driver.code,
            debut: driver.debut.toISOString(),
            interimaire: driver.interimaire,
            naissance: driver.naissance,
        },
        onSuccess: addDriverLocal,
        paramOnSuccess: driver,
    }
})

export const updateContractDriverToAPI = (id, contract) => {

    const sBegin = dateToString(contract.debut) 
    const sEnd = dateToString(contract.fin)
    const iInterim = (contract.interimaire) ? 1 : 0
    const now = new Date()

    const params = {
        id: id,
        field: 'actif',
        value: (contract.debut <= now && (contract.fin === null || contract.fin >= now))
    }

    return {
        type: API,
        payload: {
            url: `${URL_API}/driver/${id}/contract/${contract.id_contrat}/${iInterim}/${sBegin}/${sEnd}`,
            method: METHOD_PUT,
            onSuccess: updateLocalContractDriver,
            paramOnSuccess: params,
        }
    }
}

export const updateLocalContractDriver = (APIreturn, params) => dispatch => {

    dispatch(updateLocalDriver(params.id, params.field, params.value))

}

export const updateDriver = (id, changes) => dispatch => {

    dispatch(updateDriverToAPI(id, changes))
    if (changes.nom) {
        dispatch(updateLocalDriver(id, 'name', changes.nom))
    }
    if (changes.prenom) {
        dispatch(updateLocalDriver(id, 'forename', changes.prenom))
    }
    
    
}

const updateDriverToAPI = (id, changes) => ({
    type: API,
    payload: {
        url: `${URL_API}/driver/${id}`,
        method: METHOD_PUT,
        params: changes,
    }
})

/**
 * Charge la liste des fichiers téléchargés durant une période
 * @param {string} type 
 * @param {int} id 
 * @param {Date} begin 
 * @param {Date} end 
 */
export const getUploads = (type, id, begin, end) => {

    const sBegin = begin.toISOString ()
    const sEnd = end.toISOString ()
    
    return {
        type: API,
        payload: {
            url: `${URL_API}/${type}/${id}/${sBegin}/${sEnd}`,
            method: METHOD_GET,
            params: null,
            cancelable: true,
            onSuccess: setData,
        }
    }

}

/**
 * Liste des événements du mois 
 * @param {string} type 
 * @param {Date} month 
 */
export const getMonthUploads = (type, month) => {

    const sYear = month.getFullYear().toString ()
    let sMonth = (month.getMonth() + 1).toString ()
    const typeUsed = (type === DRIVER_TYPE) ? 'drivers' : 'vehicles'

    sMonth = sMonth.padStart(2, '0')

    return {
        type: API,
        payload: {
            url: `${URL_API}/${typeUsed}/${sYear}/${sMonth}`,
            method: METHOD_GET,
            params: null,
            loadingEnd: false,
            onSuccess: setDataMonthCalendar,
        }
    }
}

/**
 * Liste des événements de l'année
 *
 * @param {string}  type
 * @param {number}  id
 * @param {Date}    year 
 */
export const getYearUploads = (type, id, year) => {

    const sYear = year.getFullYear().toString ()

    return {
        type: API,
        payload: {
            url: `${URL_API}/${type}/${id}/${sYear}`,
            method: METHOD_GET,
            params: null,
            cancelable: true,
            loadingEnd: false,
            onSuccess: setDataYearCalendar,
        }
    }
}

/**
 * Récupère le rapport des activités du jour
 * @param {string} type 
 * @param {int} id 
 * @param {object} dateRef  Date 
 */
export const getDailyReport = (type, id, dateRef) => {

    const sDateRef = dateRef.toISOString()
    return {
        type: API,
        payload: {
            url: `${URL_API}/${type}/${id}/daily/${sDateRef}`,
            method: METHOD_GET,
            params: null,
            cancelable: true,
            loadingEnd: false,                  /* report de l'information de chargement après les adaptatations */
            onSuccess: setDataDailyReport,
        }
    }

}

/**
 * Récupère les activités d'un mois
 * @param {string} type 
 * @param {int} id 
 * @param {Date} dateRef 
 * @param {boolean} refresh 
 */
export const getMonthlyReport = (type, id, dateRef, refresh) => {

    const sDateRef = dateRef.toISOString()
    const sRefresh = refresh ? '@1' : ''
    return {
        type: API,
        payload: {
            url: `${URL_API}/${type}/${id}/monthly/${sDateRef}${sRefresh}`,
            method: METHOD_GET,
            params: null,
            cancelable: true,
            loadingEnd: false,
            onSuccess: setDataMonthlyReport,
        }
    }

}

const getActivitiesWithoutCardFromAPI = (dateRef, assigned, significantDuration) => {
    
    const sMonth = (dateRef.getMonth() + 1).toString().padStart(2, '0')
    const sYear = dateRef.getFullYear()
    const nAssigned = (assigned ? 1 : 0)
    const nSignificantDuration = (significantDuration ? 1 : 0)

    return {
        type: API,
        payload: {
            url: `${URL_API}/vehicles/withoutCard/${sYear}/${sMonth}/${nAssigned}/${nSignificantDuration}`,
            method: METHOD_GET,
            params: null,
            cancelable: true,
            loadingEnd: true,
            onSuccess: setDataAttributions,
        }
    }
}
export const getActivitiesWithoutCard = (dateRef, assigned, significantDuration) => dispatch => {
    dispatch(resetData())
    dispatch(getActivitiesWithoutCardFromAPI(dateRef, assigned, significantDuration))
}

export const assignActivities = (activities) => dispatch => {
    dispatch(prepareDataBeforeAttributions(activities))
    dispatch(assignActivitiesToAPI(activities))    
}

export const assignActivitiesToAPI = (data) => ({
    type: API,
    payload: {
        url: `${URL_API}/vehicles/assignWithoutCard`,
        method: METHOD_POST,
        params: data,
        loadingEnd: false,
        onSuccess: updateDataAfterAttributions,
    }
})

export const updateCodeDriver = (id, newCode) => {

    const params = {
        id: id,
        code: newCode,
    }
    const sCode = newCode
    return {
        type: API,
        payload: {
            url: `${URL_API}/driver/exist/${sCode}`,
            method: METHOD_GET,
            cancelable: true,
            paramOnSuccess: params,
            onSuccess: handleUpdateCodeDriver
        }
    }
}

const handleUpdateCodeDriver = (exist, params) => dispatch => {

    /*
     * le code n'existe pas : on va l'utiliser
     */
    if (exist === 0) {
        dispatch(updateLocalDriver(params.id, 'drv_code', params.code))
        dispatch(updateCodeDriverToApi(params.id, params.code))

    /*
     * sinon erreur
     */
    } else {
        dispatch(setDataError('Code existant.'))
    }
}

const updateCodeDriverToApi = (id, newCode) => {
    const sCode = newCode
    return {
        type: API,
        payload: {
            url: `${URL_API}/driver/${id}/code/${sCode}`,
            method: METHOD_PUT,
            params: null,
        }
    }
}

const updateLocalDriver = (id, field, value) => ({
    type: CLIENT_UPDATE_DRIVER,
    payload: {
        id: id,
        field: field,
        value: value,
    }
})

export const addDriverContract = (id, contract) => ({
    type: API,
    payload: {
        url: `${URL_API}/driver/${id}/contract`,
        method: METHOD_PUT,
        params: {
            begin: contract.debut,
            end: contract.fin,
            interim: contract.interimaire
        },
        paramOnSuccess: id,
        onSuccess: reloadDriver,
    }
})

const reloadDriver = (data, id) => dispatch => {
    dispatch(getDriver(id))
}

export const addVehicleContract = (id, contract) => ({
    type: API,
    payload: {
        url: `${URL_API}/vehicle/${id}/use`,
        method: METHOD_PUT,
        params: {
            begin: dateToString(contract.debut),
            end: dateToString(contract.fin),
            zone: contract.type_zone === ZONE_PUBLIQUE ? 'publique' : 'privee'
        },
        paramOnSuccess: id,
        onSuccess: reloadVehicle,
    }
})

const reloadVehicle = (dataApi, id) => dispatch => {
    dispatch(getVehicle(id))
}

export const updateVehicle = (id, changes) => dispatch => {

    dispatch(updateVehicleToAPI(id, changes))
    if (changes.immatriculation) {
        dispatch(updateLocalVehicle(id, 'veh_reg_no', changes.immatriculation))
    }   
    
}

const updateVehicleToAPI = (id, changes) => ({
    type: API,
    payload: {
        url: `${URL_API}/vehicle/${id}`,
        method: METHOD_PUT,
        params: changes,
    }
})

const updateLocalVehicle = (id, field, value) => ({
    type: CLIENT_UPDATE_VEHICLE,
    payload: {
        id: id,
        field: field,
        value: value,
    }
})

export const updateCodeVehicle = (id, newCode) => {

    const params = {
        id: id,
        code: newCode,
    }
    const sCode = newCode
    return {
        type: API,
        payload: {
            url: `${URL_API}/vehicle/exist/code/${sCode}`,
            method: METHOD_GET,
            cancelable: true,
            paramOnSuccess: params,
            onSuccess: handleUpdateCodeVehicle
        }
    }
}

const handleUpdateCodeVehicle = (exist, params) => dispatch => {

    /*
     * le code n'existe pas : on va l'utiliser
     */
    if (exist === 0) {
        dispatch(updateLocalVehicle(params.id, 'veh_code', params.code))
        dispatch(updateCodeVehicleToApi(params.id, params.code))

    /*
     * sinon erreur
     */
    } else {
        dispatch(setDataError('Code existant.'))
    }
}

const updateCodeVehicleToApi = (id, newCode) => {
    const sCode = newCode
    return {
        type: API,
        payload: {
            url: `${URL_API}/vehicle/${id}/code/${sCode}`,
            method: METHOD_PUT,
            params: null,
        }
    }
}

export const setAvailability = (newEvent) => {
    
    const sType = (newEvent.element.type === DRIVER_TYPE) ? 'driver' : 'vehicle'
    const id = newEvent.element.id
    const sBegin = newEvent.begin.toISOString()
    const sEnd = newEvent.end.toISOString()

    return {
        type: API,
        payload: {
            url: `${URL_API}/${sType}/${id}/availability/${sBegin}/${sEnd}/${newEvent.reason}`,
            method: METHOD_PUT,
            params: null,
            cancelable: false,
        }
    }
}