import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

/**
 * Menu d'un module
 * 
 * @param   {string}    id  identifiant
 * @param   {element}   icon    icône à utiliser (JSXElement)
 * @param   {string}    label   libellé de l'entrée du module
 * @param   {string}    service service associé au module : module à afficher sur le service est souscrit. * correspond à tout service
 * @param   {bool}      expanded    @see  https://material-ui.com/fr/api/accordion/#props
 * @param   {func}      onChange    @see https://material-ui.com/fr/api/accordion/#props
 */
class MenuModule extends Component {
  static propTypes = {
    id : PropTypes.string.isRequired,
    icon : PropTypes.element.isRequired,
    label : PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
    expanded : PropTypes.bool.isRequired,
    onChange : PropTypes.func.isRequired
  }

  // @todo introduire le calcul de l'affichage du menu en tenant compte du droit actuel et des droits des enfants
  hasChildrenDisplayed = () => {
      this.props.children.props.children.forEach(ligne => {
        console.debug('ligne', ligne.props.minRoll)
      })
  }
  render() {
    
    const aria = `${this.props.id}-content`
    const id = `${this.props.id}-header`
    const services = (Array.isArray (this.props.services)) ? this.props.services.map (elt => elt.nom.replaceAll(' ', '').toLowerCase()) : []
    const displayed = (
        this.props.idClientEnCours > 0
        && (this.props.service === '*' 
            || services.includes (this.props.service))
        )
    
    return (
        <>
        { (displayed) && 
            <Accordion 
                    expanded={this.props.expanded} 
                    onChange={this.props.onChange}
                    className={this.props.classes.root}>
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />} 
                    aria-controls={aria}
                    id={id} >
                    {this.props.icon} {this.props.label}
                </AccordionSummary>
                <AccordionDetails>
                    {this.props.children}
                </AccordionDetails>
            </Accordion>
        }
        </>
    )
  }

}

const classes = theme => ({
    root : {
        color: theme.palette.primary.main,
    }
})

const mapStateToProps = state => ({
    idClientEnCours : state.identity.idClientEnCours,
    services : state.client.services,
})
export default connect (mapStateToProps) (withStyles (classes) (MenuModule))