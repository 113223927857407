import * as act from '../actions/TerminalsActions'

const INITIAL_STATE = {
    terminals: [],

}

const terminalsReducer = (state = INITIAL_STATE, action) => {

    let terminals
    let terminal

    switch (action.type) {

        case act.TERM_SAVE_LIST:
            return Object.assign({}, state, {
                terminals: action.payload,
            })
            
        case act.TERM_UPDATE:

            terminals = state.terminals
            terminal = terminals.find(term => term.id === action.payload.id)
            
            terminal.nom = action.payload.name
            terminal.description = action.payload.description
            terminal.ligne1Adresse = action.payload.line1
            terminal.line2 = action.payload.line2
            terminal.codePostal = action.payload.zipCode
            terminal.ville = action.payload.city
            terminal.pays = action.payload.nation

            return Object.assign({}, state, {
                terminals: terminals,
            })

        case act.TERM_UPDATE_STATE:
            terminals = state.terminals
            terminal = terminals.find(term => term.id === action.payload.id)
            
            terminal.HS = action.payload.hs === 1
            return Object.assign({}, state, {
                terminals: terminals,
            })

        default:
            return state
    }
}

export default terminalsReducer