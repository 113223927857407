import {DATE_INFINIE} from '../../../components/Constants'

/**
 * Transforme une chaîne ISO en une date
 * 
 * @param {string} sDate 
 * @returns {Date|null}
 */
export const stringToDate = (sDate) => {

    const d = new Date(sDate)
    const inf = new Date('2100-12-31')

    /*
     * null correspond à une absence de date, soit l'infini
     */
    if (d > inf) {
        return null 
    } else {
        return d
    }

}

/**
 * Transforme une date en une chaîne ISO
 * @param {Date|null} date 
 * @returns {string}
 */ 
 export const dateToString = (date) => {

    /*
     * absence de date est traduit en date infinie "proche"
     */
    if ( (typeof(date) === 'undefined')
            || (date === null)
    ) {
        return DATE_INFINIE.toISOString()
    } else {
        return date.toISOString()
    }

}