import React, { Component } from 'react'
import {withStyles, Container,
        Link,
        Dialog, DialogTitle, DialogActions, DialogContent,
        Typography} from '@material-ui/core'
import IconMail from '@material-ui/icons/MailOutline'
import IconPhone from '@material-ui/icons/Phone'
import {format} from 'date-fns'
import CloseButton from './CloseButton'

class LegalsInsert extends Component {

    state = {
        display: false,
    }
        
        
    handleDisplay = () => {
        this.setState({
            display: !this.state.display,
        })
    }
    render() {
        const today = new Date()

        return (
        <Container className={this.props.classes.root}>
            <Link onClick={this.handleDisplay} color='primary' className={this.props.classes.link}>
                Mentions légales
            </Link>
            <Dialog onClose={this.handleDisplay} open={this.state.display} className={this.props.classes.card}>
                <DialogTitle>
                    Mentions légales
                </DialogTitle>
                <DialogContent className={this.props.classes.content}>
                    <div>
                        <Typography variant='subtitle1'>Application <em>Optimal Web</em></Typography><br />
                        Version {process.env.REACT_APP_VERSION}{process.env.REACT_APP_ENV}<br />
                        Tous droits réservés {format(today, 'yyyy')}<br />
                    </div>
                    <div> 
                        <Typography variant='subtitle1'>Athmo</Typography><br />
                        SARL Recherche Étude Méthode<br />
                        Espace Vernèdes 7<br />
                        Route des Vernèdes<br />
                        83480 Puget sur Argens<br />
                        France
                    </div>
                    <div>
                        SIRET 501 814 313 00034<br />
                        R.C.S. Fréjus - France
                    </div>
                    <div>
                        <Typography variant='subtitle1'>Contacts</Typography>
                            <IconPhone /> +33 4 83 12 00 06<br />
                            <IconMail /> contact@athmo.eu
                    </div>
                </DialogContent>
                <DialogActions>
                    <CloseButton  onClick={this.handleDisplay} />
                </DialogActions>
            </Dialog>
            
        </Container>
        )
    }
}

const classes = theme => ({
    root: {
        color: theme.palette.primary.main,
        display: 'inline',
        marginLeft: 0,
        padding: 0,
    },
    link: {
        cursor: 'pointer',
    },
    card: {
        padding: '2em',
    },
    content: {
        marginTop: '0.5em',
        '&& div': {
            padding: '0.4em',
        }
    }
})

export default withStyles(classes) (LegalsInsert)
