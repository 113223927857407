import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Tooltip, IconButton} from '@material-ui/core'
import ExportIcon from '@material-ui/icons/CloudDownload'

class ExportButton extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    }

    render() {
        return (
            <Tooltip title={this.props.title}>
                <IconButton onClick={this.props.onClick}>
                    <ExportIcon />
                </IconButton>
            </Tooltip>
        )
    }
}

export default ExportButton
