import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withStyles, Paper, Toolbar,
        TableContainer, Table, TableHead, TableBody,
        TableRow, TableCell, Typography,
        } from '@material-ui/core'
import DailyRow from './DailyRow'
import WarningIcon from '../icons/WarningIcon'
import PrintButton from '../window/PrintButton'
import TabGraphButton from '../window/TabOrGraphButton'
import toPrint from '../tools/toPrint'
import ExportButton from '../window/ExportButton'
import {exportDaysActivities} from './exportActivities'
import Overlaps from './Overlaps';
import {DISPLAY_TYPE} from '../Constants'
import ActivitiesHistogram from './graphics/ActivitiesHistogram'

class DailyDataGrid extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    selection: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    refresh: PropTypes.func.isRequired,            /* permet d'actualiser les données */
    displayType: PropTypes.oneOf([DISPLAY_TYPE.TAB, DISPLAY_TYPE.HISTO]).isRequired,
    handleDisplayType: PropTypes.func.isRequired, /* gestion du type d'affichage (tableau ou histogramme) */
    monthHistogram: PropTypes.bool.isRequired,
    handleMonthHistogram: PropTypes.func.isRequired,    /* gestion de l'affichage du mois dans l'histogramme */
  }

    state = {
        buttons: [],
        informations: [],
        displayType: DISPLAY_TYPE.TAB,
        reloadRequired: false,                      /* indique la nécessité de procéder à un rechargement des données */ 
    }

    componentDidMount () {
        const printButton = <PrintButton 
                title="Imprimer" 
                onClick={this.print} />
        const exportButton = <ExportButton 
                title="Exporter en CSV"
                onClick={this.export} />
        this.setState({
            buttons: this.state.buttons.concat([printButton, exportButton], this.props.options.buttons)
        })

        this.updateInformations()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.overlapsValues !== this.props.overlapsValues) {
            this.updateInformations()
        }

        if (this.state.reloadRequired 
                && (prevProps.displayType === DISPLAY_TYPE.HISTO && this.props.displayType === DISPLAY_TYPE.TAB)) {

            /*
             * actualisation des données depuis le serveur
             */
            this.props.refresh()

            this.setState({
                reloadRequired: false,
            })
        }

    }

    setReloadRequired = () => {
        this.setState({
            reloadRequired: true,
        })
    }
    updateInformations = () => {

        let informations = []

        const infosTxt = [
            {   
                code : 3,
                txt: "Présence d'événements de chevauchements temporels",
            },
            {
                code: 9,
                txt: "Erreur sur les données de mouvement",
            },
            {
                code: 10,
                txt: 'Conflit concernant le mouvement du véhicule',
            },
            {
                code: 21,
                txt: "Erreur de transfert de données internes",
            },
            {
                code: 23,
                txt: "Sabotage du matériel",
            },
            {
                code: 35,
                txt: "Erreur de transfert de données internes",
            },
            {
                code: 37,
                txt: "Sabotage du matériel",
            },
            {
                code: 53,
                txt: "Anomalie affectant le capteur de mouvement",
            },
        ]

        if (this.props.overlapsValues.length > 0) {
            this.props.overlapsValues.forEach(elt => {                
                const exist = infosTxt.find(info => info.code === elt.event_code)
                if (exist
                        && (!informations.includes(exist.txt))) {
                    informations.push(exist.txt);
                }
            });

        }
        
        this.setState({
            informations: informations,
        })

    }
    print = () => {
    
        console.info ('Print datagrid', this.props.options.gridName)
        
        const supprClass = /class="[a-zA-Z- 0-9]+"/gm;
        let html = document.getElementById('tableAct').innerHTML.replace(supprClass, '')
        
        toPrint(html, this.props.client.nom_client, this.props.options.gridName, this.props.logo)
    
    }
    export = () => {
        exportDaysActivities(this.props.data, this.props.options.gridName, this.state.informations)
    }
  render() {
    
    /*
     * affichage de l'histogramme
     */
    if (this.props.displayType === DISPLAY_TYPE.HISTO) {
        
        return (
            <ActivitiesHistogram 
                data={this.props.data} 
                handleDisplay={this.props.handleDisplayType}
                selection={this.props.selection} 
                refresh={this.props.refresh} 
                onActivitiesChanges={this.setReloadRequired} 
                monthHistogram={this.props.monthHistogram}
                handleMonthHistogram={this.props.handleMonthHistogram}
                />
        )

    /*
     * affichage en tableau
     */
    } else {
        
        return (
            <Paper>                
                <Toolbar className={this.props.classes.toolbar}>
                    <Overlaps
                        id={this.props.selection.id}
                        begin={this.props.selection.dateRef}
                        button />
                    {this.props.data.length > 0 &&
                        <TabGraphButton
                            isGraphic={this.props.displayType === DISPLAY_TYPE.HISTO}
                            onClick={this.props.handleDisplayType} />
                    }
                    {this.state.buttons.length > 0 && 
                        this.state.buttons.map( (bt, i) => {
                            return <div key={`bt_day_${i}`}>{bt}</div>
                        })
                    }
                </Toolbar>
                <TableContainer id="tableAct">
                    <Table                       
                        size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={11}>
                                    {this.state.informations.map( (info, i) => 
                                        <Typography key={`avert_${i}`} color="error">{info}</Typography>)}
                                </TableCell>
                            </TableRow>
                            {
                                this.props.options.informations.length > 0 && 
                                    this.props.options.informations.map( (info, i) => 
                                        (<TableRow key={`informations${i}`} >
                                            <TableCell key={`informations${i}_cell`} colSpan={12}>
                                                {info}
                                            </TableCell>
                                        </TableRow>)
                                    )                     
                            }
                            <TableRow key="headers">
                                <TableCell>Début</TableCell>
                                <TableCell>Fin</TableCell>
                                <TableCell>Activité</TableCell>
                                <TableCell>Conduite</TableCell>
                                <TableCell>Travail</TableCell>
                                <TableCell>Disponibilité</TableCell>
                                <TableCell>Double équipage</TableCell>
                                <TableCell>Repos</TableCell>
                                <TableCell>Km</TableCell>
                                <TableCell>Vitesse</TableCell>
                                <TableCell>Source</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.data.length > 0 ?
                                this.props.data.map( (row, i) => <DailyRow 
                                                                    key={`rowday_${i}`}
                                                                    row={row} 
                                                                    dateRef={this.props.selection.dateRef} />) :
                                <TableRow key="noData">
                                    <TableCell 
                                            key="noDataCell"
                                            className={this.props.classes.noData}
                                            colSpan={12}>
                                        <WarningIcon />&nbsp;Aucun enregistrement présent
                                    </TableCell>
                                </TableRow>
                                }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        )

    }

  }
}

const mapStateToProps = state => ({
    client: state.identity.clients[state.identity.idClientEnCours],
    logo: state.client.logo,
    overlapsValues: state.data.overlapsValues,
})

const classes = {
    root: {

    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    titleGrid: {
        textAlign: 'center',
        fontSize: 'large',
    },
    noData: {
        textAlign: 'center',
    }
}

export default connect(mapStateToProps) (withStyles(classes) (DailyDataGrid))