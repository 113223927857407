import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {Link} from 'react-router-dom'
import {setTitle} from '../../core/redux/actions/WindowAppActions'
import {getClients} from '../../core/redux/actions/IdentityActions'
import BaseService from '../../services/BaseService'
import { bindActionCreators } from 'redux';
import {withStyles, CircularProgress, Container,Grid, Typography, Button, } from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import AccountValidationSwitch from '../../core/components/parameters/AccountValidationSwitch';
import {URL_API} from '../../core/redux/actions/DataActions'

/**
 * Page d'accueil
 */
class HomePage extends Component {

  state = {
    client : null,
    contact: false,
    contacting: false,
  }

  componentDidMount() {
    
    let client = this.props.clients[this.props.idClientEnCours];

    this.setState ({
      client : client,
    })

    this.props.setTitle ('Mon entreprise')
           
  }
  
  contactSupport = () => {

    this.setState({
      contacting: true,
    })
    const service = new BaseService()

    service.put(`${URL_API}/support/contact/accountValidation`).then(response => {
      this.setState({
        contact: true,
        contacting: false,
      })
    })
    
  }

  render() {
    const maintenant = new Date ();
    
    return (
      <>{ (this.props.idClientEnCours === 0
            || this.props.loading
            || !this.state.client) ? (
        <Container className={this.props.classes.loading}>
          <Typography variant="caption">Chargement en cours des informations de l'entreprise...</Typography>
          <CircularProgress />
        </Container>
      ) : (
        <Grid container className={this.props.classes.root} spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{this.state.client.nom_client}</Typography>

            <Grid container spacing={3}>
              <Grid item>
                Carte entreprise
              </Grid>
              <Grid item>
                {this.state.client.code_societe}
              </Grid>
            </Grid>
                         
            <Grid container spacing={3}>
              <Grid item>Logotype</Grid>
              <Grid item>
                <Link to='/parameters/companySettings/logo'>
                  {this.props.logo.length > 0 ?
                    <img src={`data:image/png;base64,${this.props.logo}`}
                        alt="Logo actuel" 
                        className={this.props.classes.img} /> :
                    <Typography variant="caption">à définir (optionnel)</Typography>
                  }
                </Link>
              </Grid>
            </Grid>

            {!this.props.logo.length === 0 &&
              <Typography variant="body2">Au sein de la rubrique "Paramètres  d'entreprise", vous pouvez définir le logotype à utiliser dans certains documents.</Typography>
            }
          </Grid>
          
          {this.props.informations.address && (<>
            <Grid item>
              <Typography variant="h5">Adresse</Typography>
              <ul>
                <li>{this.props.informations.address.line1}</li>
                {this.props.informations.address.line2 && <li>{this.props.informations.address.line2}</li>}
                <li>{this.props.informations.address.zipCode} {this.props.informations.address.city}</li>
                <li>{this.props.informations.address.country}</li>
              </ul>
            </Grid>
            <Grid item>
              <Typography variant="h5">Comptabilité</Typography>
              <ul>
                  <li>Code comptable <em>Athmo</em> = {this.state.client.code}</li>
                  <li>{this.props.informations.dematerialized ? 
                    <span>Factures dématérialisées</span> : 
                    <span>Factures au format papier</span>}</li>
                  { (this.props.informations.dematerialized && this.props.informations.invoicesRecipients) &&
                      (<li><label>Destinataires</label><ul>
                          {this.props.informations.invoicesRecipients.map ( (elt, index) => 
                              <li key={index}>{elt.nom} {elt.prenom} &lt;{elt.courriel}&gt;</li>)}
                          </ul></li>)}
                </ul>
            </Grid>
            
          </>)}

          {this.props.informations.formula && (
            <Grid item>
              <Typography variant="h5">Abonnement{this.props.informations.formula.length > 1 ? 's' : ''}</Typography>
              <ul>{this.props.informations.formula.map( (elt, index) => 
                  <li key={index}>{elt.label}<ul>
                      <li>Début : {elt.begin.toLocaleDateString()}</li>
                      <li>Fin : {elt.end.toLocaleDateString()}</li>
                      </ul>
                  </li>)}
              </ul>
              </Grid>)
          }

          {this.props.informations.ow.actuals && (
            <Grid item>
              <Typography variant="h5">Actifs</Typography>
              <ul>
                <li>Conducteurs : {this.props.informations.ow.actuals.drivers}</li>
                <li>Véhicules : {this.props.informations.ow.actuals.vehicles}</li>
              </ul>
            </Grid>
            
          )}
          {(this.props.groups && this.props.groups.enabled) &&
            <Grid item>
              <Typography variant="h5">Gestion par groupes</Typography>
              <Typography>Gestion par groupes active</Typography>
              {!this.props.groups.configured ?
                <Typography className={this.props.classes.warning}>Nécessite un paramétrage !</Typography> :
                <Typography>Site = {this.state.client.site_optimal_web}</Typography>
              }
            </Grid>
          }
                   
          {!this.props.downloadable &&
            <Grid item xs={3}>
              <Typography variant="h5">Restriction d'usage</Typography>
              <Alert severity="warning">
                  Le téléchargements des archives est désactivé.
                  {this.state.contacting ?
                    <CircularProgress /> :
                    this.state.contact ?
                      <Typography variant="body2">Support contacté. Veuillez patienter ou appeler.</Typography> :
                      <Button onClick={this.contactSupport}>
                        Demander au support l'usage de cette fonctionnalité.
                      </Button>
                  }
              </Alert>
            </Grid>
          }
          {(this.props.isHelpUser && this.props.services) && (<>
            <Grid item className={this.props.classes.support}> 
              <Typography variant="h5">
                Mémo assistance Athmo
              </Typography>
              <ul>
               
                <li>Id client = {this.state.client.id_client}</li>
                <li>Id société = {this.props.informations.ow.company.id}</li>
                <li>
                  <label>Services souscrits</label>
                  <ul>{this.props.services.map((elt)=> {
                    return (<li key={elt.nom.replace(' ', '')}>{elt.nom}</li>)
                  })}</ul></li>
                <li>Heure du poste client = {maintenant.toISOString()}</li>
              </ul>              
            </Grid>
            <Grid item className={this.props.classes.support}>
              <Typography variant="h5">Limitations</Typography>
              <AccountValidationSwitch />
            </Grid>
          </>)}
        </Grid>
      )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.identity.loading,
  clients : state.identity.clients,
  idClientEnCours : state.identity.idClientEnCours,
  informations: state.client.informations,
  services : state.client.services,
  isHelpUser: state.identity.user.supportAthmo,
  downloadable: state.client.downloadable,
  logo: state.client.logo,
  groups: state.client.groups,
})

const mapDispatchToProps = (dispatch) => ({
  setTitle : bindActionCreators(setTitle, dispatch),
  getClients : bindActionCreators (getClients, dispatch),
})

const classes = theme => ({
  root: {
    color: theme.palette.primary.main,
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  support: {
    backgroundColor: theme.palette.info.light,
  },
  img: {
    maxWidth: '10em',
  },
  warning: {
    color: theme.palette.warning.main,
  }
})
export default connect (mapStateToProps, mapDispatchToProps) (withRouter(withStyles(classes) (HomePage)))
