import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {JUSTIFICATIONS} from '../../Constants'
import {Toolbar, withStyles, Grid, Switch, FormControl, Select, MenuItem, InputLabel, Button} from '@material-ui/core'
import DailyActivitiesHistogram from './DailyActivitiesHistogram';
import { Slider } from '@material-ui/core';
import TabButton from '../../window/TabButton';
import MonthActivitiesHistogram from './MonthActivitiesHistogram';
import BaseService from '../../../../services/BaseService';
import { setError } from '../../../redux/actions/WindowAppActions'
import ActivitiesMagnification from './ActivitiesMagnification';
import ContextWindow from './ContextWindow';
import {isEqual, addMinutes} from 'date-fns'

export class ActivitiesHistogram extends Component {
	static propTypes = {
		data: PropTypes.array.isRequired,
		handleDisplay: PropTypes.func.isRequired,
		refresh: PropTypes.func.isRequired,						/* actualisation des données */
		selection: PropTypes.object,
		onActivitiesChanges: PropTypes.func,					/* notification d'un éventuel rechargement des données nécessaires */
		monthHistogram: PropTypes.bool.isRequired,					/* affichage de l'histogramme du mois */
		handleMonthHistogram: PropTypes.func.isRequired,		/* gestion de l'histogramme mensuel */
	}

	state = {
		magnification: 1,
		dayData: [],
		dayDataOrig: [],
		monthHistogram: false,
		monthLoading: false,
		monthData: [],
		monthDataOrig: [],
		dataMagnification: [],				/* données à grossir visuellement */
		anchorElt: null,							/* DOM de l'activité ciblée */
		rightClickTarget: null,
		modifications: [],						/* liste des modifications à appliquer */
		justification: '',						/* motif des changements sur les activités */
		displayAddNewAct: false,			/* affichage de la fenêtre d'ajout d'une nouvelle activité */
	}

	componentDidMount() {

		/*
		 * préchargement des données du mois
		 */
		this.getDataMonth()

		/*
		 * Initialisation des données du jour examiné
		 */
		this.initializeData()
		
	}
	componentDidUpdate(prevProps, prevState) {

		/*
		 * mise à jour du référentiel des données d'un jour
		 */
		if (prevProps.data !== this.props.data) {
			this.initializeData()
		} 

		/*
		 * rechargement de la liste des activités du mois
		 */
		if (prevProps.selection !== this.props.selection) {
			this.getDataMonth()
		}


	}

	initializeData = () => {
		
		this.setState({
			dayData: this.copyDayActs(this.props.data),
			dayDataOrig: this.copyDayActs(this.props.data),
			justification: '',
			modifications: [],
			anchorEl: null,
			rightClickTarget: null,
			dataMagnification: [],
		})

	}
	getDataMonth = () => {
		this.setState({
			monthLoading: true,
		})
		const URL_API = process.env.REACT_APP_URL_API
		const id = this.props.selection.id
		const sDateRef = this.props.selection.dateRef.toISOString()
		const url = `${ URL_API }/driver/${id}/month/${sDateRef}`
		const service = new BaseService()
		service.get(url).then( (data) => {
			const acts = []
			let actsOrig = []
			let dateRef = this.props.selection.dateRef

			if (data.values.length > 0) {

				dateRef = new Date(data.values[0].date)

				/*
				* création d'une date Javascript
				*/
				data.values.map( (act, index) => {

					act.index = index

					act.date = new Date(act.date)
					const hours = act.heure_debut.split(':')
					act.date.setHours(hours[0])
					act.date.setMinutes(hours[1])

					return act
				})

			}

			/*
			 * attribution à chaque date des activités du jour
			 */
			data.values.forEach(act => {
				const n = act.date.getDate()
				if (!Array.isArray(acts[n])) {
					acts[n] = []
				}
				acts[n].push(act)

			})

			/*
			 * création des jours sans activités
			 */
			for (let n = 1; n <= 28; n++) {
				if (!Array.isArray(acts[n])) {

					const newDate = new Date()
					newDate.setDate(n)
					newDate.setFullYear(dateRef.getFullYear())
					newDate.setMonth(dateRef.getMonth())

					acts[n] = [{
						date: newDate
					}]
				}
			}

			/*
			 * conservation des informations
			 */
			actsOrig = this.copyMonthActs(acts)
			
			this.setState({
				monthData: acts,
				monthDataOrig: actsOrig,
			})

		}).catch(error => this.props.setError(error))
	
	}

	handleMagnification = (evt, value) => {
		this.setState({
			magnification: value,
			dataMagnification: [],
		})
	}

	

	handleActMagnification = (anchorElt) => {
		
		const targetIndex = parseInt(anchorElt.target.dataset.index)
		let dataMagnification 
		
		/*
		 * filtrage des données à grossir
		 */
		if (this.props.monthHistogram) {
			
			const targetDate = new Date(anchorElt.target.dataset.date)
			targetDate.setHours(0)
			targetDate.setMinutes(0)

			dataMagnification = this.state.monthData.filter(elt => {
				const actDate = new Date(elt[0]?.date)
				actDate.setHours(0)
				actDate.setMinutes(0)
				
				return isEqual(targetDate, actDate)
			})
			
			dataMagnification = dataMagnification[0].filter(elt => (
				 elt.index >= (targetIndex - 1) && elt.index <= (targetIndex + 1)
				))

		} else {
			const targetBegin = new Date(anchorElt.target.dataset.date)
			let targetEnd = new Date(anchorElt.target.dataset.date)
			targetEnd = addMinutes(targetEnd, parseInt(anchorElt.target.dataset.duration))
			dataMagnification = this.state.dayData.filter(elt => {
				const eltEnd = addMinutes(elt.date, parseInt(elt.duree))

				return elt.index >= (targetIndex - 1) 		/* = activité suivante */
					&& elt.index <= (targetIndex + 1)				/* = activité précédente */
					&& ((isEqual(targetBegin, elt.date)			/* 3 activités contigües */
							|| (isEqual(targetBegin, eltEnd))	
							|| (isEqual(targetEnd, elt.date)))
							)
			})
		}

		this.setState({
			anchorElt: anchorElt,
			dataMagnification: dataMagnification
		})
	}

	onCloseMagnification = () => {
		this.setState({
			anchorElt: null,
			dataMagnification: [],
		});
	}

	handleContextWindow = (evt) => {
		this.setState({
			rightClickTarget: evt.target,
		})
	}

	onCloseContextWindow = () => {
		this.setState({
			rightClickTarget: null,
			dataMagnification: [],
			anchorElt: null,
		})
	}

	handleJustification = (evt) => {
		this.setState({
			justification: evt.target.value,
		})
	}
	memorizeChanges = (elt, newType) => {
	
		elt.newType = newType
		this.state.modifications.push(elt)

		this.setState({
			rightClickTarget: null,
		})

		/*
		 * Mise à jour graphique
		 */
		this.updateDisplayData(elt)

	}

	/**
	 * Mise à jour de l'affichage des données pour refléter la modification
	 * @param {*} elt 
	 */
	updateDisplayData = (elt) => {
		
		if (elt === undefined) {
			throw new Error(`Elt n'est pas correctement défini`)
		}

		if (this.state.dataMagnification.length > 0) {
			this.updateDisplayMagnificationData(elt)
		} else if (this.props.monthHistogram) {
			this.updateDisplayMonthData(elt)
		} else {
			this.updateDisplayDayData(elt)
		}
	}

	updateDisplayMagnificationData = (elt) => {

		/*
		 * mise à jour des données de loupe
		 */
		const data = this.state.dataMagnification.find(act => (act.heure_debut === elt.begin && act.id_disque === elt.disque));
		data.activite = elt.newType;
		this.setState({
			dataMagnification: this.state.dataMagnification,
		})

		/*
		 * mise à jour des données d'affichage général
		 */
		if (this.props.monthHistogram) {
			this.updateDisplayMonthData(elt)
		} else {
			this.updateDisplayDayData(elt)
		}
		
	}
	updateDisplayDayData = (elt) => {

		if (elt === undefined) {
			throw new Error("Elt n'est pas correctement défini. Action impossible.")
		}

		const data = this.state.dayData.find(act => (act.heure_debut === elt.begin && act.id_disque === elt.disque))
		data.activite = elt.newType
		this.setState({
			dayData: this.state.dayData,
		})
	}

	updateDisplayMonthData = (elt) => {
		this.state.monthData.forEach(dayData => {
			const data = dayData.find(act => (act.heure_debut === elt.begin && act.id_disque === elt.disque))
			if (data) {
				data.activite = elt.newType
			}
		})

		/*
		 * actualisation des informations
		 */
		this.setState({
			monthData: this.state.monthData
		})

	}

	copyDayActs = (dayActs) => {
		const copy = [];

		dayActs.forEach(act => {
			if (act.date) {
				copy.push(Object.assign({}, act));
			}
		});

		return copy;
	}

	copyMonthActs = (daysActs) => {
		const copy = []

		daysActs.forEach( (aDay, n) => {
			const acts = []
			aDay.forEach(act => acts.push(Object.assign({}, act)))
			copy[n] = acts
		})

		return copy
	}
	cancelModifications = () => {

		if (this.props.monthHistogram) {
			
			const monthActs = this.copyMonthActs(this.state.monthDataOrig)
			
			this.setState({
				monthData: monthActs,
			})

		} else {
		
			this.setState({
				dayData: this.copyDayActs(this.state.dayDataOrig),
			})
			
		}
		
		this.setState({
			modifications: [],
			justification: '',
			anchorEl: null,
			rightClickTarget: null,
			dataMagnification: [],

		})
				
	}

	saveChanges = () => {
		
		let data = {
			modifications : [],
		}

		/*
		 * adaptations des données
		 */
		this.state.modifications.forEach(act => {

			const actDate = new Date(act.date)		/* la date est stockée dans DomString au format du navigateur */
			const hour = act.begin.split(':')
			actDate.setHours(hour[0])
			actDate.setMinutes(hour[1])
			data.modifications.push({
				disk: act.disque,
				num: act.numero,
				type: act.type,
				begin: actDate.toISOString(),
				just: this.state.justification,
				dur: act.duration,
			})
		})

		/*
		 * chargement en cours
		 */
		this.setState({
			modifLoading: true,
		});

		const URL_API = process.env.REACT_APP_URL_API;
		const url = `${URL_API}/driver/activities/modify`;
		const service = new BaseService();

		service.post(url, data).then((resp) => {

			/*
			 * cas normal = aucune erreur de produite
			 */
			if (resp === 0) {

				this.setState({
					modifLoading: false,
					modifications: [],
					justification: '',
					anchorEl: null,
					rightClickTarget: null,
					dataMagnification: [],
				});

				if (this.props.monthHistogram) {
					const dayNumber = this.props.selection.dateRef.getDate()
					const dayData = this.copyDayActs(this.state.monthData[dayNumber])
					this.setState({
						monthDataOrig: this.copyMonthActs(this.state.monthData),
						dayData: dayData,
						dayDataOrig: this.copyDayActs(dayData),
					})
				} else {
					this.setState({
						dayDataOrig: this.copyDayActs(this.state.dayData)
					})

					/*
					 * rechargement de la liste des activités du mois 
					 * (forcément impactée par la modification)
					 */
					this.getDataMonth()
				}

				/*
				 * notification de la présence de changements dans les activités
				 */
				this.props.onActivitiesChanges()

			}

		}).catch(error => {

			/*
			 *  notification de l'erreur
			 */
			this.props.setError(error)

			/*
			 * rechargement des données
			 */
			this.props.refresh()
		})

	}

	render() {
		
		return (
			<div>
				<Toolbar>
					<Grid container spacing={2} direction="row" justify="space-between" alignItems="center">
						
						<Grid item container spacing={1} xs={3} alignItems="center">
							<Grid item>Détail du jour</Grid>
							<Grid item>
								<Switch 
									checked={this.props.monthHistogram}
									onChange={this.props.handleMonthHistogram} 
									disabled={this.state.modifications.length > 0} />
							</Grid>
							<Grid item>Détail du mois</Grid>
						</Grid>
							
						{this.state.modifications.length > 0 &&
							<Grid item container xs={5} 
									alignItems="center" justify="space-around"
									className={this.props.classes.justification}>
								<Grid>
									<Button variant="contained" color="secondary"
											onClick={this.cancelModifications}>
										Annuler
									</Button>
								</Grid>
								<Grid>
									<FormControl className={this.props.classes.formControl}>
										<InputLabel>Motif</InputLabel>
										<Select value={this.state.justification}
											onChange={this.handleJustification}>
												{Object.entries(JUSTIFICATIONS).map( (elt) => {
													const key = elt[0]
													const label = elt[1]
													return <MenuItem key={key} value={key}>{label}</MenuItem>
												})}
										</Select>								
									</FormControl>
								</Grid>
								<Grid>
									<Button variant="contained" color="secondary" 
											disabled={this.state.justification.length === 0}
											onClick={this.saveChanges}>
										Valider
									</Button>
								</Grid>
							</Grid>
						}
						
						<Grid item container direction="row" alignItems="center" justify="flex-end" xs={3}>
							<Grid item>
								<Slider 
									value={this.state.magnification}
									min={0.7} 
									max={3}
									step={0.1}
									marks
									onChange={this.handleMagnification} 
									className={this.props.classes.slider}/>
							</Grid>
							<Grid item>
								<TabButton title="Mode tableau" 
										onClick={this.props.handleDisplay} 
										disabled={this.state.modifications.length > 0} />
							</Grid>
						</Grid>

					</Grid>
				</Toolbar>
				{this.props.monthHistogram ?
					<div className={this.props.classes.aMonth}>
						<MonthActivitiesHistogram
							data={this.state.monthData} 
							magnification={this.state.magnification}
							onActivityClick={this.handleActMagnification} 
							onActivityRightClick={this.handleContextWindow}
							/>
					</div>:
					<div className={this.props.classes.aDay}>
						<DailyActivitiesHistogram
							data={this.state.dayData} 
							magnification={this.state.magnification} 
							onActivityClick={this.handleActMagnification} 
							onActivityRightClick={this.handleContextWindow} 
							/>
					</div>
				}
				<ContextWindow 
					open={this.state.rightClickTarget !== null}
					anchorEl={this.state.rightClickTarget}
					memorizeChanges={this.memorizeChanges}
					onClose={this.onCloseContextWindow}
					addActivity={this.state.modifications.length === 0}
					selection={this.props.selection}
					refresh={this.props.refresh}
					monthHistogram={this.props.monthHistogram}
					/>
				<ActivitiesMagnification
					anchorEl={this.state.anchorElt}
					data={this.state.dataMagnification}
					open={this.state.dataMagnification.length !== 0 && this.state.anchorElt !== null}
					onClose={this.onCloseMagnification}
					onActivityRightClick={this.handleContextWindow}
					/>
				
			</div>
		)
	}
}

const classes = {
	root: {
		
	},
	aDay : {
		width: '1280px',
		overflow: 'scroll',
		borderStyle: 'outset',
		borderWidth: 'thin',
		padding: '1em 1px',
	},
	aMonth: {
		height: '65vh',
		width: '1280px',
		overflow: 'scroll',
		borderStyle: 'outset',
		borderWidth: 'thin',
		padding: '0.6em 1px',
	},
	slider: {
		minWidth: '8em'
	},
	formControl: {
		minWidth: 120,
	},
	justification: {
		borderColor: 'inherit',
		borderWidth: '0.1em',
		borderStyle: 'solid',
		backgroundColor: 'Lavender',
		borderTopLeftRadius: '0.3em',
		borderTopRightRadius: '0.3em',
	}
}

const mapDispatchToProps = ({
	setError,
})
export default connect(null, mapDispatchToProps) (withStyles(classes) (ActivitiesHistogram))
