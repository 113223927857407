
export default function toPrint(htmlToPrint, nomClient, title, dataLogo) {

    let logo = ''
    if (dataLogo.length > 0) {
        logo = `<div><img src="data:image/png;base64,${dataLogo}" class="logo" /></div>`
    } else {
        logo = `<div><img src='/images/athmo.svg' class='logo'/></div>`
    }
    const now = new Date()
    const infoClient = `<div class='infoClient'>${nomClient}</div>`
    const infoPrint = `<div class='printInfos'>${now.toISOString()}</div>`
    const enTetes = `<section class="headers">${logo}${infoClient}${infoPrint}</section>`
    const documentToPrint = window.open('about:blank', 'Print', 'location=0,height=500,width=800');
    documentToPrint.document.write(
        '<link rel="stylesheet" href="/css/print.css" />',
    );
    documentToPrint.document.title = title;
    documentToPrint.document.write('<body onload="window.print();" >');
    documentToPrint.document.write(enTetes);
    documentToPrint.document.write(`<section class="title">${title}</section>`)
    documentToPrint.document.write(htmlToPrint);
    documentToPrint.document.write('</body>');
    documentToPrint.document.close();
}