import {formatToTime}  from '../../../components/tools/datetimeFunctions'
import {stringToDate} from '../../actions/adapters/dateAdapters'

const dataAdapter = data => {

    let dureeCTDQ = 0
    let dureeC = 0
    let dureeT = 0
    let dureeD = 0
    let dureeQ = 0
    let dureeR = 0
    let km = 0
    let calcVitesse = 0
    let nbAct = 0

    let infos = data.map( (infos, index) => {
        
        let infosAct = {}
        
        km += infos.distance
        calcVitesse += (infos.distance * infos.vitesse)

        infos.index = index
        
        infos.date = stringToDate(infos.date)
        let heure = infos.heure_debut.split(':')
        infos.date.setHours(heure[0])
        infos.date.setMinutes(heure[1])

        infos.heure_debut = infos.heure_debut.substring(0, 5)
        infos.heure_fin = infos.heure_fin.substring(0, 5)
        infos.source = Number.parseInt(infos.source)

        switch (infos.activite) {
            case 'C':
                dureeCTDQ += infos.duree
                infosAct.conduite = infos.heure_duree.substring(0, 5)
                dureeC += infos.duree
                nbAct += 1
                break

            case 'T':
                dureeCTDQ += infos.duree
                infosAct.travail = infos.heure_duree.substring(0, 5)
                dureeT += infos.duree
                break

            case 'D':
                dureeCTDQ += infos.duree
                infosAct.dispo = infos.heure_duree.substring(0, 5)
                dureeD += infos.duree
                break
            
            case 'Q':
                dureeCTDQ += infos.duree
                infosAct.equipage = infos.heure_duree.substring(0, 5)
                dureeQ += infos.duree
                break

            case 'R':
                infosAct.repos = infos.heure_duree.substring(0, 5)
                dureeR += infos.duree
                break

            default:
                /* rien à effectuer */
        }
        return Object.assign({}, infos, infosAct)
        
    })
    if (nbAct > 0) {

        /*
         * ajout des totaux
         */
        infos.push({
            index: 'total',
            numero: 'sum',
            heure_duree: formatToTime(dureeCTDQ),
            duree: dureeCTDQ,
            conduite: formatToTime(dureeC),
            travail: formatToTime(dureeT),
            dispo: formatToTime(dureeD),
            equipage: formatToTime(dureeQ),
            repos: formatToTime(dureeR),
            distance: km,
            vitesse: (calcVitesse/km).toFixed(2),
        })
    }

    return infos
}

export default dataAdapter