import {processRow, exportCsvFile} from '../tools/exportFunctions'

/**
 * Export des activités du mois
 * @param {array} data 
 */
export function exportMonthsActivities (data, gridName) {

    let textExport = processRow([
        'index',
        'jourUTC',
        'debut',
        'fin',
        'conduite',
        'conduiteEnMinutes',
        'travail',
        'travailEnMinutes',
        'disponibilité',
        'disponibilitéEnMinutes',
        'Équipage',
        'ÉquipageEnMinutes',
        'CDTQ',
        'CDTQEnMinutes',
        'amplitude',
        'amplitudeEnMinutes',
        'repos',
        'reposEnMinutes',
        'km',
        'vitesse',
        'nuit',
        'nuitEnMinutes',
        'problème'
    ])
    textExport += data.reduce( (text, row) => {
        const txtRow = processRow([
            row.index,
            row.jour,
            row.debut,
            row.fin,
            row.c,
            row.c_val,
            row.t,
            row.t_val,
            row.d,
            row.d_val,
            row.q,
            row.q_val,
            row.cdtq,
            row.cdtq_val,
            row.amplitude,
            row.amplitude_val,
            row.r,
            row.r_val,
            row.km,
            row.vitesse,
            row.nuit,
            row.nuit_val,
            row.pb           
        ])

        return `${text}${txtRow}`
    }, '')

    
    exportCsvFile(textExport, `${gridName}.csv`)

}

export function exportDaysActivities (data, gridName, informations) {

    let textExport = processRow(informations)
    
    textExport += processRow([
        'index',
        'debut',
        'debutEnMinutes',
        'fin',
        'finEnMinutes',
        'duree',
        'dureeEnMinutes',
        'activite',
        'distance',
        'vitesse',
        'source',
        'conduite',
        'travail',
        'dispo',
        'equipage',
        'repos',
    ])
    textExport += data.reduce( (text, row) => {
        
        const txtRow = processRow([
            row.numero,
            row.heure_debut,
            row.debut,
            row.heure_fin,
            row.fin,
            row.heure_duree,
            row.duree,
            row.activite,
            row.distance,
            row.vitesse,
            row.source,
            row.conduite,
            row.travail,
            row.dispo,
            row.equipage,
            row.repos,           
        ])

        return `${text}${txtRow}`
    }, '')

    
    exportCsvFile(textExport, `${gridName}.csv`)

}

export function exportWithoutCardActivities (data, name) {

    let textExport = processRow([
        'index',
        'code véhicule',
        'immatriculation',
        'début',
        'date et heure',
        'durée',
        'duréeEnMinutes',
        '',
        'activité',
        'distance',
         
    ])
    textExport += data.reduce( (text, row) => {
        const txtRow = processRow([
            row.index,
            row.veh_code,
            row.veh_reg_no,
            row.datetime,
            row.sDate,
            row.s_durat,
            row.activity_durat,
            row.activity,
            row.sActivity,
            row.distance,
        ])

        return `${text}${txtRow}`
    }, '')

    
    exportCsvFile(textExport, `${name}.csv`)
}

export function exportAssignedActivities (data, name) {

    let textExport = processRow([
        'index',
        'code véhicule',
        'immatriculation',
        'début',
        'date et heure',
        'durée',
        'duréeEnMinutes',
        'activité',
        'distance',
        'Code conducteur',
        'Conducteur',
        'codeMotif',
        'Motif',
         
    ])
    textExport += data.reduce( (text, row) => {
        
        const txtRow = processRow([
            row.index,
            row.veh_code,
            row.veh_reg_no,
            row.datetime,
            row.s_begin,
            row.s_durat,
            row.activity_durat,
            row.activity,
            row.distance,
            row.drv_code,
            row.driver,
            row.reason,
            row.s_reason,
        ])

        return `${text}${txtRow}`
    }, '')

    
    exportCsvFile(textExport, `${name}.csv`)
}
