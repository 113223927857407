import React, { Component } from 'react'
import {Tooltip} from '@material-ui/core'
import SourcePaperDiscIcon from '@material-ui/icons/PanoramaFishEye'

/**
 * Source = disque papier
 * numéro = 0 
 */
class PaperDiscSource extends Component {

  render() {
    return (
      <Tooltip title="Disque papier">
        <SourcePaperDiscIcon />
      </Tooltip>
    )
  }
}

export default PaperDiscSource
