import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withStyles, Paper, Typography,
      FormControlLabel, Switch, CircularProgress,
      } from '@material-ui/core'
import WarningIcon from '../icons/WarningIcon';
import {getGroupsInfos, enableGroups} from '../../redux/actions/ClientActions'
import {U_ADMINISTRATEUR_SOCIETE, U_ADMINISTRATEUR_ATHMO} from '../Constants'
class GroupsParameter extends Component {

  state = {
    loading: false,
  }
  
  componentDidMount() {
    
    /*
     * chargement des informations des groupes si besoin
     */
    if (!this.props.groups) {
      this.props.getGroupsInfos()
    }
  }
  componentDidUpdate(prevProps, prevState) {

    /*
     * désactivation de l'attente du chargement de l'information
     */
    if (this.state.loading === true
          && (this.props.groups)
          && (prevProps.groups.enabled !== this.props.groups.enabled)
    ) {
      this.setState({
        loading: false,
      })
    }

  }

  handleChange = (evt) => {
    this.setState({
      loading: true,
    })
    this.props.enableGroups(!this.props.groups.enabled)

  }
  render() {
   
    const changeAllow = [
      U_ADMINISTRATEUR_SOCIETE,
      U_ADMINISTRATEUR_ATHMO,
    ]

    return (
      <Paper className={this.props.classes.root}>
        <Typography variant="h5">Gestion par groupes</Typography>
        
        <div className={this.props.classes.caution}>
          <WarningIcon 
              title="Fonctionnalité ayant d'importantes conséquences" 
              className={this.props.classes.icon} />
          <Typography variant="body1" className={this.props.classes.warning}>
            Activer la gestion par groupes a des conséquences importantes sur le fonctionnement de l'application. Ne le faites qu'à bon escient !
          </Typography>
        </div>

        { (this.props.groups === null || this.state.loading) ?
          <CircularProgress /> :
          <FormControlLabel
                control={
                    <Switch checked={this.props.groups.enabled}
                        onChange={this.handleChange}
                        disabled={!changeAllow.includes(this.props.user.roles)}
                        />
                }
                label={this.props.groups.enabled ? "Gestion par groupes active" : "Gestion par groupes désactivées"}
                />
        }
      </Paper>
    )
  }
}

const mapStateToProps = state => ({
  groups: state.client.groups,
  user: state.identity.user,
})
const mapDispatchToProps = ({
  getGroupsInfos,
  enableGroups,
})

const classes = theme => ({
  root: {
    padding: '0.5em'
  },
  caution: {
    borderColor: theme.palette.warning.main,
    margin: '1em',
    border: 'red 1px dashed',
    padding: '0.2em',    
  },
  icon: {
    marginInlineEnd: '0.3em'
  }
  
})

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (GroupsParameter))
