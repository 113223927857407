import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Toolbar, Typography, withStyles } from '@material-ui/core';
import Driving from './Driving';
import CloseButton from '../window/CloseButton'
import VehicleIcon from '@material-ui/icons/LocalShipping'
import ButtonOw from '../window/ButtonOw';
import DataGridInPopper from '../tools/DataGridInPopper';

class OverlapsGrid extends Component {
	static propTypes = {
		loading: PropTypes.bool.isRequired,
		data: PropTypes.array.isRequired,
		selection: PropTypes.object,
		onClose: PropTypes.func,
	}

	state = {
		openDriving: false,
	}

	handleDriving = () => {
		this.setState({
			openDriving: !this.state.openDriving,
		})
	}
	render() {

		const columns = [
			{
				field: 'dur',
				headerName: 'Durée (mn)',
				width: 100,
			},
			{
				field: 'start',
				headerName: 'entre',
				width: 160,
			},
			{
				field: 'end',
				headerName: 'et',
				width: 160,
			},
			
		]

		return (<>
			<div className={this.props.classes.root}>
				<Toolbar className={this.props.classes.toolbar}>
					<Typography variant="h6">Chevauchements temporels</Typography>
					<div>
						{this.props.selection?.id && 
							<ButtonOw 
									title="Voir les véhicules conduits"
									onClick={this.handleDriving}>
								<VehicleIcon />
							</ButtonOw>
						}
						<CloseButton
							title="Fermer"
							onClick={this.props.onClose} />
					</div>
				</Toolbar>
			
				<DataGridInPopper
						columns={columns}
						rows={this.props.data} 
						/>
			</div>

			<Dialog 
					open={this.state.openDriving}
					onClose={this.handleDriving}
					>
				<DialogTitle>Véhicules conduits</DialogTitle>
				<DialogContent>
					<Driving 
						id={this.props.selection?.id}
						dateRef={this.props.selection?.dateRef} />
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleDriving}>Fermer</Button>
				</DialogActions>
			</Dialog>
		</>)
	}
}

const classes = theme => ({
	root: {
		width: 430,
		paddingTop: '0.5em',
		paddingLeft: '1em',
		paddingRight: '1em',
		paddingBottom: '0.5em',
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
	},
})
export default withStyles(classes) (OverlapsGrid)
