import React from 'react'
import {Island} from 'mdi-material-ui'

const DriverHolidayIcon = React.forwardRef( (props, ref) => {
  let {size, color, onClick, ...propsComponent} = props 
  let htmlColor, themeColor
  size = size ? size : 1
  if (color) {
    htmlColor = color
  } else {
    themeColor = 'primary'
  }
  
  return (
      <Island ref={ref} size={size} htmlColor={htmlColor} color={themeColor} {...propsComponent} onClick={onClick} />
  )
})

export default DriverHolidayIcon
