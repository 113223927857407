import React, { Component } from 'react'
import LoginForm from '../../core/components/authentication/LoginForm';
import LegalsInsert from '../../core/components/window/LegalsInsert'

import { login } from '../../core/redux/actions/IdentityActions';
import { setTitle } from '../../core/redux/actions/WindowAppActions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Container, Paper, withStyles} from '@material-ui/core'

/**
 * Page d'authentification de l'utilisateur
 */
class AuthenticationPage extends Component {
    
    state = {
        errorAuth : false
    }
    componentDidMount() {
        this.props.setTitle ('');
    }

    onSubmit = (data) => {
        this.setState({
            errorAuth: false
        })
        
        this.props.login (data).then ( (nbClients) => {
            if (nbClients > 1) {
                this.props.history.push ('/choixEntreprise')
            } else {
                console.info ('= Choix client =', this.props.idClientEnCours)
                if ( (this.props.history.action === 'REPLACE')
                        && (this.props.history.location.state.from)
                    ) {
                    this.props.history.push('' + this.props.history.location.state.from);
                } else {
                    this.props.history.push ('/');
                }
            }

        }, (error) => {
            console.error('Erreur', error)
            this.setState ({
                errorAuth: true
            })
        } )
         
    }
    render() {
        return (
            <Container className={this.props.classes.root}>
                <div>
                    <div className={this.props.classes.title} >
                        <h1>Optimal Web</h1>
                        <h3>La gestion du chronotachygraphe</h3>
                    </div>
                    <Paper>
                        <LoginForm onLogon={this.onSubmit} errorAuth={this.state.errorAuth} />
                    </Paper>
                </div>
                <div className={this.props.classes.version}>
                    version {process.env.REACT_APP_VERSION}{process.env.REACT_APP_ENV}
                    &nbsp;&mdash;&nbsp;
                    <LegalsInsert />
                </div>                    
            </Container>
            
        )
    }
}

const mapStateToProps = state => ({
    idClientEnCours: state.identity.idClientEnCours,
    clients: state.identity.clients,
})

const mapDispatchToProps = (payload) => {
    
    return { 
            login : bindActionCreators (login, payload),
            setTitle : bindActionCreators (setTitle, payload),
        };

}

const classes = theme => ({
    root: {
        color: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '90vh',
    },
    title: {
        textAlign: 'center',
        marginBottom: '4em',
    },
    version: {
        textAlign: 'center'
    }
})
export default connect (mapStateToProps, mapDispatchToProps)(withStyles (classes) (AuthenticationPage));
