import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {format} from 'date-fns'
import localeFr from 'date-fns/locale/fr'
import {withStyles,
        Box, Typography, IconButton, Tooltip
        } from '@material-ui/core'
import Edit from '@material-ui/icons/Edit'
import LetterComposition from '../LetterComposition'
import OffensesDriver from '../OffensesDriver'
import {setError} from '../../../redux/actions/WindowAppActions'
import { connect } from 'react-redux';
class OffensesGrid extends Component {
  static propTypes = {
    offenses: PropTypes.array.isRequired,
    period: PropTypes.object.isRequired,
    driver: PropTypes.object.isRequired,
  }

  state = {
      composition: false,
  }

  handleComposition = () => {
      this.setState({
          composition: !this.state.composition
      })
  }
    render() {
        const sBegin = format(this.props.period.begin, 'P', {locale: localeFr})
        const sEnd = format(this.props.period.end, 'P', {locale: localeFr})
        const btLetterPrint = <Tooltip title="Imprimer une lettre personnalisée">
            <IconButton onClick={this.handleComposition}>
                <Edit />
            </IconButton>
        </Tooltip>
        const title=`${this.props.driver.forename} ${this.props.driver.name} - Infractions du ${sBegin} au ${sEnd}`        
        const titleBox = (this.props.offenses.length === 1) ?
                            "L'infraction commise" :
                            `Les ${this.props.offenses.length} infractions commises`
                            
        return (
            <Box margin={1} className={this.props.classes.root}>
                <Typography variant='h6' component="div" className={this.props.classes.titleGrid}>
                    {titleBox}
                </Typography>
                {this.state.composition ? 
                    <LetterComposition 
                        title={title}
                        onClose={this.handleComposition}
                        data={this.props.offenses}
                        driver={this.props.driver}
                        period={{
                            begin: sBegin,
                            end: sEnd,
                        }}
                        /> :
                    <OffensesDriver 
                        title={title}
                        data={this.props.offenses} 
                        buttons={[btLetterPrint]} 
                        />
                }
            </Box>
        )
    }

}

const classes = theme => ({
    root: {
        backgroundColor: theme.palette.secondary.light,padding: '1em',
    },
    titleGrid: {
        marginBottom: '0.5em',
        textAlign: 'center',
    }
})

const mapDispatchToProps = ({
    setError
})
export default connect(null, mapDispatchToProps) (withStyles(classes) (OffensesGrid))
