import React, { Component } from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom';
import Identity from '../core/components/identity/Identity';
import Assistance from '../core/components/assistance/Assistance'
import Galaxy from '../core/components/assistance/Galaxy'
import IconButtonRoute from '../core/components/navigation/IconButtonRoute';
import ButtonFullScreen from '../core/components/window/ButtonFullScreen';
import InspectInfos from '../core/components/window/InspectGrid'
import {withStyles} from '@material-ui/core'

class Header extends Component {

  render() {

    return (
      <header className={this.props.classes.root}>
        <div className={this.props.classes.logo}>
          <Link to="/">
            <img src="/images/athmo.svg" className="imgLogo" alt="logo Athmo"/>
          </Link>
        </div>
        {this.props.title && (
          <h2>Optimal Web - {this.props.title}</h2>
        )}
        <div className={this.props.classes.athmoMenu}>
            <InspectInfos />
            <ButtonFullScreen />
            { (this.props.isConnected && this.props.idClient > 0) && <IconButtonRoute to="/" icon="home" color="primary" title="Accueil" />}
            <Assistance />
            <Galaxy />
            <Identity />
        </div>
        
      </header>
    )
  }
}

const mapStateToProps = state => {
  return {
    isConnected : state.identity.isConnected,
    idClient: state.identity.idClientEnCours,
    title : state.windowApp.title,
  }
}

const classes = theme => ({
  root: {
    color: theme.palette.primary.main,
    height: 'calc(9vh - 2px)',
    minHeight: '26px',
    backgroundColor: 'white',
    borderBottom: '2px solid #092F6A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    margin : 0,
    '&& img': {
      height: 'calc(9vh - 2px)'
    }
  },
  athmoMenu: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& a:-webkit-any-link': {
      color: '-webkit-link',
      cursor: 'pointer',
      textDecoration: 'none',
    }
  },

})

export default connect (mapStateToProps) (withStyles(classes) (Header))