import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {Container, Grid,  Tab, withStyles,
        } from '@material-ui/core'
import {TabContext, TabList, TabPanel,} from '@material-ui/lab'
import {setTitle} from '../../core/redux/actions/WindowAppActions'
import TransportCode from '../../core/components/parameters/TransportCode'
import AlertsParameters from '../../core/components/parameters/AlertsParameters';
import AlertsParametersHelp from '../../core/components/parameters/AlertsParametersHelp'
import HelpButtonForTab from '../../core/components/window/HelpButtonForTab'
import GroupsParameter from '../../core/components/parameters/GroupsParameter';
import GroupsParameterHelp from '../../core/components/parameters/GroupsParameterHelp';
import OthersParameters from '../../core/components/parameters/OthersParameters'
import OthersParametersHelp from '../../core/components/parameters/OthersParametersHelp';

class CompanySettings extends Component {

    state = {
        tabIndex: 'alerts',
        regulationTab: false,
        tabs: [],
        displayHelp: false,
    }

    componentDidMount () {
        
        this.props.setTitle("Paramètres liées à l'entreprise")

        const tabs = [
            {   label: 'Alertes',
                value: 'alerts'
            },
            {
                label: "Gestion par groupes",
                value: "groups"
            },
            {
                label: 'Autres',
                value: 'others'
            }
        ]
        
        this.updateInformations(tabs)

        /*
         * ouverture sur l'onglet désiré
         */
        if (this.props.match.params.tabName) {
            this.setState({
                tabIndex: this.props.match.params.tabName,
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.services !== this.props.services) {
            this.updateInformations()
        }
    }

    updateInformations = (tabs) => {
        
        const regulationIsActive = (this.props.services) ? this.props.services.findIndex(service => service.nom === 'reglementation') >= 0 : false
        let newTabs = tabs ? tabs : this.state.tabs
        let activeTab = this.state.tabIndex

        if (regulationIsActive) {
            const indexRegulationTab = 'regulation'
            const tabRegulation = {
                label: 'Réglementation',
                value: indexRegulationTab,
            }            
            newTabs.unshift(tabRegulation)
            activeTab = indexRegulationTab
        }

        this.setState({
            tabs: newTabs,
            regulationTab: regulationIsActive,
            tabIndex: activeTab
        })
    }
    handleChangeTab = (evt, value) => {
        this.setState({
            tabIndex: value,
        })
    }

    handleHelp = () => {
        this.setState({
            displayHelp: !this.state.displayHelp,
        })
    }
    render() {
        
        return (
        <Container>
            <TabContext value={this.state.tabIndex}>
                <div className={this.props.classes.toolbar}>
                    <TabList onChange={this.handleChangeTab} >                   
                        {this.state.tabs?.map(tab => {
                            return <Tab key={tab.value} value={tab.value} label={tab.label} />
                        })}
                    </TabList>
                    <HelpButtonForTab value={this.state.tabIndex}>
                        <AlertsParametersHelp value='alerts' />
                        <GroupsParameterHelp value="groups" />
                        <OthersParametersHelp value="others" />
                        {/* ajouter ici les panneaux d'aide des autres onglets */}
                    </HelpButtonForTab>
                </div>

                {this.state.regulationTab &&
                    <TabPanel value='regulation'>
                        <Grid container>
                            <Grid item>
                                <TransportCode />
                            </Grid>

                        </Grid>
                    </TabPanel>
                }

                <TabPanel value="alerts">
                    <AlertsParameters />
                </TabPanel>
                
                <TabPanel value="groups">
                    <GroupsParameter />
                </TabPanel>

                <TabPanel value="others">
                    <OthersParameters />
                </TabPanel>

            </TabContext>
        </Container>
        )
    }

}

const mapStateToProps = state => ({
    services: state.client.services,
})
const mapDispatchToProps = ({
    setTitle,
})

const classes = ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    }
})
export default connect (mapStateToProps, mapDispatchToProps) (withStyles(classes) (withRouter (CompanySettings)))
