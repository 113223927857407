import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, IconButton, Popover} from '@material-ui/core';
import { URL_API, setOverlapsLoading, setOverlapsValues } from '../../redux/actions/DataActions'
import {setError} from '../../redux/actions/WindowAppActions'
import {dateToString} from '../../redux/actions/adapters/dateAdapters'
import { connect } from 'react-redux';
import BaseService from '../../../services/BaseService'
import ShuffleIcon from '@material-ui/icons/Shuffle'
import OverlapsGrid from './OverlapsGrid';
import { format } from 'date-fns';
import { DATE_HEURE_LOCALE } from '../Constants';
class Overlaps extends Component {
	static propTypes = {

		/**
		 * Date de référence ou début de la période
		 * @type	Date
		 */
		begin: PropTypes.objectOf(Date).isRequired,

		/**
		 * Date de fin de la période
		 * @type	Date
		 */
		end: PropTypes.objectOf(Date),

		/**
		 * Id du conducteur
		 */
		id: PropTypes.number,

		/**
		 * Indique l'usage d'un bouton pour afficher une carte contenant la liste des chevauchements
		 */
		button: PropTypes.bool,

	}

	state = {
		displayGrid: true,
	}

	componentDidMount() {
		this.setState({
			displayGrid: !this.props.button
		})

		if (this.props.id > 0) {
			this.loadInformations()
		}

	}
	componentDidUpdate(prevProps, prevState) {
		
		if ( (this.props.id > 0) 
				&& ( (prevProps.dateRef !== this.props.dateRef)
						|| (prevProps.id !== this.props.id)
					) 
		) {
			this.loadInformations()
		}
	}

	loadInformations = () => {

		const service = new BaseService();
		const sBegin = dateToString(this.props.begin)
		let url = `${URL_API}/driver/${this.props.id}/activities/problems/${sBegin}`

		if (this.props.end) {
			const sEnd = dateToString(this.props.end)
			url += `/${sEnd}`
		}

		this.props.setOverlapsLoading(true)
		
		service.get(url).then((data) => {

			/*
			 * formatage en heure locale, avec une précision à la minute
			 */
			data.map(act => {
				const start = new Date(act.start)
				const end = new Date(act.end)
				const dur = Math.ceil(Math.abs(start - end) / 60000)		/* valeur en millisecondes arrondie à la minute */
				
				act.start = format(start, DATE_HEURE_LOCALE)
				act.end = format(end, DATE_HEURE_LOCALE)
				act.dur = dur
				return act
			})
			this.props.setOverlapsValues(data)
		}).catch(error => this.props.setError(error))

	}

	handleDisplay = () => {
		this.setState({
			displayGrid: !this.state.displayGrid,
		})
	}

	render() {
		
		if (this.props.values.length === 0) {
			return null
		} else {
			
			return (<>
				{this.props.button ?
					<div className={this.props.classes.overlapsWarning}>
						<IconButton 
								onClick={this.handleDisplay}
								title="Visualiser les événements de chevauchement"
								className={this.props.classes.noPrintButton}
								>
							<ShuffleIcon />
						</IconButton>
					</div> : 
					<div className={this.props.classes.paper}>
						<OverlapsGrid
							loading={this.props.loading}
							data={this.props.values}
							selection={{
								id: this.props.id,
								dateRef: this.props.begin,
							}}
						/>
					</div>
				}
			
				<Popover
						open={this.state.displayGrid}
						anchorReference='anchorPosition'
						anchorPosition={{
							left: 1100,
							top: 5
						}}
						onClose={this.handleDisplay}
						transition="false"
						>
					<OverlapsGrid
							loading={this.props.loading}
							data={this.props.values}
							selection={{
								id: this.props.id,
								dateRef: this.props.begin,
							}}
							onClose={this.handleDisplay}
						/> 
				</Popover>
				
			</>)
		}

	}
}

const mapStateToProps = state => ({
	loading: state.data.overlapsLoading,
	values: state.data.overlapsValues,
})

const mapDispatchToProps = ({
	setError,
	setOverlapsLoading,
	setOverlapsValues,
})

const classes = {
	root: {

	},
	overlapsWarning: {
		display: 'flex',
		alignItems: 'center',
	},
	noPrintButton: {
		'@media print': {
			display: 'none'
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (Overlaps))
