import {URL_API, API, METHOD_DOWNLOAD} from './DataActions'
import {dateToString} from './adapters/dateAdapters'

export const downloadArchive = (fileName, begin, end, drivers, vehicles) => {

    return {        
        type: API,
        payload: {
            url: `${URL_API}/files/archive`,
            method: METHOD_DOWNLOAD,
            params: {
                fileName: fileName,
                begin: dateToString(begin),
                end: dateToString(end),
                drivers: drivers,
                vehicles: vehicles,
            },
            loadingEnd: true,
            cancelable: true,
            // onSuccess: setData,
            // paramOnSuccess: {},
        }
    }

    
}