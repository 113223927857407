import React, { Component } from 'react'
import {withStyles, Container, TextField} from '@material-ui/core'
class ExportParameters extends Component {

  state = {
    separator: localStorage.getItem('CSV_SEPARATOR')
  }
    onChange = (evt) => {
      let value = evt.target.value
      if (value.length === 1) {
        localStorage.setItem('CSV_SEPARATOR', value)       
      }
      this.setState({
        separator: value
      })
    }
  render() {
    return (
      <Container className={this.props.classes.root}>
          <h2>Export en CSV</h2>
          <TextField 
                label="Séparateur" 
                type="text" 
                inputProps={{
                  maxLength: 1,
                }} 
                onChange={this.onChange}
                value={this.state.separator}
                error={this.state.separator.length !== 1}
                />

      </Container>
    )
  }
}

const classes = theme => ({
    root: {
        color: theme.palette.primary.main,
    }
})
export default withStyles(classes) (ExportParameters)
