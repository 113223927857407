import React, { Component } from 'react'
import {withStyles, Tooltip, Grid,
       InputLabel, Select, MenuItem, Switch,
    } from '@material-ui/core'
import DriverIcon from '@material-ui/icons/Face'
import VehicleIcon from '@material-ui/icons/LocalShipping'


class SearchParametersTool extends Component {

  state = {
    driverField : localStorage.getItem ('OW_Search_driverField'),
    vehicleField : localStorage.getItem('OW_Search_vehicleField'),
    driverPriority : (localStorage.getItem('OW_Search_driverPriority') === 'true'),
  }

  handleChange = (event) => {
    event.preventDefault ()

    const destination = `OW_Search_${event.target.name}`
    localStorage.setItem([destination], event.target.value)

    this.setState ({
      [event.target.name] :  [event.target.value]
    })

    /*
     * propagation
     */
    if (this.props.onChange) {
      this.props.onChange ({
        name : event.target.name,
        value : event.target.value
      })
    }

  }

  handleChangePriority = (event) => {

    event.preventDefault ()

    localStorage.setItem('OW_Search_driverPriority', event.target.checked)
    this.setState({
      driverPriority : event.target.checked
    })

  }
  render() {
    return (
        <Grid container direction="column" className={this.props.classes.root}>
            <h2>Paramètres de recherche</h2>
            <Grid item className={this.props.classes.grid}>
              <Tooltip title="Champ utilisé pour la recherche des conducteurs">
                <InputLabel id="field-use-driver-label"><DriverIcon /></InputLabel>
              </Tooltip>
              <Select id="field-use-driver"
                  labelId="field-use-driver-label"
                  value={this.state.driverField}
                  name="driverField"
                  onChange={this.handleChange}>
                  <MenuItem key="code" value="drv_code">code</MenuItem>
                  <MenuItem key="nom" value="name">Nom</MenuItem>
                  <MenuItem key="prenom" value="forename">Prénom</MenuItem>
                  <MenuItem key="carte" value="card_number">Numéro de carte</MenuItem>
              </Select>
            </Grid>

            <Grid item className={this.props.classes.grid}>
                <Tooltip title="Champ utilisé pour la recherche des véhicules">
                  <InputLabel id="field-use-vehicle-label"><VehicleIcon /></InputLabel>
                </Tooltip>
                <Select id="field-use-vehicle"
                      labelId="field-use-vehicle-label"
                      name="vehicleField" 
                      value={this.state.vehicleField}
                      onChange={this.handleChange}>
                    <MenuItem key="code" value="veh_code">code</MenuItem>
                    <MenuItem key="veh_reg_no" value="veh_reg_no">Immatriculation</MenuItem>
                </Select>
            </Grid>

            <Grid item className={this.props.classes.grid}>
              <Tooltip title="Priorité de la recherche">
                <InputLabel id="priority-search">Priorité de la présentation</InputLabel>
              </Tooltip>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item >
                    <Tooltip title="Véhicule">
                        <VehicleIcon />
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Switch checked={this.state.driverPriority}
                        onChange={this.handleChangePriority} 
                        name="driverPriority" 
                        color="primary" />
                </Grid>
                <Grid item >
                    <Tooltip title="Conducteur">
                        <DriverIcon />
                    </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          
          </Grid>
            
    )
  }
}

const styles = theme => ({
  root : {
    color: theme.palette.primary.main,
  },
  grid: {
    marginTop: '1em',
    
  }
})
export default withStyles (styles) (SearchParametersTool)
