
import React, { Component } from 'react'
import { Container, Toolbar } from '@material-ui/core';
import HelpButton from '../../core/components/window/HelpButton';
import HelpCard from '../../core/components/window/HelpCard';
import IntervalSelector from '../../core/components/forms/archivage/telechargements/IntervalSelector';
import {setTitle, setError} from '../../core/redux/actions/WindowAppActions'
import { connect } from 'react-redux';
import {VEHICLE_TYPE} from '../../core/components/Constants'
import Kilometers from '../../core/components/optigestweb/Kilometers'

class Kilometrages extends Component {

	state = {
		selection: null,
	}

	componentDidMount() {
		this.props.setTitle('Kilométrages')
	}
	handleSelection = (selection) => {
		
		this.setState({
			selection: selection,
		})
	}
	render() {
		return (
			<Container >
				<Toolbar>
					<IntervalSelector 
						onChange={this.handleSelection} 
						uniqueType={VEHICLE_TYPE} />
					<HelpButton>
						<HelpCard title="Aide de  l'écran">
							<p>
								Visualisation des informations du compteur kilométrique enregistrées quotidiennement.
							</p>
						</HelpCard>
					</HelpButton>
				</Toolbar>
				<Kilometers
					selection={this.state.selection}
					/>

			</Container>
		)
	}
}

const mapDispatchToProps = ({
	setTitle,
	setError,
})

export default connect(null, mapDispatchToProps) (Kilometrages)
