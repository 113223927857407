import React from 'react'
import {withTheme} from '@material-ui/core'

const DriverOooIcon = React.forwardRef( (props, ref) => {
  
  let {size, color, theme, ...propsComponent} = props 
  size = size ? size : 1
  color = color ? color : theme.palette.primary.main

  return (
    <span ref={ref} {...propsComponent  }>
      <svg width="24" height="24" version="1.1" viewBox="0 0 6.35 6.35" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(0 -290.65)" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="3.4">
          <ellipse cx="3.2072" cy="293.82" rx="2.8" ry="2.8" fill={color} stroke={color} strokeWidth=".092105"/>
          <rect x="2.6458" y="291.98" width="1.04" height="3.7" ry="3.0812e-6" fill="#fff" stroke="#fff" strokeWidth=".1"/>
          <rect x="1.3229" y="293.3" width="3.7" height="1.04" ry="3.0812e-6" fill="#fff" stroke="#fff" strokeWidth=".1"/>
        </g>
      </svg>
    </span>
  )
  
})

export default withTheme(DriverOooIcon)
