import { Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import IdentityIcon from '@material-ui/icons/AccountBox'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { withStyles, Divider } from '@material-ui/core'
import React, { Component } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ButtonRoute from '../navigation/ButtonRoute';
import './Identity.css';
import { bindActionCreators } from 'redux';
import { logout } from '../../../core/redux/actions/IdentityActions';
import {sLabelRoll, LabelRoll} from '../tools/LabelRoll';
import { U_ADMINISTRATEUR_ATHMO } from '../Constants';

class Identity extends Component {
    state = {
        open : false,       
    }
    
    changeDisplay = () => {

        this.setState ({open : !this.state.open});

    }

    logout = () => {

        this.props.logout ().then ( () => {
            this.props.history.push('/')
        })
        

    }
    render() {
    
        const userInfo = `${this.props.user?.nom} ${this.props.user?.prenom} (${this.props.user?.courriel}) - ${sLabelRoll(this.props.user?.roles)}`
        const client = this.props.clients && this.props.clients[this.props.idEnCours]

        return (
           <>
                {this.props.isConnected ?
                    (<>
                        <Tooltip  title={userInfo}>
                            <IconButton 
                                    onClick={this.changeDisplay} 
                                    variant="p" 
                                    color="primary" 
                                    >
                                <IdentityIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Se déconnecter">
                            <IconButton 
                                    variant="text"
                                    color="primary" 
                                    onClick={this.logout}>
                                <ExitToAppIcon />
                            </IconButton>
                        </Tooltip>
                        <Dialog
                                open={this.state.open}
                                onClose={this.changeDisplay}
                                aria-labelledby="display-identity"
                                aria-describedby="Affichage de l'identité" >
                            <DialogTitle>
                                <IdentityIcon />
                                Qui suis-je 
                                <IconButton 
                                        title="Fermer"
                                        aria-label="close" 
                                        onClick={this.changeDisplay} 
                                        className={this.props.classes.closeButton}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent dividers>
                                <div>
                                    <div className="fnIdentite">
                                        <div>Nom : {this.props.user?.nom}</div>
                                        <div>Prénom : {this.props.user?.prenom}</div>
                                        <div>Courriel : {this.props.user?.courriel}</div>
                                    </div>
                                    <div>
                                        Rôle : <LabelRoll label={this.props.user?.roles} />
                                    </div>
                                    {client && 
                                        <div>
                                            <Divider />
                                            Entreprise : {client.nom_client}
                                            {this.props.user.roles === U_ADMINISTRATEUR_ATHMO &&
                                                <div>
                                                    <div>id client = {client.id_client}</div>
                                                    <div>carte société = {client.code_societe}</div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button className={this.props.classes.button}
                                        onClick={this.logout} title="Se déconnecter">
                                    <ExitToAppIcon />Se déconnecter
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </>
                    ) :
                    (this.props.history.location.pathname !== '/login' && 
                        <ButtonRoute 
                                to="/login" 
                                color="primary" 
                                icon="login" 
                                title="Connexion à Optimal Web"
                                />
                       
                    )
                }
            </>
        )
  }
}

const styles = (theme) => ({
    closeButton : {
        position : 'absolute',
        right : theme.spacing(1),
        top : theme.spacing(1),
    },
    button: {
        color: theme.palette.primary.main,
    }
});

const mapStateToProps = state => {
    return {
        isConnected : state.identity.isConnected,
        user : state.identity.user,
        idEnCours: state.identity.idClientEnCours,
        clients: state.identity.clients,
    }
}

const mapDismatchToProps = (payload) => {
    return {
        logout : bindActionCreators(logout, payload)
    }
}
export default connect(mapStateToProps, mapDismatchToProps) (withRouter (withStyles(styles) (Identity)));