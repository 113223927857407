import {URL_API, API, METHOD_GET, METHOD_POST, METHOD_PUT,
    setData,
    } from './DataActions'
    
export const REGULATION_RESET = 'REGULATION_RESET'
export const REGULATION_SET_PARAMETERS = 'REGULATION_SET_PARAMETERS'
export const REGULATION_ACTUAL_TRANSPORT = 'REGULATION_ACTUAL_TRANSPORT'

export const resetRegulation = () => ({
    type: REGULATION_RESET,
    payload: null,
})

export const calculateOffenses = (beginDate, endDate, drivers) => {

    const sBegin = beginDate.toISOString()
    const sEnd = endDate.toISOString()

    return {
        type: API,
        payload: {
            url: `${URL_API}/regulation/offenses`,
            method: METHOD_POST,
            params: {
                beginDate: sBegin,
                endDate: sEnd,
                drivers: drivers
            },
            cancelable: true,
            onSuccess: setData,
        }
    }
}

export const setParametersTransports = (payload) => ({
    type: REGULATION_SET_PARAMETERS,
    payload: payload,
})

export const setActualTransport = (payload) => ({
    type: REGULATION_ACTUAL_TRANSPORT,
    payload: payload,
})
//$router->get ('regulation/transports', 'RegulationController@getTypesTransport');
// $router->get ('regulation/types', 'RegulationController@getReglementations');
// $router->get ('regulation/actual', 'RegulationController@getCodeActualRegulation');
// $router->get ('regulation/parameters', 'RegulationController@getParameters');
export const getTransports = () => ({
    type: API,
    payload: {
        url: `${URL_API}/regulation/transports`,
        method: METHOD_GET,
        onSuccess: setParametersTransports,
    }
})
export const getTransportTypes = () => ({
    type: API,
    payload: {
        url: `${URL_API}/regulation/types`,
        method: METHOD_GET,
        onSuccess: setData,
    }
})

export const getActualTransport = () => ({
    type: API,
    payload: {
        url: `${URL_API}/regulation/transport`,
        method: METHOD_GET,
        onSuccess: setActualTransport,
    }
})

export const saveTransportToAPI = (code) => ({
    type: API,
    payload: {
        url: `${URL_API}/regulation/transport/${code}`,
        method: METHOD_PUT,
    }
})
// export const saveTransport = (code) => (dispatch) => {

//     dispatch(saveTransportToAPI(code))
//     dispatch(setActualTransport(code))

    
// }
const saveTransportToLocal = (vide, code) => dispatch => {
    dispatch(setActualTransport(code))
}
export const saveTransport = (code) => ({
    type: API,
    payload: {
        url: `${URL_API}/regulation/transport/${code}`,
        method: METHOD_PUT,
        onSuccess: saveTransportToLocal,
        paramOnSuccess: code
    }
})
export const getActualRegulationAndParameters = () => ({
    type: API,
    payload: {
        url: `${URL_API}/regulation/actual`,
        method: METHOD_GET,
        cancelable: true,
        onSuccess: setData,
    }
})