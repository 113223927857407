import React, { Component } from 'react'
import {Tooltip} from '@material-ui/core'
import ManualSourceIcon from '@material-ui/icons/PanTool'

/**
 * Source = entrée manuelle
 * numéro = 9
 */
class ManualSource extends Component {

  render() {
    return (
      <Tooltip title="Entrée manuelle">
        <ManualSourceIcon />
      </Tooltip>
    )
  }
}

export default ManualSource
