import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withStyles, Paper, TableContainer, Table, TableBody,
        } from '@material-ui/core'
import HeaderGrid from './Header'
import Row from './Row'
import format from 'date-fns/format'
import localeFr from 'date-fns/locale/fr'
class Results extends Component {
  
  static propTypes = {
    period: PropTypes.object.isRequired,
    titleDisplayed: PropTypes.bool,
  }

  state = {
    title: null
  }

  componentDidMount() {
    this.composeTitle()
  }
  componentDidUpdate(prevProps, prevState) {

    /*
     * nouveau calcul
     */
    if (prevProps.data !== this.props.data) {
      this.composeTitle()
    }
  }

  composeTitle = () => {

    /*
     * création d'un titre de tableau lors du changement de la période sélectionnée
     */
    const sBegin = format(this.props.period.begin, 'P', {locale: localeFr})
    const sEnd = format(this.props.period.end, 'P', {locale: localeFr})
    this.setState({
      title: `Période du ${sBegin} au ${sEnd}`
    })
    
  }
  render() {
    return (
      <TableContainer component={Paper} >
        <Table>
            <HeaderGrid title={this.state.title} titleDisplayed={this.props.titleDisplayed} />
            <TableBody>
              {Object.keys(this.props.data).map(driverCode => {
                const key=`r${driverCode}`
                const driver=this.props.drivers.find(elt => elt.drv_code === driverCode)
                return <Row key={key} id={key} driver={driver} offenses={this.props.data[driverCode]} period={this.props.period} />
              })}
            </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

const mapStateToProps = state => ({
  drivers: state.client.drivers,
  data: state.data.values,
})

const classes = theme => ({
    root: {

    }
})
export default connect (mapStateToProps) (withStyles(classes) (Results))
