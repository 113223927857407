import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withStyles,
        } from '@material-ui/core'
import HelpCard from '../../window/HelpCard'
import DriverCardIcon from '../../icons/DriverCardIcon'
import DriverHolidayIcon from '../../icons/DriverHolidayIcon'
import DriverOooIcon from '../../icons/DriverOooIcon'
import OutIcon from '../../icons/OutIcon'
import VehicleIcon from '../../icons/VehicleIcon'
import VehicleRepairIcon from '../../icons/VehicleRepairIcon'
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff'
import WarningIcon from '@material-ui/icons/Warning'
import OverIcon from '../../icons/OverIcon'
import FullIcon from '../../icons/FullIcon'
import {DRIVER_TYPE} from '../../Constants'

export class CalendarsHelpCard extends Component {

    static propTypes = {
        type: PropTypes.string.isRequired,
    }
  render() {
    return (
        <HelpCard title="Icônes" 
                onClose={this.props.onClose} >
            <ul className={this.props.classes.list}>
                {this.props.type === DRIVER_TYPE ? (
                    <>
                        <li><DriverCardIcon className={this.props.classes.icon} color="green"/>Date de téléchargement</li>                        
                        <li><DriverCardIcon className={this.props.classes.icon} color="orange" />Date proposée</li>
                        <li><DriverCardIcon className={this.props.classes.icon} />Date choisie</li>
                        <li><DriverCardIcon className={this.props.classes.icon} color="red" />Date limite légale</li>
                        <li><DriverHolidayIcon className={this.props.classes.icon} />En congés</li>
                        <li><DriverOooIcon className={this.props.classes.icon} />En arrêt maladie</li>
                        <li><FlightTakeoffIcon className={this.props.classes.icon} />Hors entreprise</li>                        
                    </>
                ) : (
                    <>
                        <li><VehicleIcon className={this.props.classes.icon} color='green' />Date de téléchargement</li>
                        <li><VehicleIcon className={this.props.classes.icon} color="orange" />Date proposée</li>
                        <li><VehicleIcon className={this.props.classes.icon} />Date choisie</li>
                        <li><VehicleIcon className={this.props.classes.icon} color="red" />Date limite légale</li>
                        <li><VehicleRepairIcon className={this.props.classes.icon} />En entretien</li>
                        <li><FlightTakeoffIcon className={this.props.classes.icon} />Hors entreprise</li>
                    </>
                )}
                <li><OutIcon className={this.props.classes.icon} />Hors contrat</li>
                <li><WarningIcon htmlColor="red"  className={this.props.classes.icon} />Perte de données</li>
                <li><OverIcon color="orange"  className={this.props.classes.icon}/>Dépassement de la date proposée</li>
                <li><OverIcon color="red"  className={this.props.classes.icon}/>Dépassement de la date limite légale</li>
                <li><FullIcon className={this.props.classes.today} />Aujourd'hui</li>
                <li><FullIcon className={this.props.classes.sunday} />Dimanche</li>
                <li><FullIcon className={this.props.classes.noday} />Jour n'existant pas</li>
            </ul>
        </HelpCard>
 
    )
  }
}

const classes = theme => ({
  
    list: {
        listStyleType: 'none',
        '& li': {
            display: 'flex',
            alignItems: 'center',
        }
       
    },
    icon: {
        marginRight: '0.4em',
    },
    today: {
        fill: theme.palette.secondary.light,
        marginRight: '0.4em',
    },
    sunday: {
        fill: 'gainsboro',
        marginRight: '0.4em',
    },
    noday: {
        fill: theme.palette.primary.main,
        marginRight: '0.4em',
    }
    
})
export default withStyles (classes) (CalendarsHelpCard)
