import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withStyles, Container, Toolbar,
        Grid,
        } from '@material-ui/core'
import {DataGrid, ToolbarOptions,} from 'tubular-react'
import WaitBlock from '../../core/components/window/WaitBlock'
import HelpCard from '../../core/components/window/HelpCard'
import HelpButton from '../../core/components/window/HelpButton'
import UserForm from '../../core/components/resources/UserForm'
import AddUserForm from '../../core/components/resources/AddUserForm'
import ButtonAdd from '../../core/components/window/ButtonAdd'
import {U_ADMINISTRATEUR} from '../../core/components/Constants'
import {isUserAllow} from '../../core/components/tools/User'

import {setTitle, setError} from '../../core/redux/actions/WindowAppActions'
import {getUsers, 
        updateNameUser, updateForenameUser, updateEmailUser,
        updateFunctionUser, updateAlertUser, updateRollUser,
        } from '../../core/redux/actions/ClientActions'
import { createColumn, ColumnDataType, ColumnSortDirection } from 'tubular-common';


class Users extends Component {

    state = {
        displayEditUser: false,
        displayAddUser: false,
        editUserId: 0,
        refreshDataGrid: 0,
    }
    componentDidMount() {
        this.props.setTitle('Utilisateurs')

        this.props.getUsers()
    }

    getColumns = () => {
        return [
            createColumn('id', {
                isKey: true,
                dataType: ColumnDataType.Numeric,
                visible: false,
            }),
            createColumn('nom', {
                label: 'Nom',
                sortOrder: 1,
                sortable: true,
                sortDirection: ColumnSortDirection.Ascending,
                searchable: true,
            }),
            createColumn('prenom', {
                label: 'Prénom',
                sortOrder: 2,
                sortable: true,
                sortDirection: ColumnSortDirection.Ascending,
                searchable: true,
            }),
            createColumn('role', {
                label: 'Rôle(s)',
                sortable: true,
                sortOrder: 3,
                visible: false,
                searchable: true,
            }),
            createColumn('sRoles', {
                label: 'Rôle(s)',
                sortable: true,
                sortOrder: 3,
            })
        ]
    }

    onRowClick = (user) => {
        this.setState({
            displayEditUser: true,
            editUserId: user.id
        })
    }

    onCloseEditUser = () => {
        this.setState({
            displayEditUser: !this.state.displayEditUser,
        })
    }
    onChangeEditUser = (changes) => {
        
        if (changes.name) {
            this.props.updateNameUser(this.state.editUserId, changes.name)
        }
        if (changes.forename) {
            this.props.updateForenameUser(this.state.editUserId, changes.forename)
        }
        if (changes.email) {
            this.props.updateEmailUser(this.state.editUserId, changes.email)
        }
        if (changes.function) {
            this.props.updateFunctionUser(this.state.editUserId, changes.function)
        }
        if (changes.alert) {
            this.props.updateAlertUser(this.state.editUserId, changes.alert)
        }
        if (changes.roll) {
            this.props.updateRollUser(this.state.editUserId, changes.roll)
        }

        /*
         * actualisation grille (refresh)
         */
        this.setState({
            refreshDataGrid: this.state.refreshDataGrid+1,          /* la mise à jour du compteur de rafraîchissement provoque un rafraichissement de la grille */
        })
    }

    handleAddUser = () => {
        this.setState({
            displayAddUser: !this.state.displayAddUser,
        })
    }
    render() {
        
        const btAddUser = isUserAllow(this.props.actualUser.roles, U_ADMINISTRATEUR) &&
                <ButtonAdd key='btAdd' title='Ajouter un utilisateur' onClick={this.handleAddUser} />

        const toolbarOptions = new ToolbarOptions({
            enablePagination: false,
            itemsPerPage: 10000,
            title: 'Utilisateurs gérés',
            customItems:[btAddUser]
        });

        return (
            <Container>
                <Toolbar className={this.props.classes.toolbar}>
                    <HelpButton>
                        <HelpCard title="Aide de l'écran" >
                            <div className={this.props.classes.memo}>
                                Cet écran permet de visualiser et gérer les utilisateurs de l'application.
                            </div>
                        </HelpCard>
                    </HelpButton>
                </Toolbar>
                {!this.props.users ? 
                    <WaitBlock text='Chargement en cours' /> :
                    <Grid container spacing={3}>
                        <Grid item className={this.props.classes.grid}>
                            <DataGrid 
                                columns={this.getColumns()} 
                                dataSource={this.props.users}  
                                gridName="Utilisateurs" 
                                onError={(err) => {
                                    console.warn('Datagrid error', err)
                                    this.props.setError ('Datagrid error')
                                }}
                                deps={[this.state.refreshDataGrid]}
                                onRowClick={this.onRowClick}
                                toolbarOptions={toolbarOptions}
                                langKey="fr" 
                                 />
                                                
                        </Grid>
                        <Grid item>
                        {this.state.displayEditUser && 
                            <UserForm id={this.state.editUserId}
                                onClose={this.onCloseEditUser} 
                                onChange={this.onChangeEditUser} />
                        }
                        </Grid>
                        <Grid item>
                        {this.state.displayAddUser &&
                            <AddUserForm onClose={this.handleAddUser} /> 
                        }
                        </Grid>
                    </Grid>
                    
                }
                
            </Container>
        )
    }

}

const mapStateToProps = state => ({
    loading: state.data.loading,
    users: state.client.users,
    actualUser: state.identity.user,
})
const mapDispatchToProps = ({
    setTitle,
    setError,
    getUsers,
    updateNameUser,
    updateForenameUser,
    updateEmailUser,
    updateFunctionUser,
    updateAlertUser,
    updateRollUser,
})

const classes = theme => ({
    root: {

    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyItems: 'space-between',

    },
    grid: {
        '&& .MuiTableRow-hover': {
            cursor: 'pointer',
        }
    },
    memo: {
        textAlign: 'justify',
    }
})

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (Users))
