import React, { Component } from 'react'
import {withStyles, Button} from '@material-ui/core'
import BugIcon from '@material-ui/icons/BugReport'
import {detect} from 'detect-browser'
class ErrorBoundary extends Component {
    state = { 
        hasError: false,
        displayForm: false,
        error: null,
        errorInfo: null,
    }

    constructor(props) {
        super(props)
        this.state = {
            error: null,
            errorInfo: null
        }
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error }
    }
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service    
        // logErrorToMyService(error, errorInfo);
        console.debug('===ERROR===', error)
        console.debug('===ERRORINFO===', errorInfo)

        this.setState({
            error: error,
            errorInfo: errorInfo,
        })

        //@todo ajouter transmission via api
    }

    handleResetForm = () => {
        this.setState({
            displayForm: true
        })
    }

    resetNav = () => {
        localStorage.clear()
        sessionStorage.clear()
        this.setState({
            displayForm: false,
        })
    }
    render() {
        const browser = detect()
        console.info('Navigateur utilisé', browser)

        if (this.state.hasError) {      // You can render any custom fallback UI  
            const tel = "tel:+33483120009"
            const dest = `mailto:supportweb@athmo.eu?subject=OW3%20Erreur%20grave&body=URL%20=%20${encodeURI(window.location)}`
            return (<div className={this.props.classes.root}>
                <h1><BugIcon /></h1>
                <h2>Something went wrong.</h2>
                <p>Please call our support service at the phone number <a href={tel}>+33 4 83 12 00 06</a></p>
                <p>or, failing that, send an email to <a href={dest}>supportweb@athmo.eu</a></p>
                <p><BugIcon /><BugIcon /></p>
                <h2>Un problème grave est survenu.</h2>
                <p>Merci d'appeler notre service d'assistance au <a href={tel}>04 83 12 00 06</a></p>
                <p>À défaut, veuillez nous envoyer un <a href={dest}>courriel</a> décrivant les étapes précédant le problème 
                et recopier les informations destinées au support technique.</p>
                <p>Navigateur = {browser.name} {browser.version}&nbsp;-&nbsp;{browser.os}</p>
                <p className={this.props.clickable} onClick={this.handleResetForm}><BugIcon /> <BugIcon /> <BugIcon /> <BugIcon /></p>
                {this.state.displayForm && 
                    <Button onClick={this.resetNav}>Supprimer toutes les informations du navigateur</Button>
                }
                    <div>
                        <h5>Informations pour le support <em>Athmo</em></h5>
                        <details style={{whiteSpace: 'pre-wrap'}}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo && this.state.errorInfo.componentStack}
                        </details>
                    </div>
            </div>)
        } else {
            return this.props.children
        }
    }

}

const classes = theme => ({
    root: {
        color: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    clickable: {
        cursor: 'pointer',
    }
})

export default withStyles(classes) (ErrorBoundary)

