import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {IconButton, Tooltip} from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print'
class PrintButton extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  render() {
    return (
        <Tooltip title={this.props.title}>
            <IconButton onClick={this.props.onClick}>
                <PrintIcon />
            </IconButton>
        </Tooltip>
    )
  }

}

export default PrintButton