import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withStyles, Paper, CircularProgress,
        Toolbar, Tooltip, Grid,
        } from '@material-ui/core'
import LetterModule, {INTRODUCTION, CONCLUSION} from './LetterModule'
import OffensesDriver from './OffensesDriver'
import CloseButton from '../window/CloseButton'
import PdfButton from '../window/PdfButton'

class LetterComposition extends Component {
  static propTypes = {
    driver: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    period: PropTypes.object.isRequired,
    data: PropTypes.array,
  }

  state = {
    offenses: [],
    introduction: '',
    conclusion: '',
    creating: false,
  }
  
  handleModule = (type, text) => {
    if (type === INTRODUCTION) {
      this.setState({
        introduction: text,
      })
    } else {
      this.setState({
        conclusion: text,
      })
    }
  }

  handleSelect = (selection) => {
    this.setState({
      offenses: selection,
    })
  }

  startPdfExport = () => {

    this.setState({
      creating: true,
    })

    this.exportPdf().then( () => {
      this.setState({
        creating: false,
      })
    })

  }
  exportPdf = () => {
    
    return import('../pdf/exportPdf')
      .then (({exportOffensesLetter}) => {
        exportOffensesLetter ({
          company: this.props.company.nom_client,
          driver: this.props.driver,
          period: this.props.period,
          introduction:  this.state.introduction,
          conclusion: this.state.conclusion,
          data: this.state.offenses,
        })

        return Promise.resolve()

      })  

  }
  render() {

    return (
      <Paper className={this.props.classes.root}>
          <Toolbar className={this.props.classes.toolbar}>
            {this.state.creating ?
              <Tooltip title="Génération du fichier PDF en cours..." >
                <CircularProgress size={20} />
              </Tooltip> :
              <PdfButton title="Télécharger la lettre au format PDF"
                  onClick={this.startPdfExport} 
                  disabled={this.state.offenses.length === 0} 
                  />
            }
            <CloseButton
                title="Annuler"
                onClick={this.props.onClose}
                />
          </Toolbar>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <LetterModule 
                  type={INTRODUCTION} 
                  onChange={this.handleModule}/>
            </Grid>
            <Grid item md={6}>
              <LetterModule
                  type={CONCLUSION}
                  onChange={this.handleModule} />
            </Grid>
            <Grid item md={12}>
              <OffensesDriver 
                  title={this.props.title}
                  data={this.props.data} select all
                  onSelect={this.handleSelect} 
                  noButtons />
            </Grid>
          </Grid>
      </Paper>
    )
  }
}

const mapStateToProps = state => ({
  company: state.identity.clients[state.identity.idClientEnCours]  
})

const classes = {
    root: {
      padding: '0.5em 1em'
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    
}
export default connect(mapStateToProps) (withStyles(classes) (LetterComposition))
