import React, { Component } from 'react'
import {connect} from 'react-redux'
import {setTitle} from '../../core/redux/actions/WindowAppActions'

import {getMonthUploads} from '../../core/redux/actions/ElementsActions'

import {Container, Toolbar, FormControl,
        withStyles} from '@material-ui/core'

import WaitBlock from '../../core/components/window/WaitBlock'
import TypeSelection from '../../core/components/forms/select/TypeSelection'
import MonthPicker from '../../core/components/forms/date/MonthPicker';
import MonthCalendar from '../../core/components/archivage/calendriers/MonthCalendar'
import {DRIVER_TYPE, VEHICLE_TYPE, EVENT_TYPE} from '../../core/components/Constants'
import HelpButton from '../../core/components/window/HelpButton'
import HelpCard from '../../core/components/archivage/calendriers/CalendarsHelpCard'
import EventDialog from '../../core/components/archivage/EventDialog'
import EventButton from '../../core/components/window/EventButton'
import addMonths from 'date-fns/addMonths'
import endOfMonth from 'date-fns/endOfMonth'
import { reasonsDriver, reasonsVehicle } from '../../core/components/archivage/EventDialog'

class CalendrierMensuel extends Component {

  /*
   * état
   * Attention, il est mis à jour avec getDerivedStateFromProps
   */
  state = {
    driverSelected: true,
    examinedDate: new Date(),
    eventDialog: false,
    targetDay: null,          /* jour cible */
    targetElement: null,      /* Élément ciblé */
  }

  /**
   * procède à la mise en tableau des données issues de l'API
   * @param {object} props 
   * @param {object} state 
   */
  componentDidMount () {
    this.props.setTitle ('Calendrier mensuel')
    this.props.getMonthUploads(
        this.state.driverSelected ? DRIVER_TYPE : VEHICLE_TYPE,
        this.state.examinedDate)

  }

  handleEventDialog = (refresh) => {
    
    this.setState({
      eventDialog: !this.state.eventDialog,
    })
    
    /*
     * rechargement du calendrier (si demandé) lors de la fermeture de la fenêtre
     */
    if (typeof(refresh) === 'boolean' && refresh) {
      this.props.getMonthUploads(
        this.state.driverSelected ? DRIVER_TYPE : VEHICLE_TYPE,
        this.state.examinedDate
      )
    }

  }
  onChangeType = () => {
    this.setState({
      driverSelected: !this.state.driverSelected
    })
    this.props.getMonthUploads(
      !this.state.driverSelected ? DRIVER_TYPE : VEHICLE_TYPE,
      this.state.examinedDate
      )
  }

  onChangeMonth = (value) => {
    this.setState({
      examinedDate: value
    })
    this.props.getMonthUploads(
      this.state.driverSelected ? DRIVER_TYPE : VEHICLE_TYPE,
      value
    )

  }
  
  onCalendarClick = (targetInfos) => {
         
    let reason = ''

    /*
     * récupération d'un motif valide
     */
    if (targetInfos.state) {
      let validReason
      if (this.state.driverSelected) {
        validReason = reasonsDriver.filter(aReason => targetInfos.state.includes(aReason.value))
      } else {
        validReason = reasonsVehicle.filter(aReason => targetInfos.state.includes(aReason.value))
      }
      if (validReason.length > 0) {
        reason = validReason[0].value
      }
    }
    
    let targetElement = {
      id: targetInfos.id,
      type: (this.state.driverSelected) ? DRIVER_TYPE : VEHICLE_TYPE,
      reason: reason,
    }
      
    this.setState({
      targetDay: new Date(targetInfos.day),
      targetElement: targetElement,
      eventDialog: true,
    });

  }
  render() {
    const today = new Date()
    
    const maxDate = endOfMonth(addMonths(today, 6))
    
    return (
      <>
        <Container className={this.props.classes.root}>
          <Toolbar className={this.props.classes.toolbar}>
            <FormControl className={this.props.classes.selector} >
              <TypeSelection type={this.state.driverSelected ? DRIVER_TYPE : VEHICLE_TYPE} onChange={this.onChangeType}/>
              <MonthPicker onChange={this.onChangeMonth} minDate={this.props.companyCreation} maxDate={maxDate} />
            </FormControl>
            <FormControl className={this.props.classes.selector}>
              <EventButton 
                eventType={EVENT_TYPE.ABSENCE}
                onClick={this.handleEventDialog} />
              <HelpButton>
                <HelpCard type={this.state.driverSelected ? DRIVER_TYPE : VEHICLE_TYPE}/>
              </HelpButton>
            </FormControl>
          </Toolbar>
          {this.props.loading ?
              <WaitBlock /> :
              this.props.values ? (
                  <MonthCalendar 
                      refDate={this.state.examinedDate} 
                      refType={this.state.driverSelected ? DRIVER_TYPE : VEHICLE_TYPE} 
                      values={this.props.values} 
                      onClick={this.onCalendarClick}
                      />
                ) : (
                  <div>Veuillez effectuer une sélection</div>
                )
            }

          <EventDialog
            open={this.state.eventDialog}
            onClose={this.handleEventDialog}
            eventType={EVENT_TYPE.ABSENCE}
            type={this.state.driverSelected ? DRIVER_TYPE : VEHICLE_TYPE}
            month={this.state.examinedDate}
            day={this.state.targetDay}
            element={this.state.targetElement}
            reason={this.state.targetElement?.reason}
            />
        </Container>
      </>
    )
  }
}

const classes = theme => ({
  root: {
     color: theme.palette.primary.main,
  },
  toolbar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selector: {
    flexDirection: 'row',
  },
  headerCell: {
    lineHeight: '0.8rem'
  }
})

const mapStateToProps = state => ({
  loading: state.data.loading,
  companyCreation: state.client.informations.ow.creation,
  values: state.data.values,
  drivers: state.client.drivers,
  vehicles: state.client.vehicles,
})

const mapDispatchToProps = ({
    setTitle,
    getMonthUploads,
})

export default connect (mapStateToProps, mapDispatchToProps) (withStyles (classes) (CalendrierMensuel))
