import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ActivityIcon from '../../icons/ActivityIcon'
import {getActProps} from './DayActivitiesHistogram'

class ActivityLabel extends Component {
	static propTypes = {
		type: PropTypes.string.isRequired,
	}

	render() {
		const act = getActProps({activite: this.props.type})

		return (
			<div>
				<ActivityIcon type={this.props.type} duration={20} />
				<>&nbsp;{act.label}&nbsp;({act.initial})</>
			</div>
		)
	}
}

export default ActivityLabel
