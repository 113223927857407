export const formatToTime = (number) => {

    if (typeof(number) !== 'number') {
        return ''
    }
    if (number < 0) {
        
        return '??:??'          // datagramme indiquant un problème
    }

    return Number.parseInt(number/60).toString().padStart(2, '0') + ':' + (number%60).toString().padStart(2, '0')
}