import React, { Component } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {TextField, MenuItem} from '@material-ui/core'
import {ROLLS, U_ADMINISTRATEUR_ATHMO} from '../../Constants'
import {sLabelRoll} from '../../tools/LabelRoll'

class AvailableRoll extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    athmo: PropTypes.bool,
  }

  render() {

    const userIndex = ROLLS.indexOf(this.props.userRoll)
    let availables = []
    const excludeAthmo = this.props.athmo ? false : true
    
    ROLLS.forEach( (roll, index) => {
      if ( ( (roll !== U_ADMINISTRATEUR_ATHMO) && (index <= userIndex))
          || (!excludeAthmo && roll === U_ADMINISTRATEUR_ATHMO)
      ) {
        availables.push(roll)
      }
    })
    
    return (
      <TextField
          name={this.props.name} 
          select
          value={this.props.value}
          onChange={this.props.onChange} >
        {availables.map(roll => <MenuItem key={roll} value={roll}>{sLabelRoll(roll)}</MenuItem>)}
      </TextField>
    )
  }
}

const mapStateToProps = state => ({
    userRoll: state.identity.user.roles,
})
export default connect(mapStateToProps) (AvailableRoll)
