import * as act from '../actions/RegulationActions'

const INITIAL_STATE = {
    transports: null,
    actualCode: null,
}

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case act.REGULATION_RESET:
            return INITIAL_STATE

        case act.REGULATION_SET_PARAMETERS:
            return Object.assign({}, state, {
                transports: action.payload,
            })

        case act.REGULATION_ACTUAL_TRANSPORT:
            return Object.assign({}, state, {
                actualCode: action.payload,
            })

        default:
            return state;
    }
}

export default reducer