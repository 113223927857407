import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core'
import DayActivitiesHistogram from './DayActivitiesHistogram'

class MonthActivitiesHistogram extends Component {

	static = {
		data: PropTypes.array.isRequired,
		magnification: PropTypes.number.isRequired,
		onActivityClick: PropTypes.func,							/* fonction à utiliser lors du clic sur une activité */
		onActivityRightClick: PropTypes.func,					/* fonction à utiliser lors du clic droit sur une activité */
	}

	render() {
		
		const height = 45 * this.props.data.length
		return (
			<svg
					width={`${1500 * this.props.magnification}`}
					height={height}
					viewBox={`0 0 ${1500 / this.props.magnification} ${height}`}
					title="Activités du jour"
					style={this.props.classes.svg}
					>
				{this.props.data.map( (values, aDay) => {
					return (<DayActivitiesHistogram
						key={`day_${aDay}`}
						data={values}
						magnification={this.props.magnification} 
						yInitial={ (aDay - 1) * 45} 
						onClick={this.props.onActivityClick} 
						onRightClick={this.props.onActivityRightClick} />)
					}
				)}
				
			</svg>
		)
	}
}

const classes = {
	root: {
		height: '45px',
		width: '1280px',
		overflow:'scroll',
		borderStyle: 'outset',
		borderWidth: 'thin',
		padding: '1px',
	}
}
export default withStyles(classes) (MonthActivitiesHistogram)
