import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withStyles, Paper, Grid, Divider, Button,
        Typography, Switch, FormControlLabel, Toolbar, FormGroup, CircularProgress,
        } from '@material-ui/core'
import {DRIVER_TYPE} from '../Constants'
import NumberInput from '../forms/NumberInput';
import {getAlertsParameters, saveAlertsParametersToAPI} from '../../redux/actions/ClientActions'

class ElementsAlertsParameters extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
  }

  /**
   * Nombre de jours entre 2 déchargements
   */
  static legal = 255
  state = {
    changed: null,
    saving: false,
    newValues: {
      middleActive: true,
      middleDef: 255,
      middleLag: {
        first: 255,
        second: 255,
      },
      limitLag: {
        first: 255,
        second: 255,
      }
    }
  }
  
  componentDidMount() {
    this.props.getAlertsParameters(this.props.type)

    if (this.props.type === DRIVER_TYPE) {
      this.legal = 28
    } else {
      this.legal = 95
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if ( (this.props.params[this.props.type]) 
        && (this.props.params[this.props.type] !== null)
        && (prevProps.params !== this.props.params)
        && (this.state.changed !== true || this.state.saving)   /* hydratation après la sauvegarde pour ajuster tous les paramètres sauvegardés */
    ) {
      this.hydrate()
    }
  }
  handleChangeMiddle = (evt) => {
    
    let values
    const active = !this.state.newValues.middleActive

    /*
     * lors de l'activation des alertes de mi-période, 
     * on rédéfinit la valeur par défaut
     */
    if (active) {
      let middleDef = (this.state.newValues.middleDef === 255) ? Math.round(this.legal / 2) : this.state.newValues.middleDef
      values = Object.assign({}, this.state.newValues, {
        middleActive: true,
        middleDef: middleDef,
      })
      
    } else {
      values = Object.assign({}, this.state.newValues, {
        middleActive: false,
      })

    }

    this.setState({
      newValues: values,
      changed: true,
    })

  }

  handleChange = (newValue) => {

    const point = newValue.name.search(/[.]/g)
    let isNewValue = false
    let newValues

    if (point >= 0) {
      const names = newValue.name.split('.')
      const obj = this.state.newValues[names[0]] 

      obj[names[1]] = newValue.value

      newValues = Object.assign({}, this.state.newValues, {
        [names[0]]: obj,
      })
      isNewValue = newValue.value !== this.props.params[this.props.type][names[0]][names[1]]

    } else {
      newValues = Object.assign({}, this.state.newValues, {
        [newValue.name]: newValue.value,
      })
      isNewValue = newValue.value !== this.props.params[this.props.type][newValue.name].value
    }

    
    this.setState({
      newValues: newValues,
      changed: (this.state.changed === true || isNewValue)
    })

  }

  hydrate = () => {
    const params = this.props.params[this.props.type]
    this.setState({
      changed: false,
      saving: false,
      newValues: {
        middleActive: params.middleActive,
        middleDef: params.middleDef,
        middleLag: {
          first: params.middleLag.first,
          second: params.middleLag.second,
        },
        limitLag: {
          first: params.limitLag.first,
          second: params.limitLag.second,
        }
      }
    })
  }

  save = () => {
    this.setState({
      saving: true,
    })
    this.props.saveAlertsParametersToAPI(this.props.type, this.state.newValues)
  }
  render() {

    const title = (this.props.type === DRIVER_TYPE) ?
                    "Limite légale entre 2 déchargements d'une carte = 28 jours" :
                    "Limite légale entre 2 déchargements d'une mémoire de masse = 95 jours"

    return (
      <Paper className={this.props.classes.root}>
        <Typography 
                variant='h5'
                gutterBottom>
            {this.props.type === DRIVER_TYPE ? "Conducteurs" : "Véhicules"}
        </Typography>
        <Typography variant='body2'>{title}</Typography>
        
        { (this.props.loading && this.state.changed === null) ?
              <CircularProgress color="secondary" /> :
          <Grid container direction="column">
            <Grid item className={this.props.classes.inter}>
              <Typography>Décalage d'envoi des alertes de fin de période légale théorique</Typography>
                <FormGroup row>
                  <NumberInput
                    id="firstLimitLag"
                    name="limitLag.first"
                    label="1er envoi (N jours avant)"
                    onChange={this.handleChange}
                    value={this.state.newValues.limitLag.first}
                    min={this.state.newValues.limitLag.second + 1}
                    max={this.legal - this.state.newValues.middleDef}
                    disabled={this.props.loading}
                    />
                  <NumberInput 
                    id="secondLimitLag"
                    name="limitLag.second"
                    label="2e envoi (N jours avant)"
                    onChange={this.handleChange}
                    value={this.state.newValues.limitLag.second}
                    min={0}
                    max={this.state.newValues.limitLag.first - 1}
                    disabled={this.props.loading}
                    />
                </FormGroup>
            </Grid>
            <Grid item>
              <Divider variant="middle" className={this.props.classes.divider} />
              <FormControlLabel
                control={<Switch
                            checked={this.state.newValues.middleActive}
                            onChange={this.handleChangeMiddle} />}
                label={(this.state.newValues.middleActive) ? "Alertes à mi-période activées" : "Aucune alerte à mi-période"}
                />
            
              {this.state.newValues.middleActive && <>        
                <Grid item className={this.props.classes.inter} >
                  <NumberInput
                    id="middle"
                    name="middleDef"
                    label="Mi-période (en jours)"
                    onChange={this.handleChange}
                    value={this.state.newValues.middleDef}
                    min={0}
                    max={(this.props.type === DRIVER_TYPE) ? 26 : 93}
                    disabled={this.props.loading}
                    />
                </Grid>
                <Grid item className={this.props.classes.inter}>
                  <Typography>Décalage d'envoi des alertes de mi-période</Typography>
                  <FormGroup row>
                    <NumberInput
                      id="firstMiddleLag"
                      name="middleLag.first"
                      label="1er envoi (N jours avant)"
                      onChange={this.handleChange}
                      value={this.state.newValues.middleLag.first}
                      min={this.state.newValues.middleLag.second + 1}
                      max={this.state.newValues.middleDef}
                      disabled={this.props.loading}
                      />
                    <NumberInput 
                      id="secondAlertMiddle"
                      name="middleLag.second"
                      label="2e envoi (N jours avant)"
                      onChange={this.handleChange}
                      value={this.state.newValues.middleLag.second}
                      min={0}
                      max={this.state.newValues.middleLag.first - 1}
                      disabled={this.props.loading}
                      />
                  </FormGroup>
                </Grid>
              </>}
            </Grid>
            {this.state.changed && (
              this.props.loading ?
              <CircularProgress /> :
              <Toolbar className={this.props.classes.toolbar}>
                <Button 
                    variant="outlined"
                    onClick={this.hydrate}>
                  Annuler
                </Button>
                <Button 
                    variant="outlined"
                    onClick={this.save}>
                  Valider
                </Button>
              </Toolbar>
            )}
          </Grid>
        }
      </Paper>

    )
  }
}

const mapStateToProps = state => ({
  params: state.client.parameters,
  loading: state.data.loading,
})

const mapDispatchToProps = ({
  getAlertsParameters,
  saveAlertsParametersToAPI,
})

const classes = ({
  root: {
    padding: '0.5em'
  },
  inter: {
    marginTop: '1em',
  },
  divider: {
    marginTop: '1em',
    marginBottom: '0.5em'
  },
  toolbar: {
    marginBlockStart: '1em',
    justifyContent: 'space-around'
  }
})
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (ElementsAlertsParameters))
