import React, { Component } from 'react'
import {withStyles, Tooltip} from '@material-ui/core'
import Icon from '@material-ui/icons/Warning'
import PropTypes from 'prop-types'
import classNames from 'classnames'
class WarningIcon extends Component {

    static propTypes = {
        title: PropTypes.string,
    }
    render() {

        let txtInfo = (this.props.title) ? this.props.title : 'Information erronée'
        return (
        <Tooltip title={txtInfo} >
            <Icon className={classNames(this.props.classes.warn, this.props.className)} />
        </Tooltip>
        )
    }
}

const classes = theme => ({
    warn: {
        color: theme.palette.error.main,
    }
})

export default withStyles(classes) (WarningIcon)