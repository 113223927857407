import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ButtonOw from './ButtonOw'

import GraphicsIcon from '@material-ui/icons/Equalizer'

class GraphicsButton extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  render() {
    return (
        <ButtonOw {...this.props}>
            <GraphicsIcon />
        </ButtonOw> 
    )
  }
}

export default GraphicsButton
