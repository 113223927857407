import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EventIcon from '@material-ui/icons/Event'
import ButtonOw from './ButtonOw';
import {EVENT_TYPE} from '../Constants'

class EventButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string,
    eventType: PropTypes.string,
  }
  render() {
    
    const defaultTitle = (this.props.eventType === EVENT_TYPE.ABSENCE) ? "Ajout d'une absence" : "Ajout d'un événement"
    const title = this.props.title ? this.props.title : defaultTitle

    return (
      <ButtonOw 
            title={title}
            onClick={this.props.onClick} >
          <EventIcon />
      </ButtonOw> 
    )
  }
}

export default EventButton
