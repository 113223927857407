import React, { Component } from 'react'

/**
 * @todo supprimer le title au profit du tooltip dans DriverInfoICon et VehicleInfoIcon
 */
class FullIcon extends Component {

  render() {
    const {title, color, className, ...props} = this.props
    
    return (
      <div title={title} {...props} >
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
          <rect width="24" height="24" color={color} />
        </svg>
      </div>
    )
  }
}

export default FullIcon