import React, { Component } from 'react'
import {Tooltip} from '@material-ui/core'
import VehicleIcon from '@material-ui/icons/LocalShipping'

/**
 * Source = activité sans carte contenue dans chronotachygraphe
 * numéro = 8
 */
class VehicleSource extends Component {
  render() {
    return (
      <Tooltip title="Activité sans carte">
          <VehicleIcon />
      </Tooltip>
    )
  }
}

export default VehicleSource
