import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {DRIVER_TYPE, } from '../../Constants'
import {withStyles, Paper, Checkbox,
        TableContainer, Table, TableRow, TableCell, TableBody, TableHead,
        } from '@material-ui/core'

class ElementDataGrid extends Component {
  static propTypes = {
    elements: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,        /* DRIVER_TYPE || VEHICLE_TYPE */
    selection: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    size: PropTypes.string,
  }

  state = {
    selection: [],
    allSelected: false,
  }

  /**
   * liste des identifiants des éléments sélectionnés
   * @return {Number[]}
   */
  getSelected = () => this.state.selection

  componentDidMount() {
    this.setState({
      selection: this.props.selection,
    })
  }
  selectElement = (evt) => {
  
    const id = Number.parseInt(evt.target.value)
    let selection = this.state.selection
    evt.preventDefault()

    if (selection.includes(id)) {
      selection = selection.filter(value => value !== id)
    } else{
      selection.push(id)
    }
    
    this.setState({
      allSelected: false,
      selection: selection,
    })

    /*
     * Propagation de la sélection
     */
    this.props.onChange(this.props.type, selection)

  }

  changeAllSelection = () => {

    const allSelected = !this.state.allSelected
    let selection = []
    if (allSelected) {
      this.props.elements.map (row => selection.push(row.id))
    }
    this.setState({
      allSelected: allSelected,
      selection: selection,
    })

    /*
     * Propagation de la sélection
     */
    this.props.onChange(this.props.type, selection)

  }

  getHeaders = () => {
    return (
      <TableHead className={this.props.classes.head}>
        <TableRow>
          <TableCell>
            <Checkbox checked={this.state.allSelected} onClick={this.changeAllSelection} />
          </TableCell>
          
          {this.props.type === DRIVER_TYPE ?
            <>
              <TableCell>Nom</TableCell>
              <TableCell>Prénom</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Numéro de carte</TableCell>
            </> :
            <>
              <TableCell>Immatriculation</TableCell>
              <TableCell>Code</TableCell>
            </>
            }
        </TableRow>
      </TableHead>
    )
  }
  getSelectableRow = (row) => {
      
    return (
      <TableRow hover={true} key={`driver_${row.id}`}>
          <TableCell >
            <Checkbox checked={this.state.selection.includes(row.id)} value={row.id} onClick={this.selectElement} />
          </TableCell>
          
          {this.props.type === DRIVER_TYPE ? 
              <>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.forename}</TableCell>
                <TableCell>{row.drv_code} </TableCell>
                <TableCell>{row.card_number}</TableCell>
              </> :
              <>
                <TableCell>{row.veh_reg_no}</TableCell>
                <TableCell>{row.veh_code}</TableCell>
              </>
          }
      </TableRow>
    )
  }
  render() {
    
    return (
      <Paper>
        <TableContainer>
          <Table className={this.props.classes.root} size='small'>
            {this.getHeaders()}
            <TableBody>
              {this.props.elements.map(row => this.getSelectableRow(row))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  }
}

const classes = {
  root: {
  
  }, 
  head: {
    '&& th': {
      fontWeight: 'bold',
    }
  }
}
export default withStyles(classes) (ElementDataGrid)
