import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GraphicsButton from './GraphicsButton'
import TabButton from './TabButton'

export class TabOrGraphButton extends Component {
	static propTypes = {
		isGraphic: PropTypes.bool.isRequired,					/* est (ou non) l'affichage du bouton histogramme */
		onClick: PropTypes.func.isRequired,
	}

	render() {
		if (!this.props.isGraphic) {
			return (
				<GraphicsButton title="Mode histogramme" onClick={this.props.onClick} />
			)
		} else {
			return (
				<TabButton title="Mode tableau" onClick={this.props.onClick} />
			)
		}
	}
}

export default TabOrGraphButton
