import React, { Component } from 'react'
import PropTypes from 'prop-types'
import WithoutCardActDataGrid from './WithoutCardActDataGrid'
import AssignDialog from './AssignDialog'

class WithoutCard extends Component {
  static propTypes = {
    data: PropTypes.array,                  /* peut-être undefined, en particulier lors du chargement */
    dateRef: PropTypes.object.isRequired,
    code: PropTypes.string.isRequired,
  }

  state = {
    displayAssignedCard: false,
    editInfos: [],
  }

  handleCard = (selection) => {
    
    if (selection) {
      this.setState({
        editInfos: selection,
      })
    } 
    this.setState({
      displayAssignedCard: ! this.state.displayAssignedCard,
    })
    
    
  }
  render() {
    return (<>
        <WithoutCardActDataGrid 
            data={this.props.data} 
            dateRef={this.props.dateRef}
            code={this.props.code}
            onSelect={this.handleCard}
            />
        {this.state.displayAssignedCard &&
          <AssignDialog 
              handle={this.handleCard} 
              selectedAct={this.state.editInfos}
              code={this.props.code}
              />
        }
    </>)
  }
}

export default WithoutCard
